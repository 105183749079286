import { useFirestore, useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { startOfDay } from "date-fns";
import { collection, query, where } from "firebase/firestore";
import { ContainerContractDocument } from "tb-utils";
import { replaceTimestamps } from "@/helper/appUtils";

/** Get contracts from today by given date field */
export function useGetContracts(day: Date, dateField: "c1_date" | "c2_date", showOnlyMyContracts?: boolean) {
    const { data: userData } = useSigninCheck();

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const selectedDay = startOfDay(day);

    const contractsQuery = query(
        collection(useFirestore(), "companies", selectedCompanyId || "---", "container-contracts"),
        where(dateField, ">=", selectedDay),
        where(dateField, "<=", selectedDay),
    );
    /** `idFiled: "id"` is used by DataGrid */
    const { data, status } = useFirestoreCollectionData(contractsQuery, { idField: "doc_id" });
    const contractsData = replaceTimestamps<ContainerContractDocument<Date>[]>(data || []);

    contractsData?.map(contract => {
        /** Set unique `id` property for mui data-grid */
        // @ts-ignore
        contract.id = contract.doc_id + "/" + (dateField === "c1_date" ? "1" : "2");

        /**
         * Make sure the date field from the other delivery note is not set.
         * This is used to identify wich type (c1 or c2) should be displayed in the delivery overview table.
         */
        // TODO: Maybe remove this since it is possible to identify c1 and c2 by id suffix (abcde/1 and abcde/2)
        if (dateField === "c1_date") {
            // @ts-ignore
            delete contract?.c2_date;
        } else {
            // @ts-ignore
            delete contract?.c1_date;
        }

        return contract;
    });

    const filteredContracts = showOnlyMyContracts ?
        contractsData.filter(data => {
            return data[dateField === "c1_date" ? "c1_driver" : "c2_driver"] === userData?.user?.uid;
        })
        :
        contractsData;

    return {
        data: filteredContracts,
        status
    };
}