import { Typography } from "@mui/material";
import { appColors } from "@/helper/appColors";
import { css } from "@emotion/css";
import { PropsWithChildren } from "react";

const styles = {
    main: css({
        // border: "1px solid red"
        display: "flex",
        flexDirection: "column",
        gap: 6
    })
};


interface Props {
    description: string;
}

export function DeliveryNoteInfoTextBox(props: PropsWithChildren<Props>) {
    return <div className={styles.main}>
        <Typography variant="body2" fontWeight="500" color={appColors.blue.main}>
            {props.description}
        </Typography>

        <div>{props.children}</div>
    </div>;
}