import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { appLogger } from "@/helper/appLogger";
import { useAuthStore } from "@/stores/useAuthStore";
import { useState } from "react";
import { Alert } from "@mui/material";
import { useFindAllJoinedCompanies } from "@/customHooks/useFindAllJoinedCompanies";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";

/** https://firebase.google.com/docs/auth/admin/errors */
const loginErrorMessages: any = {
    "auth/user-not-found": "Der Benutzer mit dieser E-Mail Adresse wurde nicht gefunden",
    "auth/wrong-password": "Das Passwort ist falsch"
};

const FILE_NAME = "useLoginUser.ts";

export function useLoginUser() {
    const setIsSignedIn = useAuthStore(state => state.setIsSignedIn);
    const setSelectedCompanyId = useCompanyPersistStore(state => state.setSelectedCompanyId);
    const { findCompanyMemberDocs } = useFindAllJoinedCompanies();

    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = () => {
        setIsLoading(true);

        /** Reset error message */
        setErrorMessage("");

        const auth = getAuth();

        signInWithEmailAndPassword(auth, userEmail, userPassword)
            .then(async userCredential => {
                appLogger.state(FILE_NAME, "User login " + userEmail);
                setIsSignedIn(true);
                /** User will be auto redirected by useRedirectIfUserIsSignedIn.ts */
            })
            .catch(error => {
                /** Set error message text */
                const defaultMessage = "Ein Fehler ist aufgetreten";
                setErrorMessage(loginErrorMessages[error.code] || defaultMessage);

                appLogger.error(FILE_NAME, "Sign in error (firebase): " + error.code);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const ErrorMessageComponent = () => {
        if (errorMessage === "") return null;

        return <Alert
            icon={false}
            color="error"
            onClose={() => setErrorMessage("")}
        >
            {errorMessage}
        </Alert>;
    };

    const isLoginButtonDisabled = (
        userEmail.length < 5 ||
        userPassword.length <= 5 ||
        isLoading
    );

    return {
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        isLoading,
        handleLogin,
        ErrorMessageComponent,
        isLoginButtonDisabled
    }
}