import { useQueryParam } from "@/customHooks/useQueryParam";
import { useNavigate } from "react-router-dom";

export function useDialog() {
    const navigate = useNavigate();
    const { value, set, remove } = useQueryParam("dialog");

    return {
        getOpenDialog: value,
        setOpenDialog: (dialogName: string) => {
            console.log("useDialog.ts: setOpenDialog() is executed with dialogName:", dialogName);
            set(dialogName);
        },
        /**
         * IMPORTANT: Do not change the url (e.g. other query params) wile a dialog is open as the closeOpenDialog would
         * not properly work.
         * navigate(-1) is used because just removing the query param from the url would result in the user having to
         * click the browsers back-button twice to actually go to the previous page.
         */
        closeOpenDialog: () => {
            // console.log("useDialog.ts: closeOpenDialog() is executed (navigate(-1))");
            //navigate(-1);

            console.log("useDialog.ts: closeOpenDialog() is executed (remove())");
            remove();
        },
        // closeOpenDialog: () => remove(),
    };
}
