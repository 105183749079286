import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useEffect } from "react";
import { appRequest } from "@/helper/appRequest";
import { appLogger } from "@/helper/appLogger";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";

const FILE_NAME = "FirebaseGetCompanyMembers.tsx";

export function FirebaseGetCompanyMembers() {
    // const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const setMembers = useCompanyPersistStore(state => state.setMembers);

    const navigate = useNavigate();

    // /** Get firestore data to trigger api call if this data changes */
    // const companyMembersRef = collection(useFirestore(), "companies", selectedCompanyId || "---", "company-members");
    // const { data, status } = useFirestoreCollectionData(companyMembersRef, { idField: "doc_id" });

    /** Call api to get members data */
    useEffect(() => {
        // if (!data?.length) return;

        appRequest.getMembers()
            .then(result => {
                setMembers(result.members);
            })
            .catch(err => {
                appLogger.error(FILE_NAME, "Error getting members data from api: " + err);

                /** Logout if company/members returns id-token-revoked status */
                if (err.response?.data.code === "auth/id-token-revoked") {
                    appLogger.event(FILE_NAME, "Redirecting user to logout page...");
                    navigate(appPaths.logout);
                }
            });
        // }, [data]);
    }, []);

    return null;
}