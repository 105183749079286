import { appLogger, EventType, Logger } from "@/helper/appLogger";
import { useState } from "react";
import { useInterval } from "react-use";
import { css } from "@/helper/css";
import { Chip, Divider, Typography } from "@mui/material";
import format from "date-fns/format";
import { Duration, formatDistance, formatDuration, intervalToDuration } from "date-fns";

const styles = {
    main: css({
        userSelect: "text",
        paddingTop: 40,
        // border: "1px solid red",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        wordBreak: "break-all"
    }),
    table: css({
        // border: "1px solid blue",
        padding: "40px 0",
        borderSpacing: 0,
        // width: "50%",
        minWidth: "60%",
    }),
    tableData: css({
        padding: 14,
        borderRadius: 10,
    }),
    divider: css({
        height: 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: 10
    })
}

export function DesktopDevLoggerPage() {
    const [logData, setLogData] = useState<Logger>(appLogger.getLogs());
    const reversedLogs = [...logData.logs].reverse();

    useInterval(() => {
        setLogData(appLogger.getLogs());
    }, 500);

    /** Returns time (via timestamps) between current and last log entry */
    const getTimeToLastEntry = (currentIndex: number) => {
        /** Returns next array index. If last index, return current index */
        const tryToGetNextIndex = () => {
            const nextIndex = currentIndex + 1;
            if (nextIndex < reversedLogs.length) return nextIndex;
            return currentIndex;
        };
        return intervalToDuration({
            start: new Date(reversedLogs[currentIndex].timeStamp),
            end: new Date(reversedLogs[tryToGetNextIndex()].timeStamp),
        });
    };

    const isDurationGreaterThanXSeconds = (duration: Duration, seconds: number) => {
        return !!(
            (duration.years && duration.years > 0) ||
            (duration.months && duration.months > 0) ||
            (duration.weeks && duration.weeks > 0) ||
            (duration.days && duration.days > 0) ||
            (duration.hours && duration.hours > 0) ||
            (duration.minutes && duration.minutes > 0) ||
            (duration.seconds && duration.seconds > seconds)
        );
    };

    return <div style={styles.main}>
        <Typography>
            Last {logData.logs.length} logs (new to old, auto refresh)
        </Typography>

        <div style={styles.table}>
            {reversedLogs.map((log, index) => {
                const durToNextEntry = getTimeToLastEntry(index);
                const showDivider = isDurationGreaterThanXSeconds(durToNextEntry, 20);

                return <div key={"" + index + log.timeStamp}>
                    <div
                        style={{
                            ...styles.tableData,
                            background: index % 2 === 1 ? "#fff" : "#0001"
                        }}
                    >
                        <StatusChip type={log.type}/>
                        &nbsp;
                        <Chip size="small" variant="outlined" label={log.location}/>
                        &nbsp;
                        <Typography variant="caption">
                            {format(new Date(log.timeStamp), "HH:mm:ss")}
                        </Typography>
                        &nbsp;-&nbsp;
                        <Typography variant="caption">
                            {formatDistance(
                                new Date(log.timeStamp),
                                new Date(),
                                { addSuffix: true }
                            )}
                        </Typography>
                        <Typography>{log.message}</Typography>
                    </div>

                    {showDivider &&
                        <div style={styles.divider}>
                            <Typography variant="caption">
                                {formatDuration(durToNextEntry)}
                            </Typography>
                            <Divider style={{ flex: 1 }}/>
                        </div>
                    }
                </div>
            })}
        </div>
    </div>;
}

interface StatusChipProps {
    type: EventType;
}

function StatusChip(props: StatusChipProps) {
    return <>
        {props.type === "ERROR" && <Chip size="small" label="error" color="error"/>}
        {props.type === "EVENT" && <Chip size="small" label="event" color="secondary"/>}
        {props.type === "INFO" && <Chip size="small" label="info" color="info"/>}
        {props.type === "STATE" && <Chip size="small" label="state" color="warning"/>}
    </>;
}