import { isMobile } from "react-device-detect";
import { M_MainFrame, M_MainFrameProps } from "@/mobile/components/M_MainFrame";
import { PropsWithChildren } from "react";
import { DesktopMainFrame, DesktopMainFrameProps } from "@/desktop/components/DesktopMainFrame";
import { M_ContentSection, M_ContentSectionProps } from "@/mobile/components/M_ContentSection";

interface Props {
    mobile?: M_MainFrameProps;
    mobileContentSectionProps?: M_ContentSectionProps;
    mobileNoContentSection?: boolean;
    desktop?: DesktopMainFrameProps;
}

export function MainFrame(props: PropsWithChildren<Props>) {
    return isMobile ?
        <M_MainFrame {...props.mobile}>
            {/** Render a default mobile content section to get some spacing without to use M_ContentSection manually */}
            {props.mobileNoContentSection ?
                props.children :
                <M_ContentSection
                    children={props.children}
                    {...props.mobileContentSectionProps}
                />
            }
        </M_MainFrame>
        :
        <DesktopMainFrame {...props.desktop}>
            {props.children}
        </DesktopMainFrame>;
}