import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";
import { MemberInterface } from "@/helper/appRequest";

type DrivingJobsTabs = "all" | "my_jobs"

interface CompanyPersistStore {

    /** The selected tab */
    drivingJobsActiveTab: DrivingJobsTabs;
    setDrivingJobsActiveTab: (newValue: DrivingJobsTabs) => void;

}

export const useContainerPersistStore = create<CompanyPersistStore>()(devtools(persist(
    set => ({

        drivingJobsActiveTab: "all",
        setDrivingJobsActiveTab: newValue => set({
            drivingJobsActiveTab: newValue
        }),

    }),
    {
        name: appLocalStorageNames.containerPersistStore
    }
)));