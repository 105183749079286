import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useDialog } from "@/customHooks/useDialog";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Alert, Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addDays, differenceInYears, isValid } from "date-fns";
import { appDialogNames } from "@/helper/appDialogNames";
import { serverTimestamp } from "firebase/firestore";
import { appLogger } from "@/helper/appLogger";
import { SimpleDialog } from "@/common/SimpleDialog";
import { CustomerFileEntryDocument } from "tb-utils";
import { useQueryParam } from "@/customHooks/useQueryParam";

const FILE_NAME = "CFEntryFormDialog.tsx";

interface Inputs {
    date: Date;
    treatment: string;
    exposureTime: string;
    recipe: string;
    followUpTreatment: string;
    price: string;
    remarks: string;
}

const inputsDefault: Inputs = {
    date: new Date(),
    treatment: "",
    exposureTime: "",
    recipe: "",
    followUpTreatment: "",
    price: "",
    remarks: "",
};

interface Props {
    entries: CustomerFileEntryDocument<Date>[];
}

const thisDialogName = appDialogNames.customerFile.addItem;

export function CFEntryFormDialog(props: Props) {
    const { getOpenDialog } = useDialog();
    const editEntryQuery = useQueryParam("edit-entry");
    const { writeCustomerFile } = useFirestoreWrite();
    const { closeOpenDialog } = useDialog();
    const { customerId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [creationError, setCreationError] = useState("");
    const [inputs, setInputs] = useState<Inputs>(inputsDefault);

    /** True if edit mode, false if create/add mode */
    const isEditMode = useMemo(() => !!editEntryQuery.value, [editEntryQuery.value]);

    const selectedEntry = useMemo(() => {
        return props.entries?.find(e => e.id === editEntryQuery.value);
    }, [props.entries, editEntryQuery.value]);

    function updateInputValue<T extends keyof Inputs>(key: T, value: Inputs[T]) {
        setInputs({
            ...inputs,
            [key]: value,
        });
    }

    function handleSubmit() {
        setIsLoading(true);
        setCreationError("");

        if (isEditMode) {
            if (!editEntryQuery.value) return;
            writeCustomerFile.updateEntry(editEntryQuery.value, {
                date: inputs.date,
                treatment: inputs.treatment,
                price: inputs.price,
                recipe: inputs.recipe,
                follow_up_treatment: inputs.followUpTreatment,
                exposure_time: inputs.exposureTime,
                remarks: inputs.remarks,
            })
                .then(() => {
                    closeOpenDialog();
                    appLogger.event(FILE_NAME, "Successfully updated customer-file entry: " + editEntryQuery.value);
                })
                .catch(err => {
                    appLogger.error(FILE_NAME, "Error updating customer-file entry " + editEntryQuery.value + ": " + JSON.stringify(err));
                    setCreationError("Fehler beim Erstellen: " + JSON.stringify(err));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            writeCustomerFile.addEntry({
                customer_id: customerId || "",
                date: inputs.date,
                treatment: inputs.treatment,
                price: inputs.price,
                recipe: inputs.recipe,
                follow_up_treatment: inputs.followUpTreatment,
                exposure_time: inputs.exposureTime,
                remarks: inputs.remarks,
                created_at: serverTimestamp(),
            })
                .then(() => {
                    closeOpenDialog();
                    appLogger.event(FILE_NAME, "Successfully created new customer-file entry");
                })
                .catch(err => {
                    appLogger.error(FILE_NAME, "Error creating new customer-file entry: " + JSON.stringify(err));
                    setCreationError("Fehler beim Erstellen: " + JSON.stringify(err));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    /** When the dialog is opened in the edit-mode: Set inputs to selected entry data */
    useEffect(() => {
        if (editEntryQuery.value && selectedEntry !== undefined) {
            setInputs({
                date: selectedEntry.date,
                treatment: selectedEntry.treatment,
                exposureTime: selectedEntry.exposure_time,
                recipe: selectedEntry.recipe,
                followUpTreatment: selectedEntry.follow_up_treatment,
                price: selectedEntry.price,
                remarks: selectedEntry.remarks
            });
        }
    }, [editEntryQuery.value]);

    /** When the dialog is opened in the creation/add mode: Set inputs to default values */
    useEffect(() => {
        if (getOpenDialog === thisDialogName) {
            setInputs(inputsDefault);
        }
    }, [getOpenDialog]);

    return <SimpleDialog
        name={thisDialogName}
        show={!!editEntryQuery.value}
        title={isEditMode ?
            "Eintrag bearbeiten" :
            "Einen Eintrag erstellen"
        }
        disableBackdropClick
        fullscreenLoading={{ loading: isLoading }}
        actionButtons={[
            {
                text: "Abbrechen",
                closeDialogOnClick: true,
            },
            {
                text: isEditMode ? "Speichern" : "Erstellen",
                onClick: handleSubmit,
            }
        ]}
    >
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DatePicker
                    label="Datum"
                    inputFormat="dd.MM.yyyy"
                    minDate={new Date("01.01.2020")}
                    maxDate={addDays(new Date, 30)}
                    disableFuture
                    onChange={event => {
                        if (isValid(event) && event) {
                            console.log(">>", differenceInYears(new Date(), event));
                            updateInputValue("date", event);
                        }
                    }}
                    value={inputs.date}
                    renderInput={e => <TextField {...e} fullWidth/>}
                    closeOnSelect
                    showToolbar={false}
                    InputProps={{
                        autoComplete: "off",
                        onBlur: () => {
                            /** Reset date if date is e.g. empty */
                            if (isValid(inputs.date) && differenceInYears(new Date(), inputs.date) > 10) {
                                updateInputValue("date", inputsDefault.date);
                            }
                        }
                    }}
                    OpenPickerButtonProps={{
                        hidden: false
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Behandlung"
                    autoComplete="off"
                    value={inputs.treatment}
                    onChange={e => updateInputValue("treatment", e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Einwirkzeit"
                    autoComplete="off"
                    value={inputs.exposureTime}
                    // onChange={e => setExposureTime(e.target.value)}
                    onChange={e => updateInputValue("exposureTime", e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Rezeptur"
                    autoComplete="off"
                    value={inputs.recipe}
                    // onChange={e => setRecipe(e.target.value)}
                    onChange={e => updateInputValue("recipe", e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Nachbehandlung"
                    autoComplete="off"
                    value={inputs.followUpTreatment}
                    // onChange={e => setFollowUpTreatment(e.target.value)}
                    onChange={e => updateInputValue("followUpTreatment", e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Preis"
                    value={inputs.price}
                    autoComplete="off"
                    // onChange={e => setPrice(e.target.value)}
                    onChange={e => updateInputValue("price", e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Bemerkungen"
                    autoComplete="off"
                    multiline
                    value={inputs.remarks}
                    // onChange={e => setRemarks(e.target.value)}
                    onChange={e => updateInputValue("remarks", e.target.value)}
                />
            </Grid>
            {!!creationError &&
                <Grid item xs={12}>
                    <Alert icon={false} color="error">{creationError}</Alert>
                </Grid>
            }
        </Grid>
    </SimpleDialog>;
}
