import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import {
    M_DayElement
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_DayOverview/M_DayElement";
import {
    M_OverviewButtonSection
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_OverviewButtonSection";
import { useQueryParam } from "@/customHooks/useQueryParam";
import format from "date-fns/format";
import { addDays, isThisYear, isToday, isTomorrow, isYesterday, parse, subDays } from "date-fns";

export function M_DayOverview() {
    const dateFormat = "dd.MM.yyyy";

    const { set: setSelectedDateString, value: selectedDateString } = useQueryParam(
        "date",
        format(new Date(), dateFormat)
    );
    const selectedDate = {
        value: parse(selectedDateString || "", dateFormat, new Date()),
        update: (date: Date) => setSelectedDateString(format(date, dateFormat)),
    };

    const getText = () => {
        const yesterdayIndicator = isYesterday(selectedDate.value) ? "Gestern" : "";
        const todayIndicator = isToday(selectedDate.value) ? "Heute" : "";
        const tomorrowIndicator = isTomorrow(selectedDate.value) ? "Morgen" : "";

        const formatTemplate = isThisYear(selectedDate.value) ? "dd.MM." : "dd.MM.yyyy";
        const displayDate = format(selectedDate.value, formatTemplate);
        return [
            yesterdayIndicator,
            todayIndicator,
            tomorrowIndicator,
            displayDate
        ].join(" ");
    };

    return <M_ContentSection>
        <M_OverviewButtonSection
            showNextClick={() => selectedDate.update(addDays(selectedDate.value, 1))}
            showPrevClick={() => selectedDate.update(subDays(selectedDate.value, 1))}
            showTodayClick={() => selectedDate.update(new Date())}
            text={getText()}
            isToday={isToday(selectedDate.value)}
        />

        <M_DayElement/>
    </M_ContentSection>;
}