import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useStorage } from "reactfire";
import { deleteObject, ref, uploadString } from "firebase/storage";

export function useFirebaseStorage() {
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const storage = useStorage();

    const storageRefs = {
        /** Container */
        drivingJobSignature: (imgId: string) => ref(storage, `companies/${selectedCompanyId}/driving-job-signatures/${imgId}`),
    };

    /**
     * Upload driving job signature image
     * @param imgId image UUID
     * @param dataUrl The image in dataUrl format
     */
    async function uploadDrivingJobSignature(imgId: string, dataUrl: string) {
        return uploadString(storageRefs.drivingJobSignature(imgId), dataUrl, "data_url");
    }

    /**
     * Delete driving job signature image
     * @param imgId image UUID
     */
    async function deleteDrivingJobSignature(imgId: string) {
        return deleteObject(storageRefs.drivingJobSignature(imgId));
    }

    return {
        storageRefs,

        /** Container */
        uploadDrivingJobSignature,
        deleteDrivingJobSignature,
    };
}