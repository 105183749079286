import {
    Alert,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { css } from "@emotion/css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { nanoid } from "nanoid";
import { CustomerFileCustomer, CustomerFilesDocument } from "tb-utils";
import { useFirestoreDocData } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { appLogger } from "@/helper/appLogger";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { appPaths } from "@/helper/appPaths";

const FILE_NAME = "CFCreateOrEditPage.tsx";

const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "flex-start",
    }),
    form: css({
        position: "relative",
        width: "min(100%, 500px)"
    })
};

interface Props {
    mode: "create" | "edit";
}

export function CFCreateOrEditPage(props: Props) {
    const navigate = useNavigate();
    const { customerId } = useParams();
    const { docRefs, writeCustomerFile } = useFirestoreWrite();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthDate, setBirthDate] = useState<Date | null>(null);
    const [unknownBirthYear, setUnknownBirthYear] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState("");

    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [showCreationError, setShowCreationError] = useState(false);
    const [showError, setShowError] = useState("");

    /** Get current user data if mode is set to "edit" */
    const { data: filesData, status: filesStatus } = useFirestoreDocData(docRefs.customerFile("customer_files_001"));
    const customerFilesData = replaceTimestamps<CustomerFilesDocument<Date>>(filesData);
    const currentCustomer = useMemo<CustomerFileCustomer<Date> | null>(() => {
        if (filesStatus !== "success") return null;
        const findSelectedCustomer = customerFilesData.customers.find(c => c.id === customerId);
        return findSelectedCustomer || null;
    }, [filesData, filesStatus, customerId]);

    /** Set current user data as input-values once */
    const setCurrentInputData = () => {
        setFirstName(currentCustomer?.first_name || "");
        setLastName(currentCustomer?.last_name || "");
        setPhoneNumber(currentCustomer?.phone_number || "");
        // @ts-expect-error
        setBirthDate(currentCustomer?.birth_date?.toDate() || null);
        setUnknownBirthYear(currentCustomer?.unknown_birth_year || false);
        setAdditionalInfo(currentCustomer?.additional_info || "");
    };
    useEffect(() => {
        setCurrentInputData();
    }, [customerFilesData]);

    const handleCustomerFileCreate = () => {
        setIsCreationLoading(true);
        setShowCreationError(false);

        writeCustomerFile.addCustomer({
            id: nanoid(20),
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            birth_date: birthDate,
            unknown_birth_year: unknownBirthYear,
            additional_info: additionalInfo,
            created_at: new Date(),
            updated_at: new Date()
        })
            .then(value => {
                navigate(-1);
            })
            .catch(err => {
                setShowCreationError(true);
                console.log("errr", err);
            })
            .finally(() => {
                setIsCreationLoading(false);
            });
    };

    const handleCustomerUpdate = () => {
        setIsCreationLoading(true);

        writeCustomerFile.updateCustomer(customerId || "", {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            birth_date: birthDate,
            unknown_birth_year: unknownBirthYear,
            additional_info: additionalInfo,
            updated_at: new Date()
        })
            .then(() => {
                navigate(-1);
            })
            .catch(err => {
                appLogger.error(FILE_NAME, "Error updating customer: " + JSON.stringify(err));
            })
            .finally(() => {
                setIsCreationLoading(false);
            });
    };

    const handleHideCustomer = () => {
        setShowError("");
        writeCustomerFile.changeCustomerVisibility(customerId || "", true)
            .then(() => navigate(appPaths.cf.main))
            .catch(e => {
                console.error(e);
                setShowError("Unerwarteter Fehler. Kunde konnte nicht ausgeblendet werden.");
            });
    };

    const handleDeleteCustomer = () => {
        setShowError("");
        writeCustomerFile.deleteCustomer(customerId || "")
            .then(() => navigate(appPaths.cf.main))
            .catch(e => {
                console.error(e);
                setShowError("Unerwarteter Fehler. Kunde konnte nicht gelöscht werden.")
            });
    };

    return <>
        <div className={styles.content}>
            <Button
                startIcon={<ArrowBackIcon/>}
                variant="text"
                onClick={() => navigate(-1)}
            >
                Zurück
            </Button>


            {filesStatus === "loading" ?
                <Skeleton width={300} variant="rounded" height={20}/>
                :
                <Typography variant="h3">
                    {props.mode === "create" ?
                        "Einen neuen Kunden anlegen" :
                        `${currentCustomer?.first_name} ${currentCustomer?.last_name} Bearbeiten`
                    }
                </Typography>
            }

            <div className={styles.form}>
                {filesStatus === "loading" ?
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Skeleton width="100%" variant="rounded" height={40}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton width="100%" variant="rounded" height={40}/>
                        </Grid>
                        <Grid item xs={7}>
                            <Skeleton width="100%" variant="rounded" height={40}/>
                        </Grid>
                        <Grid item xs={5}>
                            <Skeleton width="100%" variant="rounded" height={40}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton width="100%" variant="rounded" height={40}/>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Vorname"
                                fullWidth
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Nachname"
                                fullWidth
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Telefon"
                                fullWidth
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row">
                                <DatePicker
                                    label="Geburtstag"
                                    onChange={event => {
                                        if (isValid(event) && event) {
                                            setBirthDate(event);
                                        }
                                    }}
                                    value={birthDate}
                                    renderInput={e => <TextField {...e} fullWidth/>}
                                    inputFormat={unknownBirthYear ? "dd.MM." : "dd.MM.yyyy"}
                                />
                                <IconButton onClick={() => setBirthDate(null)}>
                                    <CloseIcon/>
                                </IconButton>
                            </Stack>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    checked={!unknownBirthYear}
                                    onChange={e => setUnknownBirthYear(!e.target.checked)}
                                />}
                                label="Inklusive Jahr"
                            />


                            {/*<TextField*/}
                            {/*    disabled*/}
                            {/*    label="Geburtstag"*/}
                            {/*    fullWidth*/}
                            {/*    // value={props.data.birthDate}*/}
                            {/*    // onChange={e => updateFormValue("birthDate", e.target.value)}*/}
                            {/*/>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Zusätzliche Infos"
                                fullWidth
                                multiline
                                value={additionalInfo}
                                onChange={e => setAdditionalInfo(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                }
            </div>

            {showCreationError &&
                <Alert
                    color="error"
                    icon={false}
                    onClose={() => setShowCreationError(false)}
                >
                    Unerwarteter Fehler. Kunde konnte nicht angelegt werden.
                </Alert>
            }

            {!!showError.length &&
                <Alert
                    color="error"
                    icon={false}
                    onClose={() => setShowError("")}
                >
                    {showError}
                </Alert>
            }

            <Button
                disabled={!(!!firstName || !!lastName) || filesStatus === "loading"}
                onClick={props.mode === "create" ? handleCustomerFileCreate : handleCustomerUpdate}
            >
                {props.mode === "create" ? "Kunde anlegen" : "Kunde aktualisieren"}
            </Button>

            {/** Hide or delete customer */}
            {props.mode === "edit" &&
                <div className={css({ display: "flex", flexDirection: "column", gap: 10, alignItems: "start" })}>
                    <Button
                        size="small"
                        variant="text"
                        onClick={handleHideCustomer}
                    >
                        Kunde Ausblenden
                    </Button>
                    <Button
                        size="small"
                        variant="text"
                        color="error"
                        onClick={handleDeleteCustomer}
                    >
                        Kunde Löschen
                    </Button>
                </div>
            }
        </div>

        {isCreationLoading &&
            <LoadingSpinnerWithBackdrop/>
        }
    </>;
}
