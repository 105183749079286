import { getDay } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { ContainerContractDocument, DeliveryNote } from "tb-utils";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import RedoIcon from "@mui/icons-material/Redo";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import format from "date-fns/format";

export function safeFormatDate(date: Date | null | undefined, fmt: string = "dd.MM.yyyy"): string {
    if (!date) return "";
    return format(date, fmt);
}


/** Same as getDay() but first day is monday */
export function tbGetDay(date: Date | number): number {
    const dayNumber = getDay(date) - 1;
    return dayNumber === -1 ? 6 : dayNumber;
}

/** (Use cx() form @emotion/css) Chain class names */
export function tbClasses(...className: (string | undefined)[]): string {
    return className.filter(c => c).join(" ");
}

/** Force a number to be at least two digits */
export function twoDigitNumber(number: number): string {
    return number < 10 ? "0" + number : number.toString();
}

export function stringToNumber(str: string): number {
    if (!isNaN(Number(str)) && !isNaN(parseFloat(str))) {
        return parseFloat(str);
    }
    return NaN;
}

/**
 * Find and replace Firestore timestamps with js dates.
 * Inspired from: https://medium.com/@peterkracik/firebase-timestamp-to-javascript-date-format-876a42978c10
 */
export function replaceTimestamps<T>(val: any) {
    const findAndReplaceTimestamp = (obj: any) => {
        /** If value is not an object, just return current value */
        if (!obj) return obj;

        for (const [key, value] of Object.entries(obj)) {
            if (value && value.hasOwnProperty("seconds")) {
                obj[key] = (value as Timestamp).toDate();
            }
        }
        return obj as T;
    };

    return Array.isArray(val) ?
        val.map(obj => findAndReplaceTimestamp(obj)) as T :
        findAndReplaceTimestamp(val) as T;
}

/**
 * Build delivery-note from container-contract
 */
export function buildDeliveryNote<DateType>(
    contract: ContainerContractDocument<DateType>,
    contractType: number,
    c1?: boolean,
    // deliveryId: string // contract id with prefix `/1` or `/2`
): DeliveryNote<DateType> {
    const isC1 = c1 ?? contractType === 1;
    return {
        contractType: contractType,
        raw: contract,

        created_at: isC1 ? contract.c1_created_at : contract.c2_created_at,
        created_by: isC1 ? contract.c1_created_by : contract.c2_created_by,
        driver: isC1 ? contract.c1_driver : contract.c2_driver,
        number: isC1 ? contract.c1_number : contract.c2_number,
        date: isC1 ? contract.c1_date : contract.c2_date,
        time_of_day: isC1 ? contract.c1_time_of_day : contract.c2_time_of_day,
        cargo_info: isC1 ? contract.c1_cargo_info : contract.c2_cargo_info,
        recycler_and_others_text: isC1 ? contract.c1_recycler_and_others_text : contract.c2_recycler_and_others_text,
        info_text: isC1 ? contract.c1_info_text : contract.c2_info_text,
        waste_key: isC1 ? contract.c1_waste_key : contract.c2_waste_key,
        status: isC1 ? contract.c1_status : contract.c2_status,
        status_changed_by: isC1 ? contract.c1_status_changed_by : contract.c2_status_changed_by,

        // id: deliveryId || "",
        id: contract.doc_id + (isC1 ? "/1" : "/2")
    };
}

export const contractType = {
    /** Return name by type-number */
    name: (type: number) => ["liefern", "stellen und holen", "umsetzen", "abfahren"][type - 1],
    /** Return the type-name by given type-number (for c1) */
    c1Name: (type: number) => ["liefern", "stellen", "umsetzen", "abfahren"][type - 1],
    c2Name: () => "holen",
    /** Return c1 icons by type-number (for c1) */
    c1Icon: (type: number) => [LocalShippingIcon, KeyboardDoubleArrowDownIcon, RedoIcon, NorthEastIcon][type - 1],
    /** Return the "holen" icon (for c2) */
    c2Icon: () => KeyboardDoubleArrowUpIcon,
};


/**
 * CONTAINER MODULE:
 * Exact contract type helper
 */
export type ExactContractType = "delivery" | "place" | "fetch" | "umsetzen" | "abfahren" | undefined;

export function getExactContractType(type: number | undefined, isC1: boolean): ExactContractType {
    if (type === 1) return "delivery";
    if (type === 2) return isC1 ? "place" : "fetch";
    if (type === 3) return "umsetzen";
    if (type === 4) return "abfahren";
    return undefined;
}
