interface Props {
  stat: string;
  label: string;
  colorClass: string;
}

export function ContainerSimpleStat(props: Props) {
  return <div className={`${props.colorClass} w-72 rounded-lg p-4 min-w-60`}>
    <h1 className="text-4xl font-semibold pb-0.5">{props.stat}</h1>
    <p className="text-sm text-slate-500 pb-1">{props.label}</p>
    <h2 className="text-xl font-semibold">+30%</h2>
    <p className="text-sm text-slate-500">This month</p>
  </div>;
}
