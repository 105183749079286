import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { Alert, Button } from "@mui/material";
import { css } from "@emotion/css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { useSigninCheck } from "reactfire";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { useRegisterUser } from "@/customHooks/useRegisterUser";

const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: 10,
    }),
    buttonSection: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    }),
};

function useEmailVerification(
    setShowEmailNotVerifiedError: Dispatch<SetStateAction<string>>,
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    const { data: userData } = useSigninCheck();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    /** Check email verification on page load and if search param is set */
    useEffect(() => {
        const checkEmailVerification = searchParams.get("check_email_verification");

        /** Display loading spinner */
        if (checkEmailVerification) setIsLoading(true);

        if (userData?.user) {
            const isEmailVerified = userData?.user?.emailVerified;
            if (isEmailVerified) navigate(appPaths.setupPages.createOrJoinCompany);

            /** If verify button was clicked by user */
            if (checkEmailVerification && !isEmailVerified) {
                setShowEmailNotVerifiedError("Die E-Mail Adresse wurde noch nicht bestätigt. Die Bestätigungsmail sollte in Kürze in deinem Posteingang eintreffen.");
                setIsLoading(false);
            }
        }
    }, [location, userData?.user]);
}

export function SetupVerifyEmail() {
    const location = useLocation();
    const navigate = useNavigate();
    const { sendRegisterEmail } = useRegisterUser();

    const [showError, setShowError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hideResendButton, setHideResendButton] = useState(false);

    useEmailVerification(setShowError, setIsLoading);

    const handleResendMail = async () => {
        const errMsg = await sendRegisterEmail();
        if (errMsg) setShowError(errMsg);
        setHideResendButton(true);
    };

    /**
     * Reload page to refresh user auth data.
     * Append search param to check for email verification on next page load.
     */
    const handleCheckValidatedEmailClick = () => {
        setIsLoading(true);
        window.location.href = location.pathname + "?check_email_verification=1";
    };

    return <DesktopSetupMainFrame
        title="E-Mail bestätigen"
        description="Du hast eine E-Mail mit einem Bestätigungslink erhalten. Klicke auf diesen Link, um deine E-Mail-Adresse zu bestätigen."
        disableLogoutButton={isLoading}
    >

        {showError ?
            <Alert
                icon={false}
                color="error"
                onClose={() => setShowError("")}
            >
                {showError}
            </Alert>
            :
            <div/>
        }

        <div className={styles.buttonSection}>
            {!hideResendButton &&
                <Button variant="text" onClick={handleResendMail}>
                    Erneut senden
                </Button>
            }
            <div style={{ flex: 1 }}/>
            <Button onClick={handleCheckValidatedEmailClick}>
                E-Mail bestätigt
            </Button>
        </div>

        {isLoading &&
            <LoadingSpinnerWithBackdrop/>
        }
    </DesktopSetupMainFrame>;
}