import { DeliveryNoteChatInput } from "@/common/DeliveryNotePage/DeliveryNoteChatInput";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { DeliveryNoteMessagesDocument } from "tb-utils";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { css } from "@emotion/css";
import { Typography } from "@mui/material";
import { appImages } from "@/helper/appImages";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { useOnScreen } from "@/customHooks/useOnScreen";
import { usePrevious } from "react-use";
import { DeliveryNoteMessage } from "@/common/DeliveryNotePage/DeliveryNoteMessage";

const styles = {
    main: css({
        // border: " 1px solid red",
        position: "relative",
        paddingTop: 20
    })
};

export function DeliveryNoteChat() {
    const bottomChatDivRef = useRef<HTMLDivElement | null>(null);
    const isScrolledToBottom = useOnScreen(bottomChatDivRef);
    const { drivingJobId, contractNumber } = useParams();

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const deliveryNoteId = `${drivingJobId}/${contractNumber}`;

    const deliveryNoteMessageQuery = query(
        collection(useFirestore(), "companies", selectedCompanyId, "delivery-note-messages"),
        where("delivery_note_id", "==", deliveryNoteId),
        orderBy("created_at", "asc"),
        limit(200)
    );

    const { data, status } = useFirestoreCollectionData(deliveryNoteMessageQuery, { idField: "doc_id" });
    const messageData = replaceTimestamps<DeliveryNoteMessagesDocument<Date>[]>(data || []);
    const prevMessageCount = usePrevious(messageData.length);

    const scrollToBottom = () => {
        bottomChatDivRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    /**
     * Scroll to bottom on new message
     * Do not scroll to bottom if prevMessageCount is 0 to prevent scroll on page load
     */
    useEffect(() => {
        if (prevMessageCount && isScrolledToBottom) {
            scrollToBottom();
        }
    }, [messageData, isScrolledToBottom, prevMessageCount]);

    return <div className={styles.main}>

        {messageData.length === 0 &&
            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", gap: 20, padding: "60px 0 30px"
            }}>
                <img src={appImages.undraw.chatting} style={{ height: 160 }} alt=""/>
                <Typography color="#666" fontStyle="italic">Es wurde noch keine Nachricht geschrieben.</Typography>
            </div>

        }

        {messageData.map((message, i) => (
            <DeliveryNoteMessage
                key={i}
                messageData={message}
                prevMessageData={i === 0 ? null : messageData[i - 1]}
                isFirstMessage={i === 0}
            />
        ))}

        <div
            ref={bottomChatDivRef}
            style={{ height: 1, position: "absolute", bottom: 0 }}
        />

        <DeliveryNoteChatInput
            isScrolledToBottom={isScrolledToBottom}
            scrollToBottom={scrollToBottom}
        />

        {status === "loading" &&
            <LoadingSpinnerWithBackdrop disableTransparentBackground/>
        }

    </div>;
}