import { css } from "@emotion/css";
import React, { useEffect, useMemo } from "react";
import { DriverReportContent } from "@/common/DriverReportsPage/DriverReportContent";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { NoDriverReport } from "@/common/DriverReportsPage/NoDriverReport";
import { useDriverReport } from "@/common/DriverReportsPage/useDriverReport";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { pdf } from "@react-pdf/renderer";
import { PdfDriverReportDocument } from "@/common/DriverReportsPage/PdfDriverReportDocument";
import format from "date-fns/format";
import { useFirebaseStorage } from "@/customHooks/useFirebaseStorage";
import { useGetStorageDownloadUrl } from "@/customHooks/useGetStorageDownloadUrl";
import { Button } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { getBlob } from "firebase/storage";

const FILE_NAME = "DriverReport.tsx";

const styles = {
    main: css({
        flex: 1
    }),
    loadingWrapper: css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        minHeight: 200
    })
};

export function DriverReport() {
    const { value: selectedReportId } = useQueryParam("report");
    const { driverReportData, deliveryNotes, isLoading } = useDriverReport();
    const members = useCompanyPersistStore(state => state.members);
    const pdfName = `${format(new Date(), "yyyy_MM_dd")} ${"Max Mustermann"} Tagesbericht`;

    const driver = useMemo(() => {
        return members.find(m => m.user_id === driverReportData.driver);
    }, [members, driverReportData]);

    useEffect(() => {
        console.log("driver", driver);
    }, [members]);

    const { storageRefs } = useFirebaseStorage();
    const { data: signatureImageUrl, status: signatureImageStatus } = useGetStorageDownloadUrl(
        storageRefs.drivingJobSignature(driverReportData.signature_image_id)
    );

    async function abc() {
        const a = await getBlob(storageRefs.drivingJobSignature(driverReportData.signature_image_id));
        return a;
    }

    const isReportIdValid = (
        !!selectedReportId &&
        !isLoading &&
        !!driverReportData?.doc_id
    );

    async function handlePrintPDF() {
        const getSignatureImage = async (): Promise<string> => {
            try {
                const signatureBlob = await getBlob(storageRefs.drivingJobSignature(driverReportData.signature_image_id));
                return URL.createObjectURL(signatureBlob);
            } catch (err) {
                console.error("getSignatureImage():", err);
                return "";
            }
        };

        // Create a PDF document
        const doc = <PdfDriverReportDocument
            driverReportData={driverReportData}
            data={{
                driver: driver?.displayName || "",
                pdfName,
                drivingJobs: deliveryNotes || [],
                signatureImageUrl: await getSignatureImage(),
            }}
        />;
        const asPdf = pdf(); // Creating an empty PDF object
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();

        // Create a Blob URL
        const blobURL = URL.createObjectURL(blob);

        // Open the Blob URL in a new window
        // const newWindow = window.open(blobURL);
        // newWindow?.print();

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobURL;
        document.body.appendChild(iframe);

        iframe.onload = () => {
            iframe.contentWindow?.print();
        };
    }

    return <div className={styles.main}>
        {isLoading ?
            <LoadingSpinnerWithBackdrop disableTransparentBackground/>
            :
            isReportIdValid ? <>
                    <DriverReportContent
                        driverReportData={driverReportData}
                        deliveryNotes={deliveryNotes}
                        signatureImageUrl={signatureImageUrl}
                        signatureImageStatus={signatureImageStatus}
                    />
                    <Button
                        sx={{ mt: "20px" }}
                        size="small"
                        onClick={handlePrintPDF}
                        startIcon={<PrintIcon/>}
                    >
                        Bericht drucken
                    </Button>
                </> :
                <NoDriverReport selectedReportId={selectedReportId}/>
        }

        {/*<PDFViewer style={{ width: "100%", height: "1000px", paddingTop: 20, border: "none" }}>*/}
        {/*    <PdfDriverReportDocument data={{*/}
        {/*        driver: "Max Mustermann",*/}
        {/*        date: new Date(),*/}
        {/*        pdfName,*/}
        {/*        drivingJobs: deliveryNotes || [],*/}
        {/*        signatureImageUrl: signatureImageUrl*/}
        {/*    }}/>*/}
        {/*</PDFViewer>*/}
    </div>;
}
