import { useLocation, useNavigate } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { useAuthStore } from "@/stores/useAuthStore";
import { useEffect } from "react";
import { appPaths } from "@/helper/appPaths";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "GlobalAutoSignOut.tsx";

/**
 * Auto sign out if no user is found in auth db
 * (but time-booking auth cookie is set to true)
 */
export function GlobalAutoSignOut() {
    const location = useLocation();
    const { status, data } = useSigninCheck();
    const navigate = useNavigate();
    const isSignedIn = useAuthStore(state => state.isSignedIn);

    useEffect(() => {
        const validUserDetected = status === "success" && !data.signedIn;
        const isLogoutPage = location.pathname.startsWith(appPaths.logout);
        const isAuthPage = (
            location.pathname.startsWith(appPaths.login) ||
            location.pathname.startsWith(appPaths.register)
        );

        if (
            validUserDetected &&
            /* !isSetupPage && */
            !isLogoutPage &&
            !isAuthPage &&
            isSignedIn
        ) {
            appLogger.info(FILE_NAME, "Auto sign out");
            navigate(appPaths.logout);
        }
    }, [status, data, isSignedIn]);

    return null;
}