import { useEffect } from "react";
import { useUserStore } from "@/stores/useUserStore";

export function useNotification() {
    const notificationPerms = useUserStore(state => state.notificationPerms);
    const updateNotificationPerms = useUserStore(state => state.updateNotificationPerms);

    /** Check current permission */
    useEffect(() => {
        updateNotificationPerms();
    }, []);

    /** Request for permission if not denied and return promise */
    async function requestPermission() {
        if (notificationPerms !== undefined && notificationPerms !== "denied") {
            return Notification.requestPermission()
                .then(() => {
                    updateNotificationPerms();
                    location.reload();
                });
        }
    }

    return {
        permission: notificationPerms,
        requestPermission,
    };
}