import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";

interface CisPersistStore {
    taxNumber: string;
    setTaxNumber: (taxNumber: string) => void;

    tableTitle: string;
    setTableTitle: (tableTitle: string) => void;
}


export const useCisPersistStore = create<CisPersistStore>()(devtools(persist(
    set => ({

        taxNumber: "",
        setTaxNumber: newValue => set({
            taxNumber: newValue
        }),

        tableTitle: "Friseurhandwerk Irina Kraft",
        setTableTitle: newValue => set({
            tableTitle: newValue
        }),

    }),
    {
        name: appLocalStorageNames.cisPersistStore
    }
)));
