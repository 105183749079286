import React, { useEffect, useState } from "react";
import { ContainerContractDocument } from "tb-utils";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useNavigate, useParams } from "react-router-dom";
import { appLogger } from "@/helper/appLogger";
import { replaceTimestamps } from "@/helper/appUtils";
import { ContainerContractFormular } from "@/common/ContainerContractPage/ContainerContractFormular";
import { MainFrame } from "@/common/MainFrame";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { Button } from "@mui/material";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { ConfirmCCDeletionDialog } from "@/common/ContainerContractPage/ConfirmCCDeletionDialog";

const FILE_NAME = "EditContainerContractPage.tsx";

export function EditContainerContractPage() {
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const { contractId } = useParams();
    const navigate = useNavigate();
    const { updateContainerContract } = useFirestoreWrite();
    const { setOpenDialog } = useDialog();

    const [localContractData, setLocalContractData] = useState<Partial<ContainerContractDocument<Date>>>({});
    const [billingAddressIsEqual, setBillingAddressIsEqual] = useState(true);
    const [c1Checked, setC1Checked] = useState(false);
    const [c2Checked, setC2Checked] = useState(false);
    const [isDbLoading, setIsDbLoading] = useState(true);
    const [isRequestLoading, setIsRequestLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    const contractRef = doc(useFirestore(), "companies", selectedCompanyId || "-", "container-contracts", contractId || "-");
    const { data, status } = useFirestoreDocData(contractRef, { idField: "doc_id" });

    const contractData = replaceTimestamps<ContainerContractDocument<Date>>(data);

    useEffect(() => {
        setLocalContractData(contractData || {});

        if (contractData?.c1_ba_recipient) setBillingAddressIsEqual(false);
        if (contractData?.c1_number) setC1Checked(true);
        if (contractData?.c2_number) setC2Checked(true);
        if (status !== "loading") setIsDbLoading(false);
    }, [contractData, status]);

    /**
     * Compare 2 objects and return all changed keys with new values
     * https://stackoverflow.com/a/37396358/10433038 (in comments)
     */
    const changedFields: Partial<ContainerContractDocument<Date>> = Object.fromEntries(
        Object.entries(localContractData || {}).filter(([k, v]) => (contractData as any)[k] !== v)
    );

    const isUpdateButtonDisabled = !!Object.keys(changedFields).length;

    const handleUpdateContractClick = () => {
        setIsRequestLoading(true);

        updateContainerContract(contractData, changedFields)
            .then(result => {
                appLogger.event(FILE_NAME, "Successfully updated container contract " + contractId);
                navigate(-1); // TODO: When the user opens this page directly via url, and then clicks the submit button, the user will get navigated outside the webapp
            })
            .catch(err => {
                appLogger.error(FILE_NAME, `Error while updating container contract (${contractId}):` + err.code + " " + err.message);
                setErrorText("Beim Aktualisieren ist ein Fehler aufgetreten");
                console.log(err);
            })
            .finally(() => {
                setIsRequestLoading(false);
            });
    };

    return <MainFrame>
        <ContainerContractFormular
            formTitle="Auftrag bearbeiten"

            contractData={localContractData}
            setContractData={setLocalContractData}

            billingAddressIsEqual={billingAddressIsEqual}
            setBillingAddressIsEqual={setBillingAddressIsEqual}

            c1Checked={c1Checked}
            setC1Checked={setC1Checked}

            c2Checked={c2Checked}
            setC2Checked={setC2Checked}

            isLoading={isDbLoading || isRequestLoading}

            submitButtonText="Auftrag aktualisieren"
            handleSubmitClick={handleUpdateContractClick}

            submitButtonTooltip={!isUpdateButtonDisabled ? "Es wurden keine Änderungen vorgenommen" : ""}
            isSubmitButtonDisabled={!isUpdateButtonDisabled}

            errorText={errorText}
            setErrorText={setErrorText}

            optionalButtons={<>
                <Button
                    color="error"
                    variant="text"
                    onClick={() => setOpenDialog(appDialogNames.container.confirmCCDeletion)}
                >
                    Auftrag löschen
                </Button>
            </>}
        />

        <ConfirmCCDeletionDialog contract={contractData}/>
    </MainFrame>;
}