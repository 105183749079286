import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { css } from "@/helper/css";
import { appStyles } from "@/helper/appStyles";
import { appColors } from "@/helper/appColors";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";
import {useMeasure} from 'react-use';

const styles = {
    daysLeftBox: css({
        background: appColors.backgroundGray.main,
        height: 80,
        borderRadius: appStyles.borderRadius.normal,
        position: "relative",
        overflow: "hidden",
    }),
    daysLeftProgressBar: css({
        // background: appStyles.backgroundCircleColor.normal,
        background: appColors.blue.main,
        boxShadow: "-5px 0 15px 0 " + appColors.blue.main,
        left: 0,
        top: 0,
        position: "absolute",
        height: "100%",
        width: "50%",
        overflow: "hidden",
    }),
    textarea: css({
        position: "absolute",
        top: 0,
        right: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        paddingRight: 20,
    })
};

export function MobileVacationOverview() {
    const [progressBarRef, { width: progressBarWidth }] = useMeasure<HTMLDivElement>();

    return <M_ContentSection title="Urlaubsplanung" isPageTitle>
        <div ref={progressBarRef} style={styles.daysLeftBox}>

            <TextSection color="#555"/>

            <motion.div
                style={styles.daysLeftProgressBar}
                transition={{ duration: 1 }}
                initial={{ width: "0%", opacity: 0 }}
                animate={{ width: "65%", opacity: 1 }}
            >
                <div 
                    style={{
                        position: "absolute",
                        width: progressBarWidth,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0
                    }}
                >
                    <TextSection color="#fff"/>
                </div>
                
            </motion.div>

        </div>
    </M_ContentSection>
}

interface TextSectionProps {
    color?: string;
}

function TextSection(props: TextSectionProps) {
    return <div style={{ ...styles.textarea, color: props.color }}>
        <Typography variant="h1" noWrap>7 Tage</Typography>
        <Typography variant="body2" noWrap>von 22 verbleibend</Typography>
    </div>
}