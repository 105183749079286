/**
 * Static data,..
 */
import { GridLocaleText } from "@mui/x-data-grid";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";

export const dataGridDefaultLocales: Partial<GridLocaleText> = {
    columnMenuSortAsc: "Aufsteigend sortieren",
    columnMenuSortDesc: "Absteigend sortieren",
    columnMenuUnsort: "Sortierung aufheben",
    noRowsLabel: "Keine Daten",
    columnHeaderSortIconLabel: "Sortieren"
}

export const basicLinkClass = css({
    textDecoration: "unset",
    color: appColors.blue.main,
    ":hover": {
        textDecoration: "underline"
    }
});

export const weekDaysSo = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
export const weekDaysMo = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];


/** [!] The current order is important */
export const containerVariants = [
    "Absetzcontainer",
    "Absetzcontainer mit Deckel",
    "Abrollcontainer",
    "Abrollcontainer mit Deckel"
];