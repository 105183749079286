import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupIcon from '@mui/icons-material/Group';
import { M_BottomNavElement } from "@/mobile/components/M_BottomNav/M_BottomNavElement";
import { useLocation } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { motion, TargetAndTransition } from "framer-motion";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useCompanyStore } from "@/stores/useCompanyStore";
import { css } from "@emotion/css";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Skeleton } from "@mui/material";

const styles = {
    main: css({
        zIndex: 100,
        position: "fixed",
        width: "100%",
        bottom: 0,
        height: 70,
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }),
    content: css({
        // border: "1px solid red",
        height: "100%",
        width: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    })
};

interface Props {
    hideNavOnPaths: string[];
}

export function M_BottomNav(props: Props) {
    const { pathname } = useLocation();
    const companyData = useCompanyStore(state => state.companyData);

    const hideNav = props.hideNavOnPaths.find(path => pathname.startsWith(path));
    const companyModules = companyData.data?.modules;

    const motionAnimate: TargetAndTransition = !!hideNav ?
        { transform: "translateY(70px)" } :
        { transform: "translateY(0px)" };

    const LoadingSkeleton = () => {
        return <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
            <Skeleton width={60} height={30} variant="rounded" sx={{ borderRadius: 15 }}/>
            <Skeleton width={50} height={18} variant="rounded"/>
        </div>;
    };

    return <motion.div className={styles.main} animate={motionAnimate}>
        <div className={styles.content}>

            {!companyModules && <>
                <LoadingSkeleton/>
                <LoadingSkeleton/>
            </>}

            {companyModules?.includes("TIME_BOOKING_MODULE") &&
                <M_BottomNavElement
                    text="Zeiten"
                    icon={<AccessTimeOutlinedIcon/>}
                    activeIcon={<AccessTimeFilledIcon/>}
                    linkTo={appPaths.timeBooking}
                    active={pathname.startsWith(appPaths.timeBooking)}
                />
            }

            {companyModules?.includes("TIME_BOOKING_MODULE") &&
                <M_BottomNavElement
                    text="Urlaub"
                    icon={<WbSunnyOutlinedIcon/>}
                    activeIcon={<WbSunnyIcon/>}
                    linkTo={appPaths.vacation}
                    active={pathname.startsWith(appPaths.vacation)}
                />
            }

            {companyModules?.includes("CONTAINER_MODULE") && <>
                <M_BottomNavElement
                    text="Fahrten"
                    icon={<LocalShippingOutlinedIcon/>}
                    activeIcon={<LocalShippingIcon/>}
                    linkTo={appPaths.drivingJobs}
                    active={pathname.startsWith(appPaths.drivingJobs)}
                />
                <M_BottomNavElement
                    text="Berichte"
                    icon={<FactCheckOutlinedIcon/>}
                    activeIcon={<FactCheckIcon/>}
                    linkTo={appPaths.driverReports}
                    active={pathname.startsWith(appPaths.driverReports)}
                />
                <M_BottomNavElement
                    text="Aufträge"
                    icon={<FolderOpenOutlinedIcon/>}
                    activeIcon={<FolderIcon/>}
                    linkTo={appPaths.containerContract.overview}
                    active={pathname.startsWith(appPaths.containerContract.overview)}
                />
                {/*<M_BottomNavElement*/}
                {/*    text="Standort"*/}
                {/*    icon={<LocationOnOutlinedIcon/>}*/}
                {/*    activeIcon={<LocationOnIcon/>}*/}
                {/*    linkTo={appPaths.containerLocation}*/}
                {/*    active={pathname.startsWith(appPaths.containerLocation)}*/}
                {/*/>*/}
            </>}

            {companyModules?.includes("NOTES_MODULE") &&
                <M_BottomNavElement
                    text="Notizen"
                    icon={<AssignmentOutlinedIcon/>}
                    activeIcon={<AssignmentIcon/>}
                    linkTo={appPaths.notes}
                    active={pathname.startsWith(appPaths.notes)}
                />
            }

            {companyModules?.includes("CUSTOMER_FILE") &&
                <M_BottomNavElement
                    text="Kunden"
                    icon={<GroupOutlinedIcon/>}
                    activeIcon={<GroupIcon/>}
                    linkTo={appPaths.cf.main}
                    active={pathname.startsWith(appPaths.cf.main)}
                />
            }

            {companyModules?.includes("CALENDAR_TO_INCOME_STATEMENT") &&
                <M_BottomNavElement
                    text="Umsätze"
                    // text="Finanzübersicht"
                    icon={<TrendingUpIcon/>}
                    activeIcon={<TrendingUpIcon/>}
                    linkTo={appPaths.cis.main}
                    active={pathname.startsWith(appPaths.cis.main)}
                />
            }

            <M_BottomNavElement
                text="Firma"
                icon={<SettingsOutlinedIcon/>}
                activeIcon={<SettingsIcon/>}
                linkTo={appPaths.companySettings}
                active={pathname.startsWith(appPaths.companySettings)}
            />
        </div>
    </motion.div>;
}
