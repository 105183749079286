import { css } from "@emotion/css";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { useLoginUser } from "@/customHooks/useLoginUser";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";

const styles = {
    inputSection: css({
        display: "flex",
        flexDirection: "column",
        gap: 20,
    }),
    buttonSection: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 20
    })
};

export function LoginPage() {
    const navigate = useNavigate();

    const {
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        isLoading,
        handleLogin,
        ErrorMessageComponent,
        isLoginButtonDisabled
    } = useLoginUser();

    return <DesktopSetupMainFrame
        title="Anmelden"
        description="Mit einem bestehenden Konto anmelden."
    >
        <div className={styles.inputSection}>
            <TextField
                fullWidth
                label="E-Mail"
                value={userEmail}
                onChange={event => setUserEmail(event.target.value)}
            />
            <TextField
                fullWidth
                label="Passwort"
                type="password"
                value={userPassword}
                onChange={event => setUserPassword(event.target.value)}
            />

            <Typography
                color="primary"
                variant="body2"
                fontWeight="500"
                sx={{ cursor: "pointer" }}
            >
                Passwort vergessen?
            </Typography>

            <ErrorMessageComponent/>
        </div>


        <div className={styles.buttonSection}>
            <Button
                variant="text"
                onClick={() => navigate(appPaths.register)}
            >
                Neues Konto erstellen
            </Button>

            <Button
                onClick={handleLogin}
                disabled={isLoginButtonDisabled}
            >
                Anmelden
            </Button>
        </div>

        {isLoading &&
            <LoadingSpinnerWithBackdrop/>
        }

    </DesktopSetupMainFrame>;
}