import { usePermission } from "@/customHooks/usePermission";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSigninCheck } from "reactfire";
import { appPaths } from "@/helper/appPaths";
import { CompanyPermissions } from "tb-utils";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "GlobalPermissionCheck.tsx";

/**
 * Check if user has required permissions to view the current url-path.
 * This is only used logged-in users and for company internal paths (not for unauthenticated users).
 */
export function GlobalPermissionCheck() {
    const { hasPerm, myPerms } = usePermission();
    const location = useLocation();
    const navigate = useNavigate();
    const { data: userData, status: userLoading } = useSigninCheck();

    const hasPermOrRedirect = (perm: CompanyPermissions) => {
        if (!hasPerm(perm)) {
            appLogger.event(FILE_NAME, `Auto redirect due to missing permissions to view ${location.pathname}`);
            navigate(appPaths.root);
        }
    };

    /** Redirect user to "/" when visiting a page for which the current user does not have permissions */
    useEffect(() => {
        /** Exit if user is not loaded yet */
        if (!userData?.user?.uid) {
            appLogger.info(FILE_NAME, "Redirect-info: user not loaded yet, no redirect");
            return;
        };

        /** Exit if perms are not loaded yet */
        if (!myPerms) {
            appLogger.info(FILE_NAME, "Redirect-info: perms not loaded yet, no redirect");
            return;
        };

        const firstPartFromPath = "/" + location.pathname.split("/")?.[1];
        switch (firstPartFromPath) {
            /** General */
            case appPaths.companySettings:
                hasPermOrRedirect("ADMIN");
                appLogger.info(FILE_NAME, "Redirect-info: case appPaths.companySettings: hasPermOrRedirect('ADMIN');");
                break;
            /** Container */
            case appPaths.driverReports:
                hasPermOrRedirect("DRIVER_REPORTS_VIEW");
                appLogger.info(FILE_NAME, "Redirect-info: case appPaths.driverReports: hasPermOrRedirect('DRIVER_REPORTS_VIEW');");
                break;
            case appPaths.containerContract.overview:
                hasPermOrRedirect("CONTAINER_CONTRACTS_VIEW");
                appLogger.info(FILE_NAME, "Redirect-info: case appPaths.containerContract.overview: hasPermOrRedirect('CONTAINER_CONTRACTS_VIEW');");
                break;
            case appPaths.containerContract.overview + "/": // For the edit contract pages
                hasPermOrRedirect("CONTAINER_CONTRACTS_EDIT");
                appLogger.info(FILE_NAME, "Redirect-info: case appPaths.containerContract.overview + '/': hasPermOrRedirect('CONTAINER_CONTRACTS_EDIT');");
                break;
        }
    }, [userData, userLoading, myPerms, location]);

    return null;
}