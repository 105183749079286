import { appDialogNames } from "@/helper/appDialogNames";
import { Alert, TextField, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { getIdToken } from "firebase/auth";
import { useAuth, useFunctions, useSigninCheck } from "reactfire";
import { useDialog } from "@/customHooks/useDialog";
import { appRequest } from "@/helper/appRequest";
import { appLogger } from "@/helper/appLogger";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { SimpleDialog } from "@/common/SimpleDialog";
import { css } from "@emotion/css";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { serverTimestamp } from "firebase/firestore";

const styles = {
    colorSelectWrapper: css({
        display: "grid",
        gridTemplateColumns: "repeat(6, minmax(0px, 1fr))",
        gap: 8,
        color: "#fff"
    }),
    colorElement: css({
        aspectRatio: "1/1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        cursor: "pointer"
    })
};

const FILE_NAME = "M_CreateNewWorkTypeDialog.tsx";

export function C_CreateNewWorkTypeDialog() {
    const functions = useFunctions();
    const { closeOpenDialog } = useDialog();
    const { data: userData } = useSigninCheck();
    const { createWorkType } = useFirestoreWrite();
    const auth = useAuth();

    const allColors = [
        "#006688", "#008866", "#886600",
        "#880066", "#123", "#234",
        "#345", "#456", "#567",
        "#7700b2", "#d3ae00", "#41a700",
    ];

    const initialColor = allColors[0];
    const [selectedColor, setSelectedColor] = useState(initialColor);
    const [workTypeName, setWorkTypeName] = useState("");

    const [isWorkTypeCreationLoading, setIsWorkTypeCreationLoading] = useState(false);
    const [showCreationError, setShowCreationError] = useState("");

    const maxLength = 30;
    const isNameValid = (
        workTypeName.trim().length >= 2 &&
        workTypeName.trim().length <= maxLength
    );

    // const createNewWorkTypeFunction = httpsCallable<
    //     CreateNewWorkTypeFunctionRequest,
    //     CreateNewWorkTypeFunctionResponse
    // >(functions, appFunctionNames.createNewWorkType);

    const handleCreateClick = async () => {
        appLogger.event(FILE_NAME, "Create new work-type button clicked");

        /** Reset states */
        setIsWorkTypeCreationLoading(true);
        setShowCreationError("");

        /**
         * Retrieve ID tokens on clients
         * https://firebase.google.com/docs/auth/admin/verify-id-tokens?hl=en#retrieve_id_tokens_on_clients
         */
        // TODO: token-verify is disabled on emulators
        //  see following links to fix:
        //  set env var to disable error: https://github.com/firebase/firebase-tools/issues/2764#issuecomment-778530394
        //  Issue for this error: https://github.com/firebase/firebase-tools/issues/2813
        if (!auth.currentUser) return;
        const idToken = await getIdToken(auth.currentUser, true);

        createWorkType({
            color: selectedColor,
            name: workTypeName,
            created_at: serverTimestamp(),
            creator: userData?.user?.uid || "",
        })
            .then(() => {
                appLogger.state(FILE_NAME, "Successfully created new work-type (via api)");

                /** Reset states after work-type was created */
                setSelectedColor(initialColor);
                setWorkTypeName("");

                closeOpenDialog();
            })
            .catch(err => {
                appLogger.error(FILE_NAME, "Error creating work-type: " + err);
                setShowCreationError("Ein Fehler ist aufgetreten");
            })
            .finally(() => {
                setIsWorkTypeCreationLoading(false);
            });
    };

    return <SimpleDialog
        name={appDialogNames.compSettingsPage.createNewWorkType}
        title="Neue Arbeitsart erstellen"
        fullscreenLoading={{
            loading: isWorkTypeCreationLoading,
            text: "Arbeitsart wird erstellt"
        }}
        actionButtons={[
            {
                text: "Abbrechen",
                closeDialogOnClick: true
            },
            {
                text: "Erstellen",
                disabled: !isNameValid,
                onClick: handleCreateClick,
            }
        ]}
    >
        <M_ContentSection width="full" gap={12}>
            <TextField
                label="Name/Beschreibung der Arbeitsart"
                autoComplete="off"
                value={workTypeName}
                onChange={e => setWorkTypeName(e.target.value)}
                error={workTypeName.length > maxLength}
                helperText={`${workTypeName.trim().length}/${maxLength} Zeichen`}
            />

            <Typography>Hintergrundfarbe</Typography>

            <div className={styles.colorSelectWrapper}>
                {allColors.map(color => (
                    <div
                        key={color}
                        className={styles.colorElement}
                        style={{ background: color }}
                        onClick={() => setSelectedColor(color)}
                    >
                        {selectedColor === color &&
                            <CheckIcon/>
                        }
                    </div>
                ))}
            </div>

        </M_ContentSection>

        {showCreationError &&
            <M_ContentSection width="full">
                <Alert
                    color="error"
                    icon={false}
                    onClose={() => setShowCreationError("")}
                >
                    {showCreationError}
                </Alert>
            </M_ContentSection>
        }

    </SimpleDialog>;
}