import { ContractChange } from "tb-utils";
import { useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import format from "date-fns/format";
import { containerVariants } from "@/helper/appStatic";
import { contractType } from "@/helper/appUtils";

interface MessageTextProps {
    data: ContractChange<Date>;
    author_name: string;
}

export function DeliveryNoteSystemMessageText(props: MessageTextProps) {
    const { data: userData } = useSigninCheck();
    const members = useCompanyPersistStore(state => state.members);

    const findMember = (id: any) => {
        if (typeof id !== "string") return;
        return members.find(m => m.user_id === id);
    };

    const formatDate = (date: any): string => {
        if (!date) return "unbekannt";
        return format(date.toDate(), "dd.MM.yyyy");
    };

    const field = props.data.field;
    const o = props.data.old;
    const n = props.data.new;

    function PrettyText() {
        /**
         * Delivery address suff
         */
        /** Delivery address recipient */
        if (field === "c1_da_recipient") {
            return <>den Kunden (Lieferadresse) zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }
        /** Delivery address street */
        if (field === "c1_da_street") {
            return <>die Straße der Lieferadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }
        /** Delivery address house number */
        if (field === "c1_da_house_number") {
            return <>die Hausnummer der Lieferadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }
        /** Delivery address zip code */
        if (field === "c1_da_zip_code") {
            return <>die Postleitzahl der Lieferadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }
        /** Delivery address city */
        if (field === "c1_da_city") {
            return <>die Stadt der Lieferadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }

        /**
         * Billing address suff
         */
        /** Delivery address recipient */
        if (field === "c1_ba_recipient") {
            if (!o) {
                return <>den Kunden (Rechnungsadresse) <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>den Kunden (Rechnungsadresse) zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>den Kunden (Rechnungsadresse) <b>{o as string}</b> entfernt</>;
            }
        }
        /** Delivery address street */
        if (field === "c1_ba_street") {
            if (!o) {
                return <>die Straße der Rechnungsadresse <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>die Straße der Rechnungsadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>die Straße der Rechnungsadresse <b>{o as string}</b> entfernt</>;
            }
        }
        /** Delivery address house number */
        if (field === "c1_ba_house_number") {
            if (!o) {
                return <>die Hausnummer der Rechnungsadresse <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>die Hausnummer der Rechnungsadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>die Hausnummer der Rechnungsadresse <b>{o as string}</b> entfernt</>;
            }
        }
        /** Delivery address zip code */
        if (field === "c1_ba_zip_code") {
            if (!o) {
                return <>die Postleitzahl der Rechnungsadresse <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>die Postleitzahl der Rechnungsadresse zu <s>{o as string}</s>
                    <b>{n as string}</b> geändert</>;
            } else {
                return <>die Postleitzahl der Rechnungsadresse <b>{o as string}</b> entfernt</>;
            }
        }
        /** Delivery address city */
        if (field === "c1_ba_city") {
            if (!o) {
                return <>die Stadt der Rechnungsadresse <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>die Stadt der Rechnungsadresse zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>die Stadt der Rechnungsadresse <b>{o as string}</b> entfernt</>;
            }
        }

        /**
         * Delivery-note stuff
         */
        /** Driver */
        if (field.endsWith("driver")) {
            if (!n) {
                return <>den Fahrer <s>{findMember(o)?.displayName}</s> entfernt</>
            } else if (n === userData?.user?.uid) {
                return <><s>{findMember(o)?.displayName}</s> sich selbst als Fahrer eingetragen</>;
            } else {
                return <><s>{findMember(o)?.displayName}</s> <b>{findMember(n)?.displayName}</b> als Fahrer
                    eingetragen</>;
            }
        }
        /** Number */
        if (field.endsWith("number")) {
            if (!o) return <>die Lieferscheinnummer <b>{n as string}</b> hinzugefügt</>;
            if (!n) return <>die Lieferscheinnummer <s>{o as string}</s> entfernt</>;
            return <>die Lieferscheinnummer zu <s>{o as string}</s> <b>{n as string}</b> geändert</>
        }
        /** Date */
        if (field.endsWith("date")) {
            if (!o) {
                return <>das Lieferdatum <b>{formatDate(n)}</b> hinzugefügt</>
            } else if (n) {
                return <>das Lieferdatum <s>{formatDate(o)}</s> <b>{formatDate(n)}</b> geändert</>
            } else {
                return <>das Lieferdatum <s>{formatDate(o)}</s> entfernt</>
            }
        }
        /** Time of day */
        if (field.endsWith("time_of_day")) {
            if (!o) {
                return <>die Uhrzeit <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>die Uhrzeit zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>die Uhrzeit <s>{o as string}</s> entfernt</>;
            }
        }
        /** Cargo info text */
        if (field.endsWith("cargo_info")) {
            if (!o) {
                return <>den Ladungstext <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>den Ladungstext zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>den Ladungstext <s>{o as string}</s> entfernt</>;
            }
        }
        /** Recycler and others text */
        if (field.endsWith("recycler_and_others_text")) {
            if (!o) return <>den Verwerter und Sonstiges Text <b>{n as string}</b> hinzugefügt</>;
            if (!n) return <>den Verwerter und Sonstiges Text <s>{o as string}</s> entfernt</>;
            return <>den Verwerter und Sonstiges Text zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
        }
        /** Info text */
        if (field.endsWith("info_text")) {
            if (!o) {
                return <>den Infotext <b>{n as string}</b> hinzugefügt</>;
            } else if (n) {
                return <>den Infotext zu <s>{o as string}</s> <b>{n as string}</b> geändert</>;
            } else {
                return <>den Infotext <s>{o as string}</s> entfernt</>;
            }
        }
        /** status */
        if (field.endsWith("status")) {
            return <>
                den Lieferschein als <b>{n === 10 ? "erledigt" : "nicht erledigt"}</b> markiert
            </>;
        }

        /**
         * General contract suff
         */
        /** Type */
        if (field === "type") {
            return <>den Auftragstyp zu <s>{contractType.name(o as number)}</s>
                <b>{contractType.name(n as number)}</b> geändert</>;
        }
        /** Container variant */
        if (field === "container_variant") {
            const prettyContainerType = ["", ...containerVariants];
            return <>
                den Containertyp
                zu <s>{prettyContainerType[o as number]}</s> <b>{prettyContainerType[n as number]}</b> geändert
            </>;
        }
        /** Container size */
        if (field === "container_size") {
            return <>die Containergröße zu <s>{String(o)} m³</s> <b>{String(n)} m³</b> geändert</>;
        }


        /**
         * Finalize
         */
        if (field === "finalize_time_spend") {
            if (!o) return <>die Zeitangabe <b>{String(n)}</b> hinzugefügt</>;
            if (!n) return <>die Zeitangabe <s>{String(o)}</s> entfernt</>;
            return <>die Zeitangabe zu <s>{String(o)}</s> <b>{String(n)}</b> geändert</>;
        }
        if (field === "finalize_weight") {
            if (!o) return <>das Gewicht <b>{String(n)}</b> hinzugefügt</>;
            if (!n) return <>das Gewicht <s>{String(o)}</s> entfernt</>;
            return <>das Gewicht zu <s>{String(o)}</s> <b>{String(n)}</b> geändert</>;
        }


        /** Default message */
        return <><b>{field}</b> zu <s>{String(o)}</s> <b>{String(n)}</b> geändert</>;
    }

    return <>{PrettyText()}</>;
}
