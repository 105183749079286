import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { DeliveryNoteMessageProps } from "@/common/DeliveryNotePage/DeliveryNoteMessage";
import { css } from "@emotion/css";
import EditIcon from '@mui/icons-material/Edit';
import { appColors } from "@/helper/appColors";
import { SxProps, Typography } from "@mui/material";
import {
    DeliveryNoteSystemMessageText
} from "@/common/DeliveryNotePage/DeliveryNoteMessage/DeliveryNoteSystemMessageText";
import { deliveryNoteMessageDate } from "@/common/DeliveryNotePage/DeliveryNoteMessage/deliveryNoteUtils";
import { useEffect, useState } from "react";
import { ContainerContractDocument } from "tb-utils";

export function DeliveryNoteSystemMessage(props: DeliveryNoteMessageProps) {
    const styles = {
        main: css({
            // border: "1px solid red",
            display: "flex",
            padding: "8px 0",
            gap: 18
        }),
        iconWrapper: css({
            // border: "1px solid blue",
            color: appColors.typography.title
        }),
        iconBubble: css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 32,
            width: 32,
            borderRadius: "50%",
            background: appColors.backgroundGray.main,
        }),
        icon: {
            fontSize: 20
        } as SxProps,
        contentWrapper: css({
            // border: "1px solid green",
            paddingTop: 1,
            flex: 1,
            display: "flex",
            alignItems: "center"
        }),
        date: css({
            color: "#aaa"
        })
    };

    const members = useCompanyPersistStore(state => state.members);
    const authorName = members.find(m => m.user_id === props.messageData.author_id)?.displayName;

    /** Filter changes */
    const [filteredChanges, setFilteredChanges] = useState(props.messageData.contract_changes);
    useEffect(() => {
        const filtered = props.messageData.contract_changes?.filter(change => {
            /** Remove if bot new and old are empty */
            if (!change.new && !change.old) return false;

            /** Remove blacklisted fields */
            const blacklist: Array<keyof ContainerContractDocument<Date>> = [
                "c1_status_changed_by",
                "c2_status_changed_by",
                "c1_created_by",
                "c2_created_by",
                "finalize_data_changed_by"
            ];
            if (blacklist.includes(change.field)) return false;

            /** True if all previous checks passed */
            return true;
        });
        setFilteredChanges(filtered || null);
    }, [props.messageData.contract_changes]);


    const DateElement = () => {
        return <span className={styles.date}>
              {deliveryNoteMessageDate(props.messageData.created_at)}
        </span>;
    };

    return <div className={styles.main}>
        <div className={styles.iconWrapper}>
            <div className={styles.iconBubble}>
                <EditIcon sx={styles.icon}/>
            </div>
        </div>

        <div className={styles.contentWrapper}>
            {/** List one or (else) multiple changes at once */}
            {filteredChanges?.length === 1 ?
                <Typography variant="body2">
                    <b>{authorName}</b> hat&nbsp;
                    <DeliveryNoteSystemMessageText
                        data={filteredChanges[0]}
                        author_name={authorName || ""}
                    />
                    {" "}
                    {DateElement()}
                </Typography>
                :
                <div>
                    <Typography variant="body2">
                        <b>{authorName}</b> hat:
                    </Typography>
                    <div>
                        {filteredChanges?.map((change, i) => (
                            <Typography variant="body2" key={i}>
                                <DeliveryNoteSystemMessageText
                                    data={change}
                                    author_name={authorName || ""}
                                />
                            </Typography>
                        ))}
                    </div>
                    <Typography variant="body2">
                        {DateElement()}
                    </Typography>
                </div>
            }
        </div>
    </div>;
}
