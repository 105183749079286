import { Alert, Button, TextField } from "@mui/material";
import { css } from "@emotion/css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { appRequest } from "@/helper/appRequest";
import { appLogger } from "@/helper/appLogger";
import { appPaths } from "@/helper/appPaths";
import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";

const styles = {
    buttonSection: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    }),
};

const FILE_NAME = "SetupCreateCompany.tsx";

/** Validate creation token on page load (if a user opens this url directly) */
function useValidateCreationToken(setIsCreationTokenValid: Dispatch<SetStateAction<undefined | boolean>>) {
    const { creationToken } = useParams();
    const { status } = useSigninCheck();

    useEffect(() => {
        appLogger.info(FILE_NAME, "Validate creation token (once on page load)");

        if (status === "success") {
            appRequest.validateCompanyCreationToken({
                creation_token: creationToken || ""
            })
                .then(result => {
                    appLogger.info(FILE_NAME, "Successfully validated creation token: " + result.is_valid);
                    setIsCreationTokenValid(result.is_valid)
                })
                .catch(() => {
                    appLogger.error(FILE_NAME, "Failed to validate creation token (once on page load)");
                    setIsCreationTokenValid(false)
                });
        }
    }, [creationToken, setIsCreationTokenValid, status]);
}

export function SetupCreateCompany() {
    const { creationToken } = useParams();
    const navigate = useNavigate();

    const [isCreationTokenValid, setIsCreationTokenValid] = useState<undefined | boolean>(undefined);
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    useValidateCreationToken(setIsCreationTokenValid);

    const maxNameLength = 50;

    const isCreationButtonDisabled = (
        !isCreationTokenValid || // Disabled if invalid creation token
        companyNameValue.trim().length < 2 || // Disabled if token length is less than x chars
        companyNameValue.length > maxNameLength // Disabled if token length is more than x chars
    );

    const showLoadingSpinner = (
        isCreationTokenValid === undefined ||
        isLoading
    );

    const handleCreateCompanyClick = () => {
        if (!creationToken) return;
        setIsLoading(true);

        appRequest.createCompany({
            name: companyNameValue.trim(),
            creation_token: creationToken
        })
            .then(response => {
                appLogger.event(FILE_NAME, "Company successfully created. Redirect user to success page");
                navigate(appPaths.setupPages.companyCreated.replace(":companyId", response.company_id));
            })
            .catch(() => {
                appLogger.error(FILE_NAME, "Failed to create company with owner/creator as member");
                setErrorText("Fehler beim erstellen der Firma");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return <DesktopSetupMainFrame
        title="Firma erstellen"
        description="Gib deiner Firma einen Namen. Dieser ist nur für Mitarbeiter sichtbar und kann jederzeit in den Einstellungen geändert werden."
        disableLogoutButton={showLoadingSpinner}
    >

        <TextField
            id="company-name-textfield" // Used to disable browser autocomplete
            label="Name der Firma"
            value={companyNameValue}
            InputProps={{ autoComplete: "off", }}
            onChange={event => setCompanyNameValue(event.target.value)}
            helperText={companyNameValue.length > maxNameLength ? `Der Name darf maximal ${maxNameLength} Zeichen lang sein.`: ""}
            error={companyNameValue.length > maxNameLength}
        />

        {isCreationTokenValid === false &&
            <Alert icon={false} color="error">
                Der Erstellungscode "{creationToken}" ist ungültig
            </Alert>
        }

        {errorText &&
            <Alert
                icon={false}
                color="error"
                onClose={() => setErrorText("")}
            >
                {errorText}
            </Alert>
        }

        <div className={styles.buttonSection}>
            <div style={{ flex: 1 }}/>
            <Button
                disabled={isCreationButtonDisabled}
                onClick={handleCreateCompanyClick}
            >
                Firma erstellen
            </Button>
        </div>

        {showLoadingSpinner &&
            <LoadingSpinnerWithBackdrop/>
        }
    </DesktopSetupMainFrame>;
}