import { CSSProperties, PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { appStyles } from "@/helper/appStyles";
import { tbClasses } from "@/helper/appUtils";
import { css } from "@emotion/css";

export interface M_ContentSectionProps {
    /** Optional disable padding-bottom */
    disableBottomPadding?: boolean;

    /** Optional background color */
    backgroundColor?: string;

    /** Optional title */
    title?: string;

    /** Optional. If true, show bigger title */
    isPageTitle?: boolean;

    /** Optional styles */
    style?: CSSProperties;

    /** Optional css class */
    className?: string;

    /** Optional width */
    width?: "normal" | "full";

    /** Optional gap between elements */
    gap?: number;

    /** Optional top padding */
    pt?: number | string;
}

export function M_ContentSection(props: PropsWithChildren<M_ContentSectionProps>) {
    const styles = {
        main: css({
            // border: "1px solid blue",
            paddingBottom: props.disableBottomPadding ? "" : 40,
            paddingLeft: props.width === "full" ? 0 : appStyles.defaultSidePadding.normal,
            paddingRight: props.width === "full" ? 0 : appStyles.defaultSidePadding.normal,
            background: props.backgroundColor || "inherit",
        }),
        content: css({
            position: "relative"
        }),
        title: css({
            paddingBottom: 20
        }),
        optionalGap: css({
            display: "flex",
            flexDirection: "column",
            gap: props.gap || 0
        }),
        optionalPaddingTop: css({
            paddingTop: props.pt,
        })
    };

    return <div className={styles.main}>
        {props.title &&
            <Typography
                className={styles.title}
                variant={props.isPageTitle ? "h2" : "h4"}
            >
                {props.title}
            </Typography>
        }

        <div
            className={tbClasses(
                props.className,
                styles.content,
                props.gap ? styles.optionalGap : "",
                props.pt ? styles.optionalPaddingTop : ""
            )}
            style={props.style}>
            {props.children}
        </div>
    </div>;
}