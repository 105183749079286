import { ContainerContractDocument, DrivingJobsSortOrderDocument } from "tb-utils";
import { useGetContracts } from "@/common/DrivingJobOverviewPage/useGetContracts";
import { query, where } from "firebase/firestore";
import { useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { startOfDay } from "date-fns";
import { replaceTimestamps } from "@/helper/appUtils";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";

interface Resp {
    contracts: ContainerContractDocument<Date>[];
    isContractsLoading: boolean;
    sortOrder: string[];
    sortOrderData: DrivingJobsSortOrderDocument<Date>[];
    isSortOrderLoading: boolean;
}

export function useFormatContracts(
    selectedDay: Date,
    showOnlyMyContracts: boolean,
    showOnlyCompleted?: boolean
): Resp {
    const { data: userData } = useSigninCheck();
    const { collRefs } = useFirestoreWrite();

    const c1Contracts = useGetContracts(selectedDay, "c1_date", showOnlyMyContracts);
    const c2Contracts = useGetContracts(selectedDay, "c2_date", showOnlyMyContracts);

    const c1ContractsDone = showOnlyCompleted ? c1Contracts.data.filter(c => c.c1_status === 10) : c1Contracts.data;
    const c2ContractsDone = showOnlyCompleted ? c2Contracts.data.filter(c => c.c2_status === 10) : c2Contracts.data;

    // const mergedData = c1Contracts.data?.concat(c2Contracts.data);
    const mergedData = c1ContractsDone.concat(c2ContractsDone);

    /** Get custom sort order */
    const drivingJobsSortOrderQuery = query(
        collRefs.drivingJobsSortOrders(),
        where("driver", "==", userData?.user?.uid || "---"),
        where("day", ">=", startOfDay(selectedDay)),
        where("day", "<=", startOfDay(selectedDay)),
    );
    const { data, status: sortOrderStatus } = useFirestoreCollectionData(drivingJobsSortOrderQuery, {
        idField: "doc_id"
    });
    const sortOrderData = replaceTimestamps<DrivingJobsSortOrderDocument<Date>[]>(data || []);
    const sortOrder = sortOrderData?.[0]?.delivery_notes_order || [];

    /**
     * Sort mergedData.
     * I don't know how to use it within useEffect, but it does work without causing loops,
     * and it does reset on tab change (to "Alle Farten")
     */
    if (showOnlyMyContracts) {
        mergedData.sort((a, b) => {
            return sortOrder.indexOf(a.id as string) - sortOrder.indexOf(b.id as string);
        });
    }

    return {
        contracts: mergedData,
        isContractsLoading: c1Contracts.status === "loading" || c2Contracts.status === "loading",
        sortOrder,
        sortOrderData,
        isSortOrderLoading: sortOrderStatus === "loading"
    };
}