import { Button, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getAuth, GoogleAuthProvider, linkWithPopup, signInWithPopup, unlink, UserInfo } from "firebase/auth";
import { CisAccessTkn } from "@/ModuleCalendarToIncomeStatement/CISMainPage";
import { useSigninCheck } from "reactfire";

interface Props {
    setAccessTkn: Dispatch<SetStateAction<CisAccessTkn>>;
    isDevMode: boolean;
}

export function CisGoogleAuth(props: Props) {
    const { data: userData } = useSigninCheck();

    const [googleProviderData, setGoogleProviderData] = useState<UserInfo | undefined>(undefined);

    useEffect(() => {
        const findGoogle = userData?.user?.providerData.find(p => p.providerId === GoogleAuthProvider.PROVIDER_ID);
        setGoogleProviderData(findGoogle);
    }, [userData]);

    // TODO: Show "mit google verlinken" only if user is not already linked with google.
    function linkWithGoogle() {
        const provider = new GoogleAuthProvider();
        console.log("get scopes 1a", provider.getScopes());
        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");
        console.log("get scopes 2", provider.getScopes());

        const auth = getAuth();
        if (auth.currentUser) {
            linkWithPopup(auth.currentUser, provider)
                .then(result => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    props.setAccessTkn(credential?.accessToken || "");
                    console.log("LINK WITH GOOGLE SUCCESS", result.user, credential, credential?.accessToken);
                })
                .catch((error) => {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.log("LINK WITH GOOGLE ERROR", error.code, error.message, error.customData.email, credential);
                });
        } else {
            console.error("(LINK WITH GOOGLE) AUTH is null"); // TODO: add error message in ui
        }
    }

    function unlinkFromGoogle() {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.error("auth.currentUser is null");
            return;
        }

        unlink(auth.currentUser, GoogleAuthProvider.PROVIDER_ID)
            .then(() => {
                console.log("Google account unlinked");
                location.reload();
            })
            .catch(err => {
                console.error("Failed to unlink Google account", err);
            });
    }

    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");

        signInWithPopup(getAuth(), provider)
            .then(result => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                props.setAccessTkn(credential?.accessToken || "");
                console.log("SUCCESS", result.user, credential, credential?.accessToken);
            })
            .catch((error) => {
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log("ERROR", error.code, error.message, error.customData.email, credential);
            });
    }

    return <div className="flex flex-col items-center justify-center border-red-500 flex-1 w-full">
        <div className="border rounded-lg px-5 py-6 w-[400px]">

            {props.isDevMode ?
                <Button onClick={() => props.setAccessTkn("DEV_ACCESS_TOKEN")}>
                    Set dev access token ("logging in to google")
                </Button>
                :
                googleProviderData ?
                    <div className="flex flex-col gap-2">
                        <Typography variant="h2">Mit Google anmelden</Typography>
                        <Typography>
                            Klicke auf folgendes Google Konto, damit diese App zugriff auf deine Google Kalender erhält:
                        </Typography>

                        <button
                            className="mt-4 bg-slate-200 rounded-lg h-14 flex items-center gap-3 px-3 hover:bg-slate-100 cursor-pointer hover:border-slate-100 transition overflow-hidden"
                            onClick={signInWithGoogle}
                        >
                            <div className="flex items-center justify-center">
                                <img
                                    src={googleProviderData?.photoURL || ""}
                                    alt=""
                                    className="h-10 w-10 rounded-full"
                                />
                            </div>
                            <div className="flex flex-col items-start flex-1 gap-1">
                                <Typography className="line-clamp-1" sx={{ lineHeight: 1 }}>
                                    {googleProviderData?.displayName || "DEF"}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ lineHeight: 1 }}
                                    className="text-slate-500 line-clamp-1"
                                >
                                    {googleProviderData?.email || "ABC"}
                                </Typography>
                            </div>
                        </button>

                        <Button
                            onClick={unlinkFromGoogle}
                            variant="text"
                            size="small"
                            sx={{ alignSelf: "flex-start", mt: 2 }}
                        >
                            Anderes Google Konto verwenden
                        </Button>
                    </div>
                    :
                    <div className="flex flex-col gap-2">
                        <Typography variant="h2">Mit Google verlinken</Typography>
                        <Typography>Verlinke dein Google Konto, auf dem die Kundentermine eingetragen sind.</Typography>

                        <Button
                            startIcon={<GoogleIcon/>}
                            variant="contained"
                            sx={{ mt: 2 }}
                            onClick={linkWithGoogle}
                        >
                            Mit Google verlinken
                        </Button>
                    </div>

            }

            {/*<Button onClick={unlinkFromGoogle}>*/}
            {/*    Unlink from Google (see console output)*/}
            {/*</Button>*/}
        </div>
    </div>;
}
