import { MainFrame } from "@/common/MainFrame";
import { useFirestoreCollectionData } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { CompaniesDocument } from "tb-utils";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { Skeleton, Stack, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { CompanyOverviewItem } from "@/ModuleAdmin/CompanyOverviewItem";

const styles = {
    list: css({
        paddingTop: 30
    })
};

export function CompanyOverviewPage() {
    const { collRefs } = useFirestoreWrite();
    const { data, status } = useFirestoreCollectionData(collRefs.companies(), { idField: "doc_id" });
    const companies = replaceTimestamps<CompaniesDocument<Date>[]>(data);

    return <MainFrame desktop={{ size: "small" }}>
        <Typography variant="h1">Firmenübersicht</Typography>

        <div className={styles.list}>
            {status === "loading" && (
                <Stack gap={1}>
                    <Skeleton variant="rounded" height="60px"/>
                    <Skeleton variant="rounded" height="60px"/>
                    <Skeleton variant="rounded" height="60px"/>
                    <Skeleton variant="rounded" height="60px"/>
                </Stack>
            )}

            {companies?.map(c => (
                <CompanyOverviewItem key={c.doc_id} company={c}/>
            ))}
        </div>
    </MainFrame>;
}
