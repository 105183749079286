export const appStyles = {
    borderRadius: {
        small: 8,
        smaller: 12,
        normal: "18px"
    },
    backgroundCircleColor: {
        normal: "#0000000a"
    },
    defaultSidePadding: {
        normal: 28
    }
}