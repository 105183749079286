import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid, IconButton, Skeleton, SxProps, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/css";
import { useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { CustomerFileEntryDocument, CustomerFilesDocument } from "tb-utils";
import { replaceTimestamps } from "@/helper/appUtils";
import React, { useMemo } from "react";
import { FullPageDataGrid } from "@/common/FullPageDataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import { isThisYear, isValid } from "date-fns";
import { limit, orderBy, query, where } from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import { appDialogNames } from "@/helper/appDialogNames";
import { useDialog } from "@/customHooks/useDialog";
import { appPaths } from "@/helper/appPaths";
import { appStyles } from "@/helper/appStyles";
import { isMobile } from "react-device-detect";
import EditIcon from "@mui/icons-material/Edit";
import { DeliveryNoteInfoTextBox } from "@/common/DeliveryNotePage/DeliveryNoteInfoTextBox";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { CFEntryFormDialog } from "@/common/CustomerFilesPage/CFCustomerPage/CFEntryFormDialog";

export function CFCustomerPage() {
    const styles = {
        main: css({
            display: "block",
        }),
        nav: css({
            gap: 0,
            display: "flex",
            alignItems: "center",
            paddingBottom: 20,
            userSelect: "text",
        }),
        navBackButton: {
            transform: "translateX(-10px)"
        } as SxProps,
        navEditCustomerButton: {
            marginLeft: "8px",
            "& *": {
                fontSize: "22px"
            }
        } as SxProps,

        infoBox: css({
            marginBottom: 20,
            // padding: "14px 0"
        }),
        infoBoxContent: css({
            padding: 20,
            marginRight: isMobile ? 0 : 30,
            border: "1px solid #ccc",
            borderRadius: appStyles.borderRadius.smaller,
            maxWidth: "100%",
            userSelect: "text"
        }),
    };

    const { setOpenDialog } = useDialog();
    const {
        value: editEntryDialog,
        set: setEditEntryDialog,
        remove: removeEditEntryDialog
    } = useQueryParam("edit-entry");
    const navigate = useNavigate();
    const { customerId } = useParams();
    const { docRefs, collRefs } = useFirestoreWrite();

    const columns: GridColDef[] = [
        {
            field: "edit",
            headerName: "",
            sortable: false,
            width: 50,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return <IconButton
                    sx={{ transform: "translateX(-8px)" }}
                    onClick={() => {
                        setEditEntryDialog(params.id as string);
                    }}
                >
                    <EditIcon/>
                </IconButton>;
            },
        },
        {
            field: "date",
            headerName: "Datum",
            sortable: false,
            flex: 1,
            minWidth: 100,
            maxWidth: 120,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return format(params.row.date, isThisYear(params.row.date) ? "dd.MM." : "dd.MM.yyyy");
            },
        },
        {
            field: "treatment",
            headerName: "Behandlung",
            sortable: false,
            flex: 1,
            minWidth: 160,
            maxWidth: 250,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return <div className="whitespace-pre-wrap break-words line-clamp-3" >
                    {params.row.treatment}
                </div>;
            },
        },
        {
            field: "exposure_time",
            headerName: "Einwirkzeit",
            sortable: false,
            flex: 1,
            minWidth: 120,
            maxWidth: 150,
        },
        {
            field: "recipe",
            headerName: "Rezeptur",
            sortable: false,
            flex: 1,
            minWidth: 200,
            maxWidth: 250,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return <div className="whitespace-pre-wrap break-words line-clamp-3" >
                    {params.row.recipe}
                </div>;
            },
        },
        {
            field: "follow_up_treatment",
            headerName: "Nachbehandlung",
            sortable: false,
            flex: 1,
            minWidth: 200,
            maxWidth: 250,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return <div className="whitespace-pre-wrap break-words line-clamp-3" >
                    {params.row.follow_up_treatment}
                </div>;
            },
        },
        {
            field: "price",
            headerName: "Preis",
            sortable: false,
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
        },
        {
            field: "remarks",
            headerName: "Bemerkungen",
            sortable: false,
            flex: 1,
            minWidth: 300,
            renderCell: (params: GridRenderCellParams<any, CustomerFileEntryDocument<Date> & Record<string, any>>) => {
                return <div className="whitespace-pre-wrap break-words line-clamp-3" >
                    {params.row.remarks}
                </div>;
            },
        },
    ];

    /** customer files */
    const { data: filesData, status: filesStatus } = useFirestoreDocData(docRefs.customerFile("customer_files_001"));
    const customerFilesData = replaceTimestamps<CustomerFilesDocument<Date>>(filesData);

    const currentCustomer = useMemo(() => {
        return customerFilesData?.customers?.find(c => c.id === customerId);
    }, [filesData]);

    /** Customer entries */
    const customerFileEntriesQuery = query(
        collRefs.customerFileEntries(),
        where("customer_id", "==", customerId || "---"),
        orderBy("date", "desc"),
        limit(50)
    );
    const { data: entriesData, status: entriesStatus } = useFirestoreCollectionData(
        customerFileEntriesQuery, { idField: "id" /** `idFiled: "id"` is used by DataGrid */ }
    );
    const fileEntries = replaceTimestamps<CustomerFileEntryDocument<Date>[]>(entriesData);

    return <FullPageDataGrid
        columns={columns}
        rows={fileEntries || []}
        isLoading={entriesStatus === "loading"}
        gridDensity="comfortable"
        className={styles.main}
        enableBodyScroll
        noFrame
    >
        <div className={styles.nav}>
            <IconButton
                sx={styles.navBackButton}
                color="primary"
                onClick={() => navigate(appPaths.cf.main)}
            >
                <ArrowBackIcon/>
            </IconButton>
            {filesStatus === "loading" ?
                <Skeleton sx={{ width: "200px" }}/>
                :
                <Typography variant="h5" color="#333">
                    {currentCustomer?.first_name} {currentCustomer?.last_name}
                </Typography>
            }
            <IconButton
                title="Bearbeiten"
                sx={styles.navEditCustomerButton}
                size="medium"
                onClick={() => navigate(appPaths.cf.edit(customerId))}
            >
                <EditIcon/>
            </IconButton>
        </div>

        {(Boolean(currentCustomer?.additional_info) || Boolean(currentCustomer?.phone_number)) &&
            <div className={styles.infoBox}>
                <div className={styles.infoBoxContent}>
                    <Grid container spacing={2}>
                        {currentCustomer?.additional_info &&
                            <Grid item xs={12} md={6} xl={4}>
                                <DeliveryNoteInfoTextBox description="Info">
                                    <Typography whiteSpace="pre-wrap">{currentCustomer.additional_info}</Typography>
                                </DeliveryNoteInfoTextBox>
                            </Grid>
                        }
                        {currentCustomer?.phone_number &&
                            <Grid item xs={12} md={6} xl={4}>
                                <DeliveryNoteInfoTextBox description="Telefonnummer">
                                    <Typography>{currentCustomer.phone_number}</Typography>
                                </DeliveryNoteInfoTextBox>
                            </Grid>
                        }
                        {/* @ts-expect-error */}
                        {!!(currentCustomer?.birth_date && isValid(currentCustomer?.birth_date?.toDate())) &&
                            <Grid item xs={12} md={6} xl={4}>
                                <DeliveryNoteInfoTextBox description="Geburtstag">
                                    <Typography>
                                        {/* @ts-expect-error */}
                                        {format(currentCustomer?.birth_date?.toDate(), currentCustomer?.unknown_birth_year ? "dd.MM." : "dd.MM.yyyy")}
                                    </Typography>
                                </DeliveryNoteInfoTextBox>
                            </Grid>
                        }
                    </Grid>
                </div>
            </div>
        }

        <Button
            startIcon={<AddIcon/>}
            onClick={() => setOpenDialog(appDialogNames.customerFile.addItem)}
        >
            Neu
        </Button>

        <CFEntryFormDialog entries={fileEntries}/>
    </FullPageDataGrid>;
}
