import { PropsWithChildren } from "react";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";
import { SxProps, Typography } from "@mui/material";

interface Props {
    sectionName: string;
    disableSidePadding?: boolean;
}

export function SimpleListSection(props: PropsWithChildren<Props>) {
    const styles = {
        navSection: css({
            // paddingTop: 20
        }),
        navSectionText: {
            color: appColors.blue.main,
            fontWeight: 500,
            paddingLeft: props.disableSidePadding ? 0 : "20px",
            paddingBottom: "5px"
        } as SxProps,
    };

    return <div className={styles.navSection}>
        <Typography variant="body2" sx={styles.navSectionText}>
            {props.sectionName}
        </Typography>

        <div>
            {props.children}
        </div>
    </div>;
}