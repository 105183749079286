import { SxProps, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { motion } from "framer-motion";

interface Props {
    text: string;
    color: string;
    isLive: boolean;
}

export function MobileMonthWorkElement(props: Props) {
    const styles = {
        main: css({
            borderRadius: 4,
            color: "white",
            padding: "0px 3px",
            background: props.color,
        }),
        text: {
            fontSize: 10,
        } as SxProps
    };

    return <div>
        <motion.div
            className={styles.main}
            animate={{
                opacity: props?.isLive ? [1, 0.5, 1] : 1,
            }}
            transition={{
                duration: 1.5,
                ease: "easeInOut",
                repeat: Infinity,
            }}
        >
            <Typography sx={styles.text}>
                {props.text}
            </Typography>
        </motion.div>

    </div>;
}