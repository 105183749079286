const framerFadeInOut = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: { duration: 0.2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 }
    }
};

const framerDefaultTransition = {
    initial: {
        translateY: 10,
        opacity: 0
    },
    animate: {
        translateY: 0,
        opacity: 1,
        transition: { duration: 0.15 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.15 }
    }
}

export const appFramerVariants = {
    framerFadeInOut,
    framerDefaultTransition
};