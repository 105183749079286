import { Typography } from "@mui/material";
import { css } from "@/helper/css";
import {
    MobileMonthWorkElement
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/MobileMonthWorkElement";
import { appColors } from "@/helper/appColors";

const styles = {
    main: css({
        paddingTop: 16
    }),
    workTypeSummary: css({
        display: "flex",
        gap: 6,
        flexWrap: "wrap",
        paddingTop: 4
    }),
    workTypeSummaryItem: css({
        minWidth: 46,
        transform: "scale(1.1)"
    }),
}

export function MobileMonthSummary() {
    return <div style={styles.main}>
        <Typography>Gesamt: <b>151:30</b> Std.</Typography>

        <div style={styles.workTypeSummary}>
            <div style={styles.workTypeSummaryItem}>
                <MobileMonthWorkElement
                    text="110:00"
                    color={appColors.blue.main}
                    isLive={false}
                />
            </div>

            <div style={styles.workTypeSummaryItem}>
                <MobileMonthWorkElement
                    text="64:25"
                    color={appColors.green.main}
                    isLive={false}
                />
            </div>

            <div style={styles.workTypeSummaryItem}>
                <MobileMonthWorkElement
                    text="16:00"
                    color="#a06"
                    isLive={false}
                />
            </div>

            <div style={styles.workTypeSummaryItem}>
                <MobileMonthWorkElement
                    text="16:00"
                    color="#a60"
                    isLive={false}
                />
            </div>

            <div style={styles.workTypeSummaryItem}>
                <MobileMonthWorkElement
                    text="12:30"
                    color="#666"
                    isLive={false}
                />
            </div>
        </div>

    </div>;
}