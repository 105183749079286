import { css } from "@/helper/css";
import { MobileVacationDayElement } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/MobileVacationDayElement";
import { Chip } from "@mui/material";
import { mobileVacationBookingHelper } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/mobileVacationBookingHelper";
import { VacationDays } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/index";

const styles = {
    main: css({
        display: "flex",
        flexDirection: "row",
        position: "relative",
    }),
    titleBox: css({
        position: "absolute",
        display: "flex",
        width: "100%",
        left: 0,
        top: 0,
    }),
    titleBoxText: css({
        position: "sticky",
        left: 10,
        zIndex: 1,
        marginTop: 4
    })
}

interface Props {
    userName: string;
    vacationDays: VacationDays[];
    showAddIcon?: boolean;
}

export function MobileUserVacationBookings(props: Props) {
    let index = 0;
    return <div style={styles.main}>
        <div style={styles.titleBox}>
            <div style={styles.titleBoxText}>
                <Chip label={props.userName} size="small" color="primary"/>
            </div>
        </div>
        {mobileVacationBookingHelper.monthsWithDays.map((month, monthIndex) => {
            return month.map((day, dayIndex) => {
                index++;

                const vacationData = props.vacationDays.find(vacationDay => vacationDay.start === index);

                return <MobileVacationDayElement
                    key={`${monthIndex} ${dayIndex}`}
                    dayIndex={index}
                    isWeekend={index % 7 === 0 || index % 7 === 6}
                    vacationData={vacationData}
                    showAddIcon={props.showAddIcon}
                />
            });
        })}
    </div>;
}