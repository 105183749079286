import { css } from "@/helper/css";
import {
    mobileVacationBookingHelper
} from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/mobileVacationBookingHelper";
import { Typography } from "@mui/material";
import { appColors } from "@/helper/appColors";
import { appStyles } from "@/helper/appStyles";

const monthNames = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
];

const gapBetweenMonths = 10;

const styles = {
    main: css({
        display: "flex",
        flexDirection: "row",
        paddingBottom: 20,
    }),
    monthElement: css({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: gapBetweenMonths,
        padding: "0px 16px",
        height: 26,
        borderRadius: 15,
        boxSizing: "border-box",
    }),
    monthName: css({
        position: "sticky",
        left: appStyles.defaultSidePadding.normal
    })
};

export function MobileMonthNamesSection() {
    return <div style={styles.main}>
        {mobileVacationBookingHelper.monthsWithDays.map((month, index) => (
            <div
                key={index}
                style={{
                    ...styles.monthElement,
                    background: month.length % 2 === 1 ?
                        appColors.backgroundGray.dark :
                        appColors.backgroundGray.main,
                    width: (mobileVacationBookingHelper.dayWidth * month.length) - gapBetweenMonths,
                }}
            >
                <Typography>
                    <span style={styles.monthName}>
                        {monthNames[index]}
                    </span>
                </Typography>
            </div>
        ))}
    </div>;
}