import { PropsWithChildren } from "react";
import { isMobile } from "react-device-detect";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { Typography } from "@mui/material";

interface Props {
    disableBottomPadding?: boolean;
    width?: "normal" | "full";
    title?: string;
    className?: string;
}

export function C_SettingsSection(props: PropsWithChildren<Props>) {
    return isMobile ?
        <M_ContentSection
            disableBottomPadding={props.disableBottomPadding}
            width={props.width}
            title={props.title}
            className={props.className}
        >
            {props.children}
        </M_ContentSection>
        :
        <div
            className={props.className}
            style={{
                paddingBottom: props.disableBottomPadding ? "" : 40
            }}
        >
            {props?.title &&
                <Typography variant="h4">{props.title}</Typography>
            }
            {props.children}
        </div>;
}