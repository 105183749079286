import { Button, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { MainFrame } from "@/common/MainFrame";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";

const styles = {
    main: css({
        // border: "1px solid red",
        paddingTop: "30vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 20,
    }),

};

export function NotFoundPage() {
    const navigate = useNavigate();

    return <MainFrame>
        <div className={styles.main}>
            <Typography variant="h1">Seite nicht gefunden</Typography>
            <Button onClick={() => navigate(appPaths.root)}>
                Zur Startseite
            </Button>
        </div>
    </MainFrame>;
}
