import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { Alert, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { appStyles } from "@/helper/appStyles";
import { appColors } from "@/helper/appColors";
import { devHttpRequests, DevHttpRequestTypes, useDevStore } from "@/stores/useDevStore";
import { useSigninCheck } from "reactfire";

const styles = {
    main: css({
        // border: "1px solid red",
        height: "100vh",
        // padding: "30px 100px",
        display: "flex",
        flexDirection: "row"

    }),
    leftNav: css({
        width: 400,
        padding: "40px 20px",
        display: "flex",
        flexDirection: "column",
        gap: 30
    }),
    navItem: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 40,
        padding: "0 20px",
        cursor: "pointer",
        borderRadius: appStyles.borderRadius.normal
    }),
    activeNavItem: css({
        background: appColors.blue.veryLight
    }),
    navReqType: css({
        width: 50
    }),
    mainContent: css({
        // border: "1px solid blue",
        userSelect: "text",
        flex: 1,
        padding: "30px",
        overflow: "hidden",
        height: "100vh",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",

    }),
    urlSection: css({
        display: "flex",
        flexDirection: "row",
        gap: 10
    }),
    requestExtraInfo: css({
        display: "flex",
        flexDirection: "row",
        gap: 20,
        background: "#0001",
        borderRadius: appStyles.borderRadius.smaller,
        padding: "10px 20px",
        marginBottom: 10
    }),
    json: css({
        overflow: "auto",
        fontSize: 14,
        background: "#0001",
        borderRadius: appStyles.borderRadius.smaller,
        padding: "10px 20px"
    })
};

interface RequestData {
    status: number;
    json: any;
    text: any;
    size: number;
    type: string;
}

export function DesktopDevRequestsPage() {
    const devRequestData = useDevStore(state => state.devRequestData);
    const setDevRequestData = useDevStore(state => state.setDevRequestData);

    const { data } = useSigninCheck();

    const [authToken, setAuthToken] = useState("");

    /** Set authToken */
    useEffect(() => {
        (async function () {
            const tokenResult = await data.user?.getIdToken(true);
            if (tokenResult) {
                setAuthToken(tokenResult);
            }
        }());
    }, [data]);


    const [activeTab, setActiveTab] = useState(0);

    const [requestData, setRequestData] = useState<RequestData | null>(null);
    const [requestError, setRequestError] = useState(false);

    const handleActiveTabChangeClick = (newTab: number) => {
        setActiveTab(newTab);

        /** Reset states */
        setRequestData(null);
        setRequestError(false);
    };

    const handleSendClick = () => {
        const tabData = devRequestData.tabs[activeTab];

        /** Reset states */
        setRequestError(false);
        setRequestData(null);

        fetch(devRequestData?.baseUrl + tabData?.urlPath, {
            method: tabData?.reqType,
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(tabData.body)
            // body: '{test: "AAAAAAAAAAAAAAAAAAAAA"}',
        })
            .then(async res => {
                const blob = await res.clone()
                    .blob();

                setRequestData({
                    status: res.status,
                    json: blob.type === "application/json" ? await res.clone()
                        .json() : "",
                    text: await res.clone()
                        .text(),
                    size: blob.size,
                    type: blob.type
                });

            })
            .catch(err => {
                setRequestError(true);
                console.error("dev-requests error:", err);
            });
    };

    return <div className={styles.main}>

        <nav className={styles.leftNav}>
            <TextField
                label="Base URL"
                value={devRequestData.baseUrl}
                onChange={value => {
                    setDevRequestData({
                        ...devRequestData,
                        baseUrl: value.target.value
                    });
                }}
            />

            <div>
                {devRequestData.tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`${styles.navItem} ${activeTab === index ? styles.activeNavItem : ""}`}
                        onClick={() => handleActiveTabChangeClick(index)}
                    >
                        <div className={styles.navReqType}>
                            <Typography>{tab?.reqType}</Typography>
                        </div>
                        <div>
                            <Typography>{tab?.urlPath}</Typography>
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <Button
                    onClick={() => {
                        const tempData = { ...devRequestData };
                        tempData.tabs.push({
                            urlPath: "/",
                            reqType: "GET",
                            body: ""
                        });
                        setDevRequestData(tempData);
                    }}
                >
                    + New Tab
                </Button>
            </div>
        </nav>


        <section className={styles.mainContent}>
            <Typography>firebase user accessToken:</Typography>
            <Typography variant="caption" sx={{ wordBreak: "break-all" }}>
                {authToken || "No accessToken"}
            </Typography>

            <br/>
            <br/>

            {devRequestData.tabs.length ? <>
                    {requestError && <>
                        <Alert
                            color="error"
                            icon={false}
                            onClose={() => setRequestError(false)}
                        >
                            Request error (see browser console)
                        </Alert>
                        <br/>
                    </>}

                    <div className={styles.urlSection}>
                        <Button onClick={handleSendClick}>
                            Send
                        </Button>
                        <Select
                            size="small"
                            value={devRequestData.tabs?.[activeTab]?.reqType}
                            onChange={event => {
                                const tempData = { ...devRequestData };
                                tempData.tabs[activeTab].reqType = event.target.value as DevHttpRequestTypes;
                                setDevRequestData(tempData);
                            }}
                        >
                            {devHttpRequests.map((value, index) => (
                                <MenuItem key={index} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            label="URL path"
                            fullWidth
                            value={devRequestData.tabs?.[activeTab]?.urlPath}
                            onChange={event => {
                                const tempData = { ...devRequestData };
                                tempData.tabs[activeTab].urlPath = event.target.value;
                                setDevRequestData(tempData);
                            }}
                        />
                    </div>

                    <br/>
                    <Alert color="warning">
                        body payload is currently not being sent
                    </Alert>
                    <br/>

                    <TextField
                        label="body"
                        multiline
                        value={devRequestData.tabs?.[activeTab]?.body}
                        onChange={event => {
                            const tempData = { ...devRequestData };
                            tempData.tabs[activeTab].body = event.target.value;
                            setDevRequestData(tempData);
                        }}
                    />

                    <br/>

                    <div className={styles.requestExtraInfo}>
                        <Typography>status: {requestData?.status || "/"}</Typography>
                        <Typography>type: {requestData?.type || "/"}</Typography>
                        <Typography>size: {requestData?.size || "/"}</Typography>
                    </div>

                    <div className={styles.json}>
                        <pre>
                            {requestData?.json ?
                                JSON.stringify(requestData?.json || "{}", null, 4)
                                    .slice(1, -1)
                                :
                                requestData?.text
                            }
                        </pre>
                    </div>
                </>
                :
                <Alert color="info">
                    Create a new Tab
                </Alert>
            }
        </section>


    </div>;
}