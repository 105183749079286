import { css } from "@emotion/css";
import { format, getDay, isThisYear, isToday, isYesterday, parse } from "date-fns";
import { weekDaysSo } from "@/helper/appStatic";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { appColors } from "@/helper/appColors";
import { SimpleList, SimpleListCategory } from "@/common/components/SimpleList";
import { Typography } from "@mui/material";
import { useDriverReportsData } from "@/common/DriverReportsPage/useDriverReportsData";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { DriverReportsNavSkeleton } from "@/common/DriverReportsPage/DriverReportsNavSkeleton";
import { useState } from "react";
import { DriverReportCustomDateFilter } from "@/common/DriverReportsPage/DriverReportCustomDateFilter";

const styles = {
    reportsNav: css({
        // border: "1px solid green",
        width: 260,
        display: "flex",
        flexDirection: "column",
        gap: 30,

    }),
    statusSuccess: css({
        color: appColors.green.main,
        fontWeight: "bold"
    }),
};

export function DriverReportsNav() {
    const members = useCompanyPersistStore(state => state.members);

    const { value: customDateFilter } = useQueryParam("date_filter");
    const customDateFilterAsDate = customDateFilter ?
        parse(customDateFilter, "dd.MM.yyyy", new Date()) :
        null;

    const { sortedReports, status } = useDriverReportsData(7, customDateFilterAsDate);
    const { value: selectedReportId, set: setSelectedReportId } = useQueryParam("report");

    const navData: SimpleListCategory[] = sortedReports.map(category => ({
        name: (
            (isToday(category.day) ? "Heute, " : "") +
            (isYesterday(category.day) ? "Gestern, " : "") +
            weekDaysSo[getDay(category.day)] + ". " +
            format(category.day, isThisYear(category.day) ? "dd.MM." : "dd.MM.yyyy")
        ),
        items: category.data.map(report => {
            const getMemberName = members.find(m => m.user_id === report.driver)?.displayName || "";
            const description = () => {
                const rides = report.delivery_notes.length;
                const isApproved = report.review_status === 1;
                return <>
                    {rides}&nbsp;
                    {rides === 1 ? "Fahrt" : "Fahrten"}&nbsp;
                    {isApproved && <>
                        - <span className={styles.statusSuccess}>genehmigt</span>
                    </>}
                </>;
            };
            return {
                title: getMemberName,
                description: description(),
                isActive: selectedReportId === report?.doc_id,
                onClick: () => {
                    setSelectedReportId(report.doc_id || "");
                }
            };
        })
    }));

    return <div className={styles.reportsNav}>
        <DriverReportCustomDateFilter/>

        {status === "loading" ?
            <DriverReportsNavSkeleton/>
            :
            <SimpleList
                navData={navData}
                width="100%"
                emptySectionContent={
                    <Typography variant="body2" fontStyle="italic" color="gray">
                        Keine Berichte
                    </Typography>
                }
            />
        }
    </div>;
}