import { FullPageDataGrid } from "@/common/FullPageDataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomerFileDocument, CustomerFilesDocument } from "tb-utils";
import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { useFirestoreDocData } from "reactfire";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { replaceTimestamps } from "@/helper/appUtils";
import { appLogger } from "@/helper/appLogger";
import CloseIcon from '@mui/icons-material/Close';
import { isValid } from "date-fns";
import format from "date-fns/format";

const FILE_NAME = "CustomerFilesPage/index.tsx";

const columns: GridColDef[] = [
    {
        field: "customer",
        headerName: "Kunde",
        sortable: false,
        flex: 1,
        minWidth: 180,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<any, CustomerFileDocument<Date> & Record<string, any>>) => {
            return `${params.row.first_name} ${params.row.last_name}`;
        },
    },
    {
        field: "phone_number",
        headerName: "Telefon",
        sortable: false,
        flex: 1,
        minWidth: 200,
        maxWidth: 200,
    },
    {
        field: "birth_date",
        headerName: "Geburtstag",
        sortable: false,
        flex: 1,
        minWidth: 120,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<any, CustomerFileDocument<Date> & Record<string, any>>) => {
            if (!params.row.birth_date) return "-";

            // @ts-expect-error
            const date = params.row.birth_date?.toDate();
            if (isValid(date)) {
                return format(date, params.row.unknown_birth_year ? "dd.MM." : "dd.MM.yyyy");
            }

            return "-";
        }
    },
];

export function CustomerFilesPage() {
    const navigate = useNavigate();
    const { docRefs, writeCustomerFile } = useFirestoreWrite();
    const [customerFilter, setCustomerFilter] = useState("");

    const { data, status } = useFirestoreDocData(docRefs.customerFile("customer_files_001"));
    const customerFilesData2 = replaceTimestamps<CustomerFilesDocument<Date>>(data);

    const filteredCustomerFiles = useMemo(() => {
        let filteredResult = customerFilesData2?.customers || [];
        /** Filter hidden or deleted */
        filteredResult = filteredResult.filter(c => !c.hidden && !c.deleted);

        /** Search filter */
        if (customerFilter) {
            filteredResult = filteredResult.filter(c =>
                c.first_name.toLowerCase().startsWith(customerFilter.toLowerCase()) ||
                c.last_name.toLowerCase().startsWith(customerFilter.toLowerCase()) ||
                c.phone_number.toLowerCase().startsWith(customerFilter.toLowerCase())
            ) || [];
        }

        return filteredResult;
    }, [data, customerFilter]);

    /** Check if customer-files doc exists */
    useEffect(() => {
        if (status === "success" && !customerFilesData2) {
            writeCustomerFile.setCustomerFile2("customer_files_001")
                .catch(() => appLogger.error(FILE_NAME, "Error creating customer_files_001 doc"));
        }
    }, [data, status]);

    return <FullPageDataGrid
        columns={columns}
        rows={filteredCustomerFiles}
        onRowClick={params => {
            navigate(appPaths.cf.customer.replace(":customerId", params.id as string));
        }}
        isLoading={status === "loading"}
        enableBodyScroll
        noFrame
        // search={{
        //     placeholder: "Nach Kunden suchen...",
        //     resultItems: [
        //         { text: "Text Item 1", description: "Text Item 1" },
        //         { text: "Text Item 1", description: "Text Item 1" },
        //         { text: "Text Item 1", description: "Text Item 1" },
        //         // { text: "Text Item 1", description: "" },
        //         // { text: "Text Item 1", description: "" },
        //         // { text: "Text Item 1", description: "" },
        //     ]
        // }}
    >
        <Button
            onClick={() => navigate(appPaths.cf.create)}
            startIcon={<AddIcon/>}
        >
            Neuer Kunde
        </Button>
        <Stack alignItems="center" direction="row" width="100%">
            <TextField
                label="Nach Kunden suchen"
                autoComplete="off"
                value={customerFilter}
                onChange={e => setCustomerFilter(e.target.value)}
                sx={{
                    maxWidth: "100%",
                    width: 400,
                }}
            />
            {!!customerFilter &&
                <Tooltip title="Suche zurücksetzen" placement="top">
                    <IconButton onClick={() => setCustomerFilter("")}>
                        <CloseIcon/>
                    </IconButton>
                </Tooltip>
            }
        </Stack>
    </FullPageDataGrid>;
}
