import { appDialogNames } from "@/helper/appDialogNames";
import { Alert, TextField, Typography } from "@mui/material";
import { css } from "@/helper/css";
import { SimpleDialog } from "@/common/SimpleDialog";
import { ChangeEvent, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { appRequest } from "@/helper/appRequest";
import { appLogger } from "@/helper/appLogger";
import { useDialog } from "@/customHooks/useDialog";
import { usePendingInvitations } from "@/common/companySettings/pages/C_ManageEmployeesPage/usePendingInvitations";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";

const FILE_NAME = "C_InviteNewEmployeeDialog.tsx"

const styles = {
    dialog: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "stretch",
        gap: 12
    }),
};

export function C_InviteNewEmployeeDialog() {
    const { closeOpenDialog } = useDialog();
    const { pendingInvitationData } = usePendingInvitations();

    const members = useCompanyPersistStore(state => state.members);

    const [email, setEmail] = useState("");
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [showCreationError, setShowCreationError] = useState(false);
    const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);

    const handleEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
        setEmail(inputValue);

        const isPendingDuplicate = !!pendingInvitationData.find(invitation => invitation.email === inputValue);
        const isMemberDuplicate = !!members.find(member => member.email === inputValue);
        setIsDuplicateEmail(isPendingDuplicate || isMemberDuplicate);
    };

    const handleCreateInvitationClick = () => {
        setIsCreationLoading(true);
        setShowCreationError(false);

        appRequest.createInvitation({
            email: email
        })
            .then(result => {

                /** Reset states after creation */
                setEmail("");

                closeOpenDialog();
            })
            .catch(err => {
                appLogger.error(FILE_NAME, "Error creating new work-type (catch block): " + JSON.stringify(err));
                setShowCreationError(true);
            })
            .finally(() => {
                setIsCreationLoading(false);
            });
    };

    const handleCancelClick = () => {
        closeOpenDialog();
        setEmail("");
        setShowCreationError(false);
        setIsDuplicateEmail(false);
    };

    return <SimpleDialog
        title="Mitarbeiter einladen"
        name={appDialogNames.compSettingsPage.inviteNewEmployee}
        style={styles.dialog}
        fullscreenLoading={{
            loading: isCreationLoading,
            text: "Einladung wird erstellt"
        }}
        actionButtons={[
            {
                text: "Abbrechen",
                onClick: handleCancelClick,
            },
            {
                text: "Einladung senden",
                disabled: (
                    email.length < 5 ||
                    isDuplicateEmail
                ),
                onClick: handleCreateInvitationClick
            },
        ]}
    >
        <Typography>
            Zum Einladen die E-Mail-Adresse des Mitarbeiters eingeben:
        </Typography>

        <TextField
            fullWidth
            label="Mitarbeiter E-Mail"
            value={email}
            onChange={handleEmailInputChange}
            helperText={isDuplicateEmail ? "Eine Einladung an diese E-Mail wurde bereits gesendet" : ""}
            error={isDuplicateEmail}
        />

        <div/>
        <div/>

        <Alert color="info" icon={<InfoOutlinedIcon/>}>
            Die eingeladenen Mitarbeiter sehen die Einladung, nachdem sie sich eingeloggt / registriert und die
            E-Mail bestätigt haben.
        </Alert>


        {showCreationError &&
            <div style={{ width: "100%" }}>
                <Alert
                    icon={false}
                    color="error"
                    onClose={() => setShowCreationError(false)}
                >
                    Fehler beim Erstellen. Die E-Mail ist möglicherweise fehlerhaft.
                </Alert>
            </div>
        }
    </SimpleDialog>
}