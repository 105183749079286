import { appDialogNames } from "@/helper/appDialogNames";
import { Checkbox, Divider, FormControlLabel, Grid, IconButton, SxProps, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import {
    MobileAddWorkElementSelect
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/MobileAddWorkElementDialog/MobileAddWorkElementSelect";
import {
    MobileAddWorkElementWorkTimePicker
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/MobileAddWorkElementDialog/MobileAddWorkElementWorkTimePicker";
import {
    addDays,
    addHours,
    getHours,
    getMinutes,
    intervalToDuration,
    isBefore,
    setHours,
    setMinutes,
    startOfHour
} from "date-fns";
import { useDialog } from "@/customHooks/useDialog";
import { twoDigitNumber } from "@/helper/appUtils";
import { DialogActionButton } from "@/common/SimpleDialog/DialogActionButton";
import { SimpleDialog } from "@/common/SimpleDialog";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { serverTimestamp } from "firebase/firestore";
import { useSigninCheck } from "reactfire";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "MobileAddWorkElementDialog/index.tsx";

const styles = {
    buttonGroup: {
        "& .MuiButtonGroup-grouped": {
            // border: "1px solid red"
            borderColor: "#0002 !important"
        }
    } as SxProps,
    textfieldNoPadding: {
        maxWidth: "60px",
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "none",
        },
        "& .MuiInputBase-input": {
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "center"
        }
    } as SxProps,
}

export function MobileAddWorkElementDialog() {
    const [startDateValue, setStartDateValue] = useState<Date | null>(null);
    const [endDateValue, setEndDateValue] = useState<Date | null>(null);
    const [selectedWorkTypeId, setSelectedWorkTypeId] = useState("");
    const [endsOnNextDay, setEndsOnNextDay] = useState(false);
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [workBreakLength, setWorkBreakLength] = useState(0);

    const dialogId = appDialogNames.timeBooking.addWorkElement;
    const { getOpenDialog, closeOpenDialog } = useDialog();
    const { data: userData } = useSigninCheck();
    const { createTimeBooking } = useFirestoreWrite();

    /** Set start and end dates if null on dialog open */
    useEffect(() => {
        const isCurrentDialog = dialogId === getOpenDialog;
        const isDateValuesNull = !startDateValue && !endDateValue
        if (isCurrentDialog && isDateValuesNull) {
            const currentTime = new Date();
            const nextHour = addHours(startOfHour(currentTime), 1);
            setStartDateValue(nextHour);
            setEndDateValue(addHours(nextHour, 1));
        }

        /** Reset dates on close */
        return () => {
            setStartDateValue(null);
            setEndDateValue(null);
            setSelectedWorkTypeId("");
            setEndsOnNextDay(false)
        };
    }, [getOpenDialog]);

    /** Update endDate on startDate changes */
    useEffect(() => {
        if (startDateValue) {
            let newEndDate = addDays(startDateValue || 0, endsOnNextDay ? 1 : 0);
            newEndDate = setHours(newEndDate, getHours(endDateValue || 0));
            newEndDate = setMinutes(newEndDate, getMinutes(endDateValue || 0));
            setEndDateValue(newEndDate);
        }
    }, [startDateValue, endsOnNextDay]);


    const areDatesValid = (
        !!startDateValue && !!endDateValue &&
        isBefore(startDateValue, endDateValue)
    );

    const actionButtons: DialogActionButton[] = [
        {
            text: "Abbrechen",
            closeDialogOnClick: true,
            color: "error"
        },
        {
            text: "Erstellen",
            disabled: !areDatesValid || !selectedWorkTypeId,
            onClick: () => {
                createTimeBooking({
                    break_length: 0,
                    end_time: endDateValue as Date,
                    start_time: startDateValue as Date,
                    user_id: userData?.user?.uid || "",
                    work_type: selectedWorkTypeId,
                    created_at: serverTimestamp(),
                })
                    .catch(() => {
                        appLogger.error(FILE_NAME, "Error creating time-booking");
                    });
                closeOpenDialog();
            }
        }
    ];

    const changeBreakLength = (amount: number) => {
        const newBreakLength = workBreakLength + amount;
        if (newBreakLength <= 999) {
            setWorkBreakLength(Math.max(newBreakLength, 0));
        }
    };

    const workDuration = intervalToDuration({
        start: startDateValue || 0,
        end: endDateValue || 0
    });

    return <SimpleDialog
        name={dialogId}
        title="Arbeit erstellen"
        actionButtons={actionButtons}
        fullscreenLoading={{
            loading: isCreationLoading,
            text: "Wird erstellt"
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MobileAddWorkElementSelect
                    selectedWorkTypeId={selectedWorkTypeId}
                    setSelectedWorkTypeId={setSelectedWorkTypeId}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    <MobileDatePicker
                        value={startDateValue}
                        inputFormat="dd.MM.yyyy"
                        toolbarTitle="Tag Auswählen"
                        onChange={v => setStartDateValue(v)}
                        renderInput={params => <TextField style={{ width: "115px" }} {...params} />}
                        // showToolbar={false}
                    />

                    <div style={{ flex: 1 }}/>

                    <MobileAddWorkElementWorkTimePicker
                        value={startDateValue}
                        setValue={setStartDateValue}
                        toolbarTitle="Startzeit"
                        error={!areDatesValid}
                    />
                    <Typography>-</Typography>
                    <MobileAddWorkElementWorkTimePicker
                        value={endDateValue}
                        setValue={setEndDateValue}
                        toolbarTitle="Endzeit"
                        error={!areDatesValid}
                    />
                </div>

                {!areDatesValid && (
                    <Typography variant="caption" color="red">
                        Die Startzeit muss vor der Endzeit liegen
                    </Typography>
                )}
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox
                        checked={endsOnNextDay}
                        onChange={() => setEndsOnNextDay(v => !v)}
                    />}
                    label="Endet am nächsten Tag"
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2">
                    Arbeitslänge: {
                    twoDigitNumber(((workDuration.days || 0) * 24) + (workDuration.hours || 0)) +
                    ":"
                    + twoDigitNumber(workDuration.minutes || 0)
                }
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5
                }}>
                    <IconButton onClick={() => changeBreakLength(-5)}>
                        <Typography fontWeight={500}>-5</Typography>
                    </IconButton>
                    <IconButton onClick={() => changeBreakLength(-10)}>
                        <Typography fontWeight={500}>-10</Typography>
                    </IconButton>

                    <div style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <TextField
                            sx={{ ...styles.textfieldNoPadding }}
                            type="number"
                            value={String(workBreakLength)}
                            onChange={event => {
                                let newLength = event.target.value ?? "0";
                                if (Number(newLength) <= 999 && Number(newLength) >= 0) {
                                    setWorkBreakLength(Number(newLength));
                                }
                            }}
                        />
                    </div>

                    <IconButton onClick={() => changeBreakLength(10)}>
                        <Typography fontWeight={500}>+10</Typography>
                    </IconButton>
                    <IconButton onClick={() => changeBreakLength(5)}>
                        <Typography fontWeight={500}>+5</Typography>
                    </IconButton>

                </div>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2">
                    Länge der
                    Pause: {twoDigitNumber(Math.trunc(workBreakLength / 60)) + ":" + twoDigitNumber(workBreakLength % 60)}
                </Typography>
            </Grid>

        </Grid>


    </SimpleDialog>
}