import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { appFramerVariants } from "@/helper/appFramerVariants";
import {
    M_SwitchOverviewView
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_SwitchOverviewView";
import { AnimatePresence, motion } from "framer-motion";
import {
    MobileAddWorkElementDialog
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/MobileAddWorkElementDialog";
import { M_DayOverview } from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_DayOverview";
import { M_MonthOverview } from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview";
import { useQueryParam } from "@/customHooks/useQueryParam";

export function MobileTimeBookingOverview() {
    const { value: activeView } = useQueryParam("overview", "month");

    return <>
        <M_ContentSection title="Übersicht" disableBottomPadding>
            <M_SwitchOverviewView/>
        </M_ContentSection>

        <AnimatePresence mode="wait" initial={false}>
            {activeView === "month" ?
                <motion.div
                    key="monthOverview"
                    variants={appFramerVariants.framerFadeInOut}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <M_MonthOverview/>
                </motion.div>
                :
                <motion.div
                    key="dayOverview"
                    variants={appFramerVariants.framerFadeInOut}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <M_DayOverview/>
                </motion.div>
            }
        </AnimatePresence>

        <MobileAddWorkElementDialog/>
    </>;
}