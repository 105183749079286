import { appPaths } from "@/helper/appPaths";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { SimpleListCategory } from "@/common/components/SimpleList";

export function useCompanySettings() {
    const companyData = useCompanyStore(state => state.companyData);

    const companyModules = companyData.data?.modules;

    const navData: SimpleListCategory[] = [
        {
            name: "Allgemein",
            items: [
                {
                    title: "Allgemein",
                    description: "Name der Firma ändern",
                    linkTo: appPaths.companyGeneral
                },
                {
                    title: "Mitarbeiter",
                    description: "Verwalte Mitarbeiter der Firma",
                    linkTo: appPaths.companyManageEmployees
                },
            ],
        },
        ...companyModules?.includes("TIME_BOOKING_MODULE") ? [
            {
                name: "Zeiten",
                items: [
                    {
                        title: "Arbeitsarten",
                        description: "Verwalte Arbeitsarten der Firma",
                        linkTo: appPaths.companyManageWorkTypes
                    },
                    {
                        title: "Sollstunden",
                        description: "Lege die Arbeitsstunden der Mitarbeiter fest",
                        linkTo: appPaths.companyManageTargetHours
                    },
                    {
                        title: "Feiertage",
                        description: "Lege fest, wann Mitarbeiter nicht arbeiten",
                        linkTo: appPaths.companyManagePublicHolidays
                    },
                ],
            },
            {
                name: "Urlaub",
                items: [
                    {
                        title: "Urlaubstage",
                        description: "Lege die Urlaubstage der Mitarbeiter fest",
                        linkTo: appPaths.companyManageVacationDays
                    },
                ],
            },
        ] : [],
    ];

    return {
        navData
    };
}