import { SxProps, Typography } from "@mui/material";
import { appColors } from "@/helper/appColors";
import {
    MobileMonthWorkElement
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/MobileMonthWorkElement";
import {
    MonthDataInterface
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/index";
import { css } from "@emotion/css";
import { intervalToDuration, isToday } from "date-fns";
import { twoDigitNumber } from "@/helper/appUtils";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";
import format from "date-fns/format";
import { useQueryParam } from "@/customHooks/useQueryParam";

interface Props {
    index: number;
    data: MonthDataInterface;
}

export function MobileMonthDayElement(props: Props) {
    const workTypes = useTimeBookingStore(state => state.workTypes);

    const { setMultipleQueryParams } = useQueryParam("");

    const styles = {
        gridItem: css({
            background: props.data.isOtherMonth ? "#00000005" : (isToday(props.data.date) ? appColors.blue.veryLight : ""),
            borderBottom: `1px solid ${appColors.mobileCalendarBorderColor}`,
            borderRight: `1px solid ${appColors.mobileCalendarBorderColor}`,
            minHeight: 85,
            boxSizing: "border-box",
            padding: "8px 3px 2px 0px",
            borderLeft: (props.index % 7 === 0) ? `1px solid ${appColors.mobileCalendarBorderColor}` : "none"
        }),
        title: {
            // border: "1px solid red",
            paddingBottom: "4px",
            color: props.data.isOtherMonth ? "#aaa" : "",
            display: "flex",
            justifyContent: "center",
        } as SxProps,
        titleBubble: css({
            background: appColors.blue.main,
            borderRadius: "50%",
            height: 24,
            width: 24,
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }),
        workElementWrapper: css({
            display: "flex",
            flexDirection: "column",
            gap: 1
        })
    };

    const handleClick = () => {
        setMultipleQueryParams({
            "date": format(props.data.date, "dd.MM.yyyy"),
            "overview": "day"
        });
    };

    return <div
        className={styles.gridItem}
        onClick={handleClick}
    >
        <Typography
            sx={styles.title}
            variant="body2"
            textAlign="center"
        >
            {format(props.data.date, "d")}
        </Typography>

        <div className={styles.workElementWrapper}>
            {props.data.timeBookings?.map((tb, index) => {
                const workLength = intervalToDuration({
                    start: tb.start_time,
                    end: tb?.end_time || new Date()
                });

                const formatLength = () => {
                    const minutes = twoDigitNumber(workLength.minutes || 0);
                    const hours = twoDigitNumber(workLength.hours || 0);
                    return `${hours}:${minutes}`;
                }

                const workElementColor = workTypes.data.find(wt => wt.doc_id === tb.work_type)?.color;

                return <MobileMonthWorkElement
                    key={index}
                    text={formatLength()}
                    color={workElementColor || "#ccc"}
                    isLive={!tb?.end_time}
                />
            })}
        </div>
    </div>;
}