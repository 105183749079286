import { Route, Routes } from "react-router-dom";
import { CompanyOverviewPage } from "@/ModuleAdmin/CompanyOverviewPage";
import { CompanyInfoPage } from "@/ModuleAdmin/CompanyInfoPage";

export function ModuleAdmin() {
    return <Routes>
        <Route path="/" element={<CompanyOverviewPage/>}/>
        <Route path="/company/:companyId" element={<CompanyInfoPage/>}/>
    </Routes>;
}
