export interface CisCalendarsResponse {
    kind?: string;
    etag?: string;
    nextSyncToken?: string;
    items?: CisCalendarItem[];
}

interface CisCalendarItem {
    kind?: string;
    etag?: string;
    id?: string;
    summary?: string;
    description?: string;
    location?: string;
    timeZone?: string;
    summaryOverride?: string;
    colorId?: string;
    backgroundColor?: string;
    foregroundColor?: string;
    selected?: boolean;
    accessRole?: string;
    defaultReminders?: any[];
    notificationSettings?: any;
    primary?: boolean;
    deleted?: boolean;
    conferenceProperties?: any;
}

export interface CisEventsResponse {
    kind?: string;
    etag?: string;
    summary?: string;
    description?: string;
    updated?: string;
    timeZone?: string;
    accessRole?: string;
    defaultReminders?: any[];
    nextSyncToken?: string;
    items?: CisEventItem[];
}

export interface CisEventItem {
    kind?: string;
    etag?: string;
    id?: string;
    status?: string;
    htmlLink?: string;
    created?: string;
    updated?: string;
    summary?: string;
    // description?: string;
    // location?: string;
    // colorId?: string;
    creator?: any;
    organizer?: any;
    start?: {
        dateTime?: any;
        date?: any;
        timeZone?: string;
    };
    end?: {
        dateTime?: any;
        date?: any;
        timeZone?: string;
    };
    // endTimeUnspecified?: boolean;
    // recurrence?: string[];
    // recurringEventId?: string;
    // originalStartTime?: any;
    transparency?: string;
    // visibility?: string;
    iCalUID?: string;
    sequence?: number;
    // attendees?: any[];
    // attendeesOmitted?: boolean;
    // extendedProperties?: any;
    // hangoutLink?: string;
    // conferenceData?: any;
    // anyoneCanAddSelf?: boolean;
    // guestsCanInviteOthers?: boolean;
    // guestsCanModify?: boolean;
    // guestsCanSeeOtherGuests?: boolean;
    // privateCopy?: boolean;
    // locked?: boolean;
    reminders?: any;
    // source?: any;
    // attachments?: any;
    eventType?: string;
}


/**
 * CIS data
 */
export interface CisMonth {
    // events: CisEventItem[];
    events: {
        id: string; // id from the event (required for the data grid)
        data: CisEventItem;
        editedIncome: string;
        editedExpense: string;
    }[];
    selectedEventIds: string[];
}

export interface CisData {
    months: {
        [monthKey: string]: CisMonth;
    };
}

export const monthNames = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
];

export function getMonthInfo(monthKey: string) {
    const yearNumber = parseInt(monthKey.split("-")[0]);
    const monthNumber = parseInt(monthKey.split("-")[1]);
    const monthName = monthNames[monthNumber - 1];

    return {
        yearNumber,
        monthNumber,
        monthName,
        full: `${monthName} ${yearNumber}`,
    }
}


type FindPriceInStringResponse = null | {
    asString: string;
    isNegative: boolean;
    asNumber: number;
    validDecimalLength: boolean;
    tooManyPrices: boolean;
};

export function findPriceInString(input: string): FindPriceInStringResponse {
    // const findPrices = input.match(/-?\d+([.,]\d{0,2})?\s*€/g); // this only allows up to 2 decimal places
    const findPrices = input.match(/-?\d+([.,]\d+)?\s*€/g);
    if (!findPrices?.length) return null;

    let price = findPrices[0];

    /** Ensure there is a space between the number and the €*/
    price = price.replace(/(\d)(€)/, "$1 €");

    /** Replace dot with comma*/
    price = price.replace(".", ",");

    /** Check if the price is a whole number and add ",00" if it is */
    if (/^\d+ €$/.test(price)) {
        price = price.replace(" €", ",00 €");
    }

    /** Check if there are more than two decimal places in the price */
    const decimalMatch = price.match(/,(\d+)/);
    const validDecimalLength = !decimalMatch || decimalMatch[1].length <= 2;

    /** Check if there are multiple prices in the string */
    const tooManyPrices = findPrices.length > 1;

    return {
        asString: price,
        isNegative: price.startsWith("-"),
        asNumber: Number(price.replace(",", ".").replace(/[^0-9.-]+/g, "")),
        validDecimalLength,
        tooManyPrices
    };
}
