import { mobileVacationBookingHelper } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/mobileVacationBookingHelper";
import { css } from "@/helper/css";

const styles = {
    main: css({
        display: "flex",
        flexDirection: "row",
    }),
    dayElement: css({
        width: mobileVacationBookingHelper.dayWidth,
        height: 15,
        boxSizing: "border-box",
        // borderBottomLeftRadius: mobileVacationBookingHelper.dayWidth / 3,
        // borderBottomRightRadius: mobileVacationBookingHelper.dayWidth / 3,
    }),
    isWeekend: css({
        background: "#ddd"
    })
};

export function MobileVacationBookingEndRow() {
    let index = 0;
    return <div style={styles.main}>
        {mobileVacationBookingHelper.monthsWithDays.map((month, monthIndex) => {
            return month.map((day, dayIndex) => {
                index++;
                return <div
                    key={`${monthIndex} ${dayIndex}`}
                    style={{
                        ...styles.dayElement,
                        ...mobileVacationBookingHelper.dayElementStyles(index, "bottom"),
                    }}
                />;
            });
        })}
    </div>;
}