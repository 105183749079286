import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { Button, Typography } from "@mui/material";
import { appStyles } from "@/helper/appStyles";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";
import { appColors } from "@/helper/appColors";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { M_WorkElement } from "@/mobile/pages/MobileTimeBookingPage/M_WorkElement";
import { css, cx } from "@emotion/css";
import { useEffect, useState } from "react";

const styles = {
    main: css({
        paddingTop: 20
    }),
    timeBox: css({
        // border: "1px solid red",
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        gap: 10,
        paddingLeft: appStyles.defaultSidePadding.normal,
        paddingRight: appStyles.defaultSidePadding.normal,
    }),
    noWorkElements: css({
        background: appColors.backgroundGray.main,
        borderRadius: appStyles.borderRadius.normal,
        padding: "30px 20px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 15
    })
};

export function M_SelectWorkType() {
    const workTypes = useTimeBookingStore(state => state.workTypes);
    const activeTimeBooking = useTimeBookingStore(state => state.activeTimeBooking);

    const [disableWorkElements, setDisableWorkElements] = useState(false);
    const [isWorkElementClickLoading, setIsWorkElementClickLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const activeTbLoading = activeTimeBooking.status === "loading";
        const hasActiveTb = !!activeTimeBooking.data?.start_time;

        setDisableWorkElements(activeTbLoading || hasActiveTb);
    }, [activeTimeBooking]);

    const handleGoToWorkTypesSettingsClick = () => {
        navigate(appPaths.companyManageWorkTypes);
    };

    if (workTypes.status !== "loading" && !workTypes.data.length) return (
        <M_ContentSection pt={20}>
            <div className={styles.noWorkElements}>
                <Typography>
                    Es wurden keine Arbeitsarten erstellt
                </Typography>

                <div>
                    <Button
                        color="info"
                        onClick={handleGoToWorkTypesSettingsClick}
                    >
                        Arbeitsarten erstellen
                    </Button>
                </div>
            </div>
        </M_ContentSection>
    );

    return <M_ContentSection width="full" className={styles.main}>
        <div className={cx(styles.timeBox, "hideScrollbar")}>
            {workTypes.data.map((workType, index) => (
                <M_WorkElement
                    key={index}
                    workType={workType}
                    disabled={disableWorkElements}
                    isClickLoading={isWorkElementClickLoading}
                    setIsClickLoading={setIsWorkElementClickLoading}
                />
            ))}
        </div>
    </M_ContentSection>;
}

