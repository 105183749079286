import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { limit, orderBy, query, where } from "firebase/firestore";
import { endOfDay, isSameDay, startOfDay, subDays } from "date-fns";
import { useFirestoreCollectionData } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { DriverReportDocument } from "tb-utils";
import { useEffect, useState } from "react";

interface DriverReportNavDay {
    day: Date,
    data: DriverReportDocument<Date>[];
}

/**
 * Get n reports and optionally specify a day
 * @param daysToShow Number of days to show
 * @param customDate Optionally show data from specific date
 */
export function useDriverReportsData(daysToShow: number, customDate: Date | null) {
    const { collRefs } = useFirestoreWrite();

    const startDate = customDate || new Date();
    const amountOfDays = customDate ? 1 : daysToShow;

    /** Get driver-reports from last x days */
    const driverReportsQuery = query(
        collRefs.driverReports(),
        where("day", "<", endOfDay(startDate)),
        where("day", ">", subDays(startOfDay(startDate), amountOfDays)),
        orderBy("day", "asc"),
        limit(200)
    );

    const { data, status } = useFirestoreCollectionData(driverReportsQuery, { idField: "doc_id" });
    const driverReportsData = replaceTimestamps<DriverReportDocument<Date>[]>(data || []);

    const [sortedReports, setSortedReports] = useState<DriverReportNavDay[]>([]);

    /**
     * Build navData
     * Loop over temp reports, remove in every run all reports with same day
     * and store them into tempSortedDriverReports until temp reports is empty
     */
    useEffect(() => {
        const tempSortedDriverReports: DriverReportNavDay[] = [];

        /** Fill empty days */
        for (let i = 0; i < amountOfDays; i++) {
            tempSortedDriverReports.push({
                day: subDays(startDate, i),
                data: []
            });
        }

        /** Fill data */
        let tempDriverReports = [...driverReportsData];
        while (tempDriverReports.length) {
            const dateFromFirstElement = tempDriverReports[0].day;
            const findAllWithSameDay = tempDriverReports.filter(r => isSameDay(r.day, dateFromFirstElement));
            /** Save them temporally */
            const findIndex = tempSortedDriverReports.findIndex(tr => isSameDay(tr.day, dateFromFirstElement));
            const data = { day: dateFromFirstElement, data: findAllWithSameDay };
            if (findIndex >= 0) {
                tempSortedDriverReports[findIndex] = data;
            }
            /** And remove them from temp reports */
            tempDriverReports = tempDriverReports.filter(r => !isSameDay(r.day, dateFromFirstElement));
        }

        /** Sort by day */
        tempSortedDriverReports.sort((a, b) => +b.day - +a.day);

        setSortedReports(tempSortedDriverReports);
    }, [data]);

    return {
        sortedReports,
        status
    };
}