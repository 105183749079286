import { PropsWithChildren } from "react";
import { motion } from "framer-motion";
import { appFramerVariants } from "@/helper/appFramerVariants";
import { M_NavWithBackButton } from "@/mobile/components/M_MainFrame/M_NavWithBackButton";
import { css } from "@emotion/css";

interface SimpleNavProps {
    title: string;
    backButtonUrl: string;
    background?: string;
    color?: string;
}

interface PreviewPageProps {
    showPreview: boolean;
    previewPage: JSX.Element;
}

export interface M_MainFrameProps {
    disableTopPadding?: boolean;
    navWithBackButton?: SimpleNavProps;
    size?: "default" | "full";
}

export function M_MainFrame(props: PropsWithChildren<M_MainFrameProps>) {
    const styles = {
        main: css({
            // border: "1px solid red",
            paddingTop: props.disableTopPadding || props.navWithBackButton ? 0 : 60,
            paddingBottom: 70
        }),
        navWithBackButtonWrapper: css({
            position: "sticky",
            top: 0,
            zIndex: 2,
            background: props.navWithBackButton?.background || "#fff",
            color: "inherit"
        }),
        contentWrapper: css({
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center"
        }),
        content: css({
            // border: "1px solid green",
            width: props.size === "full" ? "100%" : "min(1000px, 100%)",
        }),
    }

    return <motion.div
        className={styles.main}
        variants={appFramerVariants.framerDefaultTransition}
        initial="initial"
        animate="animate"
        exit="exit"
    >
        {props.navWithBackButton &&
            <div className={styles.navWithBackButtonWrapper}>
                <M_NavWithBackButton
                    title={props.navWithBackButton.title}
                    backButton={props.navWithBackButton.backButtonUrl}
                    color={props.navWithBackButton.color}
                />
            </div>
        }

        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    </motion.div>;
}