import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { buildDeliveryNote, replaceTimestamps } from "@/helper/appUtils";
import { ContainerContractDocument, DriverReportDocument } from "tb-utils";
import { documentId, query, where } from "firebase/firestore";

export function useDriverReport() {
    const { docRefs, collRefs } = useFirestoreWrite();
    const { value: selectedReportId } = useQueryParam("report");

    /** Get driver-report from query param */
    const driverReportRef = docRefs.driverReport(selectedReportId || "---");
    const { data: drData, status: drStatus } = useFirestoreDocData(driverReportRef, { idField: "doc_id" });
    const driverReportData = replaceTimestamps<DriverReportDocument<Date>>(drData || []);

    const contractIds = driverReportData.delivery_notes
        ?.map(dn => dn.slice(0, -2))
        .slice(0, 30);

    /** Get contracts from driver report */
    const contractsQuery = query(
        collRefs.containerContracts(),
        where(documentId(), "in", contractIds || ["---"])
    );
    const { data: cData, status: cStatus } = useFirestoreCollectionData(contractsQuery, { idField: "doc_id" });
    const contractsData = replaceTimestamps<ContainerContractDocument<Date>[]>(cData || []);

    /** Build delivery-note objects */
    const deliveryNotes = driverReportData.delivery_notes
        ?.map(deliveryId => {
            const contractType = deliveryId.endsWith("/1") ? 1 : 2;
            const contractId = deliveryId.slice(0, -2);
            const contractData = contractsData.find(c => c.doc_id === contractId);
            if (!contractData) return;
            return buildDeliveryNote<Date>(contractData, contractType);
        })
        .filter(v => v);

    return {
        driverReportData,

        deliveryNotes,
        isLoading: drStatus === "loading" || cStatus === "loading",
    };
}