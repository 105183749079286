import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { appStyles } from "@/helper/appStyles";
import { useParams } from "react-router-dom";
import { ContainerContractDocument } from "tb-utils";
import format from "date-fns/format";
import { DeliveryNoteInfoTextBox } from "@/common/DeliveryNotePage/DeliveryNoteInfoTextBox";
import { appColors } from "@/helper/appColors";
import { endOfDay, isPast } from "date-fns";
import { containerVariants } from "@/helper/appStatic";
import { contractType, ExactContractType, getExactContractType } from "@/helper/appUtils";
import EditIcon from "@mui/icons-material/Edit";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";

interface Props {
    contractData: ContainerContractDocument<Date> | null;
}

export function DeliveryNoteMainBox(props: Props) {
    const { contractNumber } = useParams();
    const { setOpenDialog } = useDialog();

    const isStatusDone = contractNumber === "1" ?
        props.contractData?.c1_status === 10 :
        props.contractData?.c2_status === 10;

    const styles = {
        mainBox: css({
            border: "1px solid #ccc",
            // background: appColors.blue.veryLight,
            // background: appColors.green.veryLight,
            borderColor: isStatusDone ? appColors.green.light : "#ccc",
            // borderColor: isStatusDone ? "#0607": "#ccc",
            borderRadius: appStyles.borderRadius.smaller,
            minHeight: 200,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: 20,
            overflow: "hidden",
        }),
        iconStyle: css({
            display: "flex",
            alignItems: "center",
            gap: 5,
            ".MuiSvgIcon-root": {
                fontSize: 17,
                color: "#0009"
            }
        })
    };

    const date = props.contractData?.[contractNumber === "1" ? "c1_date" : "c2_date"];

    const deliveryDate = () => {
        return !date ? "-" : format(date, "dd.MM.yyy");
    };

    const isDeliveryDateInPast = (): boolean => {
        const status = props.contractData?.[contractNumber === "1" ? "c1_status" : "c2_status"];
        if (!date || status === 10) return false;
        return isPast(endOfDay(date));
    };

    const C1Icon = contractType.c1Icon(props.contractData?.type || 1);
    const C2Icon = contractType.c2Icon();

    function isOneExactType(...oneOf: ExactContractType[]): boolean {
        return oneOf.includes(getExactContractType(props.contractData?.type, contractNumber === "1"));
    }

    return <div className={styles.mainBox}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DeliveryNoteInfoTextBox description="Lieferadresse">
                    <Typography>{props.contractData?.c1_da_recipient}</Typography>
                    <Typography>{props.contractData?.c1_da_street} {props.contractData?.c1_da_house_number}</Typography>
                    <Typography>{props.contractData?.c1_da_zip_code} {props.contractData?.c1_da_city}</Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
            <Grid item xs={6}>
                <DeliveryNoteInfoTextBox description="Rechnungsadresse">
                    <Typography>{props.contractData?.c1_ba_recipient ?? "-"}</Typography>
                    <Typography>{props.contractData?.c1_ba_street} {props.contractData?.c1_ba_house_number ?? "-"}</Typography>
                    <Typography>{props.contractData?.c1_ba_zip_code} {props.contractData?.c1_ba_city ?? "-"}</Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
        </Grid>

        <Divider/>

        <Grid container>
            <Grid item xs={6}>
                <DeliveryNoteInfoTextBox description="Auftrag">
                    <Typography className={styles.iconStyle}>
                        {/*{contractNumber === "1" ?*/}
                        {/*    props.contractData?.type === 1 ?*/}
                        {/*        "Container liefern" :*/}
                        {/*        "Container stellen"*/}
                        {/*    :*/}
                        {/*    "Container holen"*/}
                        {/*}*/}
                        Container&nbsp;
                        {props.contractData?.type && contractNumber === "1" && <>
                            {contractType.c1Name(props.contractData.type)}
                            <C1Icon/>
                        </>}
                        {props.contractData?.type && contractNumber === "2" && <>
                            {contractType.c2Name()}
                            <C2Icon/>
                        </>}
                    </Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
            <Grid item xs={4}>
                <DeliveryNoteInfoTextBox description="Lieferdatum">
                    <Typography color={isDeliveryDateInPast() ? "red" : ""}>
                        {deliveryDate()}
                    </Typography>
                    <Typography>
                        {contractNumber === "1" ?
                            props.contractData?.c1_time_of_day :
                            props.contractData?.c2_time_of_day
                        }
                    </Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
        </Grid>

        {/*<Divider/>*/}

        <Grid container>
            <Grid item xs={6}>
                <DeliveryNoteInfoTextBox description="Verwerter und Sonstiges">
                    <Typography>
                        {contractNumber === "1" ?
                            (props.contractData?.c1_recycler_and_others_text || "-") :
                            (props.contractData?.c2_recycler_and_others_text || "-")
                        }
                    </Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
            <Grid item xs={6}>
                <DeliveryNoteInfoTextBox description="Abfall / Baustoff">
                    <Typography>
                        {contractNumber === "1" ?
                            (props.contractData?.c1_waste_key || "-") :
                            (props.contractData?.c2_waste_key || "-")
                        }
                    </Typography>
                </DeliveryNoteInfoTextBox>
            </Grid>
        </Grid>


        <DeliveryNoteInfoTextBox description="Container und Ladung">
            <Typography>
                {props.contractData?.container_size} m³
                &nbsp;
                {containerVariants[(props.contractData?.container_variant || 0) - 1]}
            </Typography>
            <Typography>
                {contractNumber === "1" ?
                    props.contractData?.c1_cargo_info :
                    props.contractData?.c2_cargo_info
                }
            </Typography>
        </DeliveryNoteInfoTextBox>

        <DeliveryNoteInfoTextBox description="Info">
            <Typography>
                {contractNumber === "1" ?
                    (props.contractData?.c1_info_text || "-") :
                    (props.contractData?.c2_info_text || "-")
                }
            </Typography>
        </DeliveryNoteInfoTextBox>


        {isOneExactType("umsetzen") && <>
            <Divider/>
            <Grid container>
                <Grid item xs={11}>
                    <DeliveryNoteInfoTextBox description="Zeitangabe">
                        <Typography>{props.contractData?.finalize_time_spend || "-"}</Typography>
                    </DeliveryNoteInfoTextBox>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => setOpenDialog(appDialogNames.container.finalizeDeliveryNote)}>
                        <EditIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </>}

        {isOneExactType("abfahren", "delivery", "fetch") && <>
            <Divider/>
            <Grid container>
                <Grid item xs={6}>
                    <DeliveryNoteInfoTextBox description="Menge">
                        <Typography>{props.contractData?.finalize_weight || "-"}</Typography>
                    </DeliveryNoteInfoTextBox>
                </Grid>
                <Grid item xs={5}>
                    <DeliveryNoteInfoTextBox description="Wiegeschein Nummer">
                        <Typography>{props.contractData?.finalize_weighbridge_number || "-"}</Typography>
                    </DeliveryNoteInfoTextBox>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => setOpenDialog(appDialogNames.container.finalizeDeliveryNote)}>
                        <EditIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </>}

    </div>;
}
