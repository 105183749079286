import { AnimatePresence, motion } from "framer-motion";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { DialogActionButton } from "@/common/SimpleDialog/DialogActionButton";
import { MobileDialogProgressSpinner } from "@/common/SimpleDialog/MobileDialogProgressSpinner";
import { css } from "@emotion/css";
import { appStyles } from "@/helper/appStyles";
import { useDialog } from "@/customHooks/useDialog";
import { tbClasses } from "@/helper/appUtils";

const styles = {
    main: css({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 101,
        // border: "1px solid red",
    }),
    box: css({
        minHeight: 60,
        width: "100%",
        maxWidth: 400,
        margin: "0 12px",
        background: "#fff",
        borderRadius: appStyles.borderRadius.normal,
        zIndex: 102,
        maxHeight: "90vh",
        overflowY: "auto",
    }),
    content: css({
        padding: 20,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        position: "relative"
    }),
    actionButtonsWrapper: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: 8
    }),
    backdrop: css({
        position: "absolute",
        background: "#0008",
        height: "100%",
        width: "100%",
    })
};

interface Props {
    name: string;
    show?: boolean; // Show dialog if set to true and ignore the "name"-property
    handleCustomBackdropClick?: () => void; // Overwrite the default behavior
    title: string;
    actionButtons?: DialogActionButton[];
    style?: CSSProperties;
    className?: string;
    disableBackdropClick?: boolean;

    /** If "loading" true: Hides dialog content and shows progress-spinner with optional text */
    fullscreenLoading?: {
        loading: boolean;
        text?: string;
    };
}

export function SimpleDialog(props: PropsWithChildren<Props>) {
    const { getOpenDialog, closeOpenDialog } = useDialog();

    /** Disable body scroll if dialog is shown */
    useEffect(() => {
        document.body.style.overflow = getOpenDialog ? "hidden" : "unset";

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [getOpenDialog]);

    console.log("SimpleDialog", props.name);

    return <AnimatePresence>
        {(getOpenDialog === props.name || props.show) &&
            <motion.div
                className={styles.main}
                transition={{ duration: 0.2 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className={tbClasses(styles.box, "hideScrollbar")}>
                    <div className={styles.content}>

                        {props.fullscreenLoading?.loading &&
                            <MobileDialogProgressSpinner text={props.fullscreenLoading.text}/>
                        }

                        <Typography variant="h6">{props.title}</Typography>

                        <div
                            style={props.style}
                            className={props.className}
                        >
                            {props.children}
                        </div>

                        <div className={styles.actionButtonsWrapper}>
                            {props.actionButtons?.map((actionButton, index) => (
                                <Button
                                    key={index}
                                    variant="text"
                                    size="small"
                                    color={actionButton.color}
                                    onClick={() => {
                                        actionButton.onClick?.();

                                        if (actionButton.closeDialogOnClick) {
                                            props.handleCustomBackdropClick !== undefined ?
                                                props.handleCustomBackdropClick() :
                                                closeOpenDialog();
                                        }
                                    }}
                                    disabled={actionButton.disabled}
                                >
                                    {actionButton.text}
                                </Button>
                            ))}
                        </div>

                    </div>
                </div>

                <div
                    className={styles.backdrop}
                    onClick={() => {
                        if (!props.disableBackdropClick) {
                            props.handleCustomBackdropClick !== undefined ?
                                props.handleCustomBackdropClick() :
                                closeOpenDialog();
                        }
                    }}
                />
            </motion.div>
        }
    </AnimatePresence>;
}
