import React, { PropsWithChildren, useEffect } from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { AuthProvider, FirestoreProvider, FunctionsProvider, StorageProvider, useFirebaseApp } from "reactfire";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { appLogger } from "@/helper/appLogger";
import { ThemeProvider } from "@mui/material";
import { theme } from "@/helper/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import de from "date-fns/locale/de";
import { useAuthStore } from "@/stores/useAuthStore";
import { useNotification } from "@/customHooks/useNotification";

const FILE_NAME = "RegisterProvider.tsx";

export function RegisterProvider(props: PropsWithChildren<{}>) {
    const setPushKey = useAuthStore(state => state.setPushKey);
    const { permission } = useNotification();

    const authInstance = getAuth(useFirebaseApp());
    const firestoreInstance = getFirestore(useFirebaseApp());
    const functionsInstance = getFunctions(useFirebaseApp());
    const storageInstance = getStorage(useFirebaseApp());
    const messagingInstance = getMessaging(useFirebaseApp());
    // const analyticsInstance = getAnalytics(useFirebaseApp());

    useEffect(() => {
        console.log("AAA");
        if (permission === "granted") {
            getToken(messagingInstance, {
                // vapidKey: "BNXE46XYPdWftw0ug5_HbFW8b3DuohnRC0izPyKaMAzHsTYv00x200v_V801i-El4MMriFLn5qEbKblGcxcZv58"
                vapidKey: import.meta.env.VITE_WEB_PUSH_KEY
            })
                .then(currentToken => {
                    console.log("currentToken", currentToken);
                    setPushKey(currentToken);
                })
                .catch(console.error);
        }
    }, [getToken, messagingInstance, setPushKey, permission]);

    /** Watch for messages when app is in foreground */
    useEffect(() => {
        console.log("????")
        const unsub = onMessage(messagingInstance, payload => {
            console.log("Message received in foreground", payload);
        });

        return () => unsub();
    }, []);

    // TODO: new Notification fails on mobile
    // useEffect(() => {
    //     if (!("Notification" in window)) {
    //         const testMsg = new Notification("Test Notification", {
    //         });
    //     }
    // }, [window]);

    // useEffect(() => {
    //     console.log("LOOP01");
    //     Notification.requestPermission().then(permission => {
    //         console.log("Notification permission:", permission)
    //     });
    // }, []);

    /** Change functions region */
    functionsInstance.region = "europe-west1";

    /**
     * Handle firebase emulators
     * https://stackoverflow.com/a/68128826
     */
    function startEmulators() {
        const EMULATORS_STARTED = 'EMULATORS_STARTED';
        // @ts-ignore
        if (!global[EMULATORS_STARTED]) {
            // @ts-ignore
            global[EMULATORS_STARTED] = true;

            appLogger.info(FILE_NAME, "Emulators started");

            connectAuthEmulator(authInstance, "http://localhost:9099", { disableWarnings: true });
            connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
            connectFunctionsEmulator(functionsInstance, "localhost", 5001);
            connectStorageEmulator(storageInstance, "localhost", 9199);
        }
    }

    const disableDevEmulators = import.meta.env.VITE_FIREBASE_DISABLE_DEV_EMULATORS === "true";
    const isDevEnv = process.env.NODE_ENV === "development";

    if (!disableDevEmulators && isDevEnv) {
        startEmulators();
    }

    return <AuthProvider sdk={authInstance}>
        <FirestoreProvider sdk={firestoreInstance}>
            <FunctionsProvider sdk={functionsInstance}>
                <StorageProvider sdk={storageInstance}>
                    {/*<AnalyticsProvider sdk={analyticsInstance}>*/}
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                        <ThemeProvider theme={theme}>
                            {props.children}
                        </ThemeProvider>
                    </LocalizationProvider>
                    {/*</AnalyticsProvider>*/}
                </StorageProvider>
            </FunctionsProvider>
        </FirestoreProvider>
    </AuthProvider>;
}