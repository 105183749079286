import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useFirestoreDocDataOnce, useSigninCheck } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { CompanyMemberDocument } from "tb-utils";
import { useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";
import { useAuthStore } from "@/stores/useAuthStore";
import { differenceInDays } from "date-fns";
import { useNotification } from "@/customHooks/useNotification";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "FirestoreSyncFCMToken.tsx";

/**
 * Get the own companyMember-document once to check if the fcm token is-to-date.
 * If not, update token and timestamp.
 */
export function FirestoreSyncFCMToken() {
    const { docRefs, updateCompanyMember } = useFirestoreWrite();
    const { data: userData } = useSigninCheck();
    const pushKey = useAuthStore(state => state.pushKey);
    const { permission } = useNotification();

    const { data, status } = useFirestoreDocDataOnce(docRefs.companyMember(userData?.user?.uid), { idField: "doc_id" });
    const companyMember = replaceTimestamps<CompanyMemberDocument<Date>>(data);

    useEffect(() => {
        /** Exit function if */
        if (
            !userData?.user?.uid ||
            !pushKey ||
            status !== "success" ||
            permission !== "granted"
        ) return;

        const fcmTimestamp = companyMember?.primary_fcm_timestamp || 0;
        const fcmToken = companyMember?.primary_fcm_token || "";
        const isKeyStale = differenceInDays(new Date(), fcmTimestamp) > 30;

        /** Update push-key in firestore when the following criteria are met */
        if (!fcmToken || isKeyStale || pushKey !== fcmToken) {
            // console.log("------ FirestoreSyncFCMToken");


            updateCompanyMember(userData.user.uid, {
                primary_fcm_token: pushKey,
                primary_fcm_timestamp: serverTimestamp(),
            })
                .then(() => appLogger.event(FILE_NAME, `Updated FCM-token from ${fcmToken} to ${pushKey}`))
                .catch(err => appLogger.error(FILE_NAME, "Error updating FCM-token: " + JSON.stringify(err)));
        }
    }, [data, pushKey, permission]);

    return null;
}