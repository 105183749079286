import { useEffect } from "react";
import { collectionGroup, FieldValue, onSnapshot, query, where } from "firebase/firestore";
import { useFirestore, useSigninCheck } from "reactfire";
import { appLogger } from "@/helper/appLogger";
import { useUserStore } from "@/stores/useUserStore";
import { CompanyMemberDocument } from "tb-utils";

const FILE_NAME = "FirestoreCompaniesMember.tsx";

export function FirestoreGetCompanyMemberData() {
    const { data: userData } = useSigninCheck();
    const db = useFirestore();

    const setCompaniesMember = useUserStore(state => state.setCompaniesMember);

    // useEffect(() => {
    //     console.log("GGGGGGGGGGGGGGGGGGG")
    // }, []);

    useEffect(() => {
        /** Exit function if no uid is set */
        if (!userData?.user?.uid) return;

        const usersCompanies = query(
            collectionGroup(db, "company-members"),
            where("user_id", "==", userData.user.uid)
        );
        // console.log("22222222------ FirestoreGetCompanyMemberData");

        const unsub = onSnapshot(
            usersCompanies,
            snap => {
                const data = snap.docs.map(doc => doc.data() as CompanyMemberDocument<any>);
                setCompaniesMember(data);
            },
            error => {
                const errorPrefix = FILE_NAME + " Error getting documents";
                console.error(errorPrefix, error);
                appLogger.error(FILE_NAME, `${errorPrefix}: ${error}`);
            }
        );

        return () => {
            console.log("___ unsub on 222");
            unsub();
        };

    }, [userData]);

    return null;
}