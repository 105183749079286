import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { css } from "@emotion/css";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import { ContainerContractDocument, DeliveryNote } from "tb-utils";
import { DeliveryNoteInfoTextBox } from "@/common/DeliveryNotePage/DeliveryNoteInfoTextBox";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import DirectionsIcon from '@mui/icons-material/Directions';
import NavigationIcon from '@mui/icons-material/Navigation';
import { MobileView } from "react-device-detect";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { DrivingJobOverviewDriverCell } from "@/common/components/DrivingJobOverviewDriverCell";
import { appLogger } from "@/helper/appLogger";
import { useDialog } from "@/customHooks/useDialog";
import { buildDeliveryNote } from "@/helper/appUtils";
import { appDialogNames } from "@/helper/appDialogNames";

const FILE_NAME = "DeliveryNoteInfoCard.tsx";

const styles = {
    infoCardSection: css({
        width: 250,
        paddingTop: 10,
        display: "flex",
        flexDirection: "column",
        gap: 14
    }),
    navigationWrapper: css({
        display: "flex",
        flexWrap: "wrap",
        gap: 8
    })
};

interface Props {
    contractData: ContainerContractDocument<Date> | null;
    // setFinalizeDeliveryNote: Dispatch<SetStateAction<DeliveryNote<Date> | null>>;
}

export function DeliveryNoteInfoCard(props: Props) {
    const { contractNumber } = useParams();
    const { setOpenDialog } = useDialog();
    const { updateContainerContract, toggleDeliveryNoteStatus } = useFirestoreWrite();
    const members = useCompanyPersistStore(state => state.members);

    const createdBy = () => {
        const findCreator = members.find(m => {
            return m.user_id === (
                contractNumber === "1" ?
                    props.contractData?.c1_created_by :
                    props.contractData?.c2_created_by
            );
        })
        return findCreator?.displayName || "-";
    };

    const handleDoneClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (!props.contractData) {
            appLogger.error(FILE_NAME, "Error handling delivery-note done-click. props.contract data can't be undefined/null");
            return;
        }

        const newValue = event.target.checked ? 10 : 0;
        toggleDeliveryNoteStatus(props.contractData, contractNumber === "1" ? "c1" : "c2", newValue)
            .catch(err => console.log("Error updating status", err));

        /** Open finalize delivery-note dialog */
        const isStellenType = contractNumber === "1" && props.contractData.type === 2;
        if (event.target.checked && !isStellenType) {
            setOpenDialog(appDialogNames.container.finalizeDeliveryNote);
        }
    };

    const googleMapsUrl = (startNavigation: boolean) => {
        const arrUrl = ["https://www.google.com/maps/dir/?api=1&travelmode=driving"];
        /** Optional add navigate */
        if (startNavigation) arrUrl.push("&dir_action=navigate");
        /** Add Destination */
        const addr = [
            props.contractData?.c1_da_street,
            props.contractData?.c1_da_house_number,
            props.contractData?.c1_da_zip_code,
            props.contractData?.c1_da_city
        ].join("+").replace(/ /gi, "+");
        // arrUrl.push(`&destination=Im+Flath+13+38542+Leiferde`);
        arrUrl.push(`&destination=${addr}`);

        return arrUrl.join("");
    };

    const createdAt = () => {
        const createdAtTimestamp = props.contractData?.contract_created_at;
        return createdAtTimestamp ? format(createdAtTimestamp, "dd.MM.yyyy") : "00.00.0000";
    };

    return <div className={styles.infoCardSection}>
        <DeliveryNoteInfoTextBox description="Erstellt von">
            <Typography variant="body2">
                {createdBy()}
            </Typography>
        </DeliveryNoteInfoTextBox>

        <Divider/>

        <DeliveryNoteInfoTextBox description="Erstellt am">
            <Typography variant="body2">
                {createdAt()}
            </Typography>
        </DeliveryNoteInfoTextBox>

        <Divider/>

        <DeliveryNoteInfoTextBox description="Fahrer">
            {!!props.contractData &&
                <DrivingJobOverviewDriverCell
                    isC1={contractNumber === "1"}
                    contractData={props.contractData}
                />
            }
        </DeliveryNoteInfoTextBox>

        <Divider/>

        <DeliveryNoteInfoTextBox description="Status">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox
                        size="small"
                        color="secondary"
                        onChange={handleDoneClick}
                        checked={contractNumber === "1" ?
                            props.contractData?.c1_status === 10 :
                            props.contractData?.c2_status === 10
                        }
                    />}
                    label={<Typography variant="body2">Erledigt</Typography>}
                />
            </FormGroup>

        </DeliveryNoteInfoTextBox>

        <Divider/>

        {/*
         Directions:
         https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=Im+Flath+13+Leiferde
         https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=Auf+d+Schafweide+38536+Meinersen

         Navigate:
         https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=Im+Flath+13+Leiferde
         https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=Auf+d+Schafweide+38536+Meinersen
         */}


        <DeliveryNoteInfoTextBox description="Google Maps">
            <div className={styles.navigationWrapper}>
                <Button
                    size="small"
                    startIcon={<DirectionsIcon/>}
                    onClick={() => {
                        window.open(googleMapsUrl(false), "_blank", "noreferrer");
                    }}
                >
                    Route
                </Button>
                <MobileView>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<NavigationIcon/>}
                        onClick={() => {
                            window.open(googleMapsUrl(true), "_blank", "noreferrer");
                        }}
                    >
                        Starten
                    </Button>
                </MobileView>
            </div>
        </DeliveryNoteInfoTextBox>
    </div>;
}
