import { RefObject, useEffect, useMemo, useState } from "react";

/** https://stackoverflow.com/a/65008608/10433038 */
export function useOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])

    useEffect(() => {
        // @ts-ignore
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => observer.disconnect();
    }, [])

    return isIntersecting
}