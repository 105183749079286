import { css } from "@emotion/css";
import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { appColors } from "@/helper/appColors";
import { appImages } from "@/helper/appImages";
import { appStyles } from "@/helper/appStyles";
import { appPaths } from "@/helper/appPaths";
import { useNavigate } from "react-router-dom";
import { appFramerVariants } from "@/helper/appFramerVariants";
import { useRedirectIfUserIsSignedIn } from "@/customHooks/useRedirectIfUserIsSignedIn";

const boxWidth = 420;
const mobileScreenWidth = "800px";

const styles = {
    main: css({
        // border: "1px solid red",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [`@media only screen and (max-width: ${mobileScreenWidth})`]: {
            minHeight: "90vh",
        }
    }),

    mainBox: css({
        position: "relative",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: appColors.backgroundGray.dark,
        borderRadius: appStyles.borderRadius.smaller,
        overflow: "hidden",
        boxSizing: "border-box",
        padding: 40,
        marginTop: 20,
        width: boxWidth,
        maxWidth: "calc(100% - 20px)",
        display: "flex",
        flexDirection: "column",
        gap: 40,
        [`@media only screen and (max-width: ${mobileScreenWidth})`]: {
            maxWidth: "100%",
            borderColor: "transparent",
            padding: 30,
        }
    }),
    center: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10
    }),
    logo: css({
        height: 50,
        width: "auto",
        borderRadius: 6,
        marginBottom: 20,
        [`@media only screen and (max-width: ${mobileScreenWidth})`]: {
            height: 70,
            borderRadius: 12,
        }
    }),
    bottomButtonSection: css({
        boxSizing: "border-box",
        padding: "10px 20px 0px",
        marginBottom: 20,
        width: boxWidth,
        maxWidth: "calc(100% - 20px)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    })
};

interface Props {
    title?: string;
    description?: string;
    gap?: "small" | "default";
    isMobile?: boolean;

    disableLogoutButton?: boolean;
}

export function DesktopSetupMainFrame(props: PropsWithChildren<Props>) {
    const navigate = useNavigate();

    useRedirectIfUserIsSignedIn();

    return <div className={styles.main}>
        <motion.div
            className={styles.mainBox}
            style={{ gap: props.gap === "small" ? 20 : "" }}
            variants={appFramerVariants.framerFadeInOut}
            initial="initial"
            animate="animate"
            exit="exit"
        >

            {props.title &&
                <div className={styles.center}>
                    <img className={styles.logo} src={appImages.logo.x192} alt=""/>
                    <Typography variant="h2">{props.title}</Typography>
                    <Typography>{props.description}</Typography>
                </div>
            }

            {props.children}

        </motion.div>

        {props.disableLogoutButton !== undefined &&
            <div className={styles.bottomButtonSection}>
                <>
                    <div style={{ flex: 1 }}/>
                    <Button
                        variant="text"
                        onClick={() => navigate(appPaths.logout)}
                        disabled={props.disableLogoutButton}
                    >
                        Abmelden
                    </Button>
                </>
            </div>
        }

    </div>;
}