import { appDialogNames } from "@/helper/appDialogNames";
import { Button, Typography } from "@mui/material";
import { useSigninCheck } from "reactfire";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import format from "date-fns/format";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { SimpleDialog } from "@/common/SimpleDialog";
import { css } from "@emotion/css";
import { UserSettingsDialogListItem } from "@/common/UserSettingsDialogListItem";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useNotification } from "@/customHooks/useNotification";
import { appColors } from "@/helper/appColors";
import { appStyles } from "@/helper/appStyles";
import { useDialog } from "@/customHooks/useDialog";

const styles = {
    accInfoList: css({
        paddingBottom: 30
    }),
    pushInfo: css({
        background: appColors.backgroundGray.light,
        borderRadius: appStyles.borderRadius.smaller,
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "flex-start"
    }),
};

export function UserSettingsDialog() {
    const { data: userData } = useSigninCheck();
    const navigate = useNavigate();
    const { permission, requestPermission } = useNotification();
    const {closeOpenDialog} = useDialog();
    const handleLogoutClick = async () => {
        navigate(appPaths.logout);
    };

    const formatTime = (str: string) => {
        const stringToDate = new Date(str);

        /** Check if date is Valid */
        if (!stringToDate.getDate()) return null;

        return format(stringToDate, "dd.MM.yyyy");
    };

    const lastSignedIn = formatTime(userData?.user?.metadata.lastSignInTime || "");
    const accountCreated = formatTime(userData?.user?.metadata.creationTime || "");

    return <SimpleDialog
        name={appDialogNames.general.userSettings}
        title="Aktuell angemeldet als"
    >
        {permission !== "granted" && <>
            <div className={styles.pushInfo}>
                <Typography variant="body2">
                    Aktiviere Push-Benachrichtigungen, um auch bei geschlossener App informiert zu bleiben.
                </Typography>
                <Button variant="outlined" onClick={() => {
                    requestPermission()
                        .catch(() => console.error("Error getting push permission from browser"));
                    closeOpenDialog();
                }}>
                    Aktivieren
                </Button>
            </div>
            <br/>
        </>}

        <div className={styles.accInfoList}>
            <UserSettingsDialogListItem
                icon={<EmailOutlinedIcon/>}
                fontWeight="500"
                text={userData?.user?.email || ""}
                loading={!userData?.user?.email}
            />
            <UserSettingsDialogListItem
                icon={<AccessTimeOutlinedIcon/>}
                text={`Zuletzt angemeldet ${lastSignedIn}`}
                loading={!lastSignedIn}
            />
            <UserSettingsDialogListItem
                icon={<PersonAddOutlinedIcon/>}
                text={`Konto erstellt ${accountCreated}`}
                loading={!accountCreated}
            />
            <UserSettingsDialogListItem
                icon={<FingerprintIcon/>}
                text={`ID: ${userData?.user?.uid || ""}`}
                loading={!userData?.user?.uid}
            />

        </div>

        <Button fullWidth onClick={handleLogoutClick}>
            Abmelden
        </Button>

    </SimpleDialog>;
}