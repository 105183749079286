import { Chip, Stack, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";
import { CompaniesDocument } from "tb-utils";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { collection, getCountFromServer } from "firebase/firestore";

const styles = {
    listItem: css({
        // border: "1px solid red",
        padding: "18px 20px",
        borderBottom: `1px solid ${appColors.backgroundGray.main}`,
        cursor: "pointer",

        ":hover": {
            background: appColors.backgroundGray.light
        }
    }),

};

interface Props {
    company: CompaniesDocument<Date>;
}

export function CompanyOverviewItem(props: Props) {
    const { collRefs, docRefs } = useFirestoreWrite();
    const [memberCount, setMemberCount] = useState(0);

    useEffect(() => {
        const query = collection(docRefs.company(props.company.doc_id || ""), "company-members");
        getCountFromServer(query)
            .then(snapshot => {
                setMemberCount(snapshot.data().count);
            })
            .catch(reason => {
                setMemberCount(-1);
                console.error("Error getting member count from " + props.company.doc_id, reason);
            });
    }, []);

    return <div className={styles.listItem}>
        <Stack direction="row" alignItems="center" gap={1}>
            <Typography>{props.company.name}</Typography>
            {props.company.modules.map((m, i) => (
                <Chip key={i} size="small" label={m} sx={{ fontSize: "10px", height: "16px" }}/>
            ))}
        </Stack>

        <Stack direction="row" gap={2}>
            <Typography variant="body2" color="#555">{memberCount} Mitarbeiter</Typography>
            <Typography variant="body2" color="#555">
                {format(props.company.created_at, "dd.MM.yyyy")}
            </Typography>
        </Stack>
    </div>
}
