import { SxProps, Typography } from "@mui/material";
import { css } from "@emotion/css";
import FactCheckIcon from '@mui/icons-material/FactCheck';

const styles = {
    main: css({
        // border: "1px solid red",
        minHeight: 200,
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#888",
        gap: 20
    }),
    icon: {
        fontSize: 80
    } as SxProps
};

interface Props {
    selectedReportId: string | null;
}

export function NoDriverReport(props: Props) {
    return <div className={styles.main}>
        <FactCheckIcon sx={styles.icon}/>
        <Typography>
            {props.selectedReportId ?
                "Der ausgewählte Bericht konnte nicht gefunden werden" :
                "Keinen Bericht ausgewählt"
            }
        </Typography>
    </div>;
}