import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Alert, Button, ButtonGroup, Checkbox, Chip, IconButton, SxProps, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useEffect, useState } from "react";
import { ContainerContractDocument, DriverReportDocument } from "tb-utils";
import { addDays, getDay, getWeek, isThisYear, isToday, isTomorrow, isYesterday, startOfDay } from "date-fns";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { FullPageDataGrid } from "@/common/FullPageDataGrid";
import format from "date-fns/format";
import { DeliveryNoteCell } from "@/common/ContainerContractPage/DeliveryNoteCell";
import { DrivingJobOverviewDriverCell } from "@/common/components/DrivingJobOverviewDriverCell";
import { useContainerPersistStore } from "@/stores/useContainerPersistStore";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { weekDaysSo } from "@/helper/appStatic";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { CreateDriverReportDialog } from "@/common/DrivingJobOverviewPage/CreateDriverReportDialog";
import { css } from "@emotion/css";
import { useFormatContracts } from "@/common/DrivingJobOverviewPage/useFormatContracts";
import { useHandleMoveElementClick } from "@/common/DrivingJobOverviewPage/useHandleMoveElementClick";
import { useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { contractType, replaceTimestamps } from "@/helper/appUtils";
import { limit, query, where } from "firebase/firestore";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneIcon from "@mui/icons-material/Done";
import { usePermission } from "@/customHooks/usePermission";
import { FinalizeDeliveryNoteDialog } from "@/common/DrivingJobOverviewPage/FinalizeDeliveryNoteDialog";
import { useQueryParam } from "@/customHooks/useQueryParam";

const FILE_NAME = "DrivingJobOverviewPage/index.tsx";

export function DrivingJobOverviewPage() {
    const drivingJobsActiveTab = useContainerPersistStore(state => state.drivingJobsActiveTab);
    const setDrivingJobsActiveTab = useContainerPersistStore(state => state.setDrivingJobsActiveTab);

    const showOnlyMyContracts = drivingJobsActiveTab === "my_jobs";

    // const functions = useFunctions();
    const { hasPerm } = usePermission();
    const { toggleDeliveryNoteStatus, collRefs } = useFirestoreWrite();
    const { setOpenDialog, closeOpenDialog } = useDialog();
    const { value: fromDriverReportDialog, setMultipleQueryParams } = useQueryParam("from-driver-report-dialog");
    const { data: userData } = useSigninCheck();
    const [selectedDay, setSelectedDay] = useState(new Date());
    const {
        contracts,
        isContractsLoading,
        sortOrderData,
        isSortOrderLoading
    } = useFormatContracts(selectedDay, showOnlyMyContracts);
    const { handleMoveElementClick } = useHandleMoveElementClick();
    const [finalizeContract, setFinalizeContract] = useState<ContainerContractDocument<Date> | null>(null);
    const [finalizeContractIsC1, setFinalizeContractIsC1] = useState(false);

    /** Get driver-report from selected day */
    const driverReportQuery = query(
        collRefs.driverReports(),
        where("driver", "==", userData?.user?.uid || "---"),
        where("day", "==", startOfDay(selectedDay)),
        limit(1)
    );
    const { data, status: driverReportsStatus } = useFirestoreCollectionData(driverReportQuery, { idField: "doc_id" });
    const driverReportsData = replaceTimestamps<DriverReportDocument<Date>[]>(data);

    /** Auto select my_jobs if user has no permission to view all jobs */
    useEffect(() => {
        if (!hasPerm("DRIVING_JOBS_VIEW_ALL")) {
            console.log(FILE_NAME, "Missing DRIVING_JOBS_VIEW_ALL perm, set setDrivingJobsActiveTab to my_jobs");
            setDrivingJobsActiveTab("my_jobs");
        }
    }, []);

    const isDriverReportCreated = !!driverReportsData?.length;

    const styles = {
        wrapper: css({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "inherit",
            flexWrap: "wrap",
            paddingRight: 30,
            flex: 1,
        }),
        topSection: css({
            display: "flex",
            gap: "inherit",
            flexWrap: "wrap",
        }),
        bottomSection: css({
            width: "100%",
        }),
        dataGrid: {
            "& .custom-bg-color-10": {
                background: drivingJobsActiveTab === "my_jobs" ? "#0c02" : ""
            },
            "& .custom-bg-color-10:hover": {
                background: drivingJobsActiveTab === "my_jobs" ? "#0c04" : ""
            }
        } as SxProps,
        buttonSectionWrapper: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10
        }),
    };

    const columns: GridColDef[] = [
        /** Show:
         * - Driver: when "all" driving jobs are shown
         * - Sort-handle: when "only my" driving jobs are shown
         */
        ...drivingJobsActiveTab === "all" ?
            [{
                field: 'driver',
                headerName: 'Fahrer',
                flex: 1,
                minWidth: 160,
                maxWidth: 200,
                renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                    return <DrivingJobOverviewDriverCell
                        isC1={(params.id as string).endsWith("/1")}
                        contractData={params.row}
                    />;
                }
            }]
            :
            [
                {
                    field: "sortable-handle",
                    headerName: "Sortierung",
                    sortable: false,
                    renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                        const getRowIndex = contracts.map(v => v.id).indexOf(params.id as string);
                        const isFirst = getRowIndex === 0;
                        const isLast = getRowIndex === contracts.length - 1;

                        return <div style={{ display: "flex" }}>
                            <IconButton
                                onClick={() => handleMoveElementClick(
                                    "up",
                                    contracts,
                                    params.id as string,
                                    sortOrderData,
                                    selectedDay
                                )}
                                disabled={isFirst || isDriverReportCreated}
                            >
                                <ArrowUpwardIcon sx={{ color: isDriverReportCreated ? "#0002" : "#0006" }}/>
                            </IconButton>

                            <IconButton
                                onClick={() => handleMoveElementClick(
                                    "down",
                                    contracts,
                                    params.id as string,
                                    sortOrderData,
                                    selectedDay
                                )}
                                disabled={isLast || isDriverReportCreated}
                            >
                                <ArrowDownwardIcon sx={{ color: isDriverReportCreated ? "#0002" : "#0006" }}/>
                            </IconButton>
                        </div>;
                    }
                },
                {
                    field: 'drivingJobStatus',
                    headerName: 'Erledigt',
                    sortable: false,
                    width: 80,
                    renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                        const isC1 = (params.id as string).endsWith(("/1"));
                        const isChecked = isC1 ?
                            params.row.c1_status === 10 :
                            params.row.c2_status === 10;

                        return <Checkbox
                            disabled={isDriverReportCreated}
                            color="secondary"
                            checked={isChecked}
                            onChange={event => {
                                const newValue = event.target.checked ? 10 : 0;
                                toggleDeliveryNoteStatus(params.row, isC1 ? "c1" : "c2", newValue)
                                    .catch(err => console.log("Error updating status", err));
                                /** Write clicked deliver-note to setFinalizeDeliveryNote */
                                if (!isChecked) {
                                    setFinalizeContract(params.row);
                                    setFinalizeContractIsC1(isC1);
                                    setOpenDialog(appDialogNames.container.finalizeDeliveryNote);
                                }
                            }}
                        />;
                    }
                },
            ],
        {
            field: 'drivingJobId',
            headerName: 'Auftrag',
            sortable: false,
            flex: 1,
            minWidth: 90,
            maxWidth: 130,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith(("/1"));
                return <DeliveryNoteCell
                    isC1={isC1}
                    contractData={params.row}
                    hideDate
                />;
            }
        },
        {
            field: 'timeOfArrival',
            headerName: 'Uhrzeit',
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 80,
            maxWidth: 100,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith(("/1"));
                return <div>
                    {isC1 ? params.row.c1_time_of_day : params.row.c2_time_of_day}
                </div>;
            }
        },
        {
            field: 'daRecipient',
            headerName: 'Kunde',
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 220,
            maxWidth: 350,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => (
                <div>
                    {params.row.c1_da_recipient} ({params.row.c1_da_city})
                </div>
            )
        },
        {
            field: 'container_size',
            headerName: 'Menge',
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 80,
            maxWidth: 180,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => (
                <div>
                    {params.row.container_size} m³
                </div>
            )
        },
        {
            field: 'jobType',
            headerName: 'Leistung',
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 80,
            maxWidth: 150,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith("/1");
                return <div>
                    {isC1 ? contractType.c1Name(params.row.type) : contractType.c2Name()}
                </div>;
            }
        },
        {
            field: 'wasteKey',
            headerName: 'Abfall / Baustoff',
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 150,
            maxWidth: 200,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith("/1");
                return <div>
                    {isC1 ? params.row.c1_waste_key : params.row.c2_waste_key}
                </div>;
            }
        },
        {
            field: 'recyclerAndOthersText',
            headerName: "Verwerter und Sonstiges",
            sortable: !showOnlyMyContracts,
            flex: 1,
            minWidth: 200,
            maxWidth: 300,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith("/1");
                return <div>
                    {isC1 ? params.row.c1_recycler_and_others_text : params.row.c2_recycler_and_others_text}
                </div>;
            }
        },
    ];

    const emptyText = drivingJobsActiveTab === "all" ?
        "Keine Fahrten für diesen Tag" :
        "Du hast keine Fahrten für diesen Tag";
    const isLoading = (
        isContractsLoading ||
        isSortOrderLoading ||
        driverReportsStatus === "loading"
    );

    const changeCurrentDay = (diff: number) => {
        setSelectedDay(addDays(selectedDay, diff));
    };

    // const onCallLogging = httpsCallable(functions, 'onCallLogging');
    //
    // const makeReq = async () => {
    //     const { data: funRes } = await onCallLogging();
    //     console.log("DATA", funRes);
    // };

    return <FullPageDataGrid
        columns={columns}
        rows={contracts || []}
        customEmptyIcon={<LocalShippingIcon/>}
        customEmptyText={emptyText}
        isLoading={isLoading}
        getRowClassName={params => {
            return `custom-bg-color-${(params.id as string).endsWith("1") ? params.row.c1_status : params.row.c2_status}`;
        }}
        sx={styles.dataGrid}
    >
        <div className={styles.wrapper}>
            <div className={styles.topSection}>
                <div className={styles.buttonSectionWrapper}>
                    <div>
                        <IconButton onClick={() => changeCurrentDay(-1)}>
                            <ArrowBackIosNewIcon sx={{ fontSize: 18 }}/>
                        </IconButton>
                        <IconButton onClick={() => changeCurrentDay(1)}>
                            <ArrowForwardIosIcon sx={{ fontSize: 18 }}/>
                        </IconButton>
                    </div>

                    <Typography fontWeight="500" variant="body1">
                        {isToday(selectedDay) && <>Heute,&nbsp;</>}
                        {isYesterday(selectedDay) && <>Gestern,&nbsp;</>}
                        {isTomorrow(selectedDay) && <>Morgen,&nbsp;</>}
                        {weekDaysSo[getDay(selectedDay)]}.&nbsp;
                        {format(selectedDay, isThisYear(selectedDay) ? "dd.MM." : "dd.MM.yyyy")}
                    </Typography>

                    <Chip
                        size="small"
                        label={"KW " + getWeek(selectedDay, { weekStartsOn: 1, firstWeekContainsDate: 4 })}
                    />
                </div>

                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setSelectedDay(new Date())}
                    disabled={isToday(selectedDay)}
                >
                    Heute
                </Button>

                {hasPerm("DRIVING_JOBS_VIEW_ALL") &&
                    <div>
                        <ButtonGroup size="small" disableElevation>
                            <Button
                                variant={drivingJobsActiveTab === "my_jobs" ? "outlined" : "contained"}
                                onClick={() => setDrivingJobsActiveTab("all")}
                            >
                                Alle Fahrten
                            </Button>
                            <Button
                                variant={drivingJobsActiveTab === "all" ? "outlined" : "contained"}
                                onClick={() => setDrivingJobsActiveTab("my_jobs")}
                            >
                                Meine Fahrten
                            </Button>
                        </ButtonGroup>
                    </div>
                }

                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setOpenDialog(appDialogNames.container.createDriverReport)}
                >
                    {driverReportsData?.length ?
                        "Bericht bearbeiten" :
                        "Bericht erstellen"
                    }
                </Button>
            </div>

            <div className={styles.bottomSection}>
                {!!driverReportsData?.length && showOnlyMyContracts && <>
                    {driverReportsData[0].review_status === 1 ?
                        <Alert color="success" icon={<DoneIcon/>}>
                            Dein Bericht wurde für diesen Tag bestätigt. Du kannst keine Änderungen mehr vornehmen.
                        </Alert>
                        :
                        <Alert color="info" icon={<InfoOutlinedIcon/>}>
                            Du hast bereits einen Bericht für diesen Tag erstellt. Lösche diesen, um wieder Änderungen
                            vornehmen zu können.
                        </Alert>
                    }
                </>}
            </div>
        </div>

        <CreateDriverReportDialog
            selectedDay={selectedDay}
            driverReportData={driverReportsData?.length ? driverReportsData[0] : null}
            setFinalizeContract={setFinalizeContract}
            setFinalizeContractIsC1={setFinalizeContractIsC1}
        />
        <FinalizeDeliveryNoteDialog
            contract={finalizeContract}
            isC1={finalizeContractIsC1}
            onClose={() => {
                /** Do not set finalizeContract to null if finalize delivery-note dialog is opened within the create-driver-report dialog */
                if (fromDriverReportDialog === "1") {
                    /** Use this instead of setOpenDialog to remove "from-driver-report-dialog" query param */
                    setMultipleQueryParams({
                        "dialog": appDialogNames.container.createDriverReport,
                        "from-driver-report-dialog": "0"
                    });
                    // setOpenDialog(appDialogNames.container.createDriverReport);
                } else {
                    closeOpenDialog();
                    setTimeout(() => {
                        setFinalizeContract(null);
                    }, 100);
                }
            }}
        />
    </FullPageDataGrid>;
}
