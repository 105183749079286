import React, { useEffect } from 'react';
import { FirebaseAppProvider, useSigninCheck } from "reactfire";
import { FirebaseOptions } from 'firebase/app';
import { FirestoreGetCompanyMemberData } from "@/firestoreListener/FirestoreGetCompanyMemberData";
import { GlobalUrlChangeLogger } from "@/common/GlobalUrlChangeLogger";
import { GlobalAutoSignOut } from "@/common/GlobalAutoSignOut";
import { FirestoreGetWorkTypesData } from "@/firestoreListener/FirestoreGetWorkTypesData";
import { FirestoreGetSelectedCompanyData } from "@/firestoreListener/FirestoreGetSelectedCompanyData";
import { RegisterProvider } from "@/common/RegisterProvider";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { FirebaseGetCompanyMembers } from "@/firestoreListener/FirebaseGetCompanyMembers";
import { FirestoreGetActiveTimeBookingData } from "@/firestoreListener/FirestoreGetActiveTimeBookingData";
import { GlobalPermissionCheck } from "@/common/GlobalPermissionCheck";
import { FirestoreSyncFCMToken } from "@/firestoreListener/FirestoreSyncFCMToken";
import { AppRoutes } from "@/AppRoutes";

const firebaseConfig: FirebaseOptions = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

export function App() {
    useEffect(() => console.log("App() called"), []);

    return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <RegisterProvider>
            {/**
             * Global app stuff
             * Use these components to prevent full page re-render
             */}
            <FirestoreListener/>
            <GlobalUrlChangeLogger/>
            <GlobalAutoSignOut/>
            <GlobalPermissionCheck/>

            {/** Render main content */}
            <AppRoutes/>

        </RegisterProvider>
    </FirebaseAppProvider>;
}

function FirestoreListener() {
    const { data: userData } = useSigninCheck();

    useEffect(() => {
        console.log("FirestoreListener() called");
    }, []);

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const companyData = useCompanyStore(state => state.companyData);

    // /** Return null if uid does not exist */
    // if (!userData?.user?.uid || !selectedCompanyId) return null;

    /** Return null if uid does not exist */
    return (!userData?.user?.uid || !selectedCompanyId) ?
        null :
        <>
            <FirestoreGetSelectedCompanyData/>
            <FirestoreGetCompanyMemberData/>
            <FirebaseGetCompanyMembers/>
            {/*<FirestoreGetNotificationsCount/>*/} {/* Not needed for now */}
            <FirestoreSyncFCMToken/>

            {companyData.data?.modules?.includes("TIME_BOOKING_MODULE") && <>
                <FirestoreGetWorkTypesData/>
                <FirestoreGetActiveTimeBookingData/>
            </>}
        </>;
}
