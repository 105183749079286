import { LeftClickMenu } from "@/common/components/ContextMenu/LeftClickMenu";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Chip, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { ContainerContractDocument } from "tb-utils";
import { css } from "@emotion/css";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { ContextMenuItemProps } from "@/common/components/ContextMenu/ContextMenuContent";
import { usePermission } from "@/customHooks/usePermission";

const styles = {
    main: css({
        overflow: "hidden",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        placeContent: "center"
    })
}

interface Props {
    isC1: boolean;
    contractData: ContainerContractDocument<Date>;
}

export function DrivingJobOverviewDriverCell(props: Props) {
    const { updateContainerContract } = useFirestoreWrite();
    const { hasPerm } = usePermission();

    const members = useCompanyPersistStore(state => state.members);
    const findAssignedDriver = members.find(m => m.user_id === props.contractData[props.isC1 ? "c1_driver" : "c2_driver"]);

    if (!props.contractData.doc_id) return (
        <Typography variant="body2" color="red">Error finding user id</Typography>
    );

    const updateDriver = (newDriver: string) => {
        updateContainerContract(props.contractData, {
            [props.isC1 ? "c1_driver" : "c2_driver"]: newDriver
        })
            .then(result => {
                console.log("updateContainerContract success", result);
            })
            .catch(err => {
                console.log("updateContainerContract failed", err);
            });
    };

    const menuItems: ContextMenuItemProps[][] = [
        [
            ...members.map(m => ({
                displayName: m.displayName,
                onClick: () => updateDriver(m.user_id)
            }))
        ],
        [
            {
                displayName: "Keinen Fahrer zuweisen",
                icon: <PersonOffIcon/>,
                danger: true,
                onClick: () => updateDriver("")
            }
        ]
    ];

    return <div className={styles.main}>
        <LeftClickMenu items={menuItems} horizontalAlign="center">
            {!findAssignedDriver ?
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    {hasPerm("CONTAINER_CONTRACTS_EDIT") ?
                        <IconButton>
                            <AddIcon sx={{ height: 20, width: "auto" }}/>
                        </IconButton>
                        :
                        <Typography variant="body2">-</Typography>
                    }
                </div>
                :
                <Chip
                    variant="outlined"
                    size="small"
                    color="primary"
                    label={<Typography variant="body2">{findAssignedDriver?.displayName}</Typography>}
                    sx={{ paddingTop: "1px", cursor: "pointer" }}
                />
            }
        </LeftClickMenu>
    </div>;
}