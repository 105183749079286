import { css } from "@emotion/css";
import { Button, IconButton, Typography } from "@mui/material";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { Link, useNavigate, useParams } from "react-router-dom";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { ContainerContractDocument } from "tb-utils";
import { replaceTimestamps } from "@/helper/appUtils";
import { MainFrame } from "@/common/MainFrame";
import { isMobileOnly } from "react-device-detect";
import { DeliveryNoteMainBox } from "@/common/DeliveryNotePage/DeliveryNoteMainBox";
import { DeliveryNoteInfoCard } from "@/common/DeliveryNotePage/DeliveryNoteInfoCard";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import EditIcon from '@mui/icons-material/Edit';
import { appPaths } from "@/helper/appPaths";
import { DeliveryNoteChat } from "@/common/DeliveryNotePage/DeliveryNoteChat";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePermission } from "@/customHooks/usePermission";
import { FinalizeDeliveryNoteDialog } from "@/common/DrivingJobOverviewPage/FinalizeDeliveryNoteDialog";

export function DeliveryNotePage() {
    const styles = {
        main: css({
            // border: "1px solid red",
            display: "flex",
            flexDirection: isMobileOnly ? "column-reverse" : "row",
            gap: 20,
            paddingTop: 10,
        }),
        goBackSection: css({
            display: "flex",
            alignItems: "center",
            gap: 10,
            paddingBottom: 10
        }),
        mainContentWrapper: css({
            flex: 1
        }),
        titleWrapper: css({
            display: "flex",
            gap: 10,
            alignItems: "center",
        })
    };

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const { drivingJobId, contractNumber } = useParams();
    const navigate = useNavigate();
    const { hasPerm } = usePermission();

    const contractRef = doc(useFirestore(), "companies", selectedCompanyId || "-", "container-contracts", drivingJobId || "-");
    const { data, status } = useFirestoreDocData(contractRef, { idField: "doc_id" });
    const contractData = replaceTimestamps<ContainerContractDocument<Date>>(data);

    return <MainFrame
        desktop={{
            size: "small",
            disableBottomPadding: true,
            paddingBottom: 0
        }}
        mobileContentSectionProps={{
            disableBottomPadding: true
        }}
    >
        {status === "loading" ?
            <LoadingSpinnerWithBackdrop disableTransparentBackground/>
            :
            <>
                <div className={styles.goBackSection}>
                    <Button
                        startIcon={<ArrowBackIcon/>}
                        variant="text"
                        onClick={() => navigate(-1)}
                    >
                        Zurück
                    </Button>
                </div>

                <div className={styles.titleWrapper}>
                    <Typography variant="h1">
                        Lieferschein Nr. {contractNumber === "1" ? contractData?.c1_number : contractData?.c2_number}
                    </Typography>
                    {hasPerm("CONTAINER_CONTRACTS_EDIT") && contractData?.doc_id &&
                        <Link to={appPaths.containerContract.edit.replace(":contractId", contractData.doc_id)}>
                            <IconButton>
                                <EditIcon/>
                            </IconButton>
                        </Link>
                    }
                </div>

                <div className={styles.main}>
                    {/*<div style={{ flex: 1, border: "1px solid red" }}>*/}
                    <div className={styles.mainContentWrapper}>
                        <DeliveryNoteMainBox contractData={contractData}/>
                        <DeliveryNoteChat/>
                    </div>
                    <DeliveryNoteInfoCard contractData={contractData}/>
                </div>
            </>
        }

        <FinalizeDeliveryNoteDialog
            contract={contractData}
            isC1={contractNumber === "1"}
        />
    </MainFrame>
}
