import { MobileTimePicker } from "@mui/x-date-pickers";
import { SxProps, TextField } from "@mui/material";
import { Dispatch, SetStateAction } from "react";


interface Props {
    value: Date | null;
    setValue: Dispatch<SetStateAction<Date | null>>;
    toolbarTitle: string;
    error?: boolean;
}

const styles = {
    textfield: {
        width: "67px",
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "none",
        },
        "& .MuiInputBase-input": {
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "center"
        }
    } as SxProps,
};

export function MobileAddWorkElementWorkTimePicker(props: Props) {
    return <MobileTimePicker
        value={props.value}
        onChange={v => props.setValue(v)}
        toolbarTitle={props.toolbarTitle}
        ampm={false}
        inputFormat="kk:mm"
        renderInput={params => (
            <TextField
                sx={styles.textfield}
                {...params}
                error={props.error}
            />
        )}
    />

}