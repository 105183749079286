import { CircularProgress, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";
import { ReactElement } from "react";

interface Props {
    disableTransparentBackground?: boolean;
    background?: string;
    text?: ReactElement | string;
    spinnerSize?: number;
    spinnerThickness?: number;
}

export function LoadingSpinnerWithBackdrop(props: Props) {
    const styles = {
        loadingSection: css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: props.disableTransparentBackground ? "#fff" : (props.background || "#fffc"),
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            color: appColors.blue.main
        })
    };

    return <div className={styles.loadingSection}>
        <CircularProgress
            size={props.spinnerSize}
            thickness={props.spinnerThickness}
        />

        {props.text &&
            <Typography variant="body2">
                {props.text}
            </Typography>
        }
    </div>;
}