import { useAuthStore } from "@/stores/useAuthStore";
import { css } from "@emotion/css";
import { Typography } from "@mui/material";

const styles = {
    main: css({
        userSelect: "text",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        gap: 20
    })
};

export function DevPage() {
    const pushKey = useAuthStore(state => state.pushKey);

    return <div className={styles.main}>
        <Typography fontWeight={500}>Page vor development purposes.</Typography>

        {/** Push key*/}
        <Typography><b>Push key:</b> {pushKey}</Typography>
    </div>;
}