import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { useAuthStore } from "@/stores/useAuthStore";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { CompanyModules } from "tb-utils";

export function useAutoRedirectToSetupPage() {
    const navigate = useNavigate();

    const isSignedIn = useAuthStore(state => state.isSignedIn);
    const companyData = useCompanyStore(state => state.companyData);
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const navigateTo = (path: string, excludedPaths: string[]) => {
        const currentArray = [
            /** Include default paths */
            appPaths.logout,
            appPaths.dev,
            appPaths.devLogger,
            appPaths.devRequests,
            appPaths.tax.main,

            /** Merge custom array */
            ...excludedPaths
        ];

        const isExcludedPath = !!currentArray.find(path => {
            return location.pathname.startsWith(path);
        });

        if (!isExcludedPath) navigate(path);
    };


    /** Find and return url from first enabled module. Defaults to company-settings url */
    const findFirstEnabledModuleUrl = () => {
        const enabledModules = companyData.data?.modules;
        const moduleUrlMapping: { module: CompanyModules, url: string }[] = [
            { module: "TIME_BOOKING_MODULE", url: appPaths.timeBooking },
            { module: "NOTES_MODULE", url: appPaths.notes },
            { module: "CONTAINER_MODULE", url: appPaths.drivingJobs },
            { module: "CUSTOMER_FILE", url: appPaths.cf.main },
        ];

        /** Find first enabled module */
        const findFirst = moduleUrlMapping.find(mapping => enabledModules?.includes(mapping.module));

        return findFirst?.url || appPaths.companySettings
    };

    useEffect(() => {
        if (!isSignedIn) {
            /** Redirect to login page if user is not signed in */
            console.log("useAutoRedirectTosetupPage.ts: Redirect to login page if user is not signed in");
            navigateTo(appPaths.login, [
                appPaths.login,
                appPaths.register
            ]);
        } else if (!selectedCompanyId) {
            /** Redirect to setup-pages if no company is selected */
            console.log("useAutoRedirectTosetupPage.ts: Redirect to setup-pages if no company is selected");
            navigateTo(appPaths.setupPages.mainPage, [
                appPaths.setupPages.mainPage
            ]);
        } else if (location.pathname === "/" && companyData.status === "success") {
            /** Redirect to one enabled component page if the user is signed in and a company is selected */
            console.log("useAutoRedirectTosetupPage.ts: Redirect to one enabled component page if the user is signed in and a company is selected");
            navigate(findFirstEnabledModuleUrl());
        } else {
            console.log("useAutoRedirectTosetupPage.ts: No Redirect");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, selectedCompanyId, companyData, location.pathname]);
}
