import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { appRequest } from "@/helper/appRequest";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "useHandleCompanyCreationTokenInput.ts";

export function useHandleCompanyCreationTokenInput(
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    setCompanyCreationTokenValue: Dispatch<SetStateAction<string>>,
    setCreationTokenError: Dispatch<SetStateAction<string>>
) {
    const navigate = useNavigate();

    const handleCompanyCreationTokenInput = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setCompanyCreationTokenValue(newValue);

        /** Exit function if newValue is not x chars long */
        if (newValue.length !== 5) return;

        setIsLoading(true);

        appRequest.validateCompanyCreationToken({
            creation_token: newValue
        })
            .then(response => {
                if (response.is_valid) {
                    appLogger.info(FILE_NAME, "Successfully validated company creation token");
                    navigate(appPaths.setupPages.createCompany.replace(":creationToken", newValue));
                } else {
                    appLogger.info(FILE_NAME, "Invalid company creation token validated");
                    setCreationTokenError("Erstellungscode ist ungültig");
                }
            })
            .catch(error => {
                appLogger.error(FILE_NAME, "Failed to validate company creation token");
                setCreationTokenError("Ein Fehler ist aufgetreten");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        handleCompanyCreationTokenInput
    };
}