import { useSigninCheck } from "reactfire";
import { CompanyPermissions } from "tb-utils";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";

export function usePermission() {
    const { data: userData } = useSigninCheck();

    const members = useCompanyPersistStore(state => state.members);
    const myPerms = members.find(m => m.user_id === userData?.user?.uid)?.permissions;

    /** Check if current user has required permissions */
    const hasPerm = (perm: CompanyPermissions) => {
        if (myPerms?.includes("ADMIN")) return true;
        return myPerms?.includes(perm);
    };

    return {
        hasPerm,
        myPerms
    }
}