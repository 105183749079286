import { SimpleDialog } from "@/common/SimpleDialog";
import { TextField } from "@mui/material";
import { css } from "@emotion/css";
import { ContainerContractDocument, DeliveryNote } from "tb-utils";
import { useEffect, useState } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useSigninCheck } from "reactfire";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { buildDeliveryNote, ExactContractType, getExactContractType } from "@/helper/appUtils";

const styles = {
    main: css({
        display: "flex",
        flexDirection: "column",
        gap: 14,
    }),
};

interface Props {
    /** Selected delivery-note */
    // deliveryNote: DeliveryNote<Date> | null;
    contract: ContainerContractDocument<Date> | null;
    isC1: boolean;
    /** Optional on close actions */
    onClose?: () => void;
}

export function FinalizeDeliveryNoteDialog(props: Props) {
    const { data: userData } = useSigninCheck();
    const { getOpenDialog, closeOpenDialog } = useDialog();
    const { updateContainerContract } = useFirestoreWrite();

    /** Input values */
    const [timeSpent, setTimeSpent] = useState("");
    const [weight, setWeight] = useState("");
    const [weighbridgeNumber, setWeighbridgeNumber] = useState("");
    const [recyclerAndOthersText, setRecyclerAndOthersText] = useState("");

    const [deliveryNote, setDeliveryNote] = useState<DeliveryNote<Date> | null>(null);

    useEffect(() => {
        if (props.contract)
            setDeliveryNote(buildDeliveryNote(props.contract, props.contract.type, props.isC1));
        else
            setDeliveryNote(null);
    }, [props.contract]);

    const handleClose = () => {
        props.onClose ? props.onClose() : closeOpenDialog();
    };

    /** Close this dialog if `props.contracts` is empty (e.g. page was reloaded) */
    useEffect(() => {
        if (!deliveryNote) handleClose();
    }, []);

    /** Find selected container-contract from props and write it to selectedDn */
    useEffect(() => {
        /** Close dialog if props.deliveryNote is empty */
        const isThisDialogOpen = getOpenDialog === appDialogNames.container.finalizeDeliveryNote;
        if (isThisDialogOpen && !deliveryNote) handleClose();

        /** Set input data if exists */
        setTimeSpent(deliveryNote?.raw.finalize_time_spend || "");
        setWeight(deliveryNote?.raw.finalize_weight || "");
        setWeighbridgeNumber(deliveryNote?.raw.finalize_weighbridge_number || "");
        setRecyclerAndOthersText(deliveryNote?.recycler_and_others_text || "");
    }, [getOpenDialog]);

    function isOneExactType(...oneOf: ExactContractType[]): boolean {
        return oneOf.includes(getExactContractType(deliveryNote?.raw?.type, props.isC1));
    }

    return <SimpleDialog
        name={appDialogNames.container.finalizeDeliveryNote}
        title={`Weitere Daten angeben (LS ${deliveryNote?.number})`}
        className={styles.main}
        disableBackdropClick={true}
        actionButtons={[
            {
                text: (
                    deliveryNote?.raw.finalize_time_spend ||
                    deliveryNote?.raw.finalize_weight ||
                    deliveryNote?.raw.finalize_weighbridge_number
                ) ? "Schließen" : "Später angeben",
                color: "inherit",
                onClick: handleClose
            },
            {
                text: "Speichern",
                onClick: () => {
                    if (!deliveryNote?.raw) return;
                    updateContainerContract(deliveryNote.raw, {
                        finalize_data_changed_by: userData?.user?.uid,
                        finalize_time_spend: timeSpent || null,
                        finalize_weight: weight || null,
                        finalize_weighbridge_number: weighbridgeNumber || null,
                        // [props.contract?.type === 2 ? "c2_recycler_and_others_text" : "c1_recycler_and_others_text"]: recyclerAndOthersText || null,
                        [props.isC1 ? "c1_recycler_and_others_text" : "c2_recycler_and_others_text"]: recyclerAndOthersText || null,
                    });
                    handleClose();
                }
            }
        ]}
    >
        {isOneExactType("umsetzen") &&
            <TextField
                key="Zeitangabe"
                label="Zeitangabe"
                value={timeSpent}
                onChange={e => setTimeSpent(e.target.value)}
            />
        }
        {isOneExactType("delivery", "fetch", "abfahren") &&
            <TextField
                key="Menge"
                label="Menge"
                value={weight}
                onChange={e => setWeight(e.target.value)}
            />
        }
        {isOneExactType("delivery", "fetch", "abfahren") &&
            <TextField
                key="Wiegeschein Nummer"
                label="Wiegeschein Nummer"
                value={weighbridgeNumber}
                onChange={e => setWeighbridgeNumber(e.target.value)}
            />
        }
        {isOneExactType("delivery", "place", "fetch", "abfahren") &&
            <TextField
                key="Verwerter und sonstiges"
                label="Verwerter und sonstiges"
                value={recyclerAndOthersText}
                onChange={e => setRecyclerAndOthersText(e.target.value)}
            />
        }
    </SimpleDialog>
}
