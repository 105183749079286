import { CircularProgress, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { css } from "@emotion/css";
import { appFramerVariants } from "@/helper/appFramerVariants";

const styles = {
    main: css({
        background: "#fff",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 20,
    }),
};

interface Props {
    text?: string;
}

export function MobileDialogProgressSpinner(props: Props) {
    return <motion.div
        className={styles.main}
        variants={appFramerVariants.framerFadeInOut}
        initial="initial"
        animate="animate"
        exit="exit"
    >
        <CircularProgress color="success" thickness={4}/>
        <Typography fontWeight="500">{props.text}</Typography>
    </motion.div>;
}