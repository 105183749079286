import { SimpleDialog } from "@/common/SimpleDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { Alert, Typography } from "@mui/material";
import { ContainerContractDocument, DriverReportDocument } from "tb-utils";
import { useDialog } from "@/customHooks/useDialog";
import { useEffect, useState } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { getDocs, limit, query, where } from "firebase/firestore";
import { appLogger } from "@/helper/appLogger";
import { replaceTimestamps } from "@/helper/appUtils";
import { css } from "@emotion/css";
import format from "date-fns/format";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";

const FILE_NAME = "ConfirmCCDeletionDialog.tsx";

interface Props {
    contract: ContainerContractDocument<Date>;
}

export function ConfirmCCDeletionDialog(props: Props) {
    const thisDialogName = appDialogNames.container.confirmCCDeletion;
    const { getOpenDialog } = useDialog();
    const { collRefs, deleteContainerContract } = useFirestoreWrite();
    const navigate = useNavigate();

    const members = useCompanyPersistStore(state => state.members);

    const [driverReport, setDriverReport] = useState<DriverReportDocument<Date>[]>([]);
    const [isDriverReportsLoading, setIsDriverReportsLoading] = useState(false);
    const [isDeletionLoading, setIsDeletionLoading] = useState(false);

    const driverReportQuery = query(
        collRefs.driverReports(),
        where("delivery_notes", "array-contains-any", [
            props.contract?.doc_id + "/1",
            props.contract?.doc_id + "/2",
        ]),
        limit(3)
    );

    useEffect(() => {
        if (getOpenDialog === thisDialogName) {
            setIsDriverReportsLoading(true);
            getDocs(driverReportQuery)
                .then(result => {
                    const rawRes: any = [];
                    result.forEach(v => rawRes.push(v.data()));
                    const driverReportData = replaceTimestamps<DriverReportDocument<Date>[]>(rawRes);
                    setDriverReport(driverReportData);
                })
                .catch(() => {
                    appLogger.error(FILE_NAME, "Error getting driver-report");
                })
                .finally(() => {
                    setIsDriverReportsLoading(false);
                });
        } else {
            setDriverReport([]);
        }
        console.log("open dialog", getOpenDialog);
    }, [getOpenDialog]);

    return <SimpleDialog
        name={thisDialogName}
        title="Auftrag löschen?"
        fullscreenLoading={{
            loading: isDriverReportsLoading || isDeletionLoading
        }}
        disableBackdropClick={isDeletionLoading}
        actionButtons={[
            {
                text: "Unwiderruflich löschen",
                color: "error",
                onClick: () => {
                    if (props.contract.doc_id) {
                        setIsDeletionLoading(true);
                        deleteContainerContract(props.contract.doc_id)
                            .then(() => {
                                location.href = appPaths.containerContract.overview;
                            })
                            .finally(() => {
                                setIsDeletionLoading(false);
                            });
                    }
                },
            },
            {
                text: "Abbrechen",
                closeDialogOnClick: true,
            }
        ]}
        className={css({
            display: "flex",
            flexDirection: "column",
            gap: 20
        })}
    >
        {!!driverReport.length &&
            <Alert color="warning" icon={false}>
                Dieser Auftrag wurde bereits {driverReport.length} mal in Berichten hinzugefügt:
                <br/>
                {driverReport.map((v, i) => <div key={i}>
                    {i + 1}.
                    Am {format(v.day, "dd.MM.yyyy")} von {members.find(m => m.user_id === v.driver)?.displayName}
                </div>)}
                <br/>
                Wenn dieser Auftrag gelöscht wird, werden die oben genannten Fahrten ebenfalls aus den Berichten
                entfernt.
            </Alert>
        }

        <Typography>Diese Aktion kann nicht rückgängig gemacht werden.</Typography>
    </SimpleDialog>;
}