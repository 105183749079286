import { Alert, Button, Divider, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { useHandleCompanyCreationTokenInput } from "./useHandleCompanyCreationTokenInput";
import { useRedirectIfEmailIsNotVerified } from "./useRedirectIfEmailIsNotVerified";
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { css } from "@emotion/css";
import { appStyles } from "@/helper/appStyles";
import { collectionGroup, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { PendingInvitationDocument } from "tb-utils";
import CheckIcon from '@mui/icons-material/Check';
import { appColors } from "@/helper/appColors";
import { AcceptInvitationData, appRequest } from "@/helper/appRequest";
import { appPaths } from "@/helper/appPaths";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useNavigate } from "react-router-dom";

const styles = {
    invitationBox: css({
        // border: "1px solid red",
        borderRadius: appStyles.borderRadius.smaller,
        background: "#0001",
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "gray",
        gap: 5
    }),
    companyInvitation: css({
        borderRadius: appStyles.borderRadius.smaller,
        background: appColors.blue.veryLight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 14,
        gap: 5,
        color: appColors.typography.title,
    }),
};

export function SetupCreateOrJoinCompany() {
    useRedirectIfEmailIsNotVerified();
    const { data: userData } = useSigninCheck();
    const navigate = useNavigate();

    const setSelectedCompanyId = useCompanyPersistStore(state => state.setSelectedCompanyId);

    const [isLoading, setIsLoading] = useState(false);
    const [companyCreationTokenValue, setCompanyCreationTokenValue] = useState("");
    const [creationTokenError, setCreationTokenError] = useState("");
    const [showJoinCompanyError, setShowJoinCompanyError] = useState(false);

    const pendingInvitationsRef = query(
        collectionGroup(useFirestore(), "pending-invitations"),
        where("email", "==", userData?.user?.email || "")
    );
    const { data, status } = useFirestoreCollectionData(pendingInvitationsRef, { idField: "doc_id" });
    const pendingInvitationsData = replaceTimestamps<PendingInvitationDocument<Date>[]>(data);

    const { handleCompanyCreationTokenInput } = useHandleCompanyCreationTokenInput(
        setIsLoading,
        setCompanyCreationTokenValue,
        setCreationTokenError
    );

    const handleAcceptInvitationClick = async (data: AcceptInvitationData) => {
        setShowJoinCompanyError(false);
        setIsLoading(true);

        await appRequest.acceptInvitation(data)
            .then(result => {
                setSelectedCompanyId(result.joined_company_id);
                navigate(appPaths.root);
            })
            .catch(() => {
                setShowJoinCompanyError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return <DesktopSetupMainFrame
        title="Firma beitreten oder erstellen"
        gap="small"
        disableLogoutButton={isLoading}
    >
        <Typography variant="h4">
            Firma beitreten
        </Typography>

        {pendingInvitationsData?.length ?
            <>
                <Typography variant="body2">
                    Du wurdest von folgender Firma eingeladen:
                </Typography>
                {pendingInvitationsData?.map((invitation, index) => (
                    <div key={index} className={styles.companyInvitation}>
                        <div>
                            <Typography fontWeight={500} noWrap>
                                {invitation.company_name}
                            </Typography>
                            <Typography variant="body2" noWrap>
                                von {invitation.creator_display_name}
                            </Typography>
                        </div>
                        <div>
                            <Button
                                color="secondary"
                                startIcon={<CheckIcon/>}
                                size="small"
                                onClick={() => handleAcceptInvitationClick({
                                    company_id: invitation.company_id,
                                    pending_invitation_id: invitation.doc_id || ""
                                })}
                            >
                                Beitreten
                            </Button>
                        </div>
                    </div>
                ))}

                {showJoinCompanyError &&
                    <Alert
                        icon={false}
                        color="error"
                        onClose={() => setShowJoinCompanyError(false)}
                    >
                        Fehler beim Beitreten der Firma
                    </Alert>
                }
            </>
            :
            <div className={styles.invitationBox}>
                <DraftsOutlinedIcon sx={{ fontSize: 30 }}/>
                <Typography variant="body2">Keine Einladungen</Typography>
            </div>
        }


        {/*<Typography>*/}
        {/*    Um einer Firma beizutreten, klicke auf einen Einladungslink. Dieser kann von einem Admin einer*/}
        {/*    Firma ausgestellt werden.*/}
        {/*</Typography>*/}
        {/*<TextField*/}
        {/*    label="Einladungslink eingeben"*/}
        {/*/>*/}

        <Divider sx={{ my: 2 }}/>

        <Typography variant="h4">
            Firma erstellen
        </Typography>
        <Typography>
            Um eine neue Firma erstellen zu können, benötigst du einen Erstellungscode.
        </Typography>
        <TextField
            id="creation-token-textfield" // Used to disable browser autocomplete
            label="Erstellungscode eingeben"
            value={companyCreationTokenValue}
            onChange={handleCompanyCreationTokenInput}
        />

        {creationTokenError &&
            <Alert
                icon={false}
                color="error"
                onClose={() => setCreationTokenError("")}
            >
                {creationTokenError}
            </Alert>
        }

        {(isLoading || status === "loading") &&
            <LoadingSpinnerWithBackdrop disableTransparentBackground/>
        }
    </DesktopSetupMainFrame>;
}