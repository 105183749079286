import { css } from "@/helper/css";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TodayIcon from '@mui/icons-material/Today';
import AddIcon from '@mui/icons-material/Add';
import { appDialogNames } from "@/helper/appDialogNames";
import { useDialog } from "@/customHooks/useDialog";
import { isThisMonth } from "date-fns";

const styles = {
    main: css({
        // border: "1px solid red",
        display: "flex",
        alignItems: "center",
        height: 40,
        padding: "0px 5px 10px",
    }),
    gapElement: css({
        flex: 1
    }),
    buttonsWrapper: css({
        display: "flex",
        flexDirection: "row",
        gap: 10
    })
};

interface Props {
    /** Title text */
    text: string;
    /** Show next month/week/day */
    showNextClick: () => void;
    /** Show previous month/week/day */
    showPrevClick: () => void;
    /** Show current month/week/day */
    showTodayClick: () => void;
    /** True if selected date is current month/week/day */
    isToday: boolean;
}

export function M_OverviewButtonSection(props: Props) {
    const { setOpenDialog } = useDialog();
    return <div style={styles.main}>
        <Typography fontWeight="500">
            {props.text}
        </Typography>

        <div style={styles.gapElement}/>

        <div style={styles.buttonsWrapper}>
            <IconButton onClick={() => setOpenDialog(appDialogNames.timeBooking.addWorkElement)}>
                <AddIcon/>
            </IconButton>

            <IconButton
                disabled={props.isToday}
                onClick={props.showTodayClick}
            >
                <TodayIcon/>
            </IconButton>

            <IconButton onClick={props.showPrevClick}>
                <ArrowBackIosNewIcon/>
            </IconButton>

            <IconButton onClick={props.showNextClick}>
                <ArrowForwardIosIcon/>
            </IconButton>
        </div>

    </div>;
}