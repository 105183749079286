import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export function useQueryParam(key: string, fallback?: string) {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchAsObject = Object.fromEntries(new URLSearchParams(searchParams));

    const [value, setValue] = useState<string | null>("");
    useEffect(() => {
        setValue(searchParams.get(key));
    }, [searchParams]);

    const setQueryParam = (value: string) => {
        setSearchParams({
            ...searchAsObject,
            [key]: value
        });
    };

    /**
     * Use this to update multiple query-params at once.
     * It is not possible to use setQueryParam-function to update 2 or more at query-params
     * the same time (the second value-change will overwrite the previous one).
     */
    const setMultipleQueryParams = (params: {
        [s: string]: string
    }) => {
        setSearchParams({
            ...searchAsObject,
            ...params
        });
    };

    const removeQueryParam = () => {
        const tempSearchObj = { ...searchAsObject };
        delete tempSearchObj[key];

        setSearchParams(
            { ...tempSearchObj, },
            // { replace: true }
        );
    };

    return {
        value: value || (fallback || null),
        set: setQueryParam,
        setMultipleQueryParams,
        remove: removeQueryParam
    };
}
