import { Badge, Button, IconButton, Typography } from "@mui/material";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import InboxIcon from "@mui/icons-material/Inbox";
import { D_NotificationItem } from "@/desktop/components/D_NotificationItem";
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { LeftClickMenu } from "@/common/components/ContextMenu/LeftClickMenu";
import React, { useEffect, useState } from "react";
import { limit, orderBy, query, Timestamp, where } from "firebase/firestore";
import { useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { NotificationsDocument } from "tb-utils";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { css } from "@emotion/css";
import { useNotification } from "@/customHooks/useNotification";
import { appLogger } from "@/helper/appLogger";
import { useUserStore } from "@/stores/useUserStore";
import { isAfter, isBefore } from "date-fns";

const FILE_NAME = "D_Notifications.tsx";

const styles = {
    notificationsWrapper: css({
        display: "flex",
        flexDirection: "column",
        gap: 5,
        padding: "8px 12px"
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 10
    }),
    notificationsEmptyOrLoading: css({
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        position: "relative"
    }),
    noPermissionsWrapper: css({
        // border: "1px solid red",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        padding: "20px 0"
    }),
};

const docLimit = 50;

export function D_Notifications() {
    const { permission } = useNotification();
    const { collRefs, updateCompanyMember } = useFirestoreWrite();
    const { data: userData } = useSigninCheck();
    const [isOpen, setIsOpen] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);

    const companiesMember = useUserStore(state => state.companiesMember);
    const me = companiesMember.find(m => m.user_id === userData?.user?.uid);

    const notificationsQuery = query(
        collRefs.notifications(),
        where("receiver", "==", userData?.user?.uid || "---"),
        orderBy("created_at", "desc"),
        limit(docLimit)
    );
    const { data, status: notifStatus } = useFirestoreCollectionData(notificationsQuery, { idField: "doc_id" });
    const notifData = replaceTimestamps<NotificationsDocument<Date>[]>(data);

    /** Update notifications_read_at when notifications popup is closed */
    const updateNotificationsReadDate = () => {
        console.log("updateNotificationsReadDate");
        setIsOpen(false);

        if (!userData?.user?.uid) return;
        updateCompanyMember(userData.user.uid, {
            notifications_read_at: Timestamp.now()
        })
            .then(() => appLogger.event(FILE_NAME, "Updated 'notifications_read_at' for current user (set to now)"))
            .catch(err => appLogger.error(FILE_NAME, "Error updating 'notifications_read_at': " + JSON.stringify(err)));
    };

    /** Update new notificationCount */
    useEffect(() => {
        const newNotifications = notifData
            ?.filter(n => isBefore(me?.notifications_read_at || new Date(), n.created_at))
            .length;
        setNotificationCount(newNotifications);
    }, [me, data]);

    return <LeftClickMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        width={450}
        items={[]}
        gapToParent="small"
        customHtml={<NotificationsContent
            notifData={notifData}
            notifStatus={notifStatus}
            notifications_read_at={me?.notifications_read_at || new Date()}
            notificationItemLinkClicked={updateNotificationsReadDate}
        />}
        onClose={updateNotificationsReadDate}
    >
        <IconButton>
            <Badge
                badgeContent={notificationCount}
                max={40}
                color="primary"
                overlap="circular"
            >
                {permission === "granted" ?
                    <NotificationsNoneOutlinedIcon/> :
                    <NotificationsOffOutlinedIcon color="error"/>
                }

            </Badge>
        </IconButton>
    </LeftClickMenu>;
}

interface NotificationsContentProps {
    notifData: NotificationsDocument<Date>[];
    notifStatus: "loading" | "success" | "error";
    notifications_read_at: Date;
    notificationItemLinkClicked: () => void;
}

function NotificationsContent(props: NotificationsContentProps) {
    const { permission, requestPermission } = useNotification();

    return <div className={styles.notificationsWrapper}>
        {permission === "granted" ?
            <>
                <div className={styles.header}>
                    <Typography fontWeight={500}>
                        Benachrichtigungen
                    </Typography>
                </div>
                {props.notifStatus === "loading" && (
                    <div className={styles.notificationsEmptyOrLoading}>
                        <LoadingSpinnerWithBackdrop/>
                    </div>
                )}
                {props.notifStatus === "success" && props.notifData?.length === 0 && (
                    <div className={styles.notificationsEmptyOrLoading}>
                        <InboxIcon sx={{ fontSize: 60, color: "#888" }}/>
                        <Typography>Keine Benachrichtigungen</Typography>
                    </div>
                )}
                {props.notifData?.map((n, index) => (
                    <D_NotificationItem
                        key={index}
                        title={n.title}
                        message={n.message}
                        date={n.created_at}
                        linktTo={n.linkTo}
                        read={isAfter(props.notifications_read_at || new Date(), n.created_at)}
                        linkClicked={props.notificationItemLinkClicked}
                    />
                ))}

                {props.notifData?.length >= docLimit && (
                    <div>
                        <br/>
                        <Typography>
                            Es werden maximal {docLimit} Benachrichtigungen angezeigt.
                        </Typography>
                    </div>
                )}
            </>
            :
            <div className={styles.noPermissionsWrapper}>
                <NotificationsOffOutlinedIcon color="disabled" sx={{ fontSize: 60 }}/>

                {permission === "denied" ?
                    <Typography textAlign="center">
                        Die Benachrichtigungen für diese Web-App wurden vom Browser deaktiviert. Um sie zu aktivieren,
                        klicke bitte auf das Info-Symbol neben der Adresszeile.
                    </Typography>
                    :
                    <>
                        <Typography textAlign="center">
                            Benachrichtigungen sind deaktiviert.
                        </Typography>
                        <Button onClick={requestPermission}>
                            Aktivieren
                        </Button>
                    </>
                }
            </div>
        }
    </div>;
}
