import { motion } from "framer-motion";
import { Typography } from "@mui/material";
import { appStyles } from "@/helper/appStyles";
import { css, cx } from "@emotion/css";
import { WorkType } from "@/stores/useTimeBookingStore";
import { Dispatch, SetStateAction } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useSigninCheck } from "reactfire";
import { serverTimestamp } from "firebase/firestore";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "M_WorkElement.tsx";

const styles = {
    main: css({
        height: 150,
        width: 120,
        background: "#068",
        borderRadius: appStyles.borderRadius.normal,
        flexShrink: 0,
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        color: "#fff"
    }),
    circle: css({
        position: "absolute",
        background: appStyles.backgroundCircleColor.normal,
        transform: "translateY(-50%)",
        borderRadius: "50%",
        left: -10,
        top: -60,
    }),
    firstCircle: css({
        height: 250,
        width: 200,
    }),
    secondCircle: css({
        height: 350,
        width: 250,
    }),
    topSection: css({
        flex: 1
    }),
    content: css({
        padding: "0px 14px 20px",
    }),
};


interface WorkElementProps {
    workType: WorkType;
    disabled: boolean;
    isClickLoading: boolean;
    setIsClickLoading: Dispatch<SetStateAction<boolean>>;
}

export function M_WorkElement(props: WorkElementProps) {
    const { data: userData } = useSigninCheck();
    const { createTimeBooking } = useFirestoreWrite();

    const handleClick = () => {
        if (props.disabled) return;

        createTimeBooking({
            break_length: 0,
            end_time: null,
            start_time: new Date(),
            user_id: userData?.user?.uid || "",
            work_type: props.workType.doc_id,
            created_at: serverTimestamp(),
        })
            .catch(err => {
                appLogger.error(FILE_NAME, "Error creating time-booking");
            });
    };

    return <motion.div
        className={cx(styles.main, css({ background: props.workType.color }))}
        onClick={handleClick}
        whileTap={{ scale: .95 }}
        animate={{
            scale: props.isClickLoading ? .95 : 1,
            opacity: props.isClickLoading ? .5 : 1
        }}
    >
        <div className={cx(styles.circle, styles.firstCircle)}/>
        <div className={cx(styles.circle, styles.secondCircle)}/>

        <div className={styles.topSection}/>

        <div className={styles.content}>
            <Typography>{props.workType.name}</Typography>
        </div>
    </motion.div>;
}