import { C_CompanySettingsFrame } from "@/common/companySettings/C_CompanySettingsFrame";
import { Alert } from "@mui/material";
import { C_SettingsSection } from "@/common/companySettings/C_SettingsSection";

export function C_ManageTargetHoursPage() {
    return <C_CompanySettingsFrame title="Sollstunden">
        <C_SettingsSection>
            <Alert color="warning" icon={false} variant="filled">
                Aktuell keine Funktion
            </Alert>
        </C_SettingsSection>
    </C_CompanySettingsFrame>;
}