import { MobileLiveCounter } from "@/mobile/pages/MobileTimeBookingPage/MobileLiveCounter";
import { M_SelectWorkType } from "@/mobile/pages/MobileTimeBookingPage/M_SelectWorkType";
import { MobileTimeBookingOverview } from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";

export function MobileTimeBookingPage() {
    // const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    //
    // const timeBookingsRef = collection(useFirestore(), "companies", selectedCompanyId, "time-bookings")
    // const { data, status } = useFirestoreCollectionData(timeBookingsRef, { idField: "doc_id" });
    //
    // useEffect(() => {
    //     console.log(status, data);
    // }, [data, status]);


    return <M_MainFrame>
        <MobileLiveCounter/>
        <M_SelectWorkType/>
        <MobileTimeBookingOverview/>
    </M_MainFrame>;
}