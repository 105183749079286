import { isThisYear, isToday } from "date-fns";
import format from "date-fns/format";

export function deliveryNoteMessageDate(createdAt: Date) {
    let formatStyle: string;
    if (isToday(createdAt)) {
        formatStyle = "HH:mm";
    } else if (isThisYear(createdAt)) {
        formatStyle = "dd.MM. HH:mm";
    } else if (!createdAt) {
        /** True if message was just send (optimistic update) and has currently no created_at field */
        formatStyle = "HH:mm";
    } else {
        formatStyle = "dd.MM.yyyy HH:mm";
    }
    return format(createdAt || new Date(), formatStyle)
}