import XLSX from 'xlsx-js-style';
import { CisData, CisMonth, findPriceInString, getMonthInfo } from "@/ModuleCalendarToIncomeStatement/CisUtils";
import format from "date-fns/format";
import Decimal from "decimal.js";

interface ExcelOptions {
    taxNumber: string;
    fileName: string;
    tableTitle: string; // The title shown in each table in the first row (not the sheet name)
}

function excelMonthSheet(cisMonth: CisMonth, monthAndYear: string, options: ExcelOptions): {
    ws: XLSX.WorkSheet,
    incomeSum: number,
    expenseSum: number
} {
    const data: (string | number)[][] = [
        [options.tableTitle],
        ['Steuernummer:', options.taxNumber],
        [],
        [`Einnahmen-Überschuss-Rechnung ${monthAndYear}`],
        [],
        ['Datum', 'Bezeichnung', 'Einnahme', 'Ausgabe'],
    ];

    let incomeSum = new Decimal(0);
    let expenseSum = new Decimal(0);

    /** Push income/expense */
    cisMonth.events.forEach(event => {
        /** Date */
        const startDateRaw = new Date(event.data.start?.dateTime || event.data.start?.date);
        const startDate = startDateRaw ? format(startDateRaw, "dd.MM.yyyy") : "";

        /** Income and expense */
        const price = findPriceInString(event.data.summary || '');
        const income = (!price || price.isNegative || !price.validDecimalLength) ? "" : price.asNumber;
        const expense = (!price || !price.isNegative || !price.validDecimalLength) ? "" : Math.abs(price.asNumber);

        /** Sum */
        if (income) incomeSum = incomeSum.add(income);
        if (expense) expenseSum = expenseSum.add(expense);

        /** Add row */
        data.push([startDate, 'Kunde', income, expense]); // TODO: Make start date date-type in excel
    });

    /** Push month summary */
    data.push(
        [],
        [],
        ['Summe Einnahmen:', '', incomeSum.toNumber()],
        ['Summe Ausgaben:', '', '', expenseSum.toNumber()],
        ['Gesamt:', incomeSum.sub(expenseSum).toNumber()],
        []
    );

    /** Create a new worksheet from the data */
    const ws = XLSX.utils.aoa_to_sheet(data);

    /** Define column widths */
    ws['!cols'] = [
        { wch: 18 }, // Width for the "Date" column
        { wch: 16 }, // Width for the "Name" column
        { wch: 16 },  // Width for the "Income" column
        { wch: 16 }  // Width for the "Expense" column
    ];

    /** Merge cells */
    ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 2 } }
    ];

    /** Calculate summary cells */
    const summaryCellsRowOffset = 7 + cisMonth.events.length + 2;
    const summaryTitleCells = [
        'A' + summaryCellsRowOffset,
        'A' + (summaryCellsRowOffset + 1),
        'A' + (summaryCellsRowOffset + 2),
    ];
    const summaryValueCells = [
        'C' + summaryCellsRowOffset,
        'D' + (summaryCellsRowOffset + 1),
        'B' + (summaryCellsRowOffset + 2),
    ]

    /** Border bottom */
    const headerCells = ['A6', 'B6', 'C6', 'D6'];
    headerCells.forEach((cell) => {
        if (!ws[cell]) ws[cell] = {};
        ws[cell].s = { border: { bottom: { style: 'thick', color: { rgb: "000000" } } } };
    });

    /** Bold text */
    const boldCells = ['A1', 'A4', ...summaryTitleCells, ...summaryValueCells];
    boldCells.forEach((cell) => {
        if (!ws[cell]) ws[cell] = {}; // Ensure the cell exists
        ws[cell].s = { ...ws[cell].s, font: { bold: true } }; // Apply the bold font style
    });

    /** Underline text */
    const underlineCells = [...summaryValueCells];
    underlineCells.forEach((cell) => {
        if (!ws[cell]) ws[cell] = {}; // Ensure the cell exists
        ws[cell].s = { ...ws[cell].s, font: { ...ws[cell].s.font, underline: true } }; // Apply the bold font style
    });

    /** Set euro format */
    const euroCells = cisMonth.events.flatMap((event, index) => {
        const beginOffset = 7;
        return [`C${index + beginOffset}`, `D${index + beginOffset}`];
    });

    const euroFormatCells = [...euroCells, ...summaryValueCells];
    euroFormatCells.forEach((cell) => {
        if (!ws[cell]) ws[cell] = {};
        ws[cell].s = { ...ws[cell].s, numFmt: '#,##0.00" €"' };
    });

    return {
        ws,
        incomeSum: incomeSum.toNumber(),
        expenseSum: expenseSum.toNumber()
    };
}

type NumbersSummary = Record<string, { income: number, expense: number; }>;

function excelSummarySheet(numbersSummary: NumbersSummary) {
    const data: (string | number)[][] = [
        ['GESAMTSUMME'],
        [],
        ['', 'EINNAHMEN', 'AUSGABEN', ''], // TODO: Make background gray,
    ];

    let totalIncome2 = new Decimal(0);
    let totalExpense2 = new Decimal(0);

    /** Add months */
    Object.entries(numbersSummary).forEach(([monthKey, { income, expense }]) => {
        const monthInfo = getMonthInfo(monthKey);
        data.push([monthInfo.monthName, income, expense]);

        totalIncome2 = totalIncome2.add(income);
        totalExpense2 = totalExpense2.add(expense)
    });

    /** Add footer */
    data.push(
        [],
        ['GESAMT', totalIncome2.toNumber(), totalExpense2.toNumber()],
        [],
        ['GEWINN GESAMT', '', totalIncome2.sub(totalExpense2).toNumber()],
    );

    /** Create a new worksheet from the data */
    const ws = XLSX.utils.aoa_to_sheet(data);

    /** Define column widths */
    ws['!cols'] = [
        { wch: 18 },
        { wch: 16 },
        { wch: 16 },
        { wch: 16 },
        { wch: 16 },
    ];

    /** Merge cells */
    ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 4 } },
    ];

    /** Set euro format */
    const tempEuroCells = [...Object.keys(numbersSummary)];
    tempEuroCells.push("", "", "", "");

    const euroCells = tempEuroCells.flatMap((value, index) => {
        const beginOffset = 4;
        return [`B${index + beginOffset}`, `C${index + beginOffset}`];
    });

    const euroFormatCells = [...euroCells];
    euroFormatCells.forEach((cell) => {
        if (!ws[cell]) ws[cell] = {};
        ws[cell].s = { ...ws[cell].s, numFmt: '#,##0.00" €"' };
    });

    return ws;
}

export function cisRenderExcel(cisData: CisData, options: ExcelOptions) {
    /** Create the workbook */
    const wb = XLSX.utils.book_new();

    /** Summary */
    const numbersSummary: NumbersSummary = {};

    /** Create sheets for each month */
    Object.entries(cisData.months).map(([monthKey, month]) => {
        const monthInfo = getMonthInfo(monthKey);

        /** Do not create month sheet if no events are selected */
        if (month.selectedEventIds.length === 0) {
            return;
        }

        const onlySelectedEvents = (): CisMonth => {
            const tempMonth = structuredClone(month);
            tempMonth.events = tempMonth.events.filter(event => tempMonth.selectedEventIds.includes(event.id));
            return tempMonth;
        }

        const { ws, incomeSum, expenseSum } = excelMonthSheet(onlySelectedEvents(), monthInfo.full, options);
        XLSX.utils.book_append_sheet(wb, ws, monthInfo.full);

        numbersSummary[monthKey] = {
            income: incomeSum,
            expense: expenseSum
        };
    });

    /** Create summary sheet */
    const summarySheet = excelSummarySheet(numbersSummary);
    const getCurrentYear = getMonthInfo(Object.keys(cisData.months)?.[0]).yearNumber;
    XLSX.utils.book_append_sheet(wb, summarySheet, `Zusammenfassung ${getCurrentYear || ""}`);

    /** Convert workbook to binary and trigger download */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    /** Create a Blob from the binary data */
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    /** Create a link element */
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = options.fileName;

    /** Append the link to the document and trigger the download */
    document.body.appendChild(link);
    link.click();

    /** Clean up and remove the link */
    document.body.removeChild(link);
}
