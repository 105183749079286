import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { DocumentData, FieldValue } from "firebase/firestore";
import { CompanyMemberDocument } from "tb-utils";
import { replaceTimestamps } from "@/helper/appUtils";

type CompaniesMemberType = DocumentData[];
type NotificationPermsType = NotificationPermission | undefined;

interface UserStore {
    /** All the documents where the user is a company member of */
    companiesMember: CompanyMemberDocument<Date>[];
    setCompaniesMember: (newData: CompanyMemberDocument<Date>[]) => void;

    /** The current browser settings for push notifications. Used by useNotifications() hook */
    notificationPerms: NotificationPermsType;
    updateNotificationPerms: () => void;
}

export const useUserStore = create<UserStore>()(devtools(set => ({
    companiesMember: [],
    setCompaniesMember: newData => set({
        companiesMember: replaceTimestamps<CompanyMemberDocument<Date>[]>(newData)
    }),

    notificationPerms: undefined,
    updateNotificationPerms: () => {
        let newValue: NotificationPermsType = undefined;
        if ("Notification" in window) newValue = Notification.permission;
        set({
            notificationPerms: newValue
        });
    }
})));