export const appColors = {
    blue: {
        main: "#08c",
        veryLight: "#d0e7fa",
    },
    green: {
        main: "#084",
        light: "#0c8",
    },
    backgroundGray: {
        light: "#f5f5f8",
        main: "#eee",
        dark: "#ddd",
    },
    typography: {
        title: "#333"
    },
    mobileCalendarBorderColor: "#e0e0e0"
};