import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import format from "date-fns/format";
import { DeliveryNote, DriverReportDocument } from "tb-utils";
import { useMemo } from "react";
import { contractType, safeFormatDate } from "@/helper/appUtils";

const s = StyleSheet.create({
    page: {
        flexDirection: "column",
        fontSize: 10,
        padding: 30
    },
    title: {
        paddingBottom: 6
    },
    table: {
        border: "1px solid #000",
        borderBottom: "none",
        flexDirection: "row",
        marginBottom: 5
    },
    tableColumn: {
        // flexGrow: 1
    },
    subHeader: {
        fontSize: 9,
        height: 18
    },
    tableItem: {
        height: 27,
        justifyContent: "center",
        paddingLeft: 5,
        paddingRight: 5,
        width: "100%"
    },
    splitItem: {
        flexDirection: "row"
    },
    bb: {
        borderBottom: "1px solid #000",
    },
    br: {
        borderRight: "1px solid #000",
    },
    bbr: {
        borderBottom: "1px solid #000",
        borderRight: "1px solid #000",
    },
    bold: {
        fontWeight: "ultralight"
    },

    footer: {
        border: "1px solid #000",
        flexGrow: 1,
    },
    footerTop: {
        padding: 5
    },
    checks: {
        flexDirection: "row",
        gap: 10,
        paddingBottom: 10,
    },
    checksItem: {
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
    },
    checksCheckbox: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 7,
        width: 7,
        borderRadius: "30%",
        border: "1px solid #000"
    },
    checksCheckboxChecked: {
        position: "absolute",
        height: 11,
        width: 1,
        backgroundColor: "#000",
    },
    footerBottom: {
        flexGrow: 1,
        flexDirection: "row",
    }
});

interface DriverReportData {
    driver: string;
    pdfName: string;
    drivingJobs: (DeliveryNote<Date> | undefined)[];
    signatureImageUrl: string;
}

interface Props {
    data: DriverReportData;
    driverReportData: DriverReportDocument<Date>;
}

export function PdfDriverReportDocument(props: Props) {

    console.log(">>>", props.driverReportData)
    const tableRows = [...Array(12).keys()];
    const data = useMemo(() => {
        return props.data.drivingJobs.concat(
            [...Array(Math.max(12 - props.data.drivingJobs.length, 0)).keys()].map(() => undefined)
        );
    }, [props.data.drivingJobs]);

    const footerChecks: { text: string; checked: boolean }[] = [
        { text: "Abfahrtskontrolle durchgeführt", checked: true },
        { text: "Papiere vollständig", checked: false },
        { text: "Ausrüstung vollständig", checked: false },
        { text: "Ü", checked: false },
        { text: "T", checked: false },
        { text: "W", checked: false },
    ];

    return <Document title={props.data.pdfName}>
        <Page size="A4" orientation="landscape" style={s.page}>
            <Text style={s.title}>Tagesbericht Peschke Containerdienst GmbH</Text>

            {/** Top Table */}
            <View style={s.table}>
                <View style={[s.tableColumn, { width: 170 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text>Fahrer: {props.data.driver}</Text></View>
                    <View style={[s.tableItem, s.bbr]}>
                        <Text>Kennz.: {props.driverReportData.license_plate}</Text>
                    </View>
                </View>
                <View style={[s.tableColumn, { width: 170 }]}>
                    <View style={[s.tableItem, s.bbr]}>
                        <Text>Datum: {format(props.driverReportData.day, "dd.MM.yyyy")}</Text>
                    </View>
                    <View style={[s.tableItem, s.bbr]}>
                        <Text>Kennz. Anh.: {props.driverReportData.license_plate_trailer}</Text>
                    </View>
                </View>
                <View style={[s.tableColumn, { width: 100 }]}>
                    <View style={[s.tableItem, s.bbr]}>
                        <Text>Beginn: {safeFormatDate(props.driverReportData.start_time, "HH:mm")}</Text>
                    </View>
                    <View style={[s.tableItem, s.bbr]}>
                        <Text>Ende: {safeFormatDate(props.driverReportData.end_time, "HH:mm")}</Text>
                    </View>
                </View>
                <View style={[s.tableColumn, { width: 100 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text> </Text></View>
                    <View style={[s.tableItem, s.bbr]}><Text>Pause: {props.driverReportData.break_length}</Text></View>
                </View>

                <View style={[s.tableColumn, { flexGrow: 1 }]}>
                    <View style={[s.tableItem, s.bb]}><Text>TÜV Anhänger</Text></View>
                    <View style={[s.tableItem, s.bb]}><Text>TÜV LKW</Text></View>
                </View>
                <View style={[s.tableColumn, { flexGrow: 1 }]}>
                    <View style={[s.tableItem, s.bb]}><Text>SP Anhänger</Text></View>
                    <View style={[s.tableItem, s.bb]}><Text>SP LKW</Text></View>
                </View>
            </View>

            {/** Main Table */}
            <View style={s.table}>
                <View style={[s.tableColumn, { width: 70 }]}>
                    <View style={[s.tableItem, s.bbr, s.bold]}><Text>Auftrags Nr.</Text></View>
                    <View style={[s.tableItem, s.bbr, s.subHeader]}><Text> </Text></View>
                    {data.map((v, i) => (
                        <View key={i} style={[s.tableItem, s.bbr,]}>
                            <Text>{v?.number ? `LS ${v.number}` : ""}</Text>
                        </View>
                    ))}
                </View>
                <View style={[s.tableColumn, { width: 190 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text>Herkunft</Text></View>
                    <View style={[s.tableItem, s.bbr, s.subHeader]}><Text>Baustelle / Kunde</Text></View>
                    {data.map((v, i) => (
                        <View key={i} style={[s.tableItem, s.bbr]}><Text>{v?.raw.c1_da_recipient}</Text></View>
                    ))}
                </View>
                <View style={[s.tableColumn, { width: 70 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text>Behälter</Text></View>
                    {/*<View style={[s.tableItem, s.bbr, s.subHeader]}><Text>m³ | Nr.</Text></View>*/}
                    <View style={s.splitItem}>
                        <View style={[s.tableItem, s.bbr, s.subHeader, { width: 40 }]}><Text>m³</Text></View>
                        <View style={[s.tableItem, s.bbr, s.subHeader, { flexGrow: 1 }]}><Text>Nr.</Text></View>
                    </View>
                    {data.map((v, i) => <View style={s.splitItem} key={i}>
                        <View style={[s.tableItem, s.bbr, { width: 40 }]}><Text>{v?.raw.container_size}</Text></View>
                        <View style={[s.tableItem, s.bbr, { flexGrow: 1 }]}><Text></Text></View>
                    </View>)}
                </View>
                <View style={[s.tableColumn, { width: 68 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text>Leistung</Text></View>
                    <View style={[s.tableItem, s.bbr, s.subHeader]}><Text> </Text></View>
                    {data.map((v, i) => <View style={s.splitItem} key={i}>
                        <View style={[s.tableItem, s.bbr, { width: 68 }]}>
                            <Text>
                                {v?.contractType === 2 ?
                                    contractType.c2Name() :
                                    contractType.c1Name(v?.raw.type || 0)}
                            </Text>
                        </View>
                        {/*<View key={v} style={[s.tableItem, s.bbr, { width: 17 }]}><Text></Text></View>*/}
                        {/*<View key={v} style={[s.tableItem, s.bbr, { width: 17 }]}><Text></Text></View>*/}
                        {/*<View key={v} style={[s.tableItem, s.bbr, { width: 17 }]}><Text></Text></View>*/}
                        {/*<View key={v} style={[s.tableItem, s.bbr, { width: 17 }]}><Text></Text></View>*/}
                    </View>)}
                </View>
                <View style={[s.tableColumn, { width: 240 }]}>
                    <View style={[s.tableItem, s.bbr]}><Text>Abfall</Text></View>
                    {/*<View style={[s.tableItem, s.bbr, s.subHeader]}><Text>Menge | Bezeichnung |*/}
                    {/*    Abfallschlüsselnr.</Text></View>*/}
                    <View style={s.splitItem}>
                        <View style={[s.tableItem, s.bbr, s.subHeader, { width: 50 }]}><Text>Menge</Text></View>
                        {/*<View style={[s.tableItem, s.bbr, s.subHeader, { width: 90 }]}><Text>Bezeichnung</Text></View>*/}
                        <View style={[s.tableItem, s.bbr, s.subHeader, { flexGrow: 1 }]}>
                            {/*<Text>Abfallschlüsselnr.</Text>*/}
                            {/*<Text>Abfall / Baustoff</Text>*/}
                        </View>
                    </View>
                    {data.map((v, i) => <View style={s.splitItem} key={i}>
                        <View style={[s.tableItem, s.bbr, { width: 50 }]}><Text>{v?.raw.finalize_weight}</Text></View>
                        {/*<View style={[s.tableItem, s.bbr, { width: 90 }]}><Text></Text></View>*/}
                        <View style={[s.tableItem, s.bbr, { flexGrow: 1 }]}><Text>{v?.waste_key}</Text></View>
                    </View>)}
                </View>
                <View style={[s.tableColumn, { width: 142 }]}>
                    <View style={[s.tableItem, s.bb]}><Text>Verwerter / Sonstiges</Text></View>
                    <View style={[s.tableItem, s.bb, s.subHeader]}><Text> </Text></View>
                    {data.map((v, i) => (
                        <View key={i} style={[s.tableItem, s.bb]}>
                            <Text>{v?.recycler_and_others_text}</Text>
                        </View>
                    ))}
                </View>
            </View>


            {/** Footer */}
            <View style={s.footer}>
                <View style={[s.footerTop, s.bb]}>
                    <View style={s.checks}>
                        <Text>Interne Kontrollen:</Text>
                        {footerChecks.map((check, i) => (
                            <View key={i} style={s.checksItem}>
                                <View style={s.checksCheckbox}>

                                    {check.checked && <>
                                        <View style={[s.checksCheckboxChecked, { transform: "rotate(45deg)" }]}/>
                                        <View style={[s.checksCheckboxChecked, { transform: "rotate(-45deg)" }]}/>
                                    </>}
                                </View>
                                <Text>{check.text}</Text>
                            </View>
                        ))}

                        {/*<Text>O Papiere vollständig</Text>*/}
                        {/*<Text>O Ausrüstung vollständig</Text>*/}
                        {/*<Text>O Ü</Text>*/}
                        {/*<Text>O T</Text>*/}
                        {/*<Text>O W</Text>*/}
                    </View>
                    <Text>
                        Vorkommnisse (Mängel, Störungen, Störzeiten, Unfall, etc.) und Maßnahmen:
                    </Text>
                </View>


                {/*<View style={s.footerBottom}>*/}
                {/*    <View style={[s.br, { flexGrow: 1 }]}/>*/}
                {/*    <View style={{ flexGrow: 1 }}/>*/}
                {/*</View>*/}

                <View style={s.footerBottom}>
                    <View style={[s.br, { width: "50%" }]}/>
                    <View style={{ flexGrow: 1, flexDirection: "row" }}>
                        <View style={{ width: 210 }}>
                        </View>
                        <Image
                            src={props.data.signatureImageUrl}
                            style={{ height: 25, width: "auto", paddingRight: 10 }}
                        />
                    </View>
                </View>
            </View>
            <Text style={{ textAlign: "right", paddingRight: 100 }}>Unterschrift Fahrer</Text>


        </Page>
    </Document>;
}
