import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";
import Avatar from "@mui/material/Avatar";
import { appStyles } from "@/helper/appStyles";
import { SxProps, Typography } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { DeliveryNoteMessageProps } from "@/common/DeliveryNotePage/DeliveryNoteMessage";
import { deliveryNoteMessageDate } from "@/common/DeliveryNotePage/DeliveryNoteMessage/deliveryNoteUtils";

export function DeliveryNoteUserMessage(props: DeliveryNoteMessageProps) {
    const { data: userData } = useSigninCheck();

    const members = useCompanyPersistStore(state => state.members);

    const isOwnMessage = userData?.user?.uid === props.messageData.author_id;
    const isPrevMessageFromSameAuthor = props.messageData.author_id === props.prevMessageData?.author_id;

    const styles = {
        main: css({
            paddingTop: props.isFirstMessage ? 0 : (isPrevMessageFromSameAuthor ? 5 : 30),
            display: "flex",
            justifyContent: isOwnMessage ? "flex-end" : "flex-start",
        }),
        wrapper: css({
            // border: "1px solid blue",
            width: isMobileOnly ? "100%" : "90%",
            display: "flex",
        }),
        avatar: {
            height: 24,
            width: 24
        } as SxProps,
        messageWrapper: css({
            flex: 1,
        }),
        messageHeader: css({
            // border: "1px solid red",
            display: "flex",
            alignItems: "center",
            gap: 10,
            padding: "0px 16px 5px 0px",
            justifyContent: isOwnMessage ? "flex-end" : "flex-start",
        }),
        messageBubbleWrapper: css({
            // border: "1px solid red",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: isOwnMessage ? "row-reverse" : "row",
            gap: 10,
            // justifyContent: isOwnMessage ? "flex-end" : "flex-start",
            // justifyContent: "space-between",
        }),
        messageImage: css({
            maxHeight: isMobileOnly ? 350 : 500,
            maxWidth: "100%",
            borderRadius: appStyles.borderRadius.smaller,
            border: "1px solid #0001",
        }),
        messageBubble: css({
            background: isOwnMessage ? appColors.blue.veryLight : "#0001",
            borderRadius: appStyles.borderRadius.smaller,
            padding: "14px 18px",
            userSelect: "text"
        }),
        messageTimeStamp: css({
            paddingBottom: 2
        })
    };

    const getAuthorData = () => {
        if (isOwnMessage) return;
        return members.find(m => m.user_id === props.messageData.author_id);
    };

    return <div className={styles.main}>
        <div className={styles.wrapper}>

            <div className={styles.messageWrapper}>
                {!isPrevMessageFromSameAuthor &&
                    <div className={styles.messageHeader}>
                        {!isOwnMessage && <>
                            <Avatar sx={styles.avatar}/>
                            <Typography variant="body2" fontWeight={500} noWrap>
                                {getAuthorData()?.displayName || ""}
                            </Typography>
                        </>}

                        {/*<Typography variant="body2" color="#888" noWrap>*/}
                        {/*    {messageDate() || <>&nbsp;</>}*/}
                        {/*</Typography>*/}
                    </div>
                }

                <div className={styles.messageBubbleWrapper}>
                    {props.messageData.imageUrl ?
                        <img src={props.messageData.imageUrl} className={styles.messageImage} alt=""/>
                        :
                        <div className={styles.messageBubble}>
                            <Typography>{props.messageData.text}</Typography>
                        </div>
                    }

                    <div className={styles.messageTimeStamp}>
                        <Typography variant="body2" color="#aaa">
                            {deliveryNoteMessageDate(props.messageData.created_at)}
                        </Typography>
                    </div>
                </div>

            </div>

        </div>
    </div>;
}