import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TimeBookingDocument } from "tb-utils";

/** https://github.com/time-booking/application/blob/develop/docs/firestore-structure/companies-document.md#companieswork-types-document */
export interface WorkType {
    color: string;
    name: string;
    created_at: Date;
    creator: string;

    /** Firestore document id */
    doc_id: string;
}

type WorkTypeType = {
    data: WorkType[];
    status: "loading" | "success";
};

type ActiveTimeBookingType = {
    data: TimeBookingDocument<Date> | null;
    status: "loading" | "success";
}

interface TimeBookingStore {
    /** Company work types */
    workTypes: WorkTypeType;
    setWorkTypes: (newValue: WorkTypeType) => void;

    /** Active time booking element (where end_date is null) */
    activeTimeBooking: ActiveTimeBookingType;
    setActiveTimeBooking: (newValue: ActiveTimeBookingType) => void;
}

export const useTimeBookingStore = create<TimeBookingStore>()(devtools(
    set => ({

        workTypes: { data: [], status: "loading" },
        setWorkTypes: newValue => set({
            workTypes: newValue
        }),

        activeTimeBooking: { data: null, status: "loading" },
        setActiveTimeBooking: newValue => set({
            activeTimeBooking: newValue
        }),

    })
));