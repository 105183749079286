import { Button, Grid, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { MobileTimePicker } from "@mui/x-date-pickers";

interface Props {
    licensePlate: string;
    setLicensePlate: Dispatch<SetStateAction<string>>;

    licensePlateTrailer: string;
    setLicensePlateTrailer: Dispatch<SetStateAction<string>>;

    startTime: Date | undefined | null;
    setStartTime: Dispatch<SetStateAction<Date | undefined | null>>;

    endTime: Date | undefined | null;
    setEndTime: Dispatch<SetStateAction<Date | undefined | null>>;

    breakLength: string;
    setBreakLength: Dispatch<SetStateAction<string>>;
}

export function DriverReportForm(props: Props) {
    return <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={6}>
            <TextField
                label="Kennz."
                value={props.licensePlate}
                onChange={e => props.setLicensePlate(e.target.value)}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                label="Kennz. Anhänger"
                value={props.licensePlateTrailer}
                onChange={e => props.setLicensePlateTrailer(e.target.value)}
            />
        </Grid>

        <Grid item xs={6}>
            <DriverReportTimePicker
                value={props.startTime}
                setValue={props.setStartTime}
                label="Startzeit"
                toolbarTitle="Startzeit festlegen"
                deleteText="Startzeit löschen"
            />
        </Grid>

        <Grid item xs={6}>
            <DriverReportTimePicker
                value={props.endTime}
                setValue={props.setEndTime}
                label="Endzeit"
                toolbarTitle="Endzeit festlegen"
                deleteText="Endzeit löschen"
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
                label="Pausenlänge"
                value={props.breakLength}
                onChange={e => props.setBreakLength(e.target.value)}
                fullWidth
            />
        </Grid>
    </Grid>;
}

interface DriverReportTimePickerProps {
    value: Date | undefined | null;
    setValue: Dispatch<SetStateAction<Date | undefined | null>>;
    label: string;
    toolbarTitle: string;
    error?: boolean;
    deleteText: string;
}

function DriverReportTimePicker(props: DriverReportTimePickerProps) {
    return <div>
        <MobileTimePicker
            label={props.label}
            value={props.value}
            onChange={v => props.setValue(v)}
            toolbarTitle={props.toolbarTitle}
            ampm={false}
            inputFormat="HH:mm"
            renderInput={params => <TextField {...params} />}
        />
        {props.value &&
            <Button
                variant="text"
                color="inherit"
                size="small"
                onClick={() => props.setValue(null)}
            >
                {props.deleteText}
            </Button>
        }
    </div>;
}
