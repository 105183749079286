import { useState } from "react";
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, updateProfile } from "firebase/auth";
import { appLogger } from "@/helper/appLogger";
import { useAuthStore } from "@/stores/useAuthStore";
import { Alert } from "@mui/material";

/** https://firebase.google.com/docs/auth/admin/errors */
const registerErrorMessages: any = {
    "auth/email-already-in-use": "Diese E-Mail wird bereits verwendet",
    "auth/weak-password": "Das Passwort ist zu schwach",
    "auth/invalid-email": "Die E-Mail Adresse ist ungültig"
};

const FILE_NAME = "useRegisterUser.tsx";

export function useRegisterUser() {
    const setIsSignedIn = useAuthStore(state => state.setIsSignedIn);

    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordConfirm, setUserPasswordConfirm] = useState("");
    const [userDisplayName, setUserDisplayName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    /** Returns an error message. If successful the string is empty */
    const sendRegisterEmail = async (): Promise<string> => {
        const { currentUser } = getAuth();
        if (!currentUser) {
            appLogger.error(FILE_NAME, "Error before sendEmailVerification() execution. currentUser is NULL");
            return "Fehler beim Senden der Bestätigungsmail (currentUser)";
        }

        try {
            await sendEmailVerification(currentUser);
            appLogger.event(FILE_NAME, "Successfully executed sendEmailVerification()");
            return "";
        } catch (err) {
            appLogger.error(FILE_NAME, "Error executing sendEmailVerification()");
            return "Fehler beim Senden der Bestätigungsmail (sendEmailVerification)"
        }
    };

    const handleRegister = () => {
        setIsLoading(true);

        /** Reset error message */
        setErrorMessage("");

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, userEmail, userPassword)
            .then(async userCredential => {
                appLogger.state(FILE_NAME, "User Register " + userEmail);

                await updateProfile(userCredential.user, {
                    displayName: userDisplayName
                });

                await sendRegisterEmail();
                setIsSignedIn(true);
            })
            .catch(error => {
                /** Set error message text */
                const defaultMessage = "Ein Fehler ist aufgetreten";
                setErrorMessage(registerErrorMessages[error.code] || defaultMessage);
                appLogger.error(FILE_NAME, "Sign up (register) error (firebase): " + error.code);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const ErrorMessageComponent = () => {
        if (errorMessage === "") return null;

        return <Alert
            icon={false}
            color="error"
            onClose={() => setErrorMessage("")}
        >
            {errorMessage}
        </Alert>;
    };

    const passwordNotIdentical = (): boolean => {
        if (userPasswordConfirm.length === 0) return false;
        return userPassword !== userPasswordConfirm;
    };

    const isRegisterButtonDisabled = (
        userEmail.length < 5 ||
        userPassword.length <= 5 ||
        userPasswordConfirm.length <= 5 ||
        passwordNotIdentical() ||
        isLoading
    );

    return {
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        userPasswordConfirm,
        setUserPasswordConfirm,
        userDisplayName,
        setUserDisplayName,
        isLoading,
        sendRegisterEmail,
        handleRegister,
        ErrorMessageComponent,
        isRegisterButtonDisabled,
        passwordNotIdentical
    }
}