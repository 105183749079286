/**
 * It's used to close the dialog when the browser back button is pressed
 */
export const appDialogNames = {
    general: {
        userSettings: "user-settings",
        createCompany: "create-company",
    },
    timeBooking: {
        addWorkElement: "add-work-element"
    },
    container: {
        createDriverReport: "create-driver-report",
        finalizeDeliveryNote: "finalize-delivery-note",
        confirmCCDeletion: "confirm-cc-deletion"
    },
    customerFile: {
        addItem: "add-customer-file-item"
    },
    compSettingsPage: {
        changeCompName: 'change-company-name',
        inviteNewEmployee: 'invite-new-employee',
        // manageEmployeeRights: 'manage-employee-rights',
        createNewWorkType: "create-new-work-type"
    }
}
