import { useEffect } from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { CompaniesDocument } from "tb-utils";

export function FirestoreGetSelectedCompanyData() {
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const setCompanyData = useCompanyStore(state => state.setCompanyData);

    const companiesRef = doc(useFirestore(), "companies", selectedCompanyId);
    const { data, status } = useFirestoreDocData(companiesRef, { idField: "doc_id" });

    // console.log("1111111------ FirestoreGetSelectedCompanyData");

    useEffect(() => {
        setCompanyData({
            data: data as CompaniesDocument<Date>,
            status: status,
            id: data?.doc_id
        });
    }, [data, status]);

    return null;
}