import { css } from "@/helper/css";
import { appColors } from "@/helper/appColors";
import { appStyles } from "@/helper/appStyles";
import { Checkbox, Typography } from "@mui/material";

const styles = {
    main: css({
        border: `1px solid ${appColors.backgroundGray.dark}`,
        borderRadius: appStyles.borderRadius.smaller,
        boxSizing: "border-box",
        minHeight: 160
    }),
    content: css({
        padding: 12,
    }),
    textSection: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2
    }),
    checkbox: css({
        padding: 0,
    }),

};

interface Props {
    data: string[];
}

export function MobileNoteBox(props: Props) {
    return <div style={styles.main}>
        <div style={styles.content}>
            <Typography variant="h6">Title abc</Typography>

            {props.data.map((text, index) => (
                <div key={index} style={styles.textSection}>
                    <Checkbox
                        sx={styles.checkbox}
                        size="small"
                        checked={false}
                        disableRipple
                    />
                    <Typography variant="body2">
                        {text}
                    </Typography>
                </div>
            ))}
        </div>
    </div>;
}