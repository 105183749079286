import { css } from "@emotion/css";
import { IconButton, TextField, Tooltip } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useState } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { addDoc, collection, FieldValue, serverTimestamp } from "firebase/firestore";
import { useFirestore, useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { DeliveryNoteMessagesDocument } from "tb-utils";
import { useParams } from "react-router-dom";


interface Props {
    isScrolledToBottom: boolean;
    scrollToBottom: () => void;
}

export function DeliveryNoteChatInput(props: Props) {

    const styles = {
        main: css({
            // border: "1px solid green",
            width: "calc(100% + 10px)",
            // transform: "translateX(-10px)",
            // paddingTop: 200,
            position: "sticky",
            bottom: isMobile ? 70 : 0,
        }),
        content: css({
            // border: "1px solid blue",
            display: "flex",
            alignItems: "flex-end",
            gap: isMobileOnly ? 4 : 10,
            background: "#fffc",
            boxShadow: "0 0 10px 5px #fffc",
            backdropFilter: "blur(5px)",
            borderRadius: 0,
            // paddingBottom: isMobile ? 20 : 40,
            paddingBottom: 20,
            marginTop: 20
        })
    };

    const [text, setText] = useState("");

    const { data: userData } = useSigninCheck();
    const { drivingJobId, contractNumber } = useParams();

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const handleTextInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newText = event.target.value;
        const isLastCharEnterKey = newText.slice(-1) === "\n";
        if (isLastCharEnterKey) {
            handleSendMessage();
        } else {
            setText(newText);
        }
    };

    const deliveryNoteMessagesRef = collection(useFirestore(), "companies", selectedCompanyId || "--", "delivery-note-messages");
    const handleSendMessage = async () => {
        if (!text.length) return;

        const messageData: DeliveryNoteMessagesDocument<FieldValue> = {
            text: text,
            imageUrl: null,
            author_id: userData?.user?.uid || "",
            type: 1,
            contract_changes: null,
            delivery_note_id: `${drivingJobId}/${contractNumber}`,
            created_at: serverTimestamp()
        };
        addDoc(deliveryNoteMessagesRef, messageData)
            .then(() => {
                /** Scroll to chat bottom on message-send in case the user isn't at the bottom */
                props.scrollToBottom();
            })
            .catch(e => {
                console.log("CATCH", e);
            });

        setText("");
    };

    return <div className={styles.main}>
        <div className={styles.content}>
            {/*<IconButton>*/}
            {/*    <CameraAltIcon/>*/}
            {/*</IconButton>*/}

            <TextField
                fullWidth
                multiline
                label={text ? "" : "Nachricht"}
                value={text}
                maxRows={10}
                onChange={handleTextInput}
                InputLabelProps={{ shrink: false }}
                sx={{
                    ".MuiInputBase-root": {
                        borderRadius: "20px",
                    }
                }}
            />

            <Tooltip title="Senden" placement="top">
                <IconButton onClick={handleSendMessage}>
                    <SendIcon/>
                </IconButton>
            </Tooltip>
        </div>
    </div>;
}