/** Use this function to get Typescript Lint,... for the second ("args") argument */
import { createTheme, ThemeOptions } from "@mui/material";
import { appColors } from "@/helper/appColors";
import { appStyles } from "@/helper/appStyles";
import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from "@mui/x-date-pickers/locales";

const customCreateTheme = (customTheme: ThemeOptions, args: ThemeOptions) => createTheme(customTheme, args);

/**
 * Custom palette
 */
export let theme = createTheme(
    {
        palette: {
            primary: { main: appColors.blue.main },
            secondary: { main: appColors.green.main }
        },
    },

    /** https://mui.com/x/react-date-pickers/localization/ */
    dataGridDeDE,
    coreDeDE,
    deDE
);

/**
 * Custom components
 */
theme = customCreateTheme(theme, {
    typography: {
        h1: {
            fontSize: 26,
            fontWeight: 700,
            color: appColors.typography.title
        },
        h2: {
            fontSize: 24,
            fontWeight: 700,
            color: appColors.typography.title
        },
        h3: {
            fontSize: 22,
            fontWeight: 700,
            color: appColors.typography.title
        },
        h4: {
            fontSize: 20,
            fontWeight: 700,
            color: appColors.typography.title
        },
        h5: {
            fontSize: 18,
            fontWeight: 700,
            color: appColors.typography.title
        },
        h6: {
            fontSize: 16,
            fontWeight: 700,
            color: appColors.typography.title
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                standardError: {
                    background: theme.palette.error.main,
                    color: "#fff",
                    "& .MuiSvgIcon-root": {
                        color: "#fff !important"
                    },
                },

                root: {
                    borderRadius: "6px !important"
                }
            }
        },

        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: "contained"
            },
            styleOverrides: {
                sizeMedium: {
                    height: 40,
                    borderRadius: 20,
                },
                sizeSmall: {
                    height: 31,
                    borderRadius: 15
                },
                containedPrimary: {
                    background: appColors.blue.veryLight,
                    color: "#333",
                    "&:hover": {
                        /** Prevents button color from getting blue (primary.blue) after clicking button */
                        background: appColors.blue.veryLight
                    }
                },
                root: {
                    textTransform: "none",
                }
            }
        },

        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: "none"
                }
            }
        },

        MuiFab: {
            styleOverrides: {
                root: {
                    background: appColors.blue.veryLight,
                    borderRadius: appStyles.borderRadius.smaller,
                    textTransform: "none"
                }
            }
        },

        MuiFormControl: {
            defaultProps: {
                size: "small"
            }
        },

        MuiListSubheader: {
            defaultProps: {
                color: "primary"
            },
            styleOverrides: {
                root: {
                    lineHeight: "unset",
                    paddingTop: 20,
                    paddingBottom: 4
                }
            }
        },

        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 7,
                    '& .MuiSwitch-track': {
                        borderRadius: 24 / 2,
                        backgroundColor: "#0001",
                        border: "2px solid #888",
                        boxSizing: "border-box",
                        opacity: 1,
                    },
                    '& .MuiSwitch-thumb': {
                        background: "#888",
                        boxShadow: 'none',
                        width: 14,
                        height: 14,
                        margin: 3,
                    },
                    "& .Mui-checked": {
                        "&.MuiSwitch-colorPrimary + .MuiSwitch-track": {
                            borderColor: appColors.blue.main,
                            backgroundColor: appColors.blue.main,
                            opacity: 1,
                        },
                        "&.MuiSwitch-colorSecondary + .MuiSwitch-track": {
                            borderColor: appColors.green.main,
                            backgroundColor: appColors.green.main,
                            opacity: 1,
                        },
                        '& .MuiSwitch-thumb': {
                            background: "#fff",
                            width: 18,
                            height: 18,
                            margin: 1,
                        },
                    },
                },

            }
        },

        MuiTextField: {
            defaultProps: {
                size: "small"
            }
        },

        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: {
                    overflow: "hidden", // Hide chrome auto complete problem (chrome makes background white with sharp corners)
                    color: "#fff",
                    background: "#fff2",
                    borderRadius: 10,
                    border: "2px solid transparent",
                    transition: "0.3s",

                    "& .MuiInputLabel-root": {
                        color: "red !important"
                    },
                    "&:hover": {
                        background: "#fff2"
                    },
                    "&.Mui-focused": {
                        background: "#fff2"
                    },
                    "&.Mui-error": {
                        borderColor: "#f00a"
                    }
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                filled: {
                    /** Set/keep input label text color white when focused */
                    color: "#fff !important"
                }
            }
        },

    }
});