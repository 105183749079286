import { isValid, parse } from "date-fns";
import { IconButton, TextField, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { css } from "@emotion/css";
import { useQueryParam } from "@/customHooks/useQueryParam";
import format from "date-fns/format";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const styles = {
    datePickerSection: css({
        display: "flex",
    }),
};

export function DriverReportCustomDateFilter() {
    const {
        value: customDateFilter,
        set: setCustomDateFilter,
        remove: removeDateFilter
    } = useQueryParam("date_filter");

    const customDateFilterAsDate = customDateFilter ?
        parse(customDateFilter, "dd.MM.yyyy", new Date()) :
        null;

    return <div className={styles.datePickerSection}>
        {/* TODO: On mobile you have to click 2 times before popup opens, maybe fix with onClick and open it via JS */}
        <DatePicker
            label="Nach Datum filtern"
            inputFormat="dd.MM.yyyy"
            minDate={new Date("01.01.2020")}
            onChange={event => {
                if (isValid(event) && event) {
                    setCustomDateFilter(format(event, "dd.MM.yyyy"));
                }
            }}
            value={customDateFilterAsDate}
            renderInput={e => <TextField {...e} fullWidth/>}
            closeOnSelect
            showToolbar={false}
            InputProps={{
                autoComplete: "off",
            }}
            OpenPickerButtonProps={{
                hidden: true
            }}
        />
        <Tooltip title="Filter entfernen" placement="top">
            <IconButton onClick={() => removeDateFilter()}>
                <CloseIcon/>
            </IconButton>
        </Tooltip>
    </div>;
}