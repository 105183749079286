import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ContainerSimpleStat } from "@/ModuleContainer/ContainerSimpleStat";

export function ContainerStatisticsPage() {
    const chartData = [
        { month: "January", desktop: 86, mobile: 80 },
        { month: "February", desktop: 105, mobile: 200 },
        { month: "March", desktop: 100, mobile: 120 },
        { month: "April", desktop: 73, mobile: 190 },
        { month: "May", desktop: 109, mobile: 130 },
        { month: "June", desktop: 114, mobile: 140 },
    ];

    const chartConfig = {
        desktop: {
            label: "Desktop",
            color: "#2563eb",
        },
        mobile: {
            label: "Mobile",
            color: "#60a5fa",
        },
    } satisfies ChartConfig;


    return <div>
        {/*<h1>Container Statistics Page</h1>*/}

        <div className="flex pb-5 gap-4">
            <ContainerSimpleStat stat="$ 1,204" label="Total Revenue" colorClass="bg-sky-100 text-sky-800"/>
            <ContainerSimpleStat stat="$ 1,204" label="Total Revenue" colorClass="bg-green-100 text-green-800"/>
            <ContainerSimpleStat stat="$ 1,204" label="Total Revenue" colorClass="bg-slate-100 text-slate-800"/>
        </div>


        <Card className="h-40 w-1/2 mb-5">
            <ChartContainer config={chartConfig} className="h-full w-full">
                <BarChart
                    accessibilityLayer
                    data={chartData}
                    margin={{
                        top: 20,
                    }}
                >
                    {/*<CartesianGrid vertical={false} />*/}
                    <XAxis
                        dataKey="month"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <ChartTooltip content={<ChartTooltipContent/>}/>
                    <Bar dataKey="desktop" fill="var(--color-desktop)" radius={4}>
                        <LabelList
                            position="top"
                            offset={12}
                            className="fill-foreground"
                            fontSize={12}
                        />
                    </Bar>
                    <Bar dataKey="mobile" fill="var(--color-mobile)" radius={4}/>
                </BarChart>
            </ChartContainer>
        </Card>

        <Card className="w-1/2 xh-40">
            <CardHeader>
                <CardTitle>Line Chart - Label</CardTitle>
                <CardDescription>January - June 2024</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig} className="h-40 w-full">
                    <AreaChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            top: 22,
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid vertical={false}/>
                        <XAxis
                            dataKey="month"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(value) => value.slice(0, 3)}
                        />
                        {/*<ChartTooltip cursor={false} content={<ChartTooltipContent/>} defaultIndex={5}/>*/}

                        <defs>
                            <linearGradient id="fillDesktop" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="5%"
                                    stopColor="var(--color-desktop)"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="var(--color-desktop)"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                            <linearGradient id="fillMobile" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="5%"
                                    stopColor="var(--color-mobile)"
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="var(--color-mobile)"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                        <Area
                            dataKey="mobile"
                            type="natural"
                            fill="url(#fillMobile)"
                            fillOpacity={0.4}
                            stroke="var(--color-mobile)"
                            stackId="a"
                        >
                            <LabelList
                                position="top"
                                offset={10}
                                className="fill-foreground"
                                fontSize={12}
                            />
                        </Area>

                        {/*<Area*/}
                        {/*    dataKey="desktop"*/}
                        {/*    type="natural"*/}
                        {/*    fill="url(#fillDesktop)"*/}
                        {/*    fillOpacity={0}*/}
                        {/*    stroke="var(--color-desktop)"*/}
                        {/*    stackId="a"*/}
                        {/*>*/}
                        {/*    <LabelList*/}
                        {/*        position="top"*/}
                        {/*        offset={12}*/}
                        {/*        className="fill-foreground"*/}
                        {/*        fontSize={12}*/}
                        {/*    />*/}
                        {/*</Area>*/}
                    </AreaChart>
                </ChartContainer>
            </CardContent>
        </Card>

    </div>;
}
