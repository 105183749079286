import { useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { appLogger } from "@/helper/appLogger";
import { useFindAllJoinedCompanies } from "@/customHooks/useFindAllJoinedCompanies";

const FILE_NAME = "useRedirectIfUserIsSignedIn.ts";

/**
 * Redirect if user is logged in and has a selected company (id)
 */
export function useRedirectIfUserIsSignedIn() {
    const { data: userData, status } = useSigninCheck();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { findCompanyMemberDocs } = useFindAllJoinedCompanies();

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const setSelectedCompanyId = useCompanyPersistStore(state => state.setSelectedCompanyId);

    /** Auto select company if one exists */
    useEffect(() => {
        if (!userData?.user?.uid || !userData?.user?.emailVerified) return;

        findCompanyMemberDocs(userData.user.uid)
            .then(companyMemberDocs => {
                /** Do not redirect if company create page to show the success page when a comp was created */
                const isCreateCompanyPage = (
                    pathname.startsWith(appPaths.setupPages.companyCreated.replace("/:companyId", "")) ||
                    pathname.startsWith(appPaths.setupPages.createCompany.replace("/:creationToken", ""))
                );
                if (companyMemberDocs && companyMemberDocs.length && !isCreateCompanyPage) {
                    const companyId = companyMemberDocs[0].company_id;
                    appLogger.state(FILE_NAME, "Auto select company " + companyId);
                    setSelectedCompanyId(companyId);
                }
            })
            .catch(() => {
                appLogger.state(FILE_NAME, "Error auto selecting company");
            });
    }, [userData, pathname]);

    /** Redirect if user has a company selected (local storage) */
    useEffect(() => {
        const uid = userData?.user?.uid;
        const isLoaded = status === "success";
        if (uid && isLoaded && selectedCompanyId) {
            appLogger.event(FILE_NAME, "Auto redirect via " + FILE_NAME);
            navigate(appPaths.root);
        }
    }, [userData, status, selectedCompanyId]);

}