import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";

/** Store types */
type IsSignedInType = boolean;

interface AuthStore {
    /** Sign in state */
    isSignedIn: IsSignedInType;
    setIsSignedIn: (isSignedIn: IsSignedInType) => void;

    /** FCM client push key */
    pushKey: string;
    setPushKey: (newValue: string) => void;
}

export const useAuthStore = create<AuthStore>()(devtools(persist(
    set => ({

        isSignedIn: false,
        setIsSignedIn: isSignedIn => set({
            isSignedIn: isSignedIn
        }),

        pushKey: "",
        setPushKey: newValue => set({
            pushKey: newValue
        }),
    }),
    {
        name: appLocalStorageNames.authStore
    }
)));