import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { replaceTimestamps } from "@/helper/appUtils";
import { PendingInvitationDocument } from "tb-utils";

export function usePendingInvitations() {
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const pendingInvitationsRef = collection(useFirestore(), "companies", selectedCompanyId || "---", "pending-invitations");
    const { data, status } = useFirestoreCollectionData(pendingInvitationsRef, { idField: "doc_id" });
    const pendingInvitationData = replaceTimestamps<PendingInvitationDocument<Date>[]>(data);

    return {
        pendingInvitationData,
        isPendingInvitationLoading: status === "loading"
    }
}