import { IconButton, SxProps, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";

interface Props {
    title: string;
    backButton: string;
    color?: string;
}

export function M_NavWithBackButton(props: Props) {
    const styles = {
        main: css({
            // border: "1px solid red",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 14,
            height: 70,
            padding: "0 20px",
        }),
        backIcon: {
            color: props.color || "",
        } as SxProps,
        title: {
            color: props.color || "#444",
        } as SxProps
    };

    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        navigate(props.backButton);
    };

    return <M_ContentSection width="full" disableBottomPadding>
        <div className={styles.main}>
            <IconButton onClick={handleBackButtonClick} size="small">
                <ArrowBackIcon sx={styles.backIcon}/>
            </IconButton>
            <Typography sx={styles.title} variant="h5" fontWeight="500">
                {props.title}
            </Typography>
        </div>
    </M_ContentSection>;
}