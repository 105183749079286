import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";
import { css } from "@/helper/css";


interface Props {
    selectedWorkTypeId: string;
    setSelectedWorkTypeId: Dispatch<SetStateAction<string>>;
}

const styles = {
    selectMenuItem: css({
        display: "flex",
        flexDirection: "row",
        gap: 14
    }),
    selectMenuItemContent: css({
        height: 30,
        width: 30,
        borderRadius: 6
    })
};

export function MobileAddWorkElementSelect(props: Props) {
    const workTypes = useTimeBookingStore(state => state.workTypes);

    return <FormControl fullWidth>
        <InputLabel>Arbeitsart</InputLabel>
        <Select
            label="Arbeitsart"
            value={props.selectedWorkTypeId}
            onChange={event => props.setSelectedWorkTypeId(event.target.value)}
            sx={{
                " .MuiSelect-select": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px"
                }
            }}
        >
            {workTypes.data.map(workType => (
                <MenuItem
                    style={styles.selectMenuItem}
                    key={workType.doc_id}
                    value={workType.doc_id}
                >
                    <div style={{
                        ...styles.selectMenuItemContent,
                        background: workType.color,
                    }}/>
                    <div>{workType.name}</div>
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}