import { C_CompanySettingsFrame } from "@/common/companySettings/C_CompanySettingsFrame";
import { Alert, Button, SxProps, TextField } from "@mui/material";
import { C_SettingsSection } from "@/common/companySettings/C_SettingsSection";
import { css } from "@emotion/css";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { ChangeEvent, useEffect, useState } from "react";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";

const maxWidth = 400;

const styles = {
    changeCompanyName: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 10,
        paddingTop: 10, // avoid textfield label from getting behind settings header
    }),
    textField: {
        maxWidth: maxWidth
    } as SxProps,
    buttonSection: css({
        display: "flex",
        gap: 10
    })
};

export function C_GeneralPage() {
    const { updateCompany } = useFirestoreWrite();
    const companyData = useCompanyStore(state => state.companyData);

    const currentCompanyName = companyData.data?.name || "";
    const [newCompanyName, setNewCompanyName] = useState(currentCompanyName);
    const [showError, setShowError] = useState(false);

    /** Fill init state on page reload */
    useEffect(() => {
        if (newCompanyName === "") {
            setNewCompanyName(currentCompanyName);
        }
    }, [companyData]);

    const hasCompanyNameChanged = newCompanyName !== currentCompanyName;

    const isCompanyNameValid = (
        newCompanyName.trim().length >= 2 &&
        newCompanyName.trim().length <= 50
    );

    const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewCompanyName(event.target.value);
    };

    const handleResetClick = () => {
        setShowError(false);
        setNewCompanyName(currentCompanyName);
    };

    const handleUpdateClick = () => {
        setShowError(false);
        updateCompany({ name: newCompanyName })
            .catch(() => {
                setShowError(true);
            });
    };

    return <C_CompanySettingsFrame title="Allgemein">
        <C_SettingsSection>
            <div className={styles.changeCompanyName}>
                <TextField
                    label="Name der Firma"
                    sx={styles.textField}
                    fullWidth
                    value={newCompanyName}
                    onChange={handleCompanyNameChange}
                    error={!isCompanyNameValid}
                    helperText={!isCompanyNameValid ? "Der Name muss 2 bis 50 Zeichen lang sein" : ""}
                />

                <div className={styles.buttonSection}>
                    <Button
                        size="small"
                        disabled={!hasCompanyNameChanged || !isCompanyNameValid}
                        onClick={handleUpdateClick}
                    >
                        Speichern
                    </Button>
                    {hasCompanyNameChanged &&
                        <Button
                            size="small"
                            variant="text"
                            onClick={handleResetClick}
                        >
                            Zurücksetzen
                        </Button>
                    }
                </div>

                {showError &&
                    <Alert icon={false} color="error" style={{ maxWidth: maxWidth }}>
                        Ein Fehler ist aufgetreten.
                    </Alert>
                }
            </div>
        </C_SettingsSection>

    </C_CompanySettingsFrame>;
}