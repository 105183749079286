import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useOnline } from "@/customHooks/useOnline";
import { css } from "@emotion/css";
import { SxProps, Typography } from "@mui/material";
import { appStyles } from "@/helper/appStyles";

const styles = {
    main: css({
        background: "#c00",
        color: "#fff",
        borderRadius: appStyles.borderRadius.normal,
        display: "flex",
        alignItems: "center",
        gap: 10,
        height: 32,
        padding: "0 14px",
    }),
    icon: {
        fontSize: 22,
    } as SxProps
}

export function OfflineAlert() {
    const { isOnline } = useOnline();

    if (isOnline) return null;

    return <div className={styles.main}>
        <WarningAmberIcon sx={styles.icon}/>
        <Typography fontWeight={500} variant="body2" noWrap>
            Keine Verbindung
        </Typography>
    </div>;
}
