import { DeliveryNoteMessagesDocument } from "tb-utils";
import { DeliveryNoteUserMessage } from "@/common/DeliveryNotePage/DeliveryNoteMessage/DeliveryNoteUserMessage";
import { DeliveryNoteSystemMessage } from "@/common/DeliveryNotePage/DeliveryNoteMessage/DeliveryNoteSystemMessage";

export interface DeliveryNoteMessageProps {
    messageData: DeliveryNoteMessagesDocument<Date>;
    prevMessageData: DeliveryNoteMessagesDocument<Date> | null;
    isFirstMessage: boolean;
}

export function DeliveryNoteMessage(props: DeliveryNoteMessageProps) {
    return props.messageData.type === 1 ?
        <DeliveryNoteUserMessage {...props} /> :
        <DeliveryNoteSystemMessage {...props} />;
}