import { collectionGroup, getDocs, limit, query, where } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { CompanyMemberDocument } from "tb-utils";

/**
 * Important: This hook returns the "company-members" docs. Not the actual "companies" docs
 */
export function useFindAllJoinedCompanies() {
    const db = useFirestore();

    async function findCompanyMemberDocs(userId: string) {
        const usersCompanyMembers = query(
            collectionGroup(db, "company-members"),
            where("user_id", "==", userId),
            limit(20)
        );
        const querySnapshot = await getDocs(usersCompanyMembers);

        return querySnapshot.docs.map(doc => ({
            ...doc.data(),
            doc_id: doc.id,
        })) as CompanyMemberDocument<Date>[];
    }

    return {
        findCompanyMemberDocs
    };
}