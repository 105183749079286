import { ContextMenuItemProps } from "@/common/components/ContextMenu/ContextMenuContent";
import { MenuItem, SxProps, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";

interface Props {
    /** Menu item data */
    menuItem: ContextMenuItemProps;

    /** Close menu on this function call */
    handleClose: () => void;
}

export default function ContextMenuItem(props: Props) {
    const theme = useTheme();
    const styles = {
        menuItem: {
            padding: "7px 10px",
            borderRadius: "2px",
            display: "flex",
            gap: "8px",
            "&:hover": {
                background: props.menuItem.danger ?
                    theme.palette.error.light :
                    appColors.blue.veryLight,
                color: props.menuItem.danger ? "#fff" : "",
            },
        } as SxProps,
        iconWrapper: css({
            // border: "1px solid green",
            fontSize: 0,
            ".MuiSvgIcon-root": {
                height: 20
            }
        })
    };

    const handleMenuClick = () => {
        /** Execute custom function on menu item click */
        if (props.menuItem.onClick) props.menuItem.onClick();

        /** Close menu after menu item click */
        props.handleClose();
    }

    return <MenuItem
        sx={styles.menuItem}
        disabled={props.menuItem.disabled}
        onClick={handleMenuClick}
    >
        <div className={styles.iconWrapper}>{props.menuItem.icon}</div>
        <Typography variant="body2">{props.menuItem.displayName}</Typography>
    </MenuItem>;
};