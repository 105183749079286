import { CircularProgress, IconButton, SxProps } from "@mui/material";
import { useLocation } from "react-router-dom";
import { motion, TargetAndTransition } from "framer-motion";
import { useDialog } from "@/customHooks/useDialog";
import { useSigninCheck } from "reactfire";
import { UserSettingsDialog } from "@/common/UserSettingsDialog";
import { css } from "@emotion/css";
import { OfflineAlert } from "@/common/components/OfflineAlert";
import { appColors } from "@/helper/appColors";
import { appDialogNames } from "@/helper/appDialogNames";
import PersonIcon from "@mui/icons-material/Person";

const styles = {
    main: css({
        // border: "1px solid red",
        zIndex: 100,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "0 20px",
        height: 50,
        boxSizing: "border-box",
        width: "100vw"
    }),
    search: css({
        background: appColors.backgroundGray.light,
        height: 40,
        flex: 1,
        borderRadius: 100,
        display: "flex",
        alignItems: "center"
    }),
    profileIconWrapper: css({
        // border: "1px solid red",
        position: "relative",
    }),
    profileSpinnerWrapper: css({
        // border: "1px solid green",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        right: 0,
        bottom: 2,
        left: 0
    }),
    icon: {
        color: "#555",
        fontSize: 30,
        transition: ".3s"
    } as SxProps,
    logo: css({
        height: 30,
        width: "auto",
        borderRadius: 2
    }),
    title: css({
        fontWeight: 500,
        color: "#444"
    }),
    avatar: css({
        height: 38,
        width: 38
    })
};

interface Props {
    hideNavOnPaths: string[];
}

export function M_TopNav(props: Props) {
    const location = useLocation();
    const { setOpenDialog } = useDialog();
    const { status: signInStatus } = useSigninCheck();

    const hideNav = props.hideNavOnPaths.find(path => location.pathname.startsWith(path));

    const motionAnimate: TargetAndTransition = hideNav ?
        { transform: "translateY(-50px)" } :
        { transform: "translateY(0px)" };

    return <>
        <motion.div
            className={styles.main}
            animate={motionAnimate}
        >
            <OfflineAlert/>

            {/*<div className={styles.search}>*/}
            {/*    <div>1</div>*/}
            {/*    <div>2</div>*/}
            {/*    <div>3</div>*/}
            {/*</div>*/}

            <div style={{ flex: 1 }}/>

            <div className={styles.profileIconWrapper}>
                {signInStatus === "loading" &&
                    <div className={styles.profileSpinnerWrapper}>
                        <CircularProgress size={30} thickness={4}/>
                    </div>
                }

                <IconButton onClick={() => setOpenDialog(appDialogNames.general.userSettings)}>
                    <PersonIcon
                        sx={styles.icon}
                        style={{
                            transform: signInStatus === "loading" ? "scale(0.7)" : "",
                        }}
                    />
                </IconButton>
            </div>
        </motion.div>

        <UserSettingsDialog/>
    </>;
}
