import { ReactElement } from "react";
import { css } from "@emotion/css";
import { appColors } from "@/helper/appColors";
import { SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tbClasses } from "@/helper/appUtils";

interface Props {
    text: string;
    description?: string | ReactElement;
    active?: boolean;
    linkTo?: string;
    onClick?: () => void;
    disableSidePadding?: boolean;
}

export function SimpleListItem(props: Props) {
    const styles = {
        navItem: css({
            // height: 46,
            display: "flex",
            flexDirection: "column",
            // gap: 4,
            justifyContent: "center",
            padding: props.disableSidePadding ? "8px 0px" : "8px 20px",
            borderRadius: "20px",
            cursor: "pointer",
            ":hover": {
                background: "#0001"
            }
        }),
        navItemActive: css({
            background: appColors.blue.veryLight + " !important"
        }),
        navItemSecondary: {
            // color: appColors.typography.
        } as SxProps,
    };

    const navigate = useNavigate();

    const handleClick = () => {
        if (props.linkTo) navigate(props.linkTo);
        if (props.onClick) props.onClick();
    };

    return <div
        onClick={handleClick}
        className={tbClasses(
            styles.navItem,
            props.active ? styles.navItemActive : ""
        )}
    >
        <Typography>{props.text}</Typography>
        <Typography
            variant="caption"
            sx={styles.navItemSecondary}
            color="gray"
        >
            {props.description}
        </Typography>
    </div>;
}