import { css } from "@emotion/css";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { appColors } from "@/helper/appColors";
import { ReactElement } from "react";

const styles = {
    main: css({
        // border: "1px solid blue",
        height: 50,
        display: "flex",
        alignItems: "center",
        gap: 20,
        paddingLeft: 20,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        cursor: "pointer",
        color: "#666",
        textDecoration: "unset",
        ":hover": {
            background: "#0001"
        }
    }),
    active: css({
        // border: "1px solid red",
        background: appColors.blue.veryLight + " !important"
    }),
    iconWrapper: css({
        // border: "1px solid red",
        color: "#666",
        display: "flex",
        width: 30,
    })
};

interface Props {
    text: string;
    linkTo: string;
    icon?: ReactElement;
    activeIcon?: ReactElement;
    active?: boolean;
}

export function DesktopMainLeftNavItem(props: Props) {
    return <Link
        to={props.linkTo}
        className={styles.main + " " + (props.active ? styles.active : "")}
    >
        <div className={styles.iconWrapper}>
            {props.active && props.activeIcon ?
                props.activeIcon :
                props.icon
            }
        </div>

        <Typography variant="body2" fontWeight="500">
            {props.text}
        </Typography>
    </Link>;
}
