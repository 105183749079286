import { css } from "@/helper/css";
import { Typography } from "@mui/material";
import { appColors } from "@/helper/appColors";

const styles = {
    grid: css({
        display: "grid",
        gridTemplateColumns: "repeat(7, minmax(0px, 1fr))",
        gap: 0,
    }),
    weekDayItem: css({
        position: "relative",
        borderBottom: `1px solid ${appColors.mobileCalendarBorderColor}`,
    }),
    halfBorder: css({
        bottom: 0,
        right: 0,
        height: 10,
        position: "absolute",
        borderRight: `1px solid ${appColors.mobileCalendarBorderColor}`
    }),
    leftHalfBorder: css({
        bottom: 0,
        left: 0,
        height: 10,
        position: "absolute",
        borderLeft: `1px solid ${appColors.mobileCalendarBorderColor}`
    })
};

export function M_MonthOverviewHeader() {
    const weekDays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

    return <div style={styles.grid}>
        {weekDays.map((day, index) => (
            <div key={index} style={styles.weekDayItem}>
                {index === 0 && (
                    <div style={styles.leftHalfBorder}/>
                )}

                <Typography variant="subtitle2" textAlign="center">
                    {day}
                </Typography>

                <div style={styles.halfBorder}/>
            </div>
        ))}
    </div>;
}
