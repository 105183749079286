import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { ContainerContractDocument } from "tb-utils";
import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid } from "date-fns";
import { containerVariants } from "@/helper/appStatic";
import { appStyles } from "@/helper/appStyles";
import { appColors } from "@/helper/appColors";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RedoIcon from '@mui/icons-material/Redo';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { contractType } from "@/helper/appUtils";

const styles = {
    content: css({
        // border: "1px solid red",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 14,
        paddingTop: 20,
        width: "min(100%, 550px)"
    }),
    topButtonSection: css({
        display: "flex",
        flexDirection: "row",
        gap: "6px 22px",
        alignItems: "center",
        flexWrap: "wrap"
    }),
    sectionWrapper: css({
        background: appColors.backgroundGray.light,
        borderRadius: appStyles.borderRadius.smaller,
        padding: 18,
        minWidth: "100%",
        boxSizing: "border-box",
    }),
    typeSelect: css({
        // Center mui-icon in select-input
        ".MuiSelect-select": {
            display: "flex",
            alignItems: "center"
        },
        // Mui-icon styling when selected
        ".MuiSvgIcon-root": {
            fontSize: 17,
            color: "#0009"
        }
    }),
    typeStyle: css({
        // Mui-icon styling when displayed in select-item
        ".MuiSvgIcon-root": {
            fontSize: 17,
            color: "#0009"
        }
    }),
    submitButtonSection: css({
        width: "100%",
        display: "flex",
        gap: 10
    }),
};

interface Props {
    formTitle: string;

    contractData: Partial<ContainerContractDocument<Date>>;
    setContractData: Dispatch<SetStateAction<Partial<ContainerContractDocument<Date>>>>;

    billingAddressIsEqual: boolean
    setBillingAddressIsEqual: Dispatch<SetStateAction<boolean>>;

    c1Checked: boolean;
    setC1Checked: Dispatch<SetStateAction<boolean>>;

    c2Checked: boolean;
    setC2Checked: Dispatch<SetStateAction<boolean>>;

    isLoading: boolean;

    submitButtonText: string;
    handleSubmitClick: () => void;

    submitButtonTooltip?: string;
    isSubmitButtonDisabled?: boolean;

    errorText?: string;
    setErrorText?: Dispatch<SetStateAction<string>>;

    /** No warnings will be displayed to the user if the user unchecks the billing address or the delivery-note sections */
    hideDataLossWarnings?: boolean;

    optionalButtons?: ReactElement;
}

export function ContainerContractFormular(props: Props) {
    const navigate = useNavigate();

    const isDeliveryAddressDataValid = (): boolean => {
        return [
            (props.contractData.c1_da_recipient ?? "").length > 0,
            (props.contractData.c1_da_street ?? "").length > 0,
            (props.contractData.c1_da_house_number ?? "").length > 0,
            (props.contractData.c1_da_zip_code ?? "").length > 0,
            (props.contractData.c1_da_city ?? "").length > 0
        ].every(value => value);
    };
    const isBillingAddressDataValid = (): boolean => {
        if (props.billingAddressIsEqual) return true;
        return [
            (props.contractData.c1_ba_recipient ?? "").length > 0,
            (props.contractData.c1_ba_street ?? "").length > 0,
            (props.contractData.c1_ba_house_number ?? "").length > 0,
            (props.contractData.c1_ba_zip_code ?? "").length > 0,
            (props.contractData.c1_ba_city ?? "").length > 0
        ].every(value => value);
    };
    const isAdditionalDataValid = (): boolean => {
        return [
            !!props.contractData.type,
            !!props.contractData.container_variant,
            (props.contractData.container_size || 0) > 0
        ].every(value => value);
    };
    const isC1DataValid = (): boolean => {
        if (!props.c1Checked) return true;
        return [
            (props.contractData.c1_number ?? "").length > 0
        ].every(value => value);
    };
    const isC2DataValid = (): boolean => {
        if (!props.c2Checked) return true;
        return [
            (props.contractData.c2_number ?? "").length > 0
        ].every(value => value);
    };

    const [showC1DataLossWarning, setShowC1DataLossWarning] = useState(false);
    const [showC2DataLossWarning, setShowC2DataLossWarning] = useState(false);
    const [showBillingAddressDataLossWarning, setShowBillingAddressDataLossWarning] = useState(false);

    const handleC1CheckboxChange = () => {
        const checkboxValue = props.c1Checked;
        props.setC1Checked(!checkboxValue);

        if (!checkboxValue) {
            setShowC1DataLossWarning(false);
            return; /** Exit function here if checkbox was unchecked */
        }

        if (!props.hideDataLossWarnings) {
            /** Show (data loss) warning if at least 1 input has some data */
            if (
                props.contractData?.c1_number ||
                props.contractData?.c1_date ||
                props.contractData?.c1_time_of_day ||
                props.contractData?.c1_cargo_info ||
                props.contractData?.c1_recycler_and_others_text ||
                props.contractData?.c1_info_text
            ) {
                setShowC1DataLossWarning(true);
            }
        }

        /** Delete input values */
        props.setContractData(value => ({
            ...value,
            c1_number: "",
            c1_date: null,
            c1_time_of_day: "",
            c1_cargo_info: "",
            c1_recycler_and_others_text: "",
            c1_info_text: ""
        }));
    };

    const handleC2CheckboxChange = () => {
        const checkboxValue = props.c2Checked;
        props.setC2Checked(!checkboxValue);

        if (!checkboxValue) {
            setShowC2DataLossWarning(false);
            return; /** Exit function here if checkbox was unchecked */
        }

        if (!props.hideDataLossWarnings) {
            /** Show (data loss) warning if at least 1 input has some data */
            if (
                props.contractData?.c2_number ||
                props.contractData?.c2_date ||
                props.contractData?.c2_time_of_day ||
                props.contractData?.c2_cargo_info ||
                props.contractData?.c2_recycler_and_others_text ||
                props.contractData?.c2_info_text
            ) {
                setShowC2DataLossWarning(true);
            }
        }

        /** Delete input values */
        props.setContractData(value => ({
            ...value,
            c2_number: "",
            c2_date: null,
            c2_time_of_day: "",
            c2_cargo_info: "",
            c2_recycler_and_others_text: "",
            c2_info_text: ""
        }));
    };

    const handleBillingAddressIsEqualCheckboxChange = () => {
        const checkboxValue = props.billingAddressIsEqual;
        props.setBillingAddressIsEqual(!checkboxValue);

        if (checkboxValue) {
            setShowBillingAddressDataLossWarning(false);
            return; /** Exit function here if checkbox was checked */
        }

        if (!props.hideDataLossWarnings) {
            /** Show (data loss) warning if at least 1 input has some data */
            if (
                props.contractData.c1_ba_recipient ||
                props.contractData.c1_ba_street ||
                props.contractData.c1_ba_house_number ||
                props.contractData.c1_ba_zip_code ||
                props.contractData.c1_ba_city
            ) {
                setShowBillingAddressDataLossWarning(true);
            }
        }

        /** Delete input values */
        props.setContractData(value => ({
            ...value,
            c1_ba_recipient: "",
            c1_ba_street: "",
            c1_ba_house_number: "",
            c1_ba_zip_code: "",
            c1_ba_city: ""
        }));
    };

    const areContractNumbersEqual = () => {
        const c1 = props.contractData?.c1_number;
        const c2 = props.contractData?.c2_number;
        if (!c1 || !c2) return false; /** False if one is empty */
        return c1 === c2;
    };

    const isContainerSizeLimitReached = (
        !!props.contractData?.container_size &&
        (
            props.contractData?.container_size > 100 ||
            props.contractData?.container_size < 0
        )
    );

    return <div>
        <div className={styles.topButtonSection}>
            <Button
                variant="text"
                startIcon={<ArrowBackIcon/>}

                onClick={() => {
                    // TODO: Check for changes and ask again if the user wants to leave current site
                    navigate(-1)
                }}
            >
                Zurück
            </Button>

            <Tooltip title="Aktuell nicht verfügbar" placement="top">
                <div>
                    <Button
                        variant="text"
                        startIcon={<GetAppIcon/>}
                        disabled
                    >
                        Daten importieren
                    </Button>
                </div>
            </Tooltip>
        </div>

        <div className={styles.content}>
            <Typography variant="h1">
                {props.formTitle}
            </Typography>

            <div className={styles.sectionWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Lieferadresse</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            sx={{
                                // background: "red"
                            }}
                            label="Name / Firma"
                            required
                            fullWidth
                            value={props.contractData?.c1_da_recipient ?? ""}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                c1_da_recipient: event.target.value
                            }))}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <TextField
                            label="Straße"
                            required
                            fullWidth
                            value={props.contractData?.c1_da_street ?? ""}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                c1_da_street: event.target.value
                            }))}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Hausnummer"
                            required
                            fullWidth
                            value={props.contractData?.c1_da_house_number ?? ""}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                c1_da_house_number: event.target.value
                            }))}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label="Postleitzahl"
                            required
                            fullWidth
                            value={props.contractData?.c1_da_zip_code ?? ""}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                c1_da_zip_code: event.target.value
                            }))}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Stadt"
                            required
                            fullWidth
                            value={props.contractData?.c1_da_city ?? ""}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                c1_da_city: event.target.value
                            }))}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className={styles.sectionWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Rechnungsadresse</Typography>
                        <FormControlLabel
                            label="Identisch mit Lieferadresse"
                            control={<Checkbox
                                checked={props.billingAddressIsEqual}
                                // onChange={() => props.setBillingAddressIsEqual(value => !value)}
                                onChange={handleBillingAddressIsEqualCheckboxChange}
                            />}
                        />
                    </Grid>

                    {showBillingAddressDataLossWarning &&
                        <Grid item xs={12}>
                            <Alert color="warning" icon={false}>
                                Beim Klicken auf "Auftrag aktualisieren", werden die Daten der <b>Rechnungsadresse
                                gelöscht</b>. Falls dies ein Versehen war, einfach ohne zu speichern zur
                                Auftragsübersicht
                                zurückkehren.
                            </Alert>
                        </Grid>
                    }

                    {!props.billingAddressIsEqual && <>
                        <Grid item xs={12}>
                            <TextField
                                label="Name / Firma"
                                required
                                fullWidth
                                value={props.contractData?.c1_ba_recipient ?? ""}
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    c1_ba_recipient: event.target.value
                                }))}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Straße"
                                required
                                fullWidth
                                value={props.contractData?.c1_ba_street ?? ""}
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    c1_ba_street: event.target.value
                                }))}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Hausnummer"
                                required
                                fullWidth
                                value={props.contractData?.c1_ba_house_number ?? ""}
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    c1_ba_house_number: event.target.value
                                }))}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                label="Postleitzahl"
                                required
                                fullWidth
                                value={props.contractData?.c1_ba_zip_code ?? ""}
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    c1_ba_zip_code: event.target.value
                                }))}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                label="Stadt"
                                required
                                fullWidth
                                value={props.contractData?.c1_ba_city ?? ""}
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    c1_ba_city: event.target.value
                                }))}
                            />
                        </Grid>
                    </>}
                </Grid>
            </div>

            <div className={styles.sectionWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Weitere Angaben</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel required>Typ auswählen</InputLabel>
                            <Select
                                value={props.contractData?.type ?? ""}
                                required
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    type: Number(event.target.value)
                                }))}
                                size="small"
                                label="Typ auswählen"
                                className={styles.typeSelect}
                            >
                                <MenuItem value={1} className={styles.typeStyle}>
                                    liefern&nbsp;<LocalShippingIcon/>
                                </MenuItem>
                                <MenuItem value={2} className={styles.typeStyle}>
                                    stellen <KeyboardDoubleArrowDownIcon/>
                                    und holen <KeyboardDoubleArrowUpIcon/>
                                </MenuItem>
                                <MenuItem value={3} className={styles.typeStyle}>
                                    umsetzen&nbsp;<RedoIcon/>
                                </MenuItem>
                                <MenuItem value={4} className={styles.typeStyle}>
                                    abfahren&nbsp;<NorthEastIcon/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                        <FormControl fullWidth>
                            <InputLabel required>Container</InputLabel>
                            <Select
                                value={props.contractData?.container_variant ?? ""}
                                required
                                onChange={event => props.setContractData(value => ({
                                    ...value,
                                    container_variant: Number(event.target.value)
                                }))}
                                size="small"
                                label="Container"
                            >
                                {containerVariants.map((variant, i) => (
                                    <MenuItem value={i + 1} key={i}>
                                        {variant}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Größe (m³)"
                            required
                            fullWidth
                            type="number"
                            error={isContainerSizeLimitReached}
                            helperText={isContainerSizeLimitReached ? "Der Wert muss zwischen 0 und 100 liegen" : ""}
                            value={Number(props.contractData?.container_size ?? 0)}
                            onChange={event => props.setContractData(value => ({
                                ...value,
                                container_size: Number(event.target.value)
                            }))}
                        />
                    </Grid>
                </Grid>
            </div>

            {props.contractData?.type !== undefined &&
                <div className={styles.sectionWrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={
                                    <Typography variant="h6">
                                        Lieferschein ({contractType.c1Name(props.contractData.type)})
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={props.c1Checked}
                                        // onChange={() => props.setC1Checked(value => !value)}
                                        onChange={handleC1CheckboxChange}
                                    />
                                }
                            />
                        </Grid>

                        {showC1DataLossWarning &&
                            <Grid item xs={12}>
                                <Alert color="warning" icon={false}>
                                    Beim Klicken auf "Auftrag aktualisieren", werden die <b>Lieferscheindaten
                                    ({contractType.c1Name(props.contractData.type)}) gelöscht</b>.
                                    Falls dies ein Versehen war, einfach ohne zu speichern zur Auftragsübersicht
                                    zurückkehren.
                                </Alert>
                            </Grid>
                        }

                        {props.c1Checked && <>
                            <Grid item xs={4}>
                                <TextField
                                    label="Lieferschein Nummer"
                                    required
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">LS</InputAdornment>
                                    }}
                                    inputProps={{ maxLength: 10 }}
                                    value={props.contractData?.c1_number ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c1_number: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    label="Lieferdatum"
                                    minDate={new Date("01.01.2020")}
                                    onChange={event => {
                                        if (!event) {
                                            props.setContractData(value => ({
                                                ...value, c1_date: null
                                            }));
                                        } else if (isValid(event)) {
                                            props.setContractData(value => ({
                                                ...value, c1_date: new Date(event)
                                            }));
                                        }
                                    }}
                                    value={props.contractData?.c1_date || null}
                                    renderInput={e => <TextField {...e} fullWidth/>}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Uhrzeit"
                                    fullWidth
                                    inputProps={{ maxLength: 300 }}
                                    value={props.contractData?.c1_time_of_day ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value, c1_time_of_day: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Ladung"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c1_cargo_info ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c1_cargo_info: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Abfall / Baustoff"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c1_waste_key ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c1_waste_key: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Verwerter und Sonstiges"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c1_recycler_and_others_text ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c1_recycler_and_others_text: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Infos"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c1_info_text ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c1_info_text: event.target.value
                                    }))}
                                />
                            </Grid>
                        </>}
                    </Grid>
                </div>
            }

            {props.contractData?.type === 2 &&
                <div className={styles.sectionWrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={
                                    <Typography variant="h6">
                                        Lieferschein (holen)
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        checked={props.c2Checked}
                                        onChange={handleC2CheckboxChange}
                                    />
                                }
                            />
                        </Grid>

                        {showC2DataLossWarning &&
                            <Grid item xs={12}>
                                <Alert color="warning" icon={false}>
                                    Beim Klicken auf "Auftrag aktualisieren", werden die <b>Lieferscheindaten (holen)
                                    gelöscht</b>.
                                    Falls dies ein Versehen war, einfach ohne zu speichern zur Auftragsübersicht
                                    zurückkehren.
                                </Alert>
                            </Grid>
                        }

                        {props.c2Checked && <>
                            <Grid item xs={4}>
                                <TextField
                                    label="Lieferschein Nummer"
                                    required
                                    fullWidth
                                    error={areContractNumbersEqual()}
                                    helperText={areContractNumbersEqual() ? "Identisch mit \"stellen\"" : ""}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">LS</InputAdornment>
                                    }}
                                    inputProps={{ maxLength: 10 }}
                                    value={props.contractData?.c2_number ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c2_number: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    label="Abholdatum"
                                    minDate={new Date("01.01.2020")}
                                    onChange={event => {
                                        if (!event) {
                                            props.setContractData(value => ({
                                                ...value, c2_date: null
                                            }));
                                        } else if (isValid(event)) {
                                            props.setContractData(value => ({
                                                ...value, c2_date: new Date(event)
                                            }));
                                        }
                                    }}
                                    value={props.contractData?.c2_date || null}
                                    renderInput={e => <TextField {...e} fullWidth/>}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Uhrzeit"
                                    fullWidth
                                    inputProps={{ maxLength: 300 }}
                                    value={props.contractData?.c2_time_of_day ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value, c2_time_of_day: event.target.value
                                    }))}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Ladung"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c2_cargo_info ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c2_cargo_info: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Abfall / Baustoff"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c2_waste_key ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c2_waste_key: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Verwerter und Sonstiges"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c2_recycler_and_others_text ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c2_recycler_and_others_text: event.target.value
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Infos"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 5000 }}
                                    value={props.contractData?.c2_info_text ?? ""}
                                    onChange={event => props.setContractData(value => ({
                                        ...value,
                                        c2_info_text: event.target.value
                                    }))}
                                />
                            </Grid>
                        </>
                        }
                    </Grid>
                </div>
            }

            {props.errorText &&
                <Alert
                    color="error"
                    icon={false}
                    onClose={() => {
                        if (props.setErrorText) props.setErrorText("");
                    }}
                >
                    {props.errorText}
                </Alert>
            }

            <div className={styles.submitButtonSection}>
                <Tooltip title={props.submitButtonTooltip}>
                    <div>
                        <Button
                            onClick={props.handleSubmitClick}
                            disabled={(
                                props.isLoading ||
                                props.isSubmitButtonDisabled ||
                                !isDeliveryAddressDataValid() ||
                                !isBillingAddressDataValid() ||
                                !isAdditionalDataValid() ||
                                !isC1DataValid() ||
                                !isC2DataValid()
                            )}
                        >
                            {props.submitButtonText}
                        </Button>
                    </div>
                </Tooltip>

                {props.optionalButtons}
            </div>

            {props.isLoading &&
                <LoadingSpinnerWithBackdrop/>
            }

        </div>
    </div>;
}

// function SectionWrapper(props: PropsWithChildren<{}>) {
//     return <div className={styles.sectionWrapper}>
//         {props.children}
//     </div>;
// }