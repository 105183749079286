import { css } from "@emotion/css";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { useRegisterUser } from "@/customHooks/useRegisterUser";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";

const styles = {
    inputSection: css({
        display: "flex",
        flexDirection: "column",
        gap: 20
    }),
    buttonSection: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
    })
};

export function RegisterPage() {
    const navigate = useNavigate();

    const {
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        userPasswordConfirm,
        setUserPasswordConfirm,
        userDisplayName,
        setUserDisplayName,
        isLoading,
        handleRegister,
        ErrorMessageComponent,
        isRegisterButtonDisabled,
        passwordNotIdentical
    } = useRegisterUser();

    return <DesktopSetupMainFrame
        title="Konto erstellen"
        description="Ein neues Konto mit E-Mail und Passwort erstellen."
    >
        <div className={styles.inputSection}>
            <TextField
                fullWidth
                label="Neue E-Mail"
                value={userEmail}
                onChange={event => setUserEmail(event.target.value)}
            />
            <TextField
                fullWidth
                label="Neues Passwort festlegen"
                type="password"
                value={userPassword}
                onChange={event => setUserPassword(event.target.value)}
            />
            <TextField
                fullWidth
                label="Neues Passwort wiederholen"
                type="password"
                value={userPasswordConfirm}
                onChange={event => setUserPasswordConfirm(event.target.value)}
                helperText={passwordNotIdentical() ? "Passwörter stimmen nicht überein" : ""}
            />

            <div/>

            <TextField
                fullWidth
                label="Dein Name (z.B. Max)"
                value={userDisplayName}
                onChange={event => setUserDisplayName(event.target.value)}
                helperText="So wirst du anderen Mitarbeitern angezeigt"
                inputProps={{ maxLength: 50 }}
            />

            <ErrorMessageComponent/>
        </div>


        <div className={styles.buttonSection}>
            <Button
                variant="text"
                onClick={() => navigate(appPaths.setupPages.mainPage)}
                sx={{ marginBottom: "15px" }}
            >
                Ich habe bereits ein Konto
            </Button>

            <Button
                onClick={handleRegister}
                disabled={isRegisterButtonDisabled}
            >
                Konto erstellen
            </Button>
        </div>


        {isLoading &&
            <LoadingSpinnerWithBackdrop/>
        }
    </DesktopSetupMainFrame>;
}