import { css } from "@emotion/css";
import { SxProps, Typography } from "@mui/material";
import { appImages } from "@/helper/appImages";
import { appStyles } from "@/helper/appStyles";
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import { appColors } from "@/helper/appColors";
import { useCompanyStore } from "@/stores/useCompanyStore";
import { UserSettingsDialog } from "@/common/UserSettingsDialog";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { OfflineAlert } from "@/common/components/OfflineAlert";
import { D_Notifications } from "@/desktop/components/D_Notifications";
import { SearchProps } from "@/desktop/components/DesktopSearch";

interface Props {
    search?: SearchProps;
}

const styles = {
    main: css({
        // border: "1px solid red",
        height: 60,
        display: "flex",
    }),
    content: css({
        // border: "1px solid blue",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        gap: 14,
        boxSizing: "border-box"
    }),
    leftSection: css({
        width: 310,
    }),
    logo: css({
        height: 44,
        width: "auto",
        borderRadius: appStyles.borderRadius.smaller
    }),
    appName: css({
        color: "#555",
    }),
    avatar: {
        bgcolor: appColors.blue.main,
        cursor: "pointer"
    } as SxProps
};

export function DesktopMainTopNav(props: Props) {
    const { setOpenDialog } = useDialog();
    const companyData = useCompanyStore(state => state.companyData);

    return <div className={styles.main}>
        <div className={styles.content + " " + styles.leftSection}>
            <img
                className={styles.logo}
                src={appImages.logo.x192}
                alt=""
            />

            <Typography
                className={styles.appName}
                variant="h4"
                fontWeight="500"
                noWrap
            >
                {companyData.data?.name}
            </Typography>
        </div>

        {/*<DesktopSearch {...props.search}/>*/}
        <div style={{ flex: 1 }}/>


        <div className={styles.content}>
            <OfflineAlert/>

            {companyData.data?.modules?.includes("CONTAINER_MODULE") &&
                <D_Notifications/>
            }

            <Avatar
                sx={styles.avatar}
                onClick={() => setOpenDialog(appDialogNames.general.userSettings)}
            >
                <PersonIcon/>
            </Avatar>
        </div>

        <UserSettingsDialog/>
    </div>;
}
