import { useSigninCheck } from "reactfire";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { appPaths } from "@/helper/appPaths";

export function useRedirectIfEmailIsNotVerified() {
    const { data: userData } = useSigninCheck();
    const navigate = useNavigate();

    /** Check if email is not verified and redirect to email auth page */
    useEffect(() => {
        if (!userData?.user?.emailVerified) {
            navigate(appPaths.setupPages.mainPage);
        }
    }, [userData]);
}