import { Dispatch, MouseEvent, PropsWithChildren, ReactElement, SetStateAction, useEffect, useState } from "react";
import { Menu, SxProps } from "@mui/material";
import { ContextMenuContent, ContextMenuItemProps } from "@/common/components/ContextMenu/ContextMenuContent";

interface Props {
    items: ContextMenuItemProps[][];
    gapToParent?: "small" | "default";
    horizontalAlign?: "center" | "right";
    verticalAlign?: "bottom" | "top";
    customHtml?: ReactElement;
    isOpen?: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    onClose?: () => void;

    /** Optional fixed with */
    width?: string | number;
}

export function LeftClickMenu(props: PropsWithChildren<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const styles = {
        menu: {
            "& .MuiPaper-root": {
                background: "#fff",
                minWidth: 170,
                // boxShadow: dashboardShadow.elevation_high,
                width: props.width
            },
        } as SxProps
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        props.onClose?.();
        setAnchorEl(null);
    };

    /**
     * Close left-click-menu if props.isOpen is set to false.
     * (If something outside this component wanted it to be closed)
     */
    useEffect(() => {
        if (!props.isOpen) setAnchorEl(null);
    }, [props.isOpen]);

    useEffect(() => {
        console.log("loop1");
        props.setIsOpen && props.setIsOpen(isOpen);
    }, [isOpen]);

    return <div>
        <Menu
            sx={styles.menu}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{
                horizontal: props.horizontalAlign || "right",
                vertical: props.verticalAlign === "top" ? "bottom" : "top"
            }}
            anchorOrigin={{
                horizontal: props.horizontalAlign || "right",
                vertical: props.verticalAlign === "top" ? "top" : "bottom"
            }}
            PaperProps={{
                sx: {
                    mt: props.gapToParent === "small" ? 0 : (props.verticalAlign === "top" ? -1.5 : 1.5)
                }
            }}
        >
            {props.customHtml || <ContextMenuContent items={props.items} handleClose={handleClose}/>}
        </Menu>

        <div onClick={handleClick}>
            {props.children}
        </div>

    </div>;
};