import { MobileWeekWorkElement } from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_DayOverview/MobileWeekWorkElement";
import { css } from "@/helper/css";
import { appColors } from "@/helper/appColors";

const styles = {
    main: css({
        display: "flex",
        flexDirection: "column",
        gap: 4,
        width: "100%"
    })
}

export function M_DayElement() {
    return <div style={styles.main}>
        <MobileWeekWorkElement
            text="Arbeit"
            workTime="05:30 - 12:30 (07:00 h)"
            breakTime="30 min"
            color={appColors.blue.main}
        />

        <MobileWeekWorkElement
            text="Biogas"
            workTime="05:30 - 12:30 (07:00h)"
            color={appColors.green.main}
        />

        <MobileWeekWorkElement
            text="Fortbildung"
            workTime="05:30 - 12:30 (07:00h)"
            color="#a06"
        />
    </div>;
}