import { isMobile } from "react-device-detect";
import { Navigate } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { useCompanySettings } from "@/common/companySettings/useCompanySettings";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";
import { SimpleList } from "@/common/components/SimpleList";

export function C_CompanySettingsStartPage() {
    /** This page is only for the mobile settings nav (on desktop the nav is shown on every (sub-) page) */
    if (!isMobile) return (
        <Navigate to={appPaths.companyGeneral}/>
    );

    const { navData } = useCompanySettings();

    return <M_MainFrame>
        <M_ContentSection
            title="Firmen Einstellungen"
            isPageTitle
            disableBottomPadding
        />

        <M_ContentSection>
            <SimpleList
                navData={navData}
                width="100%"
                disableSidePadding
            />
        </M_ContentSection>
    </M_MainFrame>;
}