import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { Fab, Grid } from "@mui/material";
import { MobileNoteBox } from "@/mobile/pages/MobileNotesPage/MobileNoteBox";
import AddIcon from '@mui/icons-material/Add';
import { css } from "@/helper/css";
import { appStyles } from "@/helper/appStyles";
import { motion } from "framer-motion";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";

const styles = {
    fabWrapper: css({
        position: "fixed",
        bottom: 90,
        right: appStyles.defaultSidePadding.normal,
    })
};

export function MobileNotesPage() {
    const leftElements = [
        ["Text 1 asdsa dasd jhasl djhal dhadh", "Text 2", "Text 3"],
        ["bbbb bbbbb", "bbbbbbbb bb bbb", "bbbb bbbbb", "bbbbbbbb bb bbb", "bbbb bbbbb", "bbbbbbbb bb bbb", "bbbb bbbbb", "bbbbbbbb bb bbb", "bbbbbbbb bb bbb"],
        ["ddddd ddddddd dddddddd", "dddd ddddd dddddddddd dddd ddddd dddddddddd"],
    ];

    const rightElements = [
        ["aaa aaaaaa aaaaa aaaa", "aaaaaaaa aaaaaaaaa aaaaaaaa a", "aaaaa aaaa a", "aaa aaa aaaa aaaaaa aaa",],
        ["ccccc ccccccc cccccccc", "cccc ccccc cccccccccc cccc cccccc ccccccccc"],
        ["eeeee eeeee", "eeeeeeee eeeee eeeeeeeeee eeee eeeee eeeeeeeeee"],
    ];

    return <M_MainFrame>
        <M_ContentSection title="Notizen" isPageTitle>

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <NoteBoxList noteElements={leftElements}/>
                </Grid>
                <Grid item xs={6}>
                    <NoteBoxList noteElements={rightElements}/>
                </Grid>
            </Grid>

            <div style={styles.fabWrapper}>
                <motion.div
                    transition={{ delay: 0.3 }}
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                >
                    <Fab variant="extended">
                        <AddIcon sx={{ mr: 1 }}/>
                        Liste erstellen
                    </Fab>
                </motion.div>
            </div>

        </M_ContentSection>

        <M_ContentSection/>
    </M_MainFrame>;
}

interface NoteBoxListProps {
    noteElements: string[][];
}

function NoteBoxList(props: NoteBoxListProps) {
    return <Grid container spacing={1}>
        {props.noteElements.map((element, index) => (
            <Grid key={index} item xs={12}>
                <MobileNoteBox
                    key={index}
                    data={element || []}
                />
            </Grid>
        ))}
    </Grid>;
}