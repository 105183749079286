import { DesktopSetupMainFrame } from "@/desktop/components/DesktopSetupMainFrame";
import { Button, Typography } from "@mui/material";
import { css } from "@emotion/css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { motion } from "framer-motion";
import { appColors } from "@/helper/appColors";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { appPaths } from "@/helper/appPaths";
import { useNavigate, useParams } from "react-router-dom";

const styles = {
    buttonSection: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    }),
    centeredContent: css({
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        paddingBottom: 80
    }),
    successIcon: css({
        fontSize: "90px !important",
        color: appColors.green.main
    }),
};


export function SetupCompanyCreatedPage() {
    const { companyId } = useParams();
    const navigate = useNavigate();

    const setSelectedCompanyId = useCompanyPersistStore(state => state.setSelectedCompanyId);

    const handleContinueClick = () => {
        setSelectedCompanyId(companyId || "");
        navigate(appPaths.root);
    };

    return <DesktopSetupMainFrame>
        <div className={styles.centeredContent}>
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.5, delay: 0 }}
            >
                <CheckCircleIcon className={styles.successIcon}/>
            </motion.div>


            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
            >
                <Typography
                    variant="h1"
                    textAlign="center"
                    color={appColors.green.main}
                >
                    Fertig!
                </Typography>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 2 }}
            >
                <Typography
                    fontWeight="500"
                    textAlign="center"
                    color={appColors.green.main}
                >
                    Die Firma wurde erfolgreich erstellt
                </Typography>
            </motion.div>
        </div>


        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 3 }}
        >
            <Button
                color="success"
                fullWidth
                onClick={handleContinueClick}
            >
                Fertigstellen
            </Button>
        </motion.div>

    </DesktopSetupMainFrame>;
}