import { css } from "@/helper/css";
import { Typography } from "@mui/material";
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import { appStyles } from "@/helper/appStyles";

const styles = {
    main: css({
        padding: "8px 12px",
        borderRadius: 14,
        color: "#fff",
        position: "relative",
        overflow: "hidden",
    }),
    circle: css({
        position: "absolute",
        transform: "translateY(-50%)",
        background: appStyles.backgroundCircleColor.normal,
        borderRadius: "50%",
        left: 20,
        top: -60,
    }),
    firstCircle: css({
        height: 170,
        width: 500,
    }),
    secondCircle: css({
        height: 250,
        width: 600,
    }),
    thirdCircle: css({
        height: 360,
        width: 700,
    }),

    item: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
    }),
    icon: css({
        height: 16,
        width: "auto"
    })
};

interface Props {
    text: string;
    workTime: string;
    breakTime?: string;
    color: string;
}

export function MobileWeekWorkElement(props: Props) {
    return <div style={{ ...styles.main, background: props.color }}>
        <div style={{ ...styles.circle, ...styles.firstCircle }}/>
        <div style={{ ...styles.circle, ...styles.secondCircle }}/>
        <div style={{ ...styles.circle, ...styles.thirdCircle }}/>

        <div style={styles.item}>
            <ConstructionIcon sx={styles.icon}/>
            <Typography variant="body2" fontWeight="500">
                {props.text}
            </Typography>
        </div>

        <div style={styles.item}>
            <AccessTimeIcon sx={styles.icon}/>
            <Typography variant="body2">
                {/*05:30 - 12:30 (07:00h)*/}
                {props.workTime}
            </Typography>
        </div>

        {props.breakTime &&
            <div style={styles.item}>
                <FreeBreakfastIcon sx={styles.icon}/>
                <Typography variant="body2">
                    {props.breakTime}
                </Typography>
            </div>
        }

    </div>;
}