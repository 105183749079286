import { ReactElement } from "react";
import { css } from "@emotion/css";
import { Tooltip } from "@mui/material";
import ContextMenuItem from "@/common/components/ContextMenu/ContextMenuItem";

export interface ContextMenuItemProps {
    /** Menu item display name */
    displayName: string;

    /** Optional disable click event */
    disabled?: boolean;

    /** If true: red background on over */
    danger?: boolean;

    /** Optional menu item icon */
    icon?: ReactElement;

    /** Show tooltip on hover (if element is enabled) */
    tooltip?: string;

    /** Show tooltip on hover if element is disabled */
    tooltipIfDisabled?: string;

    /** Custom onClick function for each menu item */
    onClick?: () => void;
}

interface Props {
    /** Menu items */
    items: ContextMenuItemProps[][];

    /** Close menu on this function call */
    handleClose: () => void;
}

export function ContextMenuContent(props: Props) {
    const styles = {
        content: css({
            padding: "0px 6px",
            // color: interactive_normal
        }),
        divider: css({
            // border: "1px solid red",
            height: 1,
            margin: "4px 4px",
            background: "#0001"
        })
    };

    return <div className={styles.content}>
        {props.items?.map((menuItemGroup, itemGroupIndex) => <div key={itemGroupIndex}>

            {menuItemGroup.map((menuItem, menuItemIndex) => {
                /** Show tooltip for enabled/disabled menu items */
                const showTooltip = (
                    /** Show tooltip on hover if element is enabled */
                    (!!menuItem.tooltip && !menuItem.disabled) ||
                    /** Show disabled-tooltip on hover if element is disabled */
                    (!!menuItem.tooltipIfDisabled && !!menuItem.disabled)
                );
                return <Tooltip
                    key={menuItemIndex}
                    placement="right"
                    title={(menuItem.disabled ? menuItem.tooltipIfDisabled : menuItem.tooltip) || null}
                    disableInteractive
                    disableFocusListener={!showTooltip}
                    disableTouchListener={!showTooltip}
                    disableHoverListener={!showTooltip}
                >
                    <div>
                        <ContextMenuItem
                            menuItem={menuItem}
                            handleClose={props.handleClose}
                        />
                    </div>
                </Tooltip>
            })}

            {/** Render a divider if there is another menu item group below the current menu item group */}
            {(props.items.length - 1 > itemGroupIndex) &&
                <div className={styles.divider}/>
            }

        </div>)}
    </div>
};