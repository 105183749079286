import { useFirestore, useSigninCheck } from "reactfire";
import { useTimeBookingStore, WorkType } from "@/stores/useTimeBookingStore";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect } from "react";

export function FirestoreGetWorkTypesData() {
    const { data: userData } = useSigninCheck();
    const db = useFirestore();

    const setWorkTypes = useTimeBookingStore(state => state.setWorkTypes);
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    function getNewWorkTypes() {
        /** Exit function if user is not signed in */
        if (!userData?.user?.uid) return;

        /** Exit function if comp id is not set/loaded */
        if (!selectedCompanyId) return;

        // TODO: Only request work types if specific modules are activated

        const workTypesQuery = query(
            collection(db, "companies", selectedCompanyId, "work-types"),
            orderBy("created_at", "desc")
        );

        /** Returns unsub function */
        return onSnapshot(
            workTypesQuery,
            snapshot => {
                const docData = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    doc_id: doc.id,
                } as WorkType));

                setWorkTypes({
                    data: docData,
                    status: "success"
                });
            }
        );
    }

    useEffect(() => {
        const unsub = getNewWorkTypes();
        /** Cleanup */
        return () => {
            if (unsub) unsub();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, selectedCompanyId]);

    return null;
}