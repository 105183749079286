import { appStyles } from "@/helper/appStyles";
import { CSSProperties } from "react";

const dayWidth = 28;
const weekendColor = "#ddd";

const monthsWithDays = [
    [...Array(31)],
    [...Array(28)],
    [...Array(31)],
    [...Array(30)],
    [...Array(31)],
    [...Array(30)],
    [...Array(31)],
    [...Array(31)],
    [...Array(30)],
    [...Array(31)],
    [...Array(30)],
    [...Array(31)]
];

const dayElementStyles = (index: number, borderRadius: "top" | "bottom" | "none"): CSSProperties => {
    return {
        /** General style */
        ...index % 7 === 0 && { background: "#0002" },
        ...index % 7 === 1 && { background: "transparent" },
        ...index % 7 === 2 && { background: appStyles.backgroundCircleColor.normal },
        ...index % 7 === 3 && { background: "transparent" },
        ...index % 7 === 4 && { background: appStyles.backgroundCircleColor.normal },
        ...index % 7 === 5 && { background: "transparent" },
        ...index % 7 === 6 && { background: "#0002" },
        ...index % 7 === 6 && { borderRight: `1px solid ${appStyles.backgroundCircleColor.normal}` },

        /** Style with top border radius */
        ...borderRadius === "top" && index % 7 !== 0 && { borderTopLeftRadius: mobileVacationBookingHelper.dayWidth / 3 },
        ...borderRadius === "top" && index % 7 !== 6 && { borderTopRightRadius: mobileVacationBookingHelper.dayWidth / 3 },

        /** Style with bottom border radius */
        ...borderRadius === "bottom" && index % 7 !== 0 && { borderBottomLeftRadius: mobileVacationBookingHelper.dayWidth / 3 },
        ...borderRadius === "bottom" && index % 7 !== 6 && { borderBottomRightRadius: mobileVacationBookingHelper.dayWidth / 3 },
    }
};

export const mobileVacationBookingHelper = {
    dayWidth,
    weekendColor,
    monthsWithDays,
    dayElementStyles
};

