import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import { Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { and, collection, limit, or, orderBy, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import format from "date-fns/format";
import DraftsIcon from '@mui/icons-material/Drafts';
import React, { useState } from "react";
import { replaceTimestamps } from "@/helper/appUtils";
import { ContainerContractDocument } from "tb-utils";
import { DeliveryNoteCell } from "@/common/ContainerContractPage/DeliveryNoteCell";
import { FullPageDataGrid } from "@/common/FullPageDataGrid";
import EditIcon from "@mui/icons-material/Edit";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { css } from "@emotion/css";
import { usePermission } from "@/customHooks/usePermission";

function CreateContractButton() {
    return <Link to={appPaths.containerContract.create}>
        <Button startIcon={<AddIcon/>}>
            Auftrag erstellen
        </Button>
    </Link>;
}

export function ContainerContractPage() {
    const [activeContractTab, setActiveContractTab] = useState<"not-done" | "done">("not-done");
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const { hasPerm } = usePermission();

    const columns: GridColDef[] = [
        {
            field: "c1_number",
            renderHeader: () => {
                return <span className={css({
                    display: "flex",
                    alignItems: "center",
                    ".MuiSvgIcon-root": {
                        fontSize: 17,
                        color: "#0009"
                    }
                })}>
                Fahrt 1 (liefern&nbsp;<LocalShippingIcon/>&nbsp;/&nbsp;stellen&nbsp;<KeyboardDoubleArrowDownIcon/>)
            </span>
            },
            sortable: false,
            flex: 1,
            minWidth: 220,
            maxWidth: 250,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                if (!params.row?.c1_number) return <i>ausstehend</i>;

                return <DeliveryNoteCell
                    isC1={true}
                    contractData={params.row}
                />;
            }
        },
        {
            field: "c2_number",
            headerName: "Fahrt 2 (holen)",
            renderHeader: () => {
                return <span className={css({
                    display: "flex",
                    alignItems: "center",
                    ".MuiSvgIcon-root": {
                        fontSize: 17,
                        color: "#0009"
                    }
                })}>
                Fahrt 2 (holen&nbsp;<KeyboardDoubleArrowUpIcon/>)
            </span>
            },
            sortable: false,
            flex: 1,
            minWidth: 200,
            maxWidth: 250,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                /** Leave empty if type is not 2 */
                if (params.row.type !== 2) return null;

                /** Display this if no 2. delivery-note is set */
                if (!params.row.c2_number) return <i>ausstehend</i>;

                return <DeliveryNoteCell
                    isC1={false}
                    contractData={params.row}
                    highlightDeadlineExceeded
                />;
            }
        },
        {
            field: "c1_da_recipient",
            headerName: "Kunde",
            flex: 1,
            minWidth: 220,
            maxWidth: 250,
        },
        {
            field: "contract_created_at",
            headerName: "Erstellt am",
            flex: 1,
            minWidth: 110,
            // maxWidth: 200,
            renderCell: params => {
                const timeStamp: Date = params.row.contract_created_at;
                return format(timeStamp, "dd.MM.yyyy");
            }
        },
        {
            field: "iconSection",
            headerName: "",
            sortable: false,
            width: 100,
            renderCell: params => (
                <div>
                    {hasPerm("CONTAINER_CONTRACTS_EDIT") &&
                        <Link to={appPaths.containerContract.edit.replace(":contractId", params.row.id)}>
                            <IconButton>
                                <EditIcon/>
                            </IconButton>
                        </Link>
                    }
                </div>
            )
        },
    ];

    const maxContracts = 200;
    const defaultOrder = "desc";

    const contractsRef = query(
        collection(useFirestore(), "companies", selectedCompanyId || "---", "container-contracts"),
        orderBy("contract_created_at", defaultOrder),
        limit(maxContracts)
    );
    const contractsQueryNotDone = query(
        contractsRef,
        or(
            // type: 1
            and(
                where("type", "in", [1, 3, 4]),
                or(
                    where("c1_status", "==", 0),
                    where("c1_status", "==", null),
                ),
            ),
            // type: 2
            and(
                where("type", "==", 2),
                or(
                    where("c1_status", "==", 0),
                    where("c1_status", "==", null),
                    where("c2_status", "==", 0),
                    where("c2_status", "==", null),
                )
            )
        )
    );
    const contractsQueryDone = query(
        contractsRef,
        or(
            // type: 1
            and(
                where("type", "in", [1, 3, 4]),
                where("c1_status", "==", 10),
            ),
            // type: 2
            and(
                where("type", "==", 2),
                where("c1_status", "==", 10),
                where("c2_status", "==", 10),
            ),
        ),
        limit(100)
    );

    /** `idFiled: "id"` is used by DataGrid */
    const { data, status } = useFirestoreCollectionData(
        activeContractTab === "not-done" ? contractsQueryNotDone : contractsQueryDone,
        { idField: "id" }
    );
    const contractsData = replaceTimestamps<ContainerContractDocument<Date>[]>(data);

    return <FullPageDataGrid
        columns={columns}
        rows={contractsData || []}
        gridDensity="comfortable"
        customEmptyIcon={<DraftsIcon/>}
        customEmptyText="Keine Aufträge"
        isLoading={status === "loading"}
        initialState={{
            sorting: {
                sortModel: [{ field: "contract_created_at", sort: defaultOrder }]
            }
        }}
    >

        {hasPerm("CONTAINER_CONTRACTS_EDIT") &&
            <CreateContractButton/>
        }

        <div>
            <ButtonGroup size="small" disableElevation>
                <Button
                    variant={activeContractTab === "done" ? "outlined" : "contained"}
                    onClick={() => setActiveContractTab("not-done")}
                >
                    Offene Aufträge
                </Button>
                <Button
                    variant={activeContractTab === "not-done" ? "outlined" : "contained"}
                    onClick={() => setActiveContractTab("done")}
                >
                    Erledigte Aufträge
                </Button>
            </ButtonGroup>
        </div>

        {contractsData?.length >= maxContracts &&
            <Chip
                label="Es können nicht alle Aufträge gezeigt werden"
                color="error"
            />
        }

    </FullPageDataGrid>;
}