import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { css } from "@/helper/css";
import { appStyles } from "@/helper/appStyles";
import { MobileMonthNamesSection } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/MobileMonthNamesSection";
import { MobileUserVacationBookings } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/MobileUserVacationBookings";
import { MobileDayNumbers } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/MobileDayNumbers";
import { MobileVacationBookingEndRow } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/MobileVacationBookingEndRow";
import { useEffect, useState } from "react";
import { appFramerVariants } from "@/helper/appFramerVariants";
import { motion } from "framer-motion";

const styles = {
    bookingSectionWrapper: css({
        // border: "1px solid red",
        overflowX: "scroll",
    }),
    dayWrapper: css({
        display: "flex",
        flexDirection: "row",
    }),
    sideGap: css({
        minWidth: appStyles.defaultSidePadding.normal,
    }),
    content: css({})
};

export interface VacationDays {
    start: number;
    length: number;
}

const vacationDays1: VacationDays[] = [
    { start: 5, length: 6 },
    { start: 22, length: 4 },
    { start: 50, length: 12 },
];
const vacationDays2: VacationDays[] = [
    { start: 12, length: 8 },
    { start: 40, length: 15 },
    { start: 100, length: 3 },
];
const vacationDays3: VacationDays[] = [
    { start: 1, length: 5 },
    { start: 43, length: 17 },
    { start: 85, length: 5 },
];
const vacationDays4: VacationDays[] = [
    { start: 57, length: 12 },
    { start: 120, length: 9 },
    { start: 358, length: 8 },
];

export function MobileVacationBooking() {
    const [showLargeComponents, setShowLargeComponents] = useState(false);

    /** Render large components after page fade-in transition (reason: bad performance) */
    useEffect(() => {
        setTimeout(() => {
            setShowLargeComponents(true);
        }, 1000);
        // return () => setShowLargeComponents(false);
    }, []);

    return <>
        <M_ContentSection title="Urlaubsbuchung" disableBottomPadding/>

        <M_ContentSection width="full">
            <div style={styles.bookingSectionWrapper} className="hideScrollbar">
                <div style={styles.dayWrapper}>
                    <div style={styles.sideGap}/>

                    {showLargeComponents && 
                        <motion.div 
                            style={styles.content}
                            variants={appFramerVariants.framerFadeInOut}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <MobileMonthNamesSection/>
                            <MobileDayNumbers/>

                            <MobileUserVacationBookings userName="Mitarbeiter 1" vacationDays={vacationDays1} showAddIcon/>
                            <MobileUserVacationBookings userName="Mitarbeiter 2" vacationDays={vacationDays2}/>
                            <MobileUserVacationBookings userName="Mitarbeiter 3" vacationDays={vacationDays3}/>
                            <MobileUserVacationBookings userName="Mitarbeiter 4" vacationDays={vacationDays4}/>

                            <MobileVacationBookingEndRow/>
                        </motion.div>
                    }

                    <div style={styles.sideGap}/>
                </div>
            </div>
        </M_ContentSection>
    </>;
}
