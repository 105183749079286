import { css } from "@/helper/css";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import {
    M_MonthOverviewHeader
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/M_MonthOverviewHeader";
import {
    M_OverviewButtonSection
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_OverviewButtonSection";
import {
    MobileMonthSummary
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/MobileMonthSummary";
import { TimeBookingDocument } from "tb-utils";
import {
    useMonthOverviewData
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/useMonthOverviewData";
import {
    MobileMonthDayElement
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/MobileMonthDayElement";
import { getYear, isThisMonth, isThisYear } from "date-fns";

const styles = {
    weekGrid: css({
        display: "grid",
        gridTemplateColumns: "repeat(7, minmax(0px, 1fr))",
        gap: 0,
    })
};

export interface MonthDataInterface {
    date: Date;
    // dayNumber: number;
    isOtherMonth?: boolean;
    timeBookings: TimeBookingDocument<Date>[];
}

export function M_MonthOverview() {
    const {
        currentMonthName,
        monthData,
        monthStart,
        showNextMonth,
        showPrevMonth,
        showCurrentMonth
    } = useMonthOverviewData();

    return <M_ContentSection width="normal">
        <M_OverviewButtonSection
            text={`${currentMonthName} ${isThisYear(monthStart) ? "" : getYear(monthStart)}`}
            showTodayClick={showCurrentMonth}
            showPrevClick={showPrevMonth}
            showNextClick={showNextMonth}
            isToday={isThisMonth(monthStart)}
        />
        <M_MonthOverviewHeader/>

        <div style={styles.weekGrid}>
            {monthData.map((day, index) => (
                <MobileMonthDayElement
                    index={index}
                    key={index}
                    data={day}
                />
            ))}
        </div>

        <MobileMonthSummary/>
    </M_ContentSection>;
}