import { useEffect, useState } from "react";
import { getDownloadURL, StorageReference, getBlob } from "firebase/storage";

export function useGetStorageDownloadUrl(ref: StorageReference) {
    const [url, setUrl] = useState("");
    const [status, setStatus] = useState<"loading" | "success" | "error">("loading");
    const [error, setError] = useState<any>(undefined);

    useEffect(() => {
        setStatus("loading");
        getDownloadURL(ref)
            .then(url => {
                setUrl(url);
                setError(undefined)
                setStatus("success");
            })
            .catch(err => {
                setUrl("");
                setError(err);
                setStatus("error");
                console.error(err);
            });
    }, [ref.fullPath]);

    return {
        data: url,
        status,
        error
    };
}
