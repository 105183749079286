import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";
import { MemberInterface } from "@/helper/appRequest";

type DrivingJobsTabs = "all" | "my_jobs"

interface CompanyPersistStore {

    /** Selected company id */
    selectedCompanyId: string;
    setSelectedCompanyId: (selectedCompanyId: string) => void;

    /** members */
    members: MemberInterface[];
    setMembers: (newValue: MemberInterface[]) => void;

}

export const useCompanyPersistStore = create<CompanyPersistStore>()(devtools(persist(
    set => ({

        selectedCompanyId: "",
        setSelectedCompanyId: selectedCompanyId => set({
            selectedCompanyId: selectedCompanyId
        }),

        members: [],
        setMembers: newValue => set({
            members: newValue
        }),

    }),
    {
        name: appLocalStorageNames.companyPersistStore
    }
)));