import { css } from "@/helper/css";
import {
    mobileVacationBookingHelper
} from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/mobileVacationBookingHelper";
import { Typography } from "@mui/material";

const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export function MobileDayNumbers() {
    let index = 0;

    const styles = {
        main: css({
            display: "flex",
            flexDirection: "row",
        }),
        dayElement: css({
            width: mobileVacationBookingHelper.dayWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: 45,
            boxSizing: "border-box",
            // borderTopRightRadius: mobileVacationBookingHelper.dayWidth / 3,
            // borderTopLeftRadius: mobileVacationBookingHelper.dayWidth / 3,
        })
    };

    return <div style={styles.main}>
        {mobileVacationBookingHelper.monthsWithDays.map((month, monthIndex) => {
            return month.map((day, dayIndex) => {
                index++;
                return <div
                    key={`${monthIndex} ${dayIndex}`}
                    style={{
                        ...styles.dayElement,
                        // background: index % 2 === 1 ? appStyles.backgroundCircleColor.normal : "#fff",
                        ...mobileVacationBookingHelper.dayElementStyles(index, "top"),
                        // ...index % 7 === 0 && { background: "#0002" },
                        // ...index % 7 !== 0 && { borderTopLeftRadius: mobileVacationBookingHelper.dayWidth / 3, },
                        // ...index % 7 === 1 && { background: "transparent" },
                        // ...index % 7 === 2 && { background: appStyles.backgroundCircleColor.normal },
                        // ...index % 7 === 3 && { background: "transparent" },
                        // ...index % 7 === 4 && { background: appStyles.backgroundCircleColor.normal },
                        // ...index % 7 === 5 && { background: "transparent" },
                        // ...index % 7 !== 6 && { borderTopRightRadius: mobileVacationBookingHelper.dayWidth / 3, },
                        // ...index % 7 === 6 && { background: "#0002" },
                    }}
                >
                    <Typography variant="caption">
                        {weekDays[index % 7]}
                    </Typography>
                    <Typography variant="caption">
                        {dayIndex + 1}
                    </Typography>

                </div>
            });
        })}
    </div>;
}