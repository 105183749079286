import { CisCalendarsResponse, CisEventsResponse } from "@/ModuleCalendarToIncomeStatement/CisUtils";

export const getCalendarsResponse: CisCalendarsResponse = {
    "kind": "calendar#calendarList",
    "etag": "\"p33gfjhnlvv7og0o\"",
    "nextSyncToken": "COD5xvX_z4gDEhtiZW5uZXQuZWJlcmhhcmR0MkBnbWFpbC5jb20=",
    "items": [
        {
            "kind": "calendar#calendarListEntry",
            "etag": "\"1710194748162000\"",
            "id": "bennet.eberhardt2@gmail.com",
            "summary": "bennet.eberhardt2@gmail.com",
            "timeZone": "UTC",
            "colorId": "14",
            "backgroundColor": "#9fe1e7",
            "foregroundColor": "#000000",
            "selected": true,
            "accessRole": "owner",
            "defaultReminders": [{ "method": "popup", "minutes": 30 }],
            "notificationSettings": {
                "notifications": [{
                    "type": "eventCreation",
                    "method": "email"
                }, { "type": "eventChange", "method": "email" }, {
                    "type": "eventCancellation",
                    "method": "email"
                }, { "type": "eventResponse", "method": "email" }]
            },
            "primary": true,
            "conferenceProperties": { "allowedConferenceSolutionTypes": ["hangoutsMeet"] }
        },
        {
            "kind": "calendar#calendarListEntry",
            "etag": "\"1712745685162000\"",
            "id": "de.german#holiday@group.v.calendar.google.com",
            "summary": "Feiertage in Deutschland",
            "description": "Feier- und Gedenktage in Deutschland",
            "timeZone": "UTC",
            "summaryOverride": "Feiertage in Deutschland",
            "colorId": "8",
            "backgroundColor": "#16a765",
            "foregroundColor": "#000000",
            "selected": true,
            "accessRole": "reader",
            "defaultReminders": [],
            "conferenceProperties": { "allowedConferenceSolutionTypes": ["hangoutsMeet"] }
        },
        {
            "kind": "calendar#calendarListEntry",
            "etag": "\"1712745686040000\"",
            "id": "addressbook#contacts@group.v.calendar.google.com",
            "summary": "Birthdays",
            "description": "Displays birthdays, anniversaries, and other event dates of people in Google Contacts.",
            "timeZone": "UTC",
            "summaryOverride": "Birthdays",
            "colorId": "13",
            "backgroundColor": "#92e1c0",
            "foregroundColor": "#000000",
            "selected": true,
            "accessRole": "reader",
            "defaultReminders": [],
            "conferenceProperties": { "allowedConferenceSolutionTypes": ["hangoutsMeet"] }
        }]
};

export const getEventsResponse: CisEventsResponse = {
    "kind": "calendar#events",
    "etag": "\"p33gfjhnlvv7og0o\"",
    "summary": "bennet.eberhardt2@gmail.com",
    "description": "",
    "updated": "2024-09-19T21:56:29.516Z",
    "timeZone": "UTC",
    "accessRole": "owner",
    "defaultReminders": [{ "method": "popup", "minutes": 30 }],
    "nextSyncToken": "COD5xvX_z4gDEOD5xvX_z4gDGAUgtuy-wQIotuy-wQI=",
    "items": [
        {
            "kind": "calendar#event",
            "etag": "\"3452844224690000\"",
            "id": "4humlvkjuj4090fiu5ugfr0boh",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NGh1bWx2a2p1ajQwOTBmaXU1dWdmcjBib2ggYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-15T17:41:52.000Z",
            "updated": "2024-09-15T17:41:52.345Z",
            "summary": "ABCD -12,121€ 40€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-15T12:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-15T13:00:00Z", "timeZone": "UTC" },
            "iCalUID": "4humlvkjuj4090fiu5ugfr0boh@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3452851996278000\"",
            "id": "6s4ju80a974e0mnguokk5tsubj",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NnM0anU4MGE5NzRlMG1uZ3Vva2s1dHN1YmogYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-15T18:46:38.000Z",
            "updated": "2024-09-15T18:46:38.139Z",
            "summary": "20€ DEF",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-15T13:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-15T14:00:00Z", "timeZone": "UTC" },
            "iCalUID": "6s4ju80a974e0mnguokk5tsubj@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453552731476000\"",
            "id": "5fh7c6kqohn8v9a8m4jesqnhb5",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NWZoN2M2a3FvaG44djlhOG00amVzcW5oYjUgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:04:54.000Z",
            "updated": "2024-09-19T20:06:05.738Z",
            "summary": "Termin für einen ganzen Tag 1€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "date": "2024-09-16" },
            "end": { "date": "2024-09-17" },
            "transparency": "transparent",
            "iCalUID": "5fh7c6kqohn8v9a8m4jesqnhb5@google.com",
            "sequence": 0,
            "reminders": { "useDefault": false },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453552740210000\"",
            "id": "7f3rjjq01abohatvq7rqt6cvgu",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=N2YzcmpqcTAxYWJvaGF0dnE3cnF0NmN2Z3UgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:05:21.000Z",
            "updated": "2024-09-19T20:06:10.105Z",
            "summary": "Termin vom 17. - 18. mit zeit 2€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-17T17:45:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-18T11:00:00Z", "timeZone": "UTC" },
            "iCalUID": "7f3rjjq01abohatvq7rqt6cvgu@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453552753746000\"",
            "id": "60m0q730v553k9m1dntqdjtsj9",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NjBtMHE3MzB2NTUzazltMWRudHFkanRzajkgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:05:30.000Z",
            "updated": "2024-09-19T20:06:16.873Z",
            "summary": "Termin über 2 Ganze Tage 3€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "date": "2024-09-19" },
            "end": { "date": "2024-09-21" },
            "transparency": "transparent",
            "iCalUID": "60m0q730v553k9m1dntqdjtsj9@google.com",
            "sequence": 0,
            "reminders": { "useDefault": false },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453556131960000\"",
            "id": "2rra0rgv0gef3md4fr14cdgjdk",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=MnJyYTByZ3YwZ2VmM21kNGZyMTRjZGdqZGsgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:34:25.000Z",
            "updated": "2024-09-19T20:34:25.980Z",
            "summary": "Geld mit komma cent 12,99€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-16T15:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-16T16:00:00Z", "timeZone": "UTC" },
            "iCalUID": "2rra0rgv0gef3md4fr14cdgjdk@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453556156812000\"",
            "id": "25b9tkfamuiolqc83qtpj9cdf8",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=MjViOXRrZmFtdWlvbHFjODNxdHBqOWNkZjggYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:34:38.000Z",
            "updated": "2024-09-19T20:34:38.406Z",
            "summary": "Geld mit cent punkt 12.99€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-16T16:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-16T17:00:00Z", "timeZone": "UTC" },
            "iCalUID": "25b9tkfamuiolqc83qtpj9cdf8@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453556176138000\"",
            "id": "5m6o2c9365upkkdd9hiikt0ur3",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NW02bzJjOTM2NXVwa2tkZDloaWlrdDB1cjMgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:34:48.000Z",
            "updated": "2024-09-19T20:34:48.069Z",
            "summary": "Geld mit leerzeichen  12 €",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-16T17:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-16T18:00:00Z", "timeZone": "UTC" },
            "iCalUID": "5m6o2c9365upkkdd9hiikt0ur3@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453558926886000\"",
            "id": "3k7e03ff6jke4pego7at6irack",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=M2s3ZTAzZmY2amtlNHBlZ283YXQ2aXJhY2sgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T20:57:43.000Z",
            "updated": "2024-09-19T20:57:43.443Z",
            "summary": "Geld mit einer nachkommastelle 13,2 €",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-16T18:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-16T19:00:00Z", "timeZone": "UTC" },
            "iCalUID": "3k7e03ff6jke4pego7at6irack@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453559539892000\"",
            "id": "5aj5s6gbd9mfvk1h7bsno2g1ce",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NWFqNXM2Z2JkOW1mdmsxaDdic25vMmcxY2UgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:02:49.000Z",
            "updated": "2024-09-19T21:02:49.946Z",
            "summary": "Ohne Geldbetrag",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-09-18T15:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-09-18T17:30:00Z", "timeZone": "UTC" },
            "iCalUID": "5aj5s6gbd9mfvk1h7bsno2g1ce@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453562162136000\"",
            "id": "2ps0m8jbnskg96k49tvedbtl1o",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=MnBzMG04amJuc2tnOTZrNDl0dmVkYnRsMW8gYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:24:19.000Z",
            "updated": "2024-09-19T21:24:41.068Z",
            "summary": "August Eintrag 50€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-08-25T09:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-08-25T10:00:00Z", "timeZone": "UTC" },
            "iCalUID": "2ps0m8jbnskg96k49tvedbtl1o@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453562171332000\"",
            "id": "3rg72vn0ehkolqpjgci66a01tm",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=M3JnNzJ2bjBlaGtvbHFwamdjaTY2YTAxdG0gYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:24:23.000Z",
            "updated": "2024-09-19T21:24:45.666Z",
            "summary": "August Eintrag 10€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-08-28T09:15:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-08-28T12:15:00Z", "timeZone": "UTC" },
            "iCalUID": "3rg72vn0ehkolqpjgci66a01tm@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453562193634000\"",
            "id": "3r0tc6idodnaa7uepieq9rc7j1",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=M3IwdGM2aWRvZG5hYTd1ZXBpZXE5cmM3ajEgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:24:56.000Z",
            "updated": "2024-09-19T21:24:56.817Z",
            "summary": "Juliy 20,20€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-07-21T09:30:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-07-21T12:45:00Z", "timeZone": "UTC" },
            "iCalUID": "3r0tc6idodnaa7uepieq9rc7j1@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453562213374000\"",
            "id": "0nthvh8u9j111fu2p81qiu6b5i",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=MG50aHZoOHU5ajExMWZ1MnA4MXFpdTZiNWkgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:25:06.000Z",
            "updated": "2024-09-19T21:25:06.687Z",
            "summary": "July 999€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-07-21T13:15:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-07-21T14:30:00Z", "timeZone": "UTC" },
            "iCalUID": "0nthvh8u9j111fu2p81qiu6b5i@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        },
        {
            "kind": "calendar#event",
            "etag": "\"3453565979032000\"",
            "id": "66i9a8oujeob1tqlt7cl078o4t",
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=NjZpOWE4b3VqZW9iMXRxbHQ3Y2wwNzhvNHQgYmVubmV0LmViZXJoYXJkdDJAbQ",
            "created": "2024-09-19T21:56:29.000Z",
            "updated": "2024-09-19T21:56:29.516Z",
            "summary": "Test Ausgabe -123,50€",
            "creator": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "organizer": { "email": "bennet.eberhardt2@gmail.com", "self": true },
            "start": { "dateTime": "2024-07-23T10:00:00Z", "timeZone": "UTC" },
            "end": { "dateTime": "2024-07-23T11:00:00Z", "timeZone": "UTC" },
            "iCalUID": "66i9a8oujeob1tqlt7cl078o4t@google.com",
            "sequence": 0,
            "reminders": { "useDefault": true },
            "eventType": "default"
        }
    ]
};
