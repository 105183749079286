import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { CompaniesDocument } from "tb-utils";

type CompanyDataType = {
    data: CompaniesDocument<Date> | undefined;
    status: "loading" | "success" | "error";
    id: string;
};
type UnreadNotificationsType = {
    count: number | null;
    status: "success" | "loading" | "error";
};

interface CompanyStore {
    /** Data selected company */
    companyData: CompanyDataType;
    setCompanyData: (newData: CompanyDataType) => void;

    /** Number of unread messages */
    unreadNotificationCount: UnreadNotificationsType;
    setUnreadNotificationCount: (newData: UnreadNotificationsType) => void;
}

export const useCompanyStore = create<CompanyStore>()(devtools(set => ({
    companyData: {
        data: undefined,
        status: "loading",
        id: ""
    },
    setCompanyData: newData => set({
        companyData: newData
    }),

    unreadNotificationCount: {
        count: null,
        status: "loading"
    },
    setUnreadNotificationCount: newData => set({
        unreadNotificationCount: newData
    })
})));