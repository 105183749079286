import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isEqual, isValid, startOfDay, subDays } from "date-fns";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { CisCalendarsResponse } from "@/ModuleCalendarToIncomeStatement/CisUtils";

interface Props {
    selectedCalendar: string;
    setSelectedCalendar: Dispatch<SetStateAction<string>>;

    startDate: Date;
    setStartDate: Dispatch<SetStateAction<Date>>;

    endDate: Date;
    setEndDate: Dispatch<SetStateAction<Date>>;

    allCalendars: CisCalendarsResponse | null;
    isAllCalendarsLoading: boolean;
}

export function CisNav(props: Props) {
    const initStartDate = subDays(new Date(), 30);
    const [tempStartDate, setTempStartDate] = useState(initStartDate);
    const [tempEndDate, setTempEndDate] = useState(new Date());

    function handleSearchClick() {
        props.setStartDate(tempStartDate);
        props.setEndDate(tempEndDate);
    }

    const isSearchDisabled = useMemo(() => {
        return (
            !isValid(tempEndDate) ||
            !isValid(tempStartDate) ||
            (
                isEqual(startOfDay(tempStartDate), startOfDay(props.startDate)) &&
                isEqual(startOfDay(tempEndDate), startOfDay(props.endDate))
            )
        );
    }, [tempStartDate, tempEndDate, props.startDate, props.endDate]);

    return <div className="flex flex-row flex-wrap items-center gap-2">
        <FormControl sx={{ minWidth: "220px" }} disabled={props.isAllCalendarsLoading}>
            <InputLabel id="demo-simple-select-label">
                <div className="flex items-center gap-2">
                    {props.isAllCalendarsLoading &&
                        <CircularProgress size={18} thickness={5}/>
                    }
                    Kalender auswählen
                </div>
            </InputLabel>
            <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.selectedCalendar}
                label="Kalender auswählen"
                onChange={event => {
                    props.setSelectedCalendar(event.target.value);
                }}
            >
                {props.allCalendars?.items?.map(calendar => (
                    <MenuItem key={calendar.id} value={calendar.id}>
                        {calendar.summary}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        {!!props.selectedCalendar && <>
            <DatePicker
                renderInput={e => <TextField {...e}/>}
                label="Start"
                value={tempStartDate}
                onChange={v => {
                    if (v) setTempStartDate(v);
                }}
            />
            <DatePicker
                renderInput={e => <TextField {...e}/>}
                label="Ende"
                value={tempEndDate}
                onChange={v => {
                    if (v) setTempEndDate(v);
                }}
            />
            <Button onClick={handleSearchClick} disabled={isSearchDisabled}>
                Suchen
            </Button>
        </>}
    </div>;
}
