import { CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { css } from "@emotion/css";
import { appLogger } from "@/helper/appLogger";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";

const styles = {
    main: css({
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 20
    })
};

const FILE_NAME = "MobileLogoutPage/index.tsx";

/** Logs out the user from the app and deletes all stored data (except logs) */
async function appLogout() {
    /** Sign out from firebase */
    const auth = getAuth();
    try {
        await signOut(auth)
            .catch(() => appLogger.error(FILE_NAME, `Error executing firebase "signOut()" function (.catch)`));
    } catch (err) {
        appLogger.error(FILE_NAME, `Error executing firebase "signOut()" function (try/catch)`);
    }

    /** Clear local storage */
    localStorage.removeItem(appLocalStorageNames.authStore);
    localStorage.removeItem(appLocalStorageNames.companyPersistStore);

    /** Clear session storage */
    sessionStorage.clear();

    /** Write log entry */
    appLogger.event(FILE_NAME, `User logged out ("clean logout")`);

    /** Reload page */
    window.location.href = "/";
}

export function LogoutView() {
    useEffect(() => {
        appLogout()
            .catch(() => appLogger.error(FILE_NAME, "app logout error"));
    }, []);

    return <div className={styles.main}>
        <CircularProgress disableShrink/>
        <Typography>Abmelden...</Typography>
    </div>;
}