import { ChangeEvent } from "react";
import { ListItem, ListItemText, Switch, SxProps } from "@mui/material";
import { appStyles } from "@/helper/appStyles";
import { css } from "@emotion/css";

interface Props {
    primary: string;
    secondary?: string;
    sidePadding?: "default" | "small";
    checked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export function ListElementWithSwitch(props: Props) {
    const sidePadding = props.sidePadding === "small" ? "5px" : `${appStyles.defaultSidePadding.normal}px`;

    const styles = {
        listItem: {
            padding: `5px ${sidePadding}`,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        } as SxProps,
        disabledElement: css({
            position: "absolute",
            background: "#fffb",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
        })
    };

    return <ListItem sx={styles.listItem}>
        <ListItemText
            primary={props.primary}
            secondary={props.secondary}
        />
        <Switch
            checked={props.checked}
            onChange={props.onChange}
        />

        {props.disabled &&
            <div className={styles.disabledElement}/>
        }
    </ListItem>;
}