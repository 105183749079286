import { css } from "@emotion/css";
import { appStyles } from "@/helper/appStyles";
import { appColors } from "@/helper/appColors";
import { Typography } from "@mui/material";
import format from "date-fns/format";
import { isThisYear, isToday, isValid, isYesterday } from "date-fns";
import { useNavigate } from "react-router-dom";

const FILE_NAME = "D_NotificationItem.tsx";

interface Props {
    title: string;
    message: string;
    date: Date;
    linktTo: string;
    read: boolean;
    linkClicked: () => void;
}

function prettyDate(date: Date): string {
    if (!isValid(date)) return "";
    if (isToday(date)) return `Heute, ${format(date, "HH:mm")}`;
    if (isYesterday(date)) return `Gestern, ${format(date, "HH:mm")}`;
    return `${format(date, isThisYear(date) ? "dd.MM. HH:mm" : "dd.MM.yyyy HH:mm")}`;
}

export function D_NotificationItem(props: Props) {
    const navigate = useNavigate();

    const styles = {
        main: css({
            // border: "1px solid red",
            // padding: 16,
            // background: appColors.blue.veryLight,
            borderRadius: appStyles.borderRadius.small,
            ":hover": {
                background: appColors.backgroundGray.main,
            },
            display: "flex",
            flexDirection: "row",
            gap: 10,
            overflow: "hidden",
            cursor: "pointer",
        }),
        leftIndicator: css({
            width: 4,
            background: props.read ? "transparent" : appColors.blue.main,
        }),
        mainContent: css({
            // border: "1px solid green"
            padding: "10px 0",
            flex: 1,
            color: props.read ? "#555" : "",
            ":hover": {
                color: "inherit",
            }
        }),
        iconSection: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 5
        }),
    };

    return <div
        className={styles.main}
        onClick={() => {
            props.linkClicked();
            navigate(props.linktTo);
        }}
    >
        <div className={styles.leftIndicator}/>

        <div className={styles.mainContent}>
            <Typography fontWeight={props.read ? 400 : 500}>
                {props.title}
            </Typography>
            <Typography fontWeight={props.read ? 400 : 500} variant="body2">
                {props.message}
            </Typography>
            <Typography variant="body2">
                {prettyDate(props.date)}
            </Typography>
        </div>

        <div className={styles.iconSection}>
            {/*{!props.read &&*/}
            {/*    <Tooltip*/}
            {/*        title="Als gelesen markieren"*/}
            {/*        disableInteractive*/}
            {/*        placement="top"*/}
            {/*    >*/}
            {/*        <IconButton>*/}
            {/*            <MarkEmailReadOutlinedIcon/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*}*/}
        </div>
    </div>;
}