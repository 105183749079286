import { SimpleDialog } from "@/common/SimpleDialog";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { usePendingInvitations } from "@/common/companySettings/pages/C_ManageEmployeesPage/usePendingInvitations";
import { Alert, Typography } from "@mui/material";
import format from "date-fns/format";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useState } from "react";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "C_ManagePendingInvitationsDialog.tsx";


export function C_ManagePendingInvitationsDialog() {
    const { value: selectedPendingId, remove: removeSelectedPendingId } = useQueryParam("pending-user");
    const { pendingInvitationData, isPendingInvitationLoading } = usePendingInvitations();
    const { deletePendingInvitation } = useFirestoreWrite();

    const [isDeletionLoading, setIsDeletionLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    const pendingInv = pendingInvitationData?.find(inv => inv.doc_id === selectedPendingId);

    const handleCustomClose = () => {
        setErrorText("");
        setIsDeletionLoading(false);
        removeSelectedPendingId();
    };

    return <SimpleDialog
        name=""
        title="Ausstehende Einladung"
        show={!!selectedPendingId}
        handleCustomBackdropClick={handleCustomClose}
        fullscreenLoading={{ loading: isPendingInvitationLoading || isDeletionLoading }}
        actionButtons={[
            {
                text: "Schließen",
                closeDialogOnClick: true,
            },
            {
                text: "Einladung löschen",
                color: "error",
                onClick: () => {
                    setIsDeletionLoading(true);
                    deletePendingInvitation(selectedPendingId || "")
                        .then(() => {
                            removeSelectedPendingId();
                        })
                        .catch(err => {
                            appLogger.error(FILE_NAME, `Error deleting pending invitation: ${selectedPendingId} ${JSON.stringify(err)}`);
                            setErrorText("Beim Löschen ist ein Fehler aufgetreten.");
                        })
                        .finally(() => {
                            setIsDeletionLoading(false);
                        });
                },
            }
        ]}
    >
        {errorText && <>
            <Alert icon={false} color="error">
                {errorText}
            </Alert>
            <br/>
        </>}

        <Typography>
            {pendingInv?.email || ""} wurde
            von {pendingInv?.creator_display_name || ""} am {format(pendingInv?.created_at || 0, "dd.MM.yyyy")} eingeladen,
            hat aber diese Einladung aktuell nicht angenommen.
        </Typography>
    </SimpleDialog>;
}

