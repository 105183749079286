import { Button, IconButton, Skeleton, SxProps, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { css } from "@emotion/css";
import EditIcon from "@mui/icons-material/Edit";
import { appStyles } from "@/helper/appStyles";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";
import { C_CompanySettingsFrame } from "@/common/companySettings/C_CompanySettingsFrame";
import { C_SettingsSection } from "@/common/companySettings/C_SettingsSection";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { C_CreateNewWorkTypeDialog } from "./C_CreateNewWorkTypeDialog";

const styles = {
    workTypesWrapper: css({
        display: "flex",
        flexDirection: "column",
        gap: 10,
        maxWidth: 450
    }),
    workTypePreviewElement: css({
        color: "#fff",
        padding: 12,
        borderRadius: appStyles.borderRadius.smaller,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    }),
    workTypeIcon: {
        color: "#fff",
    } as SxProps
};

export function C_ManageWorkTypesPage() {
    const workTypes = useTimeBookingStore(state => state.workTypes);
    const { setOpenDialog } = useDialog();

    return <C_CompanySettingsFrame title="Arbeitsarten">
        <C_SettingsSection>
            <Button
                startIcon={<AddIcon/>}
                onClick={() => setOpenDialog(appDialogNames.compSettingsPage.createNewWorkType)}
            >
                Arbeitsart hinzufügen
            </Button>
        </C_SettingsSection>

        <C_SettingsSection className={styles.workTypesWrapper}>
            {workTypes.status === "loading" &&
                [...Array(4)].map((v, i) => (
                    <Skeleton
                        key={i}
                        variant="rectangular"
                        height={58}
                        style={{ borderRadius: 12 }}
                    />
                ))
            }

            {workTypes.status === "success" && workTypes.data?.length === 0 && (
                <Typography textAlign="center" fontStyle="italic">
                    Es wurden keine Arbeitsarten erstellt
                </Typography>
            )}

            {workTypes.data?.map(workType => (
                <div
                    className={styles.workTypePreviewElement}
                    style={{ background: workType.color }}
                >
                    <Typography>{workType.name}</Typography>
                    <IconButton size="small">
                        <EditIcon sx={styles.workTypeIcon}/>
                    </IconButton>
                </div>
            ))}

        </C_SettingsSection>

        <C_CreateNewWorkTypeDialog/>
    </C_CompanySettingsFrame>;
}