import { Alert, Button, Checkbox, FormControlLabel, Skeleton, SxProps, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { appLogger } from "@/helper/appLogger";
import React, { useEffect, useState } from "react";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { isThisYear } from "date-fns";
import format from "date-fns/format";
import { DeliveryNote, DriverReportDocument } from "tb-utils";
import { DeliveryNoteCell } from "@/common/ContainerContractPage/DeliveryNoteCell";
import { serverTimestamp } from "firebase/firestore";
import { useSigninCheck } from "reactfire";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQueryParam } from "@/customHooks/useQueryParam";
import { MobileView } from "react-device-detect";
import { css } from "@emotion/css";
import { usePermission } from "@/customHooks/usePermission";
import { DriverReportForm } from "@/common/DrivingJobOverviewPage/DriverReportForm";

const FILE_NAME = "DriverReportContent.tsx";

const columns: GridColDef[] = [
    {
        field: 'contract',
        headerName: 'Auftrag',
        sortable: false,
        flex: 1,
        minWidth: 90,
        maxWidth: 130,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => {
            return <DeliveryNoteCell
                isC1={params.row.contractType === 1}
                contractData={params.row.raw}
                hideDate
            />;
        }
    },
    {
        field: 'time_of_day',
        headerName: 'Uhrzeit',
        sortable: false,
        flex: 1,
        minWidth: 80,
        maxWidth: 100,
    },
    {
        field: 'customer',
        headerName: 'Kunde',
        sortable: false,
        flex: 1,
        minWidth: 220,
        maxWidth: 350,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => (
            <div>{params.row.raw.c1_da_recipient}</div>
        )
    },
    {
        field: 'containerSize',
        headerName: 'Menge',
        sortable: false,
        flex: 1,
        minWidth: 80,
        maxWidth: 180,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => (
            <div>{params.row.raw.container_size} m³</div>
        )
    },
    {
        field: 'waste_key',
        headerName: 'Abfall / Baustoff',
        sortable: false,
        flex: 1,
        minWidth: 150,
        maxWidth: 200,
    },
    {
        field: 'recycler_and_others_text',
        headerName: 'Verwerter und Sonstiges',
        sortable: false,
        flex: 1,
        minWidth: 200,
        maxWidth: 300,
    },
    {
        field: 'finalize_time_spend',
        headerName: 'Zeitangabe',
        sortable: false,
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => (
            <div>{params.row.raw.finalize_time_spend}</div>
        )
    },
    {
        field: 'finalize_weight',
        headerName: 'Mengenangabe',
        sortable: false,
        flex: 1,
        minWidth: 120,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => (
            <div>{params.row.raw.finalize_weight}</div>
        )
    },
    {
        field: 'finalize_weighbridge_number',
        headerName: 'Wiegeschein Nummer',
        sortable: false,
        flex: 1,
        minWidth: 200,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<any, DeliveryNote<Date> & Record<string, any>>) => (
            <div>{params.row.raw.finalize_weighbridge_number}</div>
        )
    },
];

const styles = {
    main: css({
        display: "flex",
        flexDirection: "column",
        gap: 20
    }),
    mobileBackButton: {
        marginBottom: "20px"
    } as SxProps,
    signatureWrapper: css({
        height: 80
    }),
    signatureImage: css({
        maxHeight: "100%",
        width: "auto"
    })
};

interface Props {
    driverReportData: DriverReportDocument<Date>;
    deliveryNotes: (DeliveryNote<Date> | undefined)[];

    signatureImageUrl: string;
    signatureImageStatus: "loading" | "error" | "success";
}

export function DriverReportContent(props: Props) {
    const { remove: removeSelectedReportId } = useQueryParam("report");
    const { hasPerm } = usePermission();
    // const { storageRefs } = useFirebaseStorage();
    // const { data: signatureImageUrl, status: signatureImageStatus } = useGetStorageDownloadUrl(
    //     storageRefs.drivingJobSignature(props.driverReportData.signature_image_id)
    // );

    const members = useCompanyPersistStore(state => state.members);
    const { updateDriverReport } = useFirestoreWrite();
    const { data: userData } = useSigninCheck();
    const driver = members.find(m => m.user_id === props.driverReportData.driver);

    /**
     * Additional Data
     */
    const [licensePlate, setLicensePlate] = useState("");
    const [licensePlateTrailer, setLicensePlateTrailer] = useState("");
    const [startTime, setStartTime] = useState<Date | undefined | null>(null);
    const [endTime, setEndTime] = useState<Date | undefined | null>(null);
    const [breakLength, setBreakLength] = useState("");

    const [isAdditionalDataLoading, setIsAdditionalDataLoading] = useState(false);
    const [hasAdditionalDataChanged, setHasAdditionalDataChanged] = useState(false);

    function saveAdditionalData() {
        setIsAdditionalDataLoading(true);
        updateDriverReport(props.driverReportData.doc_id || "", {
            license_plate: licensePlate,
            license_plate_trailer: licensePlateTrailer,
            start_time: startTime || null,
            end_time: endTime || null,
            break_length: breakLength,
        })
            .catch(e => {
                appLogger.error(FILE_NAME, "Error updating additional data" + e);
            })
            .finally(() => {
                setHasAdditionalDataChanged(false);
                setIsAdditionalDataLoading(false);
            });
    }

    function resetAdditionalData() {
        setLicensePlate(props.driverReportData.license_plate || "");
        setLicensePlateTrailer(props.driverReportData.license_plate_trailer || "");
        setStartTime(props.driverReportData.start_time);
        setEndTime(props.driverReportData.end_time);
        setBreakLength(props.driverReportData.break_length || "");
    }

    useEffect(resetAdditionalData, []); // Set initial values


    useEffect(() => {
        setHasAdditionalDataChanged(
            licensePlate !== (props.driverReportData.license_plate || "") ||
            licensePlateTrailer !== (props.driverReportData.license_plate_trailer || "") ||
            startTime !== props.driverReportData.start_time ||
            endTime !== props.driverReportData.end_time ||
            breakLength !== (props.driverReportData.break_length || "")
        );
    }, [licensePlate, licensePlateTrailer, startTime, endTime, breakLength]);


    const formattedReportDate = () => {
        const dateFormat = isThisYear(props.driverReportData.day) ? "dd.MM." : "dd.MM.yyyy"
        return format(props.driverReportData.day || 0, dateFormat);
    };

    const isAlreadyReviewed = props.driverReportData.review_status === 1;
    const reviewedBy = members.find(m => m.user_id === props.driverReportData.reviewed_by);

    const handleCheckboxClick = () => {
        updateDriverReport(props.driverReportData.doc_id || "", {
            review_status: isAlreadyReviewed ? 0 : 1,
            reviewed_at: isAlreadyReviewed ? null : serverTimestamp(),
            reviewed_by: isAlreadyReviewed ? null : (userData?.user?.uid || ""),
        })
            .catch(e => {
                appLogger.error(FILE_NAME, "Error updating review_status" + e);
            });
    };

    return <div className={styles.main}>
        <MobileView>
            <Button
                sx={styles.mobileBackButton}
                variant="text"
                startIcon={<ArrowBackIcon/>}
                onClick={removeSelectedReportId}
            >
                Zurück zur Übersicht
            </Button>
        </MobileView>

        <div>
            <Typography variant="h3">
                Bericht von {driver?.displayName}
            </Typography>
            <Typography fontWeight="bold">
                {formattedReportDate()}
            </Typography>
        </div>

        {props.driverReportData.delivery_notes?.length > 30 &&
            <Alert color="error" icon={false}>
                Es können maximal 30 Fahrten angezeigt werden.
            </Alert>
        }

        <Typography>
            Erledigte Fahrten:
        </Typography>
        <DataGrid
            columns={columns}
            rows={props.deliveryNotes || []}
            hideFooter
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            autoHeight
            density="compact"
        />

        <Typography sx={{ pt: 2 }}>
            Weitere Angaben:
        </Typography>
        <div className="max-w-96">
            <DriverReportForm
                licensePlate={licensePlate}
                setLicensePlate={setLicensePlate}
                licensePlateTrailer={licensePlateTrailer}
                setLicensePlateTrailer={setLicensePlateTrailer}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                breakLength={breakLength}
                setBreakLength={setBreakLength}
            />
        </div>
        {hasAdditionalDataChanged &&
            <div className="flex gap-2">
                <Button size="small" onClick={saveAdditionalData} disabled={isAdditionalDataLoading}>
                    Änderungen speichern
                </Button>
                <Button size="small" color="error" variant="text" onClick={resetAdditionalData}
                        disabled={isAdditionalDataLoading}>
                    Änderungen verwerfen
                </Button>
            </div>
        }

        <FormControlLabel
            control={<Checkbox checked={true} disableFocusRipple disableRipple disableTouchRipple disabled/>}
            label={<Typography variant="body2">Abfahrtskontrolle erledigt</Typography>}
        />

        <Typography sx={{ pt: 2 }}>
            Unterschrift:
        </Typography>

        <div className={styles.signatureWrapper}>
            {props.signatureImageStatus === "loading" ?
                <Skeleton variant="rounded" width={250} height={"90%"}/> :
                <img
                    className={styles.signatureImage}
                    src={props.signatureImageUrl}
                    alt=""
                />
            }
        </div>

        {hasPerm("DRIVER_REPORTS_APPROVE") &&
            <FormControlLabel
                control={<Checkbox
                    size="small"
                    color="secondary"
                    checked={props.driverReportData.review_status === 1}
                    onChange={handleCheckboxClick}
                />}
                label={isAlreadyReviewed ?
                    `Bericht genehmigt von ${reviewedBy?.displayName || ""}` :
                    "Bericht genehmigen"
                }
            />
        }
    </div>;
}
