import { Skeleton, SxProps } from "@mui/material";
import { appStyles } from "@/helper/appStyles";
import { css } from "@emotion/css";


const styles = {
    main: css({
        display: "flex",
        flexDirection: "column",
        gap: 30
    }),
    category: css({
        display: "flex",
        flexDirection: "column",
        gap: 10
    }),
    listItem: {
        borderRadius: appStyles.borderRadius.smaller + "px"
    } as SxProps,
};

export function DriverReportsNavSkeleton() {
    function Category() {
        return <div className={styles.category}>
            <Skeleton width="40%"/>
            <Skeleton variant="rounded" height={50} width="90%" sx={styles.listItem}/>
            <Skeleton variant="rounded" height={50} width="90%" sx={styles.listItem}/>
        </div>;
    }

    return <div className={styles.main}>
        {Category()}
        {Category()}
        {Category()}
    </div>;
}

