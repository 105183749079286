import { appLocalStorageNames } from "@/helper/appLocalStorageNames";
import { twoDigitNumber } from "@/helper/appUtils";

export type EventType = "INFO" | "STATE" | "EVENT" | "ERROR";

export interface Logger {
    logs: LogEntry[];
}

interface LogEntry {
    type: EventType;
    timeStamp: string;
    message: string;
    location: string;
}

function getCurrentLogEntries(): LogEntry[] {
    const getLogs = localStorage.getItem(appLocalStorageNames.logger);
    return JSON.parse(getLogs || "{}").logs || [];
}

/**
 * Custom logger to track events and state changes
 */
export const appLogger = {
    /** Info */
    info: (location: string, message: string) => addLoggerEntry("INFO", location, message),

    /** State changes (zustand, localstorage,...) */
    state: (location: string, message: string) => addLoggerEntry("STATE", location, message),

    /** Events like user interactions (click, page change,...) */
    event: (location: string, message: string) => addLoggerEntry("EVENT", location, message),

    /** Error */
    error: (location: string, message: string) => addLoggerEntry("ERROR", location, message),

    /** Get all logs */
    getLogs: (): Logger => ({ logs: getCurrentLogEntries() })
}

function addLoggerEntry(type: EventType, location: string, message: string) {
    /** Build new log entry */
    const newEntry: LogEntry = {
        type,
        timeStamp: new Date().toISOString(),
        message,
        location
    };

    /** Remove first entry if entry length exeeds a length of x */
    const removeFirstEntryIfTooLong = () => {
        const currentEntries = getCurrentLogEntries();
        if (currentEntries.length >= 50) currentEntries.shift();
        return currentEntries;
    };

    /** Merge new log entry with old log entries */
    const newObject: Logger = {
        logs: [...removeFirstEntryIfTooLong(), newEntry]
    };

    /** Set new log entry */
    localStorage.setItem(
        appLocalStorageNames.logger,
        JSON.stringify(newObject)
    );

    /**
     * Show output in console
     * https://stackoverflow.com/a/13017382/10433038
     * */
    const cssForType = `
        color: #fff;
        font-weight: bold;
        font-family: sans-serif;
        background: ${type === "ERROR" ? "#c00":"#0ac"};
        line-height: 100%;
        padding: 4px 0px 4px 5px;
        border-radius: 4px;
        margin-right: 5px
    `;
    const cssForLocation = `
        color: #fff;
        font-family: sans-serif;
        margin-right: 10px;
        background: #d0c;
        line-height: 100%;
        padding: 4px 5px;
        border-radius: 4px;
    `;
    const cssForMessage = `
        font-weight: bold;
    `;
    console.info(
        `%c${type} %c${location} %c${message}`,
        cssForType, cssForLocation, cssForMessage
    );
}

// /** Force a number to be at least two digits */
// function twoDigitNumber(number: number): string {
//     return number < 10 ? "0" + number : number.toString();
// }

function getCurrentTimeAsString() {
    const d = new Date();
    const year = twoDigitNumber(d.getFullYear());
    const month = twoDigitNumber(d.getMonth() + 1);
    const day = twoDigitNumber(d.getDate());
    const hours = twoDigitNumber(d.getHours());
    const minutes = twoDigitNumber(d.getMinutes());
    const seconds = twoDigitNumber(d.getSeconds());
    const milliseconds = twoDigitNumber(d.getMilliseconds());


    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
}