import { useFirestore, useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import {
    addDays,
    addMonths,
    endOfMonth,
    getDaysInMonth,
    getMonth,
    isSameDay,
    parse,
    startOfDay,
    startOfMonth,
    subDays,
    subMonths
} from "date-fns";
import { replaceTimestamps, tbGetDay } from "@/helper/appUtils";
import { collection, query, where } from "firebase/firestore";
import { TimeBookingDocument } from "tb-utils";
import {
    MonthDataInterface
} from "@/mobile/pages/MobileTimeBookingPage/MobileTimeBookingOverview/M_MonthOverview/index";
import { useQueryParam } from "@/customHooks/useQueryParam";
import format from "date-fns/format";

export function useMonthOverviewData() {
    const dateFormat = "dd.MM.yyyy";

    const { data: userData } = useSigninCheck();
    const { set: setSelectedDateString, value: selectedDateString } = useQueryParam(
        "date",
        format(new Date(), dateFormat)
    );
    const selectedDate = {
        value: parse(selectedDateString || "", dateFormat, new Date()),
        update: (date: Date) => setSelectedDateString(format(date, dateFormat)),
    };
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const monthStart = startOfMonth(selectedDate.value)
    const firstMonthDayNumber = tbGetDay(monthStart);
    const monthEnd = endOfMonth(selectedDate.value);
    const lastMonthDayNumber = tbGetDay(monthEnd);

    const currentMonthName = [
        "Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ][getMonth(selectedDate.value)];

    const monthData: MonthDataInterface[] = [];

    /** Get data from db */
    const timeBookingsRef = collection(useFirestore(), "companies", selectedCompanyId, "time-bookings")
    const timeBookingsQuery = query(timeBookingsRef,
        where("start_time", ">=", monthStart),
        where("start_time", "<=", monthEnd),
        where("user_id", "==", userData?.user?.uid || null)
    );
    const { data, status } = useFirestoreCollectionData(timeBookingsQuery, { idField: "doc_id" });
    const timeBookingData = replaceTimestamps<TimeBookingDocument<Date>[]>(data);

    /** Add current month days */
    const monthLength = getDaysInMonth(selectedDate.value);
    for (let i = 0; i < monthLength; i++) {
        const currentDay = addDays(monthStart, i);
        const dataForCurrentDay = timeBookingData?.filter(tb => {
            return isSameDay(tb.start_time, currentDay);
        });

        monthData.push({
            date: startOfDay(currentDay),
            timeBookings: dataForCurrentDay as TimeBookingDocument<Date>[]
        });
    }

    /** Fill start gap with previous month days */
    for (let i = 0; i < firstMonthDayNumber; i++) {
        monthData.unshift({
            date: subDays(monthStart, i + 1),
            isOtherMonth: true,
            timeBookings: []
        });
    }

    /** Fill end gap with next month days */
    for (let i = 0; i < (6 - lastMonthDayNumber); i++) {
        monthData.push({
            date: addDays(monthEnd, i + 1),
            isOtherMonth: true,
            timeBookings: []
        });
    }

    return {
        currentMonthName,
        monthData,
        monthStart,
        isLoading: status === "loading",
        showNextMonth: () => selectedDate.update(addMonths(selectedDate.value, 1)),
        showPrevMonth: () => selectedDate.update(subMonths(selectedDate.value, 1)),
        showCurrentMonth: () => selectedDate.update(new Date()),
    };
}