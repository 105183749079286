import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { DesktopMainTopNav } from "@/desktop/components/DesktopMainTopNav";
import { DesktopMainLeftNav } from "@/desktop/components/DesktopMainLeftNav";
import { css } from "@emotion/css";
import { appFramerVariants } from "@/helper/appFramerVariants";
import { SearchProps } from "@/desktop/components/DesktopSearch";

export interface DesktopMainFrameProps {
    size?: "default" | "small" | "full";
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    disableBottomPadding?: boolean;

    search?: SearchProps;
}

export function DesktopMainFrame(props: PropsWithChildren<DesktopMainFrameProps>) {
    const styles = {
        main: css({
            // border: "1px solid red",
            height: "100vh",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
        }),
        contentWrapper: css({
            // border: "3px solid blue",
            display: "flex",
            flex: 1,
            overflow: "hidden"
        }),
        contentContainer: css({
            // border: "1px solid red",
            position: "relative",
            overflowY: "auto",
            height: "inherit",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            paddingBottom: props.disableBottomPadding ? 0 : 100
        }),
        content: css({
            // border: "1px solid blue",
            // overflowY: "auto",
            // height: "inherit",
            boxSizing: "border-box",
            flex: 1,
            width: props.size === "small" ? "min(1100px, 100%)" : "100%",
            padding: props.size === "full" ? 0 : 30,
            paddingTop: props.paddingTop + "px" || "",
            paddingRight: props.paddingRight + "px" || "",
            paddingBottom: props.paddingBottom + "px" || "",
            paddingLeft: props.paddingLeft + "px" || "",
        })
    };

    return <div className={styles.main}>
        <DesktopMainTopNav search={props.search}/>

        <div className={styles.contentWrapper}>
            <DesktopMainLeftNav/>

            <div className={styles.contentContainer}>
                <motion.div
                    className={styles.content}
                    variants={appFramerVariants.framerDefaultTransition}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    {props.children}
                </motion.div>
            </div>
        </div>
    </div>;
}
