import { Button, Skeleton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { css } from "@emotion/css";
import { C_CompanySettingsFrame } from "@/common/companySettings/C_CompanySettingsFrame";
import { C_SettingsSection } from "@/common/companySettings/C_SettingsSection";
import { useDialog } from "@/customHooks/useDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import { C_InviteNewEmployeeDialog } from "./C_InviteNewEmployeeDialog";
import { C_ManageEmployeePermissionsDialog } from "./C_ManageEmployeePermissionsDialog";
import { usePendingInvitations } from "@/common/companySettings/pages/C_ManageEmployeesPage/usePendingInvitations";
import { useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { appStyles } from "@/helper/appStyles";
import { useQueryParam } from "@/customHooks/useQueryParam";
import {
    C_ManagePendingInvitationsDialog
} from "@/common/companySettings/pages/C_ManageEmployeesPage/C_ManagePendingInvitationsDialog";

const styles = {
    list: css({
        display: "flex",
        flexDirection: "column",
    }),
    listElement: css({
        cursor: "pointer",
        padding: 10,
        borderRadius: appStyles.borderRadius.smaller,
        ":hover": {
            background: "#0001"
        },
    })
};

export function C_ManageEmployeesPage() {
    const { setOpenDialog } = useDialog();
    const { data: userData } = useSigninCheck();
    const { pendingInvitationData, isPendingInvitationLoading } = usePendingInvitations();
    const { set: setSelectedUserId } = useQueryParam("edit-user");
    const { set: setSelectedPendingUserId } = useQueryParam("pending-user");

    const members = useCompanyPersistStore(state => state.members);

    return <C_CompanySettingsFrame title="Mitarbeiter">
        <C_SettingsSection>
            <Button
                onClick={() => setOpenDialog(appDialogNames.compSettingsPage.inviteNewEmployee)}
                startIcon={<AddIcon/>}
            >
                Mitarbeiter einladen
            </Button>
        </C_SettingsSection>

        {/** Skeleton loading animation */}
        {isPendingInvitationLoading &&
            <C_SettingsSection className={styles.list}>
                {[...Array(4)].map((v, i) => (
                    <div key={i}>
                        <Skeleton variant="text" width={200} height={26}/>
                        <Skeleton variant="text" width={160} height={15}/>
                    </div>
                ))}
            </C_SettingsSection>
        }

        {!isPendingInvitationLoading &&
            <C_SettingsSection className={styles.list}>
                {/** List all pending invitations */}
                {pendingInvitationData?.map((pendingMember, index) => (
                    <div
                        key={index}
                        className={styles.listElement}
                        onClick={() => setSelectedPendingUserId(pendingMember?.doc_id || "")}
                    >
                        <Typography>{pendingMember.email}</Typography>
                        <Typography variant="body2" color="orange">
                            Einladung ausstehend
                        </Typography>
                    </div>
                ))}

                {/** List all employees */}
                {members?.map((member, index) => (
                    <div
                        key={index}
                        className={styles.listElement}
                        onClick={() => setSelectedUserId(member.user_id)}
                    >
                        <Typography noWrap>
                            {member.displayName}
                            {userData?.user?.uid === member.user_id &&
                                <span style={{ color: "#666", userSelect: "none" }}>
                                    &nbsp;(Du)
                                </span>
                            }
                        </Typography>
                        <Typography variant="body2" color="gray">
                            {member.email}
                        </Typography>
                    </div>
                ))}
            </C_SettingsSection>
        }

        <C_InviteNewEmployeeDialog/>
        <C_ManagePendingInvitationsDialog/>
        <C_ManageEmployeePermissionsDialog/>
    </C_CompanySettingsFrame>;
}