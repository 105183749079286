import { css } from "@/helper/css";
import { CircularProgress, Typography } from "@mui/material";

const styles = {
    listItem: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 12,
        userSelect: "text"
    }),
    icon: css({
        color: "#444"
    })
};

interface ListItemProps {
    icon: JSX.Element;
    text: string;
    fontWeight?: string;
    loading?: boolean;
}

export function UserSettingsDialogListItem(props: ListItemProps) {
    return <div style={styles.listItem}>
        <div style={styles.icon}>
            {props.icon}
        </div>

        {props.loading ?
            <CircularProgress size={17} thickness={6}/>
            :
            <Typography fontWeight={props.fontWeight}>
                {props.text}
            </Typography>
        }
    </div>;
}