import { useEffect } from "react";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "GlobalUrlChangeLogger.tsx";

/**
 * Log on url change
 */
export function GlobalUrlChangeLogger() {
    useEffect(() => {
        appLogger.info(FILE_NAME, "URL change: " + location.pathname + location.search);
    }, [location]);

    return null;
}