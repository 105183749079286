import { useFirestore, useFirestoreCollectionData, useSigninCheck } from "reactfire";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { collection, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { replaceTimestamps } from "@/helper/appUtils";
import { TimeBookingDocument } from "tb-utils";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";

/** The work element that is currently active (where end_time is null) */
export function FirestoreGetActiveTimeBookingData() {
    const { data: userData } = useSigninCheck();

    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);
    const setActiveTimeBooking = useTimeBookingStore(state => state.setActiveTimeBooking);

    /** Get data from db */
    const timeBookingsRef = collection(useFirestore(), "companies", selectedCompanyId, "time-bookings")
    const timeBookingsQuery = query(timeBookingsRef,
        where("end_time", "==", null),
        where("user_id", "==", userData?.user?.uid)
    );
    const { data, status } = useFirestoreCollectionData(timeBookingsQuery, { idField: "doc_id" });
    const activeTimeBookingData = replaceTimestamps<TimeBookingDocument<Date>[]>(data);

    useEffect(() => {
        setActiveTimeBooking({
            data: activeTimeBookingData?.[0] || null,
            status: "success",
        })
    }, [data, status]);

    return null;
}