import { Button, Typography } from "@mui/material";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { appStyles } from "@/helper/appStyles";
import { useTimeBookingStore } from "@/stores/useTimeBookingStore";
import { css, cx } from "@emotion/css";
import { useEffect, useState } from "react";
import { useInterval } from "react-use";
import { Duration, intervalToDuration } from "date-fns";
import { twoDigitNumber } from "@/helper/appUtils";
import { motion } from "framer-motion";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { appLogger } from "@/helper/appLogger";

const FILE_NAME = "MobileLiveCounter.tsx";

export function MobileLiveCounter() {
    const activeTimeBooking = useTimeBookingStore(state => state.activeTimeBooking);
    const workTypes = useTimeBookingStore(state => state.workTypes);
    const { updateTimeBooking } = useFirestoreWrite();

    const currentWorkType = workTypes.data.find(tw => tw.doc_id === activeTimeBooking.data?.work_type);

    const [showOptions, setShowOptions] = useState(false);
    const [isStoppingLoading, setIsStoppingLoading] = useState(false);
    const optionsHeight = 45;

    const styles = {
        timeBox: css({
            // background: currentWorkType?.color || appColors.blue.main,
            background: currentWorkType?.color || "#aaa",
            height: 180,
            borderRadius: appStyles.borderRadius.normal,
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            color: "#fff"
        }),
        circle: css({
            position: "absolute",
            transform: "translateY(-50%)",
            background: appStyles.backgroundCircleColor.normal,
            borderRadius: "50%",
            left: 10,
            top: -60,
        }),
        firstCircle: css({
            height: 190,
            width: 500,
        }),
        secondCircle: css({
            height: 320,
            width: 600,
        }),
        thirdCircle: css({
            height: 500,
            width: 750,
        }),
        forthCircle: css({
            height: 900,
            width: 900,
        }),
        topSection: css({
            // border: "1px solid red",
            flex: 1
        }),
        content: css({
            padding: "0px 20px 20px",
            zIndex: 1
        }),
        optionsWrapper: css({
            height: optionsHeight,
            display: "flex",
            alignItems: "flex-end",
        })
    };

    const handleBoxClick = () => {
        if (isStoppingLoading || !activeTimeBooking.data?.doc_id) {
            setShowOptions(false);
        } else {
            setShowOptions(v => !v);
        }
    };

    const handleStopClick = () => {
        updateTimeBooking(activeTimeBooking.data?.doc_id || "", {
            end_time: new Date()
        })
            .catch(() => {
                appLogger.error(FILE_NAME, "Error updating time-booking");
            });
    };

    return <M_ContentSection
        title="Arbeitszeiten"
        isPageTitle
        disableBottomPadding
    >
        <motion.div
            className={styles.timeBox}
            onClick={handleBoxClick}
            animate={{
                scale: isStoppingLoading ? .95 : 1,
                opacity: isStoppingLoading ? .5 : 1
            }}
        >
            {!!activeTimeBooking.data?.doc_id && <>
                <div className={cx(styles.circle, styles.firstCircle)}/>
                <div className={cx(styles.circle, styles.secondCircle)}/>
                <div className={cx(styles.circle, styles.thirdCircle)}/>
                <div className={cx(styles.circle, styles.forthCircle)}/>
            </>}
            <div className={styles.topSection}/>

            <motion.div
                initial={{ translateY: showOptions ? 0 : optionsHeight }}
                animate={{ translateY: showOptions ? 0 : optionsHeight }}
                className={styles.content}>
                <Typography>{currentWorkType?.name}</Typography>

                {!!activeTimeBooking.data?.doc_id ?
                    <Typography variant="h1" color="#fff">
                        <Counter start_date={activeTimeBooking.data?.start_time}/>
                    </Typography>
                    :
                    <Typography color="#fff" fontStyle="italic">
                        Keine Arbeit ausgewählt
                    </Typography>
                }

                <motion.div
                    initial={{ opacity: showOptions ? 1 : 0 }}
                    animate={{ opacity: showOptions ? 1 : 0 }}
                    className={styles.optionsWrapper}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        color="inherit"
                        onClick={handleStopClick}
                    >
                        Beenden
                    </Button>
                </motion.div>
            </motion.div>

            {/*{isStoppingLoading &&*/}
            {/*    <LoadingSpinnerWithBackdrop background="#0005"/>*/}
            {/*}*/}

        </motion.div>
    </M_ContentSection>;
}

interface CounterProps {
    start_date?: Date;
}

function Counter(props: CounterProps) {
    if (!props.start_date) return <>00:00:00</>;

    const [duration, setDuration] = useState<Duration>({});

    const handleDurationUpdate = () => {
        setDuration(
            intervalToDuration({
                start: props.start_date || 0,
                end: new Date()
            })
        );
    };

    /** Used for the first second (where setDuration has not executed yet) */
    useEffect(() => {
        handleDurationUpdate();
    }, []);

    useInterval(() => {
        handleDurationUpdate();
    }, 1000)

    return <div>
        {twoDigitNumber(((duration.days || 0) / 24) + (duration.hours || 0))}:
        {twoDigitNumber(duration.minutes || 0)}:
        {twoDigitNumber(duration.seconds || 0)}
    </div>;
}