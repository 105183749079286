import { appLogger } from "@/helper/appLogger";
import { startOfDay } from "date-fns";
import { serverTimestamp } from "firebase/firestore";
import { ContainerContractDocument, DrivingJobsSortOrderDocument } from "tb-utils";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { useSigninCheck } from "reactfire";

const FILE_NAME = "useHandleMoveElementClick.ts";

export function useHandleMoveElementClick() {
    const { createDrivingJobsSortOrder, updateDrivingJobsSortOrder } = useFirestoreWrite();
    const { data: userData } = useSigninCheck();

    async function handleMoveElementClick(
        dir: "up" | "down",
        contracts: ContainerContractDocument<Date>[],
        clickedElementId: string,
        sortOrderData: DrivingJobsSortOrderDocument<Date>[],
        selectedDay: Date
    ) {
        const sortOrder = contracts.map(el => (el.id as string));

        /** Update order */
            // const clickedElementId = params.id as string;
        const clickedElementIndex = sortOrder.indexOf(clickedElementId);

        /** Get element to swap with */
        const elementToSwapIndex = clickedElementIndex + (dir === "up" ? -1 : +1);
        const tempNeighborValue = sortOrder[elementToSwapIndex];

        /** Swap elements */
        sortOrder[elementToSwapIndex] = clickedElementId;
        sortOrder[clickedElementIndex] = tempNeighborValue;

        /** Write changes to db */
        if (sortOrderData.length) {
            updateDrivingJobsSortOrder(sortOrderData?.[0].doc_id || "", {
                delivery_notes_order: sortOrder
            })
                .then(() => {
                    appLogger.event(FILE_NAME, "Successfully updated driving-jobs-sort-order");
                })
                .catch(err => {
                    appLogger.error(FILE_NAME, "Failed to update driving-jobs-sort-order");
                });
        } else {
            createDrivingJobsSortOrder({
                day: startOfDay(selectedDay),
                created_at: serverTimestamp(),
                driver: userData?.user?.uid,
                delivery_notes_order: sortOrder
            })
                .then(() => {
                    appLogger.event(FILE_NAME, "Successfully created driving-jobs-sort-order");
                })
                .catch(err => {
                    appLogger.error(FILE_NAME, "Failed to create driving-jobs-sort-order");
                });
        }
    }


    return {
        handleMoveElementClick
    };
}

