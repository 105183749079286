import { css } from "@emotion/css";
import { DriverReportsNav } from "@/common/DriverReportsPage/DriverReportsNav";
import { DriverReport } from "@/common/DriverReportsPage/DriverReport";
import { isMobile } from "react-device-detect";
import { DesktopMainFrame } from "@/desktop/components/DesktopMainFrame";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { PdfDriverReportDocument } from "@/common/DriverReportsPage/PdfDriverReportDocument";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import format from "date-fns/format";

const styles = {
    desktopWrapper: css({
        // border: "1px solid red",
        display: "flex",
        gap: 50,
        [`@media only screen and (max-width: 1500px)`]: {
            gap: 20
        }
    }),
};

export function DriverReportsPage() {
    const { value: selectedReportId } = useQueryParam("report");
    // const [instance, updateInstance] = usePDF({document:});

    return isMobile ?
        <M_MainFrame>
            <M_ContentSection>
                {selectedReportId ?
                    <DriverReport/> :
                    <DriverReportsNav/>
                }
            </M_ContentSection>
        </M_MainFrame>
        :
        <DesktopMainFrame>
            <div className={styles.desktopWrapper}>
                <DriverReportsNav/>
                <DriverReport/>
            </div>
        </DesktopMainFrame>;
}
