import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { appLocalStorageNames } from "@/helper/appLocalStorageNames";

export const devHttpRequests = ["GET", "POST"] as const;
export type DevHttpRequestTypes = typeof devHttpRequests[number];

interface TabInterface {
    urlPath: string;
    reqType: DevHttpRequestTypes;
    body: string;
}

interface DevRequestDataInterface {
    baseUrl: string;
    tabs: TabInterface[];
}


interface DevStore {
    devRequestData: DevRequestDataInterface;
    setDevRequestData: (newValue: DevRequestDataInterface) => void;
}

export const useDevStore = create<DevStore>()(devtools(persist(
    set => ({

        /** devRequestData */
        devRequestData: {
            baseUrl: "",
            tabs: []
        },
        setDevRequestData: newValue => set({
            devRequestData: newValue
        }),


    }),
    { name: appLocalStorageNames.devStore }
)));