import { PropsWithChildren } from "react";
import { css } from "@emotion/css";
import { isMobile } from "react-device-detect";
import { DesktopMainFrame } from "@/desktop/components/DesktopMainFrame";
import { SxProps, Typography } from "@mui/material";
import { appPaths } from "@/helper/appPaths";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";
import { useCompanySettings } from "@/common/companySettings/useCompanySettings";
import { SimpleList } from "@/common/components/SimpleList";

const styles = {
    main: css({
        border: "1px solid red"
    }),
    desktopWrapper: css({
        display: "flex",
        flexDirection: "row",
        gap: 40
    }),
    desktopContent: css({
        flex: 1
    }),
    desktopTitle: {
        paddingBottom: "20px"
    } as SxProps,
};

interface Props {
    title: string;
}

export function C_CompanySettingsFrame(props: PropsWithChildren<Props>) {
    return isMobile ?
        <MobileSettings {...props}/> :
        <DesktopSettings {...props}/>;
}

function MobileSettings(props: PropsWithChildren<Props>) {
    return <M_MainFrame
        navWithBackButton={{
            title: props.title,
            backButtonUrl: appPaths.companySettings
        }}
    >
        {props.children}
    </M_MainFrame>;
}

function DesktopSettings(props: PropsWithChildren<Props>) {
    const { navData } = useCompanySettings();

    return <DesktopMainFrame size="small" paddingTop={50}>
        <div className={styles.desktopWrapper}>
            <SimpleList navData={navData} width={250}/>

            <div className={styles.desktopContent}>
                <Typography variant="h1" sx={styles.desktopTitle}>
                    {props.title}
                </Typography>

                {props.children}
            </div>
        </div>
    </DesktopMainFrame>;
}