import { PropsWithChildren, ReactElement } from "react";
import { ButtonBase, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { appColors } from "@/helper/appColors";
import { css } from "@emotion/css";

interface Props {
    text: string;
    icon: ReactElement;
    activeIcon: ReactElement;
    linkTo: string;
    active: boolean;
}

export function M_BottomNavElement(props: PropsWithChildren<Props>) {
    const styles = {
        link: css({
            textDecoration: "inherit",
            flex: 1,
            height: "100%"
        }),
        buttonBase: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            fontWeight: 500,
            color: "#444",
            width: "100%",
            height: "100%",
        } as SxProps,
        iconLabel: css({
            width: 60,
            height: 32,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            background: props.active ? appColors.blue.veryLight : "transparent",
            borderRadius: 15
        })
    };

    return <Link to={props.linkTo} className={styles.link}>
        <ButtonBase sx={styles.buttonBase}>

            <div className={styles.iconLabel}>
                {props.active ?
                    props.activeIcon :
                    props.icon
                }
            </div>

            <Typography variant="body2" fontWeight="inherit">
                {props.text}
            </Typography>

        </ButtonBase>
    </Link>;
}
