import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Link } from "react-router-dom";
import { App } from "@/App";
import * as Sentry from "@sentry/react";

/** https://mui.com/material-ui/react-typography/#install-with-npm */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Button } from "@mui/material";
import { appPaths } from "@/helper/appPaths";

Sentry.init({

    dsn: "https://5a4f81227b6dfce95cbea7b1aca6c162@o4507893299412992.ingest.de.sentry.io/4507893432516688",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/dev.time-booking\.app/, /^https:\/\/time-booking\.app/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function FallbackComponent() {
    return <div className="h-[100vh] w-full flex items-center justify-center flex-row gap-5">
        Etwas ist schief gelaufen. Bitte App neustarten.

        <Link to={appPaths.logout}>
            <Button>Abmelden</Button>
        </Link>
    </div>;
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <React.StrictMode>
            <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Sentry.ErrorBoundary>
        </React.StrictMode>,
    );
