import { css } from "@/helper/css";
import {
    mobileVacationBookingHelper
} from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/mobileVacationBookingHelper";
import { appStyles } from "@/helper/appStyles";
import { appColors } from "@/helper/appColors";
import { VacationDays } from "@/mobile/pages/MobileVacationPage/MobileVacationBooking/index";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";

interface Props {
    dayIndex: number;
    isWeekend?: boolean;
    vacationData?: VacationDays;
    showAddIcon?: boolean;
}

export function MobileVacationDayElement(props: Props) {
    const styles = {
        dayElement: css({
            width: mobileVacationBookingHelper.dayWidth,
            height: 75,
            paddingTop: 35,
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            borderTop: `1px solid ${appStyles.backgroundCircleColor.normal}`,
            boxSizing: "border-box",
            ...mobileVacationBookingHelper.dayElementStyles(props.dayIndex, "none")
        }),
        vacationElement: css({
            height: 32,
            marginLeft: 2,
            borderRadius: 10,
            zIndex: 1,
            boxSizing: "border-box",
            width: (mobileVacationBookingHelper.dayWidth * (props?.vacationData?.length || 1)) - 6,
            background: appColors.green.main
        }),
        addIconWrapper: css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80%",
            width: "100%",
        }),
        addIcon: css({
            height: 18,
            width: "auto",
            color: appColors.green.main
        })
    };

    return <div>
        <div style={styles.dayElement}>
            {!props.vacationData && props.showAddIcon &&
                <div style={styles.addIconWrapper}>
                    <IconButton size="medium">
                        <AddIcon sx={styles.addIcon}/>
                    </IconButton>
                </div>
            }
            {props.vacationData && <div style={styles.vacationElement}/>}
        </div>
    </div>;
}