import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { getAuth } from "firebase/auth";
import { appLogger } from "@/helper/appLogger";
import { CompanyPermissions } from "tb-utils";

const FILE_NAME = "appRequest.ts";

/**
 * ---
 * acceptInvitation
 */
export interface AcceptInvitationData {
    pending_invitation_id: string;
    company_id: string;
}

interface AcceptInvitationResponse {
    joined_company_id: string;
}

/**
 * ---
 * createInvitation
 */
interface CreateInvitationData {
    email: string;
}

interface CreateInvitationResponse {
    invitation_id: string;
}

/**
 * ---
 * validateCompanyCreationToken
 */
interface ValidateCompanyCreationTokenData {
    creation_token: string;
}

interface ValidateCompanyCreationTokenResponse {
    is_valid: boolean;
}

/**
 * ---
 * createCompany
 */
interface CreateCompanyData {
    name: string;
    creation_token: string;
}

interface CreateCompanyResponse {
    company_id: string;
}

/**
 * ---
 * getMembers
 */
export interface MemberInterface {
    /** Data from firestore */
    joined_at: Date;
    user_id: string;
    permissions: CompanyPermissions[];

    /** Data from firebase auth */
    email: string;
    displayName: string;
    photoURL?: string;
    phoneNumber?: string;
}

interface GetMembersResponse {
    members: MemberInterface[];
}


/**
 * Exposed endpoints
 */
export const appRequest = {
    /** Company */
    acceptInvitation: async (data: AcceptInvitationData): Promise<AcceptInvitationResponse> =>
        await apiRequest("/company/accept-invitation", "POST", data),
    createInvitation: async (data: CreateInvitationData): Promise<CreateInvitationResponse> =>
        await apiRequest("/company/create-invitation", "POST", data),
    createCompany: async (data: CreateCompanyData): Promise<CreateCompanyResponse> =>
        await apiRequest("/company/create", "POST", data),
    getMembers: async (): Promise<GetMembersResponse> =>
        await apiRequest("/company/members", "POST", {}),
    validateCompanyCreationToken: async (data: ValidateCompanyCreationTokenData): Promise<ValidateCompanyCreationTokenResponse> =>
        await apiRequest("/company/validate-creation-token", "POST", data),
};

/**
 * Custom error Response
 * https://stackoverflow.com/a/70401853/10433038
 */
interface HttpErrorBody {
    status: number;
    data: any;
}

class CustomHttpError extends Error {
    response: HttpErrorBody;

    constructor(message: any, response: HttpErrorBody) {
        super(message);

        this.response = response;
    }
}

async function apiRequest(path: string, method: "POST" | "PATCH", body: any) {
    const companyId = useCompanyPersistStore.getState().selectedCompanyId;
    const auth = getAuth();

    /**
     * Retrieve ID token
     * https://firebase.google.com/docs/auth/admin/verify-id-tokens#retrieve_id_tokens_on_clients
     */
    const idToken = await auth.currentUser?.getIdToken(true)
        .catch(error => {
            appLogger.error(FILE_NAME, "Error retrieving id token: " + JSON.stringify(error));
        });

    const url = import.meta.env.VITE_BASE_API_URL + path;
    const response = await fetch(url, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "tb-firebase-id-token": idToken || ""
        },
        body: JSON.stringify({
            ...body,
            selected_company_id: companyId // Always append selected_company_id to body
        })
    });

    appLogger.event(FILE_NAME, `Request sent: ${response.status} ${response.url}`);

    if (response.status < 200 || response.status > 299) {
        appLogger.error(FILE_NAME, `${response.status} ${response.url} | ${JSON.stringify(await response.clone().json())}`);
        throw new CustomHttpError(
            "HTTP error. See \"error.response\" for more info.",
            {
                status: response.status,
                data: await response.clone().json()
            }
        );
    }

    return response.clone().json();
}