import { DesktopMainFrame } from "@/desktop/components/DesktopMainFrame";
import React, { PropsWithChildren, ReactElement } from "react";
import { css, cx } from "@emotion/css";
import { DataGrid, GridColumns, GridDensity, GridRowClassNameParams } from "@mui/x-data-grid";
import { dataGridDefaultLocales } from "@/helper/appStatic";
import { SxProps, Typography } from "@mui/material";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { isMobile } from "react-device-detect";
import { M_MainFrame } from "@/mobile/components/M_MainFrame";
import { M_ContentSection } from "@/mobile/components/M_ContentSection";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { GridEventListener } from "@mui/x-data-grid/models/events";
import { SearchProps } from "@/desktop/components/DesktopSearch";

const styles = {
    mobileContentSection: css({
        /**
         * -70px: Bottom nav
         * -60px: Top nav and some spacing
         */
        height: "calc(100vh - 70px - 60px)"
    }),
    main: css({
        // border: "1px solid red",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
    }),
    topButtonSection: css({
        // border: "1px solid red",
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 16,
        flexWrap: "wrap",

    }),
    customEmptyElement: css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
        color: "#888"
    }),
    customEmptyIconWrapper: css({
        "& svg": {
            fontSize: 80,
        }
    }),
    topIcons: css({
        height: 10,
    }),
    dataGridWrapper: css({
        // border: "1px solid blue",
        position: "relative",
        flex: 1,
        display: "flex",
    })
};

interface Props<RowType> {
    columns: GridColumns;
    rows: RowType[];
    gridDensity?: GridDensity;
    customEmptyText?: ReactElement | string;
    customEmptyIcon?: ReactElement;
    isLoading?: boolean;
    getRowClassName?: (params: GridRowClassNameParams) => string;
    sx?: SxProps;
    initialState?: GridInitialStateCommunity;
    onRowClick?: GridEventListener<'rowClick'>;
    className?: string;
    /**
     * By default, only the table is scrollable.
     * Set this to true to disable table scroll/overflow and enable full-page/body scroll
     */
    enableBodyScroll?: boolean;
    search?: SearchProps;
    noFrame?: boolean;
}

export function FullPageDataGrid<RowType>(props: PropsWithChildren<Props<RowType>>) {
    if (props.noFrame) return <Content {...props}/>;

    return isMobile ?
        <M_MainFrame size="full">
            <M_ContentSection
                className={styles.mobileContentSection}
                disableBottomPadding
            >
                <Content {...props}/>
            </M_ContentSection>
        </M_MainFrame>
        :
        <DesktopMainFrame
            paddingRight={0}
            paddingBottom={0}
            disableBottomPadding
            search={props.search}
        >
            <Content {...props}/>
        </DesktopMainFrame>
}

function Content<RowType>(props: PropsWithChildren<Props<RowType>>) {
    return <div className={styles.main}>

        <div className={cx(styles.topButtonSection, props.className)}>
            {props.children}
        </div>


        <div className={styles.dataGridWrapper}>
            {props.customEmptyText && !props.rows.length ?
                <div className={styles.customEmptyElement}>
                    <div className={styles.customEmptyIconWrapper}>{props.customEmptyIcon}</div>
                    <Typography>{props.customEmptyText}</Typography>
                </div>
                :
                <DataGrid
                    autoHeight={props.enableBodyScroll}
                    density={props.gridDensity || "standard"}
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    // autoHeight={true}
                    columns={props.columns}
                    rows={props.rows}
                    getRowClassName={props.getRowClassName}
                    disableColumnFilter
                    disableColumnSelector
                    localeText={dataGridDefaultLocales}
                    initialState={props.initialState}
                    onRowClick={props.onRowClick}
                    sx={{
                        border: 0,
                        // Add extra padding because the bottom-padding from <M_MainFrame/> is ignored.
                        paddingBottom: props.enableBodyScroll ? "100px" : "",
                        ...props.sx,
                        "& .MuiDataGrid-row": {
                            cursor: props.onRowClick ? "pointer" : "inherit",
                        }
                    }}
                />
            }

            {props.isLoading &&
                <LoadingSpinnerWithBackdrop disableTransparentBackground/>
            }
        </div>
    </div>;
}
