import { Chip } from "@mui/material";
import { css } from "@/helper/css";
import { useQueryParam } from "@/customHooks/useQueryParam";

const styles = {
    main: css({
        display: "flex",
        flexDirection: "row",
        gap: 6,
        paddingBottom: 20
    })
};

const FILE_NAME = "M_SwitchOverviewView.tsx";

export function M_SwitchOverviewView() {
    const { set: setActiveView, value: activeView } = useQueryParam("overview", "month");

    return <div style={styles.main}>
        <Chip
            label="Monatsansicht"
            variant="outlined"
            color={activeView === "month" ? "primary" : "default"}
            onClick={() => setActiveView("month")}
        />
        <Chip
            label="Tagesansicht"
            variant="outlined"
            color={activeView === "day" ? "primary" : "default"}
            onClick={() => setActiveView("day")}
        />
    </div>;
}