import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { appLogger } from "@/helper/appLogger";
import { ContainerContractDocument } from "tb-utils";
import { ContainerContractFormular } from "@/common/ContainerContractPage/ContainerContractFormular";
import { MainFrame } from "@/common/MainFrame";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { serverTimestamp } from "firebase/firestore";
import { useSigninCheck } from "reactfire";

const FILE_NAME = "CreateContainerContractPage.tsx";

export function CreateContainerContractPage() {
    const navigate = useNavigate();
    const { data: userData } = useSigninCheck();
    const { createContainerContract } = useFirestoreWrite();

    const [contractData, setContractData] = useState<Partial<ContainerContractDocument<Date>>>({});
    const [billingAddressIsEqual, setBillingAddressIsEqual] = useState(true);
    const [c1Checked, setC1Checked] = useState(false);
    const [c2Checked, setC2Checked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");

    const handleCreateContractClick = () => {
        setIsLoading(true);
        setErrorText("");

        createContainerContract({
            c1_da_recipient: contractData.c1_da_recipient || "",
            c1_da_street: contractData.c1_da_street || "",
            c1_da_house_number: contractData.c1_da_house_number || "",
            c1_da_zip_code: contractData.c1_da_zip_code || "",
            c1_da_city: contractData.c1_da_city || "",

            c1_ba_recipient: contractData.c1_ba_recipient || null,
            c1_ba_street: contractData.c1_ba_street || null,
            c1_ba_house_number: contractData.c1_ba_house_number || null,
            c1_ba_zip_code: contractData.c1_ba_zip_code || null,
            c1_ba_city: contractData.c1_ba_city || null,

            ...c1Checked ?
                {
                    c1_created_at: serverTimestamp(),
                    c1_created_by: userData?.user?.uid || "",
                    c1_driver: null,
                    c1_number: contractData.c1_number || "",
                    c1_date: contractData.c1_date || null,
                    c1_time_of_day: contractData.c1_time_of_day || "",
                    c1_cargo_info: contractData.c1_cargo_info || "",
                    c1_recycler_and_others_text: contractData.c1_recycler_and_others_text || "",
                    c1_info_text: contractData.c1_info_text || "",
                    c1_waste_key: contractData.c1_waste_key || "",
                    c1_status: 0,
                    c1_status_changed_by: null
                }
                :
                {
                    c1_created_at: null,
                    c1_created_by: null,
                    c1_driver: null,
                    c1_number: null,
                    c1_date: null,
                    c1_time_of_day: null,
                    c1_cargo_info: null,
                    c1_recycler_and_others_text: null,
                    c1_info_text: null,
                    c1_waste_key: null,
                    c1_status: null,
                    c1_status_changed_by: null
                },

            ...c2Checked ?
                {
                    c2_created_at: serverTimestamp(),
                    c2_created_by: userData?.user?.uid || "",
                    c2_driver: null,
                    c2_number: contractData.c2_number || "",
                    c2_date: contractData.c2_date || null,
                    c2_time_of_day: contractData.c2_time_of_day || "",
                    c2_cargo_info: contractData.c2_cargo_info || "",
                    c2_recycler_and_others_text: contractData.c2_recycler_and_others_text || "",
                    c2_info_text: contractData.c2_info_text || "",
                    c2_waste_key: contractData.c2_waste_key || "",
                    c2_status: 0,
                    c2_status_changed_by: null
                }
                :
                {
                    c2_created_at: null,
                    c2_created_by: null,
                    c2_driver: null,
                    c2_number: null,
                    c2_date: null,
                    c2_time_of_day: null,
                    c2_cargo_info: null,
                    c2_recycler_and_others_text: null,
                    c2_info_text: null,
                    c2_waste_key: null,
                    c2_status: null,
                    c2_status_changed_by: null
                },

            type: contractData.type || 0,
            container_variant: contractData.container_variant || 0,
            container_size: contractData.container_size || 0,
            contract_created_at: serverTimestamp(),

            finalize_data_changed_by: null,
            finalize_time_spend: null,
            finalize_weight: null,
            finalize_weighbridge_number: null
        })
            .then(() => {
                appLogger.event(FILE_NAME, "Successfully created new container contract");
                navigate(-1);
            })
            .catch(err => {
                setErrorText("Ein Fehler ist aufgetreten");
                appLogger.error(FILE_NAME, "Error while creating container contract:" + err.code + " " + err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return <MainFrame>
        <ContainerContractFormular
            formTitle="Auftrag erstellen"

            contractData={contractData}
            setContractData={setContractData}

            billingAddressIsEqual={billingAddressIsEqual}
            setBillingAddressIsEqual={setBillingAddressIsEqual}

            c1Checked={c1Checked}
            setC1Checked={setC1Checked}

            c2Checked={c2Checked}
            setC2Checked={setC2Checked}

            isLoading={isLoading}

            submitButtonText="Auftrag erstellen"
            handleSubmitClick={handleCreateContractClick}

            errorText={errorText}
            setErrorText={setErrorText}

            hideDataLossWarnings
        />
    </MainFrame>;
}