import { Link } from "react-router-dom";
import { basicLinkClass } from "@/helper/appStatic";
import format from "date-fns/format";
import { endOfDay, formatDistance, isPast, isThisYear, isToday, isTomorrow, isYesterday, startOfDay } from "date-fns";
import deLocale from "date-fns/locale/de";
import React from "react";
import { css, cx } from "@emotion/css";
import { Tooltip } from "@mui/material";
import { appColors } from "@/helper/appColors";
import { ContainerContractDocument } from "tb-utils";
import { appPaths } from "@/helper/appPaths";
import { contractType } from "@/helper/appUtils";

interface Props {
    isC1: boolean;
    contractData: ContainerContractDocument<Date>;
    hideDate?: boolean;
    highlightDeadlineExceeded?: boolean;
}

export function DeliveryNoteCell(props: Props) {
    /**
     * Try to get `doc_id`, if it is undefined use `id`
     * The DrivingJobOverviewPage prefixes `id` with `/1` or `/2` to identify c1 and c2 driving jobs
     */
    const doc_id = props.contractData.doc_id || props.contractData.id;

    const deliveryNoteLink = `${appPaths.deliveryNote}/${doc_id || ""}/${props.isC1 ? "1" : "2"}`;
    const deliveryNoteNumber = props.contractData[props.isC1 ? "c1_number" : "c2_number"];
    const deliveryType = props.isC1 ? (contractType.c1Name(props.contractData.type)) : "holen";
    const DeliveryIcon = props.isC1 ? contractType.c1Icon(props.contractData.type) : contractType.c2Icon();
    const deliveryDate = props.contractData[props.isC1 ? "c1_date" : "c2_date"];
    const status = props.isC1 ? (props.contractData.c1_status || 0) : (props.contractData.c2_status || 0);

    /**
     * Green if status is done
     * Red if status is not-done and deliveryDate is in the past
     * Default color if status is not-done
     */
    const deliveryNoteColor = () => {
        if (status === 10) return appColors.green.main;
        if (deliveryDate && isPast(endOfDay(deliveryDate))) return "red";
        return "";
    };

    const styles = {
        topContent: css({
            display: "flex",
            flexDirection: "row"
        }),
        deliveryNoteLink: css({
            color: deliveryNoteColor()
            // color: status === 10 ? appColors.green.main : ""
        }),
        cellContent: css({
            display: "flex",
            alignItems: "center",
            paddingLeft: 6,
            ".MuiSvgIcon-root": {
                fontSize: 17,
                color: "#0009"
            }
        }),
    };

    return <div>
        <div className={styles.topContent}>
            <Link to={deliveryNoteLink} className={cx(basicLinkClass, styles.deliveryNoteLink)}>
                LS {deliveryNoteNumber}
            </Link>

            <div className={styles.cellContent}>
                <Tooltip title={deliveryType} placement="top">
                    <div style={{ display: "flex" }}>
                        <DeliveryIcon/>
                    </div>
                </Tooltip>
            </div>
        </div>

        {(deliveryDate && !props.hideDate) &&
            <div>
                {format(
                    deliveryDate,
                    isThisYear(deliveryDate) ? "dd.MM." : "dd.MM.yyyy"
                )}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {distanceInDaysToNow(deliveryDate)}
            </div>
        }
    </div>;
}

/** Get distance in day accuracy (do not show minutes and hours but days, month, years) */
function distanceInDaysToNow(date: Date): string {
    if (isToday(date)) return "Heute";
    if (isYesterday(date)) return "Gestern";
    if (isTomorrow(date)) return "Morgen";
    return formatDistance(date, startOfDay(new Date()), {
        addSuffix: true,
        locale: deLocale
    });
}