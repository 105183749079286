import { css } from "@emotion/css";
import { useLocation } from "react-router-dom";
import { SimpleListSection } from "@/common/components/SimpleList/SimpleListSection";
import { ReactElement } from "react";
import { SimpleListItem } from "@/common/components/SimpleList/SimpleListItem";


interface SimpleListItemProps {
    title: string;
    description?: string | ReactElement;
    linkTo?: string;
    onClick?: () => void;
    isActive?: boolean;
}

export interface SimpleListCategory {
    name: string;
    items: SimpleListItemProps[];
}

interface Props {
    navData: SimpleListCategory[];
    width?: null | string | number;
    emptySectionContent?: ReactElement;
    disableSidePadding?: boolean;
}

export function SimpleList(props: Props) {
    const { pathname } = useLocation();

    const styles = {
        main: css({
            width: props.width || 250,
            display: "flex",
            flexDirection: "column",
            gap: 30
        }),
        emptySectionContent: css({
            padding: "8px 20px",
        })
    };

    return <div className={styles.main}>
        {props.navData.map((category, categoryIndex) => (
            <SimpleListSection
                key={categoryIndex}
                sectionName={category.name}
                disableSidePadding={props.disableSidePadding}
            >
                {category.items.map((item, itemIndex) => (
                    <SimpleListItem
                        key={itemIndex}
                        text={item.title}
                        description={item.description}
                        active={item.isActive || (!!item.linkTo && pathname.startsWith(item.linkTo))}
                        linkTo={item.linkTo}
                        onClick={item.onClick}
                        disableSidePadding={props.disableSidePadding}
                    />
                ))}

                {!category.items.length && (
                    <div className={styles.emptySectionContent}>
                        {props.emptySectionContent}
                    </div>
                )}
            </SimpleListSection>
        ))}
    </div>;
}